import { InputNumber, Row, Space, Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccGeneral, RhIcon, TempIcon } from "../../icons";
import { closeGraphAxisScaleModal, modalsState } from "../../state/modalsSlice";
import { selectTemperatureScale } from "../../state/sessionSlice";
import {
  selectGlobalGraphScale,
  setGlobalGraphAxisScaleAccCount,
  setGlobalGraphAxisScaleAccMax,
  setGlobalGraphAxisScaleAccMin,
  setGlobalGraphAxisScaleRhCount,
  setGlobalGraphAxisScaleRhMax,
  setGlobalGraphAxisScaleRhMin,
  setGlobalGraphAxisScaleTempCount,
  setGlobalGraphAxisScaleTempMax,
  setGlobalGraphAxisScaleTempMin,
  toggleGlobalGraphScaleAcc,
  toggleGlobalGraphScaleRh,
  toggleGlobalGraphScaleTemp
} from "../../state/persistantStateSlice";
import { PrimaryButton } from "../Common/CommonButtons";
import { ParamsCardSubHeader, SmallTitle } from "../Common/CommonFonts";
import {
  CelsiusToFahrenheit,
  FahrenheitToCelsius,
  TempScaleSymbol
} from "../MicroComponents/TemperatureConverter";
import { size } from "../../helpers/pageHelper";
import StandardModal from "../Common/StandardModal";

interface StandardRowObj {
  title: string;
  icon: JSX.Element;
  unit: string;
  tickCount: number;
  values: RowValues;
  actions: RowActions;
  toggle: boolean;
}

interface RowValues {
  min: number;
  max: number;
}

interface RowActions {
  toggle: (value: boolean) => void;
  setMin: (value: number) => void;
  setMax: (value: number) => void;
  setCount: (value: number) => void;
}

const GraphAxisScaleModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector(modalsState);

  const graphScale = useSelector(selectGlobalGraphScale);
  const tempScale = useSelector(selectTemperatureScale);

  const renderStandardRow = (obj: StandardRowObj) => (
    <Space direction="vertical">
      <Row
        justify="space-between"
        align="middle"
        style={{ paddingRight: size.s1 }}
      >
        <Space align="center">
          {obj.icon}
          <SmallTitle disabled={!obj.toggle}>{obj.title}</SmallTitle>
        </Space>
        <Switch
          size="small"
          defaultChecked={obj.toggle}
          onChange={(e) => obj.actions.toggle(Boolean(e))}
        />
      </Row>
      <Space size="large">
        {/* Min value */}
        <Space direction="vertical">
          <ParamsCardSubHeader
            disabled={!obj.toggle}
          >{`${t("generalMinTitle")} (${obj.unit})`}</ParamsCardSubHeader>
          <InputNumber
            disabled={!obj.toggle}
            defaultValue={obj.values.min}
            onChange={(e) => obj.actions.setMin(Number(e))}
          />
        </Space>

        {/* Max value */}
        <Space direction="vertical">
          <ParamsCardSubHeader
            disabled={!obj.toggle}
          >{`${t("generalMaxTitle")} (${obj.unit})`}</ParamsCardSubHeader>
          <InputNumber
            disabled={!obj.toggle}
            defaultValue={obj.values.max}
            onChange={(e) => obj.actions.setMax(Number(e))}
          />
        </Space>

        {/* Number of steps */}
        <Space direction="vertical">
          <ParamsCardSubHeader disabled={!obj.toggle}>
            {t("ScaleSteps")}
          </ParamsCardSubHeader>
          <InputNumber
            disabled={!obj.toggle}
            value={obj.tickCount}
            onChange={(e) => obj.actions.setCount(Number(e))}
            min={1}
          />
        </Space>
      </Space>
    </Space>
  );

  const iconStyle: React.CSSProperties = { fontSize: "2em" };

  const accRowObj: StandardRowObj = {
    title: t("generalAccTitle"),
    unit: "g",
    icon: graphScale.acc.toggle ? (
      <AccGeneral style={iconStyle} />
    ) : (
      <AccGeneral fill="gray" style={iconStyle} />
    ),
    tickCount: graphScale.acc.count,
    values: {
      min: graphScale.acc.min,
      max: graphScale.acc.max
    },
    toggle: graphScale.acc.toggle,
    actions: {
      toggle: () => dispatch(toggleGlobalGraphScaleAcc()),
      setMin: (v) => dispatch(setGlobalGraphAxisScaleAccMin(v)),
      setMax: (v) => dispatch(setGlobalGraphAxisScaleAccMax(v)),
      setCount: (v) => dispatch(setGlobalGraphAxisScaleAccCount(v))
    }
  };
  /** Round input number to 2 decimals */
  const round2Decimals = (v: number) => Math.round(v * 100) / 100;

  const celciusTempRowObj: StandardRowObj = {
    title: t("generalTempTitle"),
    unit: TempScaleSymbol(tempScale),
    icon: graphScale.temp.toggle ? (
      <TempIcon style={iconStyle} />
    ) : (
      <TempIcon fill="gray" style={iconStyle} />
    ),
    tickCount: graphScale.temp.count,
    values: {
      min: round2Decimals(graphScale.temp.min),
      max: round2Decimals(graphScale.temp.max)
    },
    toggle: graphScale.temp.toggle,
    actions: {
      toggle: () => dispatch(toggleGlobalGraphScaleTemp()),
      setMin: (v) => dispatch(setGlobalGraphAxisScaleTempMin(v)),
      setMax: (v) => dispatch(setGlobalGraphAxisScaleTempMax(v)),
      setCount: (v) => dispatch(setGlobalGraphAxisScaleTempCount(v))
    }
  };

  const fahrenheitTempRowObj: StandardRowObj = {
    title: t("generalTempTitle"),
    unit: TempScaleSymbol(tempScale),
    icon: graphScale.temp.toggle ? (
      <TempIcon style={iconStyle} />
    ) : (
      <TempIcon fill="gray" style={iconStyle} />
    ),
    tickCount: graphScale.temp.count,
    values: {
      min: round2Decimals(CelsiusToFahrenheit(graphScale.temp.min)),
      max: round2Decimals(CelsiusToFahrenheit(graphScale.temp.max))
    },
    toggle: graphScale.temp.toggle,
    actions: {
      toggle: () => dispatch(toggleGlobalGraphScaleTemp()),
      setMin: (v) =>
        dispatch(setGlobalGraphAxisScaleTempMin(FahrenheitToCelsius(v))),
      setMax: (v) =>
        dispatch(setGlobalGraphAxisScaleTempMax(FahrenheitToCelsius(v))),
      setCount: (v) => dispatch(setGlobalGraphAxisScaleTempCount(v))
    }
  };

  const rhRowObj: StandardRowObj = {
    title: t("generalRhTitle"),
    unit: "%Rh",
    icon: graphScale.rh.toggle ? (
      <RhIcon style={iconStyle} />
    ) : (
      <RhIcon fill="gray" style={iconStyle} />
    ),
    tickCount: graphScale.rh.count,
    values: {
      min: graphScale.rh.min,
      max: graphScale.rh.max
    },
    toggle: graphScale.rh.toggle,
    actions: {
      toggle: () => dispatch(toggleGlobalGraphScaleRh()),
      setMin: (v) => dispatch(setGlobalGraphAxisScaleRhMin(v)),
      setMax: (v) => dispatch(setGlobalGraphAxisScaleRhMax(v)),
      setCount: (v) => dispatch(setGlobalGraphAxisScaleRhCount(v))
    }
  };

  return (
    <StandardModal
      open={modals.isGraphAxisScaleModalOpen}
      title={t("SetGlobalScale")}
      width={400}
      closable={true}
      onCancel={() => dispatch(closeGraphAxisScaleModal())}
      footer={[
        <PrimaryButton
          key="ok"
          onClick={() => dispatch(closeGraphAxisScaleModal())}
        >
          {t("genClose")}
        </PrimaryButton>
      ]}
      zIndex={1045}
    >
      <Row justify="center" style={{ width: "100%" }}>
        <Space direction="vertical" size="large" align="start">
          {renderStandardRow(accRowObj)}
          {tempScale === "F" && renderStandardRow(fahrenheitTempRowObj)}
          {tempScale === "C" && renderStandardRow(celciusTempRowObj)}
          {renderStandardRow(rhRowObj)}
        </Space>
      </Row>
    </StandardModal>
  );
};

export default GraphAxisScaleModal;
