import { Col, InputNumber, Row, Space, Switch } from "antd";
import { isNull } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";

import { maxRhValue, minRhValue } from "../../constants/params-defaults";
import {
  selectRh,
  setRhHighAlarm,
  setRhIgnoreCount,
  setRhIntervalDays,
  setRhIntervalHours,
  setRhIntervalMinutes,
  setRhLowAlarm,
  toggleRh,
  toggleRhStoreGps,
  toggleRhUseLte
} from "../../state/openParxSlice";
import {
  ParamsCardParagraph,
  ParamsCardSubHeader
} from "../Common/CommonFonts";
import {
  CommonParamsInputCard,
  CommonParamsMainContentRow,
  SendGsmAlarmCard,
  willFitWidth2,
  willFitWidth3
} from "../Common/CommonParametersComponents";
import { size } from "../../helpers/pageHelper";

interface AvailableRhFeatures {
  hasGps: boolean;
  hasLte: boolean;
}

interface IProps {
  features: AvailableRhFeatures;
}

const ParamsBuilderRh: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const rhParams = useSelector(selectRh);
  const dispatch = useDispatch();
  const { width, ref } = useResizeDetector();
  const componentWidth = width ?? window.innerWidth;

  //the max interval for a period is exacly 28 days in ms. Therefore if 28 days
  //is set, hours and minututes can't be more than 0
  const maxHours = rhParams.intervalDays === 28 ? 0 : 23;
  const maxMinutes = rhParams.intervalDays === 28 ? 0 : 59;

  const mainToggle = rhParams.useRh;
  const storeGps = rhParams.channelBits.GPS ? true : false && mainToggle;
  const useLte = rhParams.channelBits.LTE ? true : false && mainToggle;
  const ignoreCount = rhParams.IgnoreCountLTE;

  /** Style used for input fields */
  const inputFieldStyle: React.CSSProperties = {
    margin: size.s2
  };

  const setLowAlarm = (val: number | null) => {
    if (isNull(val)) return;
    if (val < minRhValue) val = minRhValue;
    if (val > maxRhValue) val = maxRhValue;
    dispatch(setRhLowAlarm(val));
  };

  const setHighAlarm = (val: number | null) => {
    if (isNull(val)) return;
    if (val < minRhValue) val = minRhValue;
    if (val > maxRhValue) val = maxRhValue;
    dispatch(setRhHighAlarm(val));
  };

  /** If high alarm is lower than low alarm, set it to low */
  const syncHighToLow = () => {
    if (rhParams.params.lowAlarm > rhParams.params.highAlarm) {
      dispatch(setRhHighAlarm(rhParams.params.lowAlarm));
    }
  };

  /** If low alarm is higher than high alarm, set it to high */
  const syncLowToHigh = () => {
    if (rhParams.params.highAlarm < rhParams.params.lowAlarm) {
      dispatch(setRhLowAlarm(rhParams.params.highAlarm));
    }
  };

  // Render functions

  const renderMainToggle = () => (
    <Row align="middle" style={{ marginBottom: size.s2 }}>
      <Space>
        <Switch checked={mainToggle} onChange={() => dispatch(toggleRh())} />
        <ParamsCardSubHeader>{t("cpRhMainToggleTitle")}</ParamsCardSubHeader>
      </Space>
    </Row>
  );

  const renderLogInterval = (withDescription: boolean) => (
    <CommonParamsInputCard
      title={t("cpRhLogIntervalTitle")}
      width={withDescription ? 2 : 1}
      height={withDescription ? 1 : 2}
    >
      <Row justify="space-between">
        <Col>
          <ParamsCardParagraph>
            {t("cpRhLogIntervalContent")}
          </ParamsCardParagraph>
        </Col>
        <div style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genDays")}</ParamsCardSubHeader>
            </Col>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genHours")}</ParamsCardSubHeader>
            </Col>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genMinutes")}</ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input-fields */}
          <Row>
            <Col span={8}>
              <InputNumber
                disabled={!mainToggle}
                value={rhParams.intervalDays}
                onChange={(val) =>
                  isNull(val) ? {} : dispatch(setRhIntervalDays(Number(val)))
                }
                min={0}
                max={28}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                disabled={!mainToggle}
                value={rhParams.intervalHours}
                onChange={(val) =>
                  isNull(val) ? {} : dispatch(setRhIntervalHours(Number(val)))
                }
                min={0}
                max={maxHours}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                disabled={!mainToggle}
                value={rhParams.intervalMinutes}
                onChange={(val) =>
                  isNull(val) ? {} : dispatch(setRhIntervalMinutes(Number(val)))
                }
                min={0}
                max={maxMinutes}
                style={{ ...inputFieldStyle, marginRight: 0 }}
              />
            </Col>
          </Row>
        </div>
      </Row>
    </CommonParamsInputCard>
  );

  const renderStoreGps = () => (
    <CommonParamsInputCard
      title={t("cpRhStoreGpsPosTitle")}
      disabled={!props.features.hasGps}
      width={1}
      height={1}
      useToggle
      toggleChecked={storeGps && props.features.hasGps}
      toggleDisabled={!mainToggle}
      toggleChangeAction={() => dispatch(toggleRhStoreGps())}
    >
      <ParamsCardParagraph disabled={!props.features.hasGps}>
        {t("cpRhStoreGpsPosContent")}
      </ParamsCardParagraph>
    </CommonParamsInputCard>
  );

  const renderAlarmLevel = (withDescription: boolean) => (
    <CommonParamsInputCard
      title={t("cpRhAlarmLevelTitle")}
      width={withDescription ? 2 : 1}
      height={2}
    >
      <Row justify="space-between" wrap>
        <Col flex="auto">
          <ParamsCardParagraph>
            {t("cpRhAlarmLevelContent")}
          </ParamsCardParagraph>
        </Col>
        <Col style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={12}>
              <ParamsCardSubHeader>{`${t("generalMinTitle")} (%)`}</ParamsCardSubHeader>
            </Col>
            <Col span={12}>
              <ParamsCardSubHeader>{`${t("generalMaxTitle")} (%)`}</ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input fields */}
          <Row>
            <Col span={12}>
              <InputNumber
                disabled={!mainToggle}
                value={rhParams.params.lowAlarm}
                onChange={(val) => setLowAlarm(val)}
                onBlur={() => syncHighToLow()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                disabled={!mainToggle}
                value={rhParams.params.highAlarm}
                onChange={(val) => setHighAlarm(val)}
                onBlur={() => syncLowToHigh()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );

  const renderGsmAlarm = () => (
    <SendGsmAlarmCard
      hasLte={props.features.hasLte}
      sendGsmTextContent={t("cpRhSendGsmAlarmContent")}
      isParentParamsActive={mainToggle}
      useLte={useLte}
      toggleUseLte={() => dispatch(toggleRhUseLte())}
      ignoreCount={ignoreCount}
      setIgnoreCount={(v: number) => dispatch(setRhIgnoreCount(v))}
    />
  );

  return (
    <div ref={ref}>
      {renderMainToggle()}
      <CommonParamsMainContentRow>
        {/* logging interval content */}
        <Col order={1}>{renderLogInterval(!willFitWidth2(componentWidth))}</Col>

        {/* store gps position content. Order explanation: if small screen size, put element below "alarm" */}
        <Col order={willFitWidth3(componentWidth) ? 3 : 2}>
          {renderStoreGps()}
        </Col>

        {/* alarm level content */}
        <Col order={willFitWidth3(componentWidth) ? 2 : 3}>
          {renderAlarmLevel(!willFitWidth2(componentWidth))}
        </Col>

        {/* send gsm alarm content */}
        <Col order={4}>{renderGsmAlarm()}</Col>
      </CommonParamsMainContentRow>
    </div>
  );
};

export default ParamsBuilderRh;
