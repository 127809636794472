import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";

type CtrlOrAlt = "Control" | "Alt" | "Control+Alt";

interface KeyEvent {
  ctrlKey: boolean;
  altKey: boolean;
  shiftKey: boolean;
  key: string;
}

/**
 * A keyboard shortcut function
 * @param keys
 * @param callback the action/function to be executed
 * @param ctrlOrAlt Wether to use ctrl, alt or both keys in combination with selected key
 */
const useKeyPress = (keys: string[], callback: any, ctrlOrAlt: CtrlOrAlt) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event: KeyEvent) => {
      if (
        ctrlOrAlt === "Control" &&
        event.ctrlKey === true &&
        event.altKey === false &&
        event.shiftKey === false &&
        keys.some((key) => event.key.toLowerCase() === key)
      ) {
        // Executes the desired action
        callbackRef.current(event);
      }

      if (
        ctrlOrAlt === "Alt" &&
        event.altKey === true &&
        event.ctrlKey === false &&
        event.shiftKey === false &&
        keys.some((key) => event.key.toLowerCase() === key)
      ) {
        callbackRef.current(event);
      }

      if (
        ctrlOrAlt === "Control+Alt" &&
        event.altKey === true &&
        event.ctrlKey === true &&
        event.shiftKey === false &&
        keys.some((key) => event.key.toLowerCase() === key)
      ) {
        callbackRef.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    // remove event listener
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);
};

export default useKeyPress;
