import React, { useRef } from "react";
import { Row } from "antd";
import { NormalButton } from "../Common/CommonButtons";
import { isNull } from "lodash-es";
import useKeyPress from "../../helpers/useKeyPress";
import { size } from "../../helpers/pageHelper";

interface IOpenFileProps {
  accept: string;
  onSelectFile: (e: any) => void;
  buttonLabel: string;
}

const OpenFileButton = (props: IOpenFileProps): React.JSX.Element => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const openLocalFile = () => {
    if (!isNull(inputRef?.current)) {
      inputRef.current.value = "";
      inputRef.current.click();
    }
  };

  useKeyPress(["o"], openLocalFile, "Control");

  return (
    <>
      <input
        type="file"
        id="file"
        accept={props.accept}
        ref={inputRef}
        onInput={props.onSelectFile}
        style={{ display: "none" }}
      />
      <Row justify="center" style={{ padding: size.s2 }}>
        <NormalButton onClick={openLocalFile}>{props.buttonLabel}</NormalButton>
      </Row>
    </>
  );
};

export default OpenFileButton;
