import React from "react";
import { Card, Row, Col, Select } from "antd";
import { size } from "../../helpers/pageHelper";
import { SmallText } from "../Common/CommonFonts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectMySpeed, setSpeedUnit } from "../../state/sessionSlice";
const { Option } = Select;

const GpsSpeedCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectAndButtonWidth = 190;
  const speedUnit = useSelector(selectMySpeed);

  return (
    <Card style={{ marginBottom: size.m1 }}>
      <Row style={{ marginBottom: size.m1 }}>
        <SmallText strong>{t("Speed")}</SmallText>
      </Row>
      <Row align="middle" style={{ paddingLeft: size.m1 }}>
        <Col span={12}>
          <SmallText>{t("SelectYourSpeedUnit")}: </SmallText>
        </Col>
        <Col>
          <Select
            style={{ minWidth: selectAndButtonWidth, textAlign: "center" }}
            defaultValue={speedUnit}
            onChange={(v) => {
              dispatch(setSpeedUnit(v));
            }}
            // Keeps Dropdown in place when scrolling
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            <Option value="kmph">{t("kmph")}</Option>
            <Option value="mph">{t("mph")}</Option>
          </Select>
        </Col>
      </Row>
    </Card>
  );
};

export default GpsSpeedCard;
