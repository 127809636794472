import * as React from "react";
import type { SVGProps } from "react";
const SvgExtRhTempIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 237.07 237.07"
    {...props}
  >
    <path
      d="M152.79 13.132c-9.292.355-49.555 61.261-62.932 117.62 9.592 5.428 17.34 14.011 21.929 24.021 6.588 14 6.347 30.888-.297 44.82-1.889 4.091-4.34 7.895-7.232 11.329 10.616 8.658 26.316 13.771 48.531 13.735 142.33-.22 17.321-212.2 0-211.53z"
      fill={props.fill === undefined ? "#3953a4" : props.fill}
    />
    <path
      d="M64.517 12.414c-9.197-.211-16.92 6.875-17.501 16.055v105.01c-18.027 6.83-29.97 24.077-30.022 43.355a46.5 46.5 0 0 0 13.619 32.878 46.495 46.495 0 0 0 65.758 0 46.5 46.5 0 0 0 13.618-32.878c-.006-19.317-11.958-36.62-30.022-43.465V27.979a16.78 16.78 0 0 0-15.452-15.57zm-.424 6.828a9.824 9.824 0 0 1 9.047 9.118v51.715H53.844v-51.43a10.04 10.04 0 0 1 10.249-9.403z"
      fill={props.fill === undefined ? "#ee2424" : props.fill}
    />
  </svg>
);
export default SvgExtRhTempIcon;
