import React from "react";
import {
  CompareStateZoomDomain,
  ICompareGraph
} from "../../state/compareGraphsSlice";
import CombinedGraph from "./CombinedGraph";

interface IProps {
  allOpenFiles: ICompareGraph[];
  width: number;
  height: number;
  handleSelectedDomain: (domain: CompareStateZoomDomain | undefined) => void;
}
const CompareGraphsCombined: React.FC<IProps> = (props) => {
  // get all open files from props
  const { allOpenFiles, width, height } = props;

  return (
    <CombinedGraph
      data={allOpenFiles}
      width={width}
      height={height}
      handleSelectedDomain={props.handleSelectedDomain}
    />
  );
};

export default React.memo(CompareGraphsCombined);
