import React from "react";

interface IProps {
  children: React.ReactNode;
}
const ResetPanelBodyPadding = (props: IProps) => {
  return <div style={{ margin: "-12px -16px 0" }}>{props.children}</div>;
};

export default ResetPanelBodyPadding;
