import { Buffer } from "buffer";
import { DT_DVA, DT_DVA_End, DT_DVA_Start } from "../../models/FAT100DataTypes";
import { KIND_DVADATA } from "../../models/FAT100Kinds";
import IDate from "../../models/IDate";
import { buf2IDate } from "../dataModelHelper";

export type IDvaData = (IDtDva | { start: IDate } | { end: IDate })[];

export interface IDtDva {
  xAlarm: number;
  yAlarm: number;
  zAlarm: number;
}

export const parseDvaData = (buf: Buffer): IDvaData => {
  const data: IDvaData = [];
  let bufferPos = 0;

  const shouldBeKindDva = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  if (shouldBeKindDva !== KIND_DVADATA) {
    throw new Error(
      "wrong kind, expected kind_dvadata but got: " + shouldBeKindDva
    );
  }

  const dvaDataLength = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  if (dvaDataLength !== buf.length - 4) {
    throw new Error("wrong length for dva data");
  }

  while (bufferPos < buf.length) {
    const id = buf.readUInt8(bufferPos);
    bufferPos += 1;

    switch (id) {
      case DT_DVA: {
        const length = buf.readUInt8(bufferPos);
        bufferPos += 1;

        /** divisor for conversation to correct number of decimals */
        const divisor = 100;

        const xAlarm = buf.readInt16LE(bufferPos) / divisor;
        bufferPos += 2;

        const yAlarm = buf.readInt16LE(bufferPos) / divisor;
        bufferPos += 2;

        const zAlarm = buf.readInt16LE(bufferPos) / divisor;
        bufferPos += 2;

        data.push({ xAlarm, yAlarm, zAlarm });
        break;
      }

      case DT_DVA_Start: {
        const length = buf.readUInt8(bufferPos);
        bufferPos += 1;

        const timestampBuf = buf.subarray(bufferPos, bufferPos + length);
        bufferPos += length;

        const start = buf2IDate(timestampBuf);

        data.push({ start });
        break;
      }

      case DT_DVA_End: {
        const length = buf.readUInt8(bufferPos);
        bufferPos += 1;

        const timestampBuf = buf.subarray(bufferPos, bufferPos + length);
        bufferPos += length;

        const end = buf2IDate(timestampBuf);

        data.push({ end });
        break;
      }

      default:
        break;
    }
  }

  return data;
};
