import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cartItemType } from "../components/AdminPage/LicenseStore";
import { StoreApi } from "./store";

export interface licenseAndPriceId {
  licenseId: string;
  licensePriceId: string;
}

/** Main interface for this state-slice */
export interface LicenseStoreState {
  isCartDrawerOpen: boolean;
  isCheckoutModalOpen: boolean;
  isOrderDeliveredModalOpen: boolean;

  cartState: cartItemType[];
  totalCartPrice: number;
  months: number;
  isProjectLicense: boolean;

  isRenewalModalOpen: boolean;
  renewalCart: licenseAndPriceId[];
  renewalMonths: number;
}

/** Initial state for this slice */
const initialState: LicenseStoreState = {
  isCartDrawerOpen: false,
  isCheckoutModalOpen: false,
  isOrderDeliveredModalOpen: false,

  cartState: [],
  totalCartPrice: 0,
  months: 3,
  isProjectLicense: false,

  isRenewalModalOpen: false,
  renewalCart: [],
  renewalMonths: 3
};

export const slice = createSlice({
  name: "Store",
  initialState,
  reducers: {
    closeAllStoreModals: (state) => {
      if (state.isOrderDeliveredModalOpen === true) {
        state.cartState = [];
      }
      state.isCartDrawerOpen = false;
      state.isCheckoutModalOpen = false;
      state.isOrderDeliveredModalOpen = false;
    },

    openCartDrawer: (state) => {
      state.isCartDrawerOpen = true;
    },
    closeCartDrawer: (state) => {
      state.isCartDrawerOpen = false;
    },

    openCheckoutModal: (state) => {
      state.isCheckoutModalOpen = true;
    },
    closeCheckoutModal: (state) => {
      state.isCheckoutModalOpen = false;
    },

    openOrderDeliveredModal: (state) => {
      state.isOrderDeliveredModalOpen = true;
    },
    closeOrderDeliveredModal: (state) => {
      state.isOrderDeliveredModalOpen = false;
    },

    setCartState: (state, action: PayloadAction<Array<cartItemType>>) => {
      state.cartState = action.payload;
    },

    addToCartState: (state, action: PayloadAction<cartItemType>) => {
      const newItem = action.payload;
      let foundItem = false;
      state.cartState.forEach((item) => {
        if (
          item.licensePriceId === newItem.licensePriceId &&
          item.months === newItem.months
        ) {
          item.quantity++;
          foundItem = true;
        }
      });
      if (!foundItem) {
        state.cartState.push(action.payload);
      }
    },

    removeFromCartState: (state, action: PayloadAction<cartItemType>) => {
      const newItem = action.payload;
      state.cartState.forEach((item) => {
        if (
          item.licensePriceId === newItem.licensePriceId &&
          item.months === newItem.months
        ) {
          if (item.quantity === 1) {
            state.cartState = state.cartState.filter(
              (existingItem) => existingItem !== item
            );
          } else {
            item.quantity--;
          }
        }
      });
    },

    calculateTotalCartPrice: (state) => {
      let totalPrice = 0;
      if (state.cartState) {
        state.cartState.forEach((item) => {
          const discountPercent = item.companyDiscountPercent / 100;
          totalPrice +=
            item.quantity *
            item.months *
            (item.price - item.price * discountPercent);
        });
      }
      state.totalCartPrice = totalPrice;
    },

    setMonths: (state, action: PayloadAction<number>) => {
      state.months = action.payload;
    },

    toggleIsProjectLicense: (state) => {
      state.isProjectLicense = !state.isProjectLicense;
    },
    setIsProjectLicense: (state, action: PayloadAction<boolean>) => {
      state.isProjectLicense = action.payload;
    },

    openRenewalModal: (state) => {
      state.isRenewalModalOpen = true;
    },
    closeRenewalModal: (state) => {
      state.isRenewalModalOpen = false;
    },

    setRenewalCart: (
      state,
      action: PayloadAction<Array<licenseAndPriceId>>
    ) => {
      state.renewalCart = action.payload;
    },

    addToRenewalCart: (state, action: PayloadAction<licenseAndPriceId>) => {
      let foundItem = false;
      state.renewalCart.forEach((item) => {
        if (item.licenseId === action.payload.licenseId) {
          foundItem = true;
        }
      });
      if (!foundItem) {
        state.renewalCart = [...state.renewalCart, action.payload];
      }
    },

    removeFromRenewalCart: (state, action: PayloadAction<string>) => {
      state.renewalCart = state.renewalCart.filter(
        (item) => item.licenseId !== action.payload
      );
    },

    setRenewalMonths: (state, action: PayloadAction<number>) => {
      state.renewalMonths = action.payload;
    }
  }
});

export const {
  closeAllStoreModals,

  openCartDrawer,
  closeCartDrawer,
  openOrderDeliveredModal,
  closeOrderDeliveredModal,

  openCheckoutModal,
  closeCheckoutModal,

  calculateTotalCartPrice,

  setCartState,
  addToCartState,
  removeFromCartState,

  setMonths,

  toggleIsProjectLicense,
  setIsProjectLicense,

  openRenewalModal,
  closeRenewalModal,
  setRenewalCart,
  addToRenewalCart,
  removeFromRenewalCart,
  setRenewalMonths
} = slice.actions;

export const licenseStoreState = (state: StoreApi) => state.licenseStore;

export default slice.reducer;
