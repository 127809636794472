import React from "react";
import { Table, Typography } from "antd";
import { ProfileOutlined } from "@ant-design/icons";
import { LandingCard } from "./Landing";
import dayjs from "dayjs";
import { ColumnsType } from "antd/lib/table";
import { useNavigate } from "react-router";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { useTranslation } from "react-i18next";
import { useGetRecentDeviceQuery } from "../../state/cargologRestApi";
import { calcNextCalibration } from "../../components/DevicesPage/MyDevicesList";
const { Text } = Typography;

interface ITableData {
  serialNumber: string;
  nextCalibration: NextCalibration;
  key: string;
}

interface NextCalibration {
  date: string;
  datePassed: boolean;
}

const CalibrationLandingCard = () => {
  const { t } = useTranslation();
  const { data: myDevices, isLoading } = useGetRecentDeviceQuery();

  const columns: ColumnsType<ITableData> = [
    {
      title: t("SerialNumber"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: "50%"
    },
    {
      title: t("myDevicesTableNextCalibrationTitle"),
      dataIndex: "nextCalibration",
      key: "nextCalibration",
      width: "50%",
      render: (data: NextCalibration) => (
        <Text type={data.datePassed ? "danger" : undefined}>{data.date}</Text>
      )
    }
  ];

  const recentDevices: ITableData[] = myDevices
    ? myDevices
        .filter(
          (device) =>
            dayjs().diff(
              calcNextCalibration(device.systemInfo.lastCalibration),
              "months"
            ) > -3
        )
        .map((item, index) => {
          const datePassed =
            dayjs().diff(
              calcNextCalibration(item.systemInfo.lastCalibration),
              "months"
            ) > 0
              ? true
              : dayjs().diff(
                    calcNextCalibration(item.systemInfo.lastCalibration),
                    "months"
                  ) === 0
                ? dayjs().diff(
                    calcNextCalibration(item.systemInfo.lastCalibration),
                    "days"
                  ) > 0
                  ? true
                  : false
                : false;

          const device: ITableData = {
            serialNumber: item.systemInfo.serial.toString(),
            nextCalibration: {
              date: calcNextCalibration(item.systemInfo.lastCalibration),
              datePassed
            },
            key: index.toString()
          };

          return device;
        })
        .sort(
          (a, b) =>
            a.nextCalibration.date.localeCompare(b.nextCalibration.date) ||
            a.serialNumber.localeCompare(b.serialNumber)
        )
    : [];

  const navigate = useNavigate();

  return (
    <>
      {LicenseAccess(
        "ABA",
        <LandingCard
          key="nextCalibration"
          title={
            <LargeHeaderTitle>
              <ProfileOutlined /> {t("NextCalibrationWithin3Months")}
            </LargeHeaderTitle>
          }
          extra={
            <NormalButtonSmall
              onClick={() => {
                navigate("/devices");
              }}
            >
              {t("Manage")}
            </NormalButtonSmall>
          }
        >
          {/* List devices with next calibration within 3 months */}
          <Table
            columns={columns}
            dataSource={recentDevices}
            loading={isLoading}
            style={{ width: "100%", height: 118 }}
            size="small"
            scroll={{ y: recentDevices.length === 0 ? undefined : 118 }}
            pagination={false}
          />
        </LandingCard>
      )}
    </>
  );
};

export default CalibrationLandingCard;
