import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeViewUserStatisticsModal,
  modalsState
} from "../../state/modalsSlice";
import StandardModal from "../Common/StandardModal";
import { Row, Table, Typography } from "antd";
import { NormalButton } from "../Common/CommonButtons";
import { UserWithStatisticAndLicenses } from "../MobitronAdminPage/MobitronAdminUsers";
import { isNil, size } from "lodash-es";
import { LicenseWithCost, UserStatistic } from "../../state/cargologRestApi";
import dayjs from "dayjs";
const { Text, Title } = Typography;

interface LicenseWithDaysLeft extends LicenseWithCost {
  daysLeft: number | string;
  projectLicense: boolean;
}

const ViewUserStatisticsModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const emptyUser: UserWithStatisticAndLicenses = {
    userId: "",
    firstName: "",
    lastName: "",
    userLicenses: [],
    userStatistic: {
      webVersion: "",
      webStarts: 0,
      winProdVersion: "",
      winProdStarts: 0,
      winDevVersion: "",
      winDevStarts: 0
    }
  };

  const timezoneOffset = new Date().getTimezoneOffset();

  const modals = useSelector(modalsState);
  const selectedUser = modals.setUserForStatistics;
  const user = selectedUser ?? emptyUser;
  const { userLicenses, userStatistic } = user;

  const statisticsTableData: UserStatistic[] = [];
  if (size(userStatistic) > 0) {
    statisticsTableData.push(userStatistic);
  }

  const licensesTableData: LicenseWithDaysLeft[] = [];
  if (size(userLicenses) > 0) {
    userLicenses.forEach((license) => {
      const endDate = new Date(license.endDate);
      const now = new Date();
      const daysLeft = Math.round(
        (endDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
      );

      const licenseWithDays: LicenseWithDaysLeft = {
        ...license,
        projectLicense: !isNil(license.projectId),
        daysLeft: daysLeft < 0 ? t("Expired") : daysLeft //TODO: Translate
      };

      licensesTableData.push(licenseWithDays);
    });
  }

  const stringToDate = (text: string) => {
    return dayjs
      .utc(text)
      .subtract(timezoneOffset, "minutes")
      .format("YYYY-MM-DD HH:mm");
  };

  const boolToText = (bool: boolean) => {
    return <Text>{bool ? "Yes" : "No"}</Text>;
  };

  const sortFunction = (a: any, b: any) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const renderStatisticTableColumn = (
    title: string,
    versionTexts: string,
    startsTexts: string
  ) => {
    return (
      <Table.ColumnGroup title={title}>
        <Table.Column
          title={t("Version")} // TODO: Translate
          dataIndex={versionTexts}
          key={versionTexts}
        />
        <Table.Column
          title={t("Starts")} //TODO: Translate
          dataIndex={startsTexts}
          key={startsTexts}
        />
      </Table.ColumnGroup>
    );
  };

  return (
    <>
      <StandardModal
        title={t("StatisticsFor") + " " + user.firstName + " " + user.lastName}
        open={modals.isViewUserStatisticsModalOpen}
        onCancel={() => dispatch(closeViewUserStatisticsModal())}
        closable={true}
        zIndex={1045}
        width={950}
        destroyOnClose={true}
        centered={true}
        footer={[
          <NormalButton
            key="cancel"
            onClick={() => dispatch(closeViewUserStatisticsModal())}
          >
            {t("Close")}
          </NormalButton>
        ]}
      >
        <Row>
          <Title level={5}>{t("Application")}</Title>
        </Row>

        <Table<UserStatistic>
          dataSource={statisticsTableData}
          pagination={false}
        >
          {renderStatisticTableColumn("Web", "webVersion", "webStarts")}
          {renderStatisticTableColumn(
            "Win Prod",
            "winProdVersion",
            "winProdStarts"
          )}
          {renderStatisticTableColumn(
            "Win Dev",
            "winDevVersion",
            "winDevStarts"
          )}
        </Table>

        <Row>
          <Title level={5}>
            {t("Licenses")} ({user.userLicenses.length})
          </Title>
        </Row>

        <Table<LicenseWithDaysLeft>
          dataSource={licensesTableData}
          pagination={false}
          scroll={{ y: 300 }}
        >
          <Table.Column<LicenseWithDaysLeft>
            title={t("License")}
            dataIndex="text"
            key="text"
            sorter={(a, b) => sortFunction(a.text, b.text)}
            render={(text) => <Text>{text}</Text>}
          />
          <Table.Column<LicenseWithDaysLeft>
            title={t("Created")}
            dataIndex="created"
            key="created"
            sorter={(a, b) => sortFunction(a.created, b.created)}
            render={(text) => stringToDate(text)}
          />
          <Table.Column<LicenseWithDaysLeft>
            title={t("endDate")}
            dataIndex="endDate"
            key="endDate"
            sorter={(a, b) => sortFunction(a.endDate, b.endDate)}
            render={(text) => stringToDate(text)}
          />

          <Table.Column<LicenseWithDaysLeft>
            title={t("DaysLeft")}
            dataIndex="daysLeft"
            key="daysLeft"
            sorter={(a, b) => sortFunction(a.daysLeft, b.daysLeft)}
            render={(text) => <Text>{text}</Text>}
          />

          <Table.Column<LicenseWithDaysLeft>
            title={t("ProjectLicense")}
            dataIndex="projectLicense"
            key="projectLicense"
            sorter={(a, b) => sortFunction(a.projectLicense, b.projectLicense)}
            render={(bool) => boolToText(bool)}
          />

          <Table.Column
            title={t("Renews")}
            dataIndex="renewAuto"
            key="renewAuto"
            render={(text) => boolToText(text)}
          />

          <Table.Column
            title={t("Activated")}
            dataIndex="isActivated"
            key="isActivated"
            render={(text) => boolToText(text)}
          />
        </Table>
      </StandardModal>
    </>
  );
};

export default ViewUserStatisticsModal;
