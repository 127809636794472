import * as React from "react";
import type { SVGProps } from "react";
const SvgRhIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 141.73 141.73"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M89.59 4.35c-2.482.094-9.536 9.157-16.443 21.816a159 159 0 0 1 3.52 5.554c7.066 11.716 14.548 27.13 19.225 42.97 3.048 10.326 5.03 20.89 4.49 30.862 51.083-10.737-2.91-101.5-10.791-101.2zM54.819 16.975c-9.827.375-80.742 120.13 0 120 80.742-.125 9.827-120.38 0-120m15.143 54.352c1.18-.11 1.836.59.086 3.39-2.62 4.19-5.241 9.091-7.691 13.461l-18.65 33.05c-.37.35-6.67 3.68-3-2.09 2.63-4.2 5.081-9.1 7.521-13.3 4.86-8.91 9.93-17.66 15-26.4 1.05-1.93 2.5-4.9 3.5-6.65.35-.435 2.054-1.352 3.234-1.46zm-29.943.15c5.456.058 10.745 4.558 10.988 13.9.52 20.99-22.21 21.691-23.08 2.451-.489-10.774 5.909-16.417 12.092-16.352zm-.426 4.033q-.4.006-.826.078c-8.25 1.36-7.69 23.39 1.75 22 7.285-1.152 7.344-22.192-.924-22.078zm20.844 9.697 2.09 2.27zm10.98 6.516c6.423-.252 12.815 5.821 11.24 18.314-2.44 18.54-26.05 14.521-22.55-6.289 1.299-7.805 6.314-11.829 11.31-12.025zm.192 3.893c-1.809-.015-3.585 1.168-4.862 3.232-2.97 4.9-2.8 20.061 5.42 19.061 6.82-1.05 6.65-15.74 3.5-19.94-1.225-1.607-2.652-2.341-4.058-2.353z"
      fill={props.fill === undefined ? "#3953a4" : props.fill}
    />
  </svg>
);
export default SvgRhIcon;
