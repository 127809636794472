import React from "react";
import { Dropdown, MenuProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { newRecordingParameters } from "../../state/openParxSlice";
import { useNavigate } from "react-router";
import {
  setActiveParametersTab,
  setParamsBasedOn,
  setParamsUserProgress
} from "../../state/paramsPageSlice";
import { useTranslation } from "react-i18next";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import { MenuButton } from "../Common/CommonButtons";
import ParamsPreviewModal from "../Modals/ParamsPreviewModal";
import SystemInfoModal from "../Modals/SystemInfoModal";
import {
  openParamsPreviewModal,
  openSystemInfoModal
} from "../../state/modalsSlice";
import useKeyPress from "../../helpers/useKeyPress";
import { cleanParams } from "../../helpers/dataModelHelper";
import { getUser } from "../../state/sessionSlice";
import { getAppVersion } from "../../helpers/versionHelper";
import { insertIf } from "../../utils/generalUtils";
import { FatIcon } from "../../icons";
import isUndefined from "lodash-es/isUndefined";

interface IProps {
  recParams?: VMRecordingParameters;
  systemInfo?: GeneralSystemInfo;
  fileName?: string;
}
const DashboardHeaderExtraInfoMenu: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { companyId } = useSelector(getUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const windowWidth = window.innerWidth;

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "viewParams":
        dispatch(openParamsPreviewModal());
        break;
      case "viewDeviceInfo":
        dispatch(openSystemInfoModal());
        break;
      case "edit":
        handleUseParamsForNewDevice();
        break;
      default:
        break;
    }
  };

  const handleUseParamsForNewDevice = () => {
    const neutralParams = cleanParams(
      props.recParams!,
      getAppVersion(),
      companyId
    );
    dispatch(newRecordingParameters(neutralParams));
    dispatch(setParamsUserProgress(1));
    dispatch(setActiveParametersTab("freeText"));
    dispatch(setParamsBasedOn(props.fileName ?? "DATX file"));

    //navigate to the view where the user can choose a recording period
    navigate("/params");
  };

  const onKeyPressStatus = () => {
    dispatch(openSystemInfoModal());
  };
  const onKeyPressView = () => {
    dispatch(openParamsPreviewModal());
  };
  const onKeyPressEdit = () => {
    handleUseParamsForNewDevice();
  };

  useKeyPress(["s"], onKeyPressStatus, "Control+Alt");
  useKeyPress(
    [CheckUserRightsAccess("ACF") ? "i" : ""],
    onKeyPressView,
    "Control"
  );
  useKeyPress(
    [CheckUserRightsAccess("ADE") ? "e" : ""],
    onKeyPressEdit,
    "Control"
  );

  if (!props.recParams) {
    return <div />;
  }

  const items: MenuProps["items"] = [
    {
      label: t("recInfoViewDeviceInfo"),
      key: "viewDeviceInfo",
      disabled: isUndefined(props.systemInfo),
      onClick: (e) => handleMenuClick(e)
    },
    ...insertIf(CheckUserRightsAccess("ACF"), {
      label: t("recInfoViewParams"),
      key: "viewParams",
      disabled: isUndefined(props.systemInfo) || isUndefined(props.recParams),
      onClick: (e: any) => handleMenuClick(e)
    }),
    ...insertIf(CheckUserRightsAccess("ADE"), {
      label: t("EditParameters"),
      disabled: isUndefined(props.recParams),
      key: "edit",
      onClick: (e: any) => handleMenuClick(e)
    })
  ];

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <MenuButton
          icon={<FatIcon style={{ transform: "translateY(12%)" }} />}
          styles={{ icon: { marginRight: windowWidth > 990 ? 8 : 0 } }}
        >
          {windowWidth > 990 ? t("Device") : undefined}
        </MenuButton>
      </Dropdown>

      {/* modals */}
      {/* view parameters modal */}
      <ParamsPreviewModal
        recParams={props.recParams!}
        systemInfo={props.systemInfo}
        fileName={props.fileName}
      />
      {/* view system info modal */}
      {props.systemInfo && <SystemInfoModal systemInfo={props.systemInfo} />}
    </>
  );
};

export default DashboardHeaderExtraInfoMenu;
