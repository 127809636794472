import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreApi } from "./store";

export type UpdateStatus =
  | "notDownloaded"
  | "available"
  | "downloading"
  | "downloaded";

/** Main interface for this state-slice */
export interface UpdatesState {
  status: UpdateStatus;
  percent: number;
}

/** Initial state for this slice */
const initialState: UpdatesState = {
  status: "notDownloaded",
  percent: 0
};

export const slice = createSlice({
  name: "Updates",
  initialState,
  reducers: {
    setUpdateStatus: (state, action: PayloadAction<UpdateStatus>) => {
      state.status = action.payload;
    },
    setUpdatePercent: (state, action: PayloadAction<number>) => {
      state.percent = action.payload;
    }
  }
});

export const { setUpdateStatus, setUpdatePercent } = slice.actions;

export const selectUpdates = (state: StoreApi) => state.updates;

export default slice.reducer;
