import React from "react";
import { useTranslation } from "react-i18next";
import { ExtInputIcon, ExtOutputIcon } from "../../icons";
import { ExternalIOGraphData, ExternalIO } from "./ExternalIOGraph";
import { Optional } from "../../utils/utilTypes";
import { Table } from "antd";
import dayjs from "dayjs";
import { isNil } from "lodash-es";
import { size } from "../../helpers/pageHelper";

/** Data type for External IO symbols in status graph */
export interface ExternalIOTableData {
  key: number;
  type: "input" | "output";
  timestamp: Date;
  channel: string;
  state: boolean;
}

interface IProps {
  data: ExternalIOGraphData;
  timezone: string;
}

/**
 * Helper function that returns one data point for each changed IO
 * @param data
 * @param domain
 */
export const createTableData = (data: Optional<ExternalIOGraphData>) => {
  if (isNil(data)) {
    return undefined;
  }

  const {
    externalInputs,
    externalOutputs,
    externalInputParams,
    externalOutputParams
  } = data;

  const usedInputParams = externalInputParams?.filter((param) => param.used);
  const inputParams =
    usedInputParams && usedInputParams.length > 0
      ? usedInputParams
      : externalInputParams;

  const usedOutputParams = externalOutputParams?.filter((param) => param.used);
  const outputParams =
    usedOutputParams && usedOutputParams.length > 0
      ? usedOutputParams
      : externalOutputParams;

  let pointsData: ExternalIOTableData[] = [];

  if (externalInputs) {
    externalInputs.forEach((item: ExternalIO) => {
      inputParams?.forEach((param) => {
        if (item.data.changed[param.inputNumber]) {
          pointsData.push({
            key: 0,
            type: "input",
            timestamp: item.x,
            channel: param.description,
            state: item.data.state[param.inputNumber]
          });
        }
      });
    });
  }

  if (externalOutputs) {
    externalOutputs.forEach((item: ExternalIO) => {
      outputParams?.forEach((param) => {
        if (item.data.changed[param.outputNumber]) {
          pointsData.push({
            key: 0,
            type: "output",
            timestamp: item.x,
            channel: param.description,
            state: item.data.state[param.outputNumber]
          });
        }
      });
    });
  }

  // sort by timestamp
  pointsData = pointsData.sort((a, b) => {
    return a.timestamp.getTime() - b.timestamp.getTime();
  });

  // add key to all data points
  pointsData = pointsData.map((item, index) => {
    return { ...item, key: index };
  });

  return pointsData;
};

const ExternalIOTable = (props: IProps) => {
  const { t } = useTranslation();
  const { data, timezone } = props;
  const tableData = data ? createTableData(data) : [];

  const tableColumns = [
    {
      title: t("Channel"),
      dataIndex: "channel",
      key: "channel"
    },
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
      render: (type: string) => {
        return type === "input" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ExtInputIcon />
            <span style={{ marginLeft: size.s1 }}>Input</span>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ExtOutputIcon />
            <span style={{ marginLeft: size.s1 }}>Output</span>
          </div>
        );
      }
    },
    {
      title: t("Timestamp"),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (time: Date) => {
        return dayjs(time).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    {
      title: t("ChangedTo"),
      dataIndex: "state",
      key: "state",
      render: (state: boolean) => {
        return state ? "ON" : "OFF";
      }
    }
  ];

  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      size="small"
      tableLayout="auto"
      pagination={false}
    />
  );
};

export default ExternalIOTable;
