import React, { useEffect, useMemo } from "react";
import {
  SelectionHelpers,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictorySelectionContainer,
  VictoryTheme
} from "victory";
import { Selection, EventPropTypeInterface } from "victory-core";
import { VMDvaData } from "../../helpers/dataModelHelper";
import {
  createDefaultDvaYAxisDomain,
  dvaSampleToMsTickFormaterWithPostfix,
  generateTickValues,
  toLowHighOrder,
  yAxisFormater,
  ZoomDimension,
  zoomDimensionToVictoryProp
} from "../../helpers/graphHelper";
import {
  commonAxisStyle,
  scoreAxisStyle
} from "../../styles/graphStylesCommon";
import { throttle, isNil, isEmpty } from "lodash-es";
import {
  moveDvaScoreValuesCursorToNextActiveDataPoint,
  moveDvaScoreValuesCursorToPriorActiveDataPoint,
  removeNearestDvaContextLineIfClose,
  selectDvaGraphTools,
  setDvaContextLineNewPos,
  setNewDvaCursorPosition,
  StateZoomDomain
} from "../../state/openDatxSlice";
import { iconColors } from "../../constants/colors";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";

const chartPaddingLeft = 50;

interface IProps {
  width: number;
  height: number;
  dvaDataBlock: VMDvaData;

  zoomDimension?: ZoomDimension;
  zoomDomain?: StateZoomDomain;
  handleZoom?: (domain: StateZoomDomain) => void;
}
const DvaGraph: React.FC<IProps> = (props) => {
  const { dvaDataBlock, zoomDomain } = props;

  const graphTools = useSelector(selectDvaGraphTools);

  const dispatch = useDispatch();

  /** Function attatched to keyboard arrow-keys events. Used to move vertical
   * line in graph */
  const keyDownCallback = useMemo(
    () =>
      throttle((evt: KeyboardEvent) => {
        if (evt?.key === "ArrowLeft") {
          dispatch(moveDvaScoreValuesCursorToPriorActiveDataPoint());
        } else if (evt?.key === "ArrowRight") {
          dispatch(moveDvaScoreValuesCursorToNextActiveDataPoint());
        }
      }, 200),
    [dispatch]
  );

  useEffect(() => {
    if (graphTools?.scoreValuesIsActive) {
      document.addEventListener("keydown", keyDownCallback, false);
    }
    /** clean up when component unmounts */
    return () => {
      document.removeEventListener("keydown", keyDownCallback, false);
    };
  }, [graphTools?.scoreValuesIsActive, keyDownCallback]);

  if (!dvaDataBlock) return <p>no dva data</p>;

  const yDomain = createDefaultDvaYAxisDomain(dvaDataBlock);
  const axisValue = yDomain[0];

  interface IBounds {
    x: [number, number];
    y: [number, number];
  }
  const handleZoomSelection = (bounds: IBounds) => {
    // If the user select the area in the y-axis, we will get a negative x0
    // value, we don't want that
    const fixedBoundsX: [number, number] =
      bounds.x[0] < 0 ? [0, bounds.x[1]] : bounds.x;

    //Note: If user selects from right to left, the first value will be larger
    //than the seconds value, causing a bugged selection. {toLowHighOrder} solves that

    const newXDomain: StateZoomDomain["x"] = toLowHighOrder(fixedBoundsX);
    const newYDomain: StateZoomDomain["y"] = toLowHighOrder(bounds.y);

    props.handleZoom?.({ x: newXDomain, y: newYDomain });
  };

  /** Creates a label for a context line */
  const getContextLineLabel = (contextLinePos: number) => {
    const round2Decimals = (v: number) => Math.round(v * 100) / 100;

    const label = `acc: ${round2Decimals(contextLinePos)}g`;

    return label;
  };

  const releaseContextLineMutation = {
    holdingContextLine: false,
    cursorValue: null
  } as any;

  const events: EventPropTypeInterface<"parent", ""> = {
    target: "parent",
    eventHandlers: {
      onMouseDown: (evt) => ({
        target: "parent",
        mutation: (targetProps) => {
          const { x, y } = Selection.getSVGEventCoordinates(evt);

          if (x < chartPaddingLeft) {
            const yPosInGraph: number = targetProps.scale.y.invert(y);

            // removes a context line if it's close enough. Otherwhise
            // nothing happens
            dispatch(removeNearestDvaContextLineIfClose(yPosInGraph));

            return {
              cursorValue: { y: yPosInGraph },
              holdingContextLine: true
            };
          }

          // pass on the default container event (zoom)
          const container = SelectionHelpers.onMouseDown(
            evt,
            targetProps
          ) as any;
          if (Array.isArray(container)) {
            return container[0].mutation();
          }
        }
      }),
      onMouseMove: (evt) => ({
        mutation: (targetProps) => {
          // Creating new context line
          if (targetProps.holdingContextLine) {
            const { y } = Selection.getSVGEventCoordinates(evt);
            const yPosInGraph: number = targetProps.scale.y.invert(y);

            return { cursorValue: { y: yPosInGraph } };
          }

          //default container event (zoom)
          const selectionContainerDefaultMutation =
            SelectionHelpers.onMouseMove(evt, targetProps)?.mutation();

          return selectionContainerDefaultMutation;
        }
      }),

      onMouseUp: (evt) => ({
        mutation: (targetProps) => {
          const { y } = Selection.getSVGEventCoordinates(evt);
          const { holdingContextLine, onSelection, x1, x2, y1, y2, scale } =
            targetProps;

          const baseMutation = releaseContextLineMutation;

          //User has released the context line
          if (holdingContextLine) {
            //todo: I dont know if this postion is correct
            const yPosInGraph: number = targetProps.scale.y.invert(y);

            dispatch(setDvaContextLineNewPos(yPosInGraph));
            return baseMutation;
          }

          const x1PosInGraph: number = scale?.x?.invert(x1);
          const x2PosInGraph: number = scale?.x?.invert(x2);
          const y1PosInGraph = scale?.y?.invert(y1);
          const y2PosInGraph = scale?.y?.invert(y2);

          const selectionContainerDefaultMutation = {
            select: false,
            x1: null,
            x2: null,
            y1: null,
            y2: null
          } as any;

          //score values event. No area has been selection = counts as a
          //simple click
          if (x1 === x2) {
            // console.log("trying to dispatch setNewDvaCursorPosition");
            dispatch(setNewDvaCursorPosition(x1PosInGraph));

            return {
              ...baseMutation,
              ...selectionContainerDefaultMutation
            };
          }

          //default container event (zoom)
          const bounds: IBounds = {
            x: [x1PosInGraph, x2PosInGraph],
            y: [y1PosInGraph, y2PosInGraph]
          };

          onSelection([], bounds, targetProps);

          return {
            ...baseMutation,
            ...selectionContainerDefaultMutation
          };
        }
      }),

      onContextMenu: (evt) => ({
        mutation: (targetProps) => {
          const { x, y } = Selection.getSVGEventCoordinates(evt);

          //context line remove
          if (x < chartPaddingLeft) {
            const yPosInGraph: number = targetProps.scale.y.invert(y);
            dispatch(removeNearestDvaContextLineIfClose(yPosInGraph));
          }
        }
      })
    }
  };

  return (
    <div>
      <VictoryChart
        name="dvaGraph"
        events={[events]}
        domain={{ x: props.zoomDomain?.x, y: props.zoomDomain?.y ?? yDomain }}
        theme={VictoryTheme.material}
        width={props.width}
        height={props.height}
        padding={{
          right: size.m1,
          bottom: size.l3,
          left: chartPaddingLeft,
          top: size.l1
        }}
        domainPadding={{ x: [0, 5], y: [0, 0] }}
        containerComponent={
          <VictorySelectionContainer
            selectionDimension={zoomDimensionToVictoryProp(props.zoomDimension)}
            allowSelection
            onSelection={(points, bounds: any, props: any) => {
              // Note i've noticed that the bounds argument contains wierd
              // values for y+, therefore I calculate the bounds manually
              let boundsNew = Selection.getBounds(props);
              handleZoomSelection(boundsNew as any);
            }}
          />
        }
      >
        {/* x-axis show values) */}
        <VictoryAxis
          axisValue={props.zoomDomain?.y[0] ?? axisValue}
          tickCount={20}
          tickFormat={dvaSampleToMsTickFormaterWithPostfix}
          fixLabelOverlap
          style={commonAxisStyle}
        />

        {/* x-axis show no values) */}
        <VictoryAxis axisValue={0} tickFormat={[]} style={commonAxisStyle} />

        {/* y-axis for dva data */}
        <VictoryAxis
          dependentAxis
          offsetX={45}
          label="g"
          tickValues={generateTickValues(19, zoomDomain?.y ?? yDomain)}
          fixLabelOverlap
          style={commonAxisStyle}
          axisLabelComponent={
            <VictoryLabel y={16} dx={39} textAnchor="middle" />
          }
          tickFormat={(t) => yAxisFormater(t, yDomain)}
        />

        {/* x-axis data */}
        <VictoryLine
          name="dvaXAlarm"
          data={dvaDataBlock.data}
          y="xAlarm"
          style={{ data: { stroke: iconColors.xAcc } }}
        />

        {/* y-axis data */}
        <VictoryLine
          name="dvaYAlarm"
          data={dvaDataBlock.data}
          y="yAlarm"
          style={{ data: { stroke: iconColors.yAcc } }}
        />

        {/* z-axis data */}
        <VictoryLine
          name="dvaZAlarm"
          data={dvaDataBlock.data}
          y="zAlarm"
          style={{ data: { stroke: iconColors.zAcc } }}
        />

        {/* score values line */}
        {!isNil(graphTools?.dvaScoreValuesCursorPosIndex) && (
          <VictoryAxis
            dependentAxis
            key={"scoreValuesCursorPos"}
            tickValues={undefined}
            style={scoreAxisStyle}
            axisValue={graphTools!.dvaScoreValuesCursorPosIndex}
          />
        )}

        {/* context line in y-axis */}
        {!isEmpty(graphTools?.dvaContextLines) &&
          graphTools?.dvaContextLines?.map((l, i) => (
            <VictoryLine
              key={i}
              y={() => l}
              labels={[getContextLineLabel(l)]}
              labelComponent={<VictoryLabel textAnchor="start" />}
            />
          ))}
      </VictoryChart>
    </div>
  );
};

export default DvaGraph;
