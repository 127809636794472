import React from "react";
import { Row, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  ProgressStepStatus,
  selectParamsPageUserProgress,
  setParamsUserProgress
} from "../../state/paramsPageSlice";
import { useTranslation } from "react-i18next";

interface IProps {
  contentWidth: number;
}
const ParamsBuilderProgress: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { userProgress, progressStatus } = useSelector(
    selectParamsPageUserProgress
  );
  const dispatch = useDispatch();

  const onChange = (current: number) => {
    dispatch(setParamsUserProgress(current));
  };

  const statusTranslation: Record<ProgressStepStatus, string> = {
    waiting: t("cpProgressWaiting"),
    inProgress: t("cpInProgress"),
    validated: t("cpValidated")
  };

  return (
    <Row justify="center" style={{ width: "100%" }}>
      <Steps
        direction="horizontal"
        type="default"
        size="default"
        current={userProgress}
        onChange={onChange}
        style={{ backgroundColor: "white", maxWidth: props.contentWidth }}
      >
        <Steps.Step
          title={t("SelectDevice")}
          description={statusTranslation[progressStatus[0]]}
        />
        <Steps.Step
          title={t("AddParameters")}
          description={statusTranslation[progressStatus[1]]}
        />
        <Steps.Step
          title={t("ScheduleRecording")}
          description={statusTranslation[progressStatus[2]]}
        />
        <Steps.Step
          title={t("TransferPrint")}
          description={statusTranslation[progressStatus[3]]}
        />
      </Steps>
    </Row>
  );
};

export default ParamsBuilderProgress;
