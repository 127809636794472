import React from "react";
import { Alert, Col, Row, Table, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import {
  useGetCompanyDetailsQuery,
  useGetOrderDetailsQuery
} from "../../state/cargologRestApi";
import { closeOrderDetailsModal, modalsState } from "../../state/modalsSlice";
import dayjs from "dayjs";
import { MobitronBigLogo } from "../../logos";
import { skipToken } from "@reduxjs/toolkit/query";
import { PdfExportComponent, useExportPdfHook } from "../PrintExport/pdfExport";
import { DocumentProps, ItemHeaderData } from "../../helpers/pdf/pdfInterfaces";
import { ExportPdfButton } from "../Common/CommonButtons";
import { SmallText } from "../Common/CommonFonts";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import StandardModal from "../Common/StandardModal";
import { isUndefined } from "lodash-es";

interface ITableData {
  key: number;
  count: number;
  licenseData: {
    licenseName: string;
    months: number;
  };
  unitPriceData: {
    fullPrice: number;
    discountPrice: number;
    currency: string;
  };
  price: {
    price: number;
    currency: string;
  };
}

const OrderDetailsModal = () => {
  const modals = useSelector(modalsState);
  const selectedInvoiceId: number | undefined = modals.invoiceObjectId;

  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Text, Title } = Typography;

  const { data: orderData } = useGetOrderDetailsQuery(
    selectedInvoiceId ? { invoiceNumber: selectedInvoiceId } : skipToken
  );
  const companyId = orderData?.companyId;

  const { data: companyData } = useGetCompanyDetailsQuery(
    companyId ? { companyId: companyId } : skipToken
  );

  const timezoneOffset = new Date().getTimezoneOffset();

  const columns: ColumnsType<ITableData> = [
    {
      title: t("QTY"),
      dataIndex: "count",
      key: "count",
      width: 30,
      align: "center",
      render: (count) => <Text>{count}</Text>
    },
    {
      title: t("Products"),
      dataIndex: "licenseData",
      key: "licenseData",
      // width: 460,
      align: "left",
      render: (data) => (
        <>
          <Row>
            <SmallText strong>
              {data.licenseName}
              <SmallText type="secondary">
                {" "}
                ({data.months} {t("months")})
              </SmallText>
            </SmallText>
          </Row>
        </>
      )
    },
    {
      title: t("UnitPrice"),
      dataIndex: "unitPriceData",
      key: "unitPriceData",
      width: 120,
      align: "right",
      render: (data) => {
        const discount = data.fullPrice !== data.discountPrice;
        return (
          <>
            <Row justify="end">
              <Text>{data.discountPrice.toFixed(2) + " " + data.currency}</Text>
            </Row>
            {discount ? (
              <Row justify="end" className="line-through">
                <Text
                  type="secondary"
                  style={{
                    fontSize: 12,
                    textDecorationLine: "line-through"
                  }}
                >
                  {data.fullPrice.toFixed(2) + " " + data.currency}
                </Text>
              </Row>
            ) : (
              <></>
            )}
          </>
        );
      }
    },
    {
      title: t("Price"),
      dataIndex: "price",
      key: "price",
      width: 120,
      align: "right",
      render: (data) => (
        <Text>{data.price.toFixed(2) + " " + data.currency}</Text>
      )
    }
  ];

  let tableData: ITableData[] | undefined = undefined;

  if (orderData && orderData.orderLines) {
    tableData = orderData.orderLines.map((orderLine, index) => {
      const rowPrice = orderLine.price * orderLine.count * orderLine.months;
      const discount = (rowPrice * orderLine.discountPercent) / 100;

      const singleFullPrice = orderLine.price * orderLine.months;
      const singleDiscountPrice =
        singleFullPrice - (singleFullPrice * orderLine.discountPercent) / 100;

      const name = orderLine.text ? orderLine.text : "";
      const currency = orderLine.currency ? orderLine.currency : "";

      let row: ITableData = {
        key: index,
        count: orderLine.count,
        licenseData: {
          licenseName: name,
          months: orderLine.months
        },
        unitPriceData: {
          fullPrice: singleFullPrice,
          discountPrice: singleDiscountPrice,
          currency: currency
        },
        price: {
          price: rowPrice - discount,
          currency: currency
        }
      };
      return row;
    });
  }

  const paddingRight: React.CSSProperties = { paddingRight: size.s2 };

  const InvoiceContent = () => {
    return (
      <>
        <Row
          className="order-header"
          style={{
            paddingTop: size.m1,
            paddingBottom: size.m1,
            marginBottom: size.m1,
            width: "100%"
          }}
        >
          <Col span={8}>
            <Text italic>{t("InvoiceAddress")}</Text>
            <br />
            <Text>{companyData?.name}</Text>
            <br />
            <Text>{companyData?.invoiceAddress}</Text>
            <br />
            <Text>{companyData?.invoiceCity}</Text>{" "}
            <Text>{companyData?.invoicePostalCode}</Text>
          </Col>

          <Col span={8}>
            <Text italic>{t("CompanyAddress")}</Text>
            <br />
            <Text>{companyData?.name}</Text>
            <br />
            <Text>{companyData?.address}</Text>
            <br />
            <Text>{companyData?.city}</Text>{" "}
            <Text>{companyData?.postalCode}</Text>
            <br />
            <Text>{companyData?.countryCode}</Text>
          </Col>

          <Col span={8}>
            <Text italic>{t("InvoiceNumber")}: </Text>
            <Text>{orderData?.invoiceNumber}</Text>
            <br />
            <Text italic>{t("YourOrderNo")}: </Text>
            <Text>
              {orderData?.purchaseOrderNumber
                ? orderData?.purchaseOrderNumber
                : ""}
            </Text>
            <br />
            <Text italic>{t("InvoiceDate")}: </Text>
            <Text>
              {dayjs
                .utc(orderData?.created)
                .subtract(timezoneOffset, "minutes")
                .format("YYYY-MM-DD")}
            </Text>
            <br />
            <Text>{companyData?.invoiceEmail}</Text>
          </Col>
        </Row>

        <Row style={{ marginBottom: size.m1, minHeight: 260 }}>
          <Col span={24}>
            <Table
              dataSource={tableData}
              columns={columns}
              pagination={false}
              size={"small"}
            />
          </Col>
        </Row>

        {/* Following row is needed for content after table to be visible in multi page PDF exports.*/}
        <div style={{ height: 0, color: "white" }}>.</div>

        <Row style={{ marginBottom: size.m1 }}>
          <Col span={24}>
            <table style={{ float: "right" }}>
              <tbody>
                <tr>
                  <td style={paddingRight}>{t("TotalAmountExclVAT")} </td>
                  <td> </td>
                  <td align="right" style={paddingRight}>
                    {(orderData?.priceExclVat ?? 0).toFixed(2) +
                      " " +
                      orderData?.currency}
                  </td>
                </tr>
                <tr>
                  <td style={paddingRight}>{t("VATAmount")} </td>
                  <td> </td>
                  <td align="right" style={paddingRight}>
                    {(orderData?.vatAmount ?? 0).toFixed(2) +
                      " " +
                      orderData?.currency}
                  </td>
                </tr>
                <tr style={{ fontWeight: "bold" }}>
                  <td style={paddingRight}>{t("TotalAmountInclVAT")} </td>
                  <td> </td>
                  <td align="right" style={paddingRight}>
                    {orderData?.priceTotal.toFixed(2) +
                      " " +
                      orderData?.currency}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Row style={{ paddingBottom: size.m1 }}>
          <Col span={24}>
            {orderData?.uploadedByMobitron && orderData?.paid ? (
              <Alert
                type={"info"}
                message={
                  t("ThisOrderWasUploadedByMobitronOn") +
                  " " +
                  dayjs
                    .utc(orderData?.paid)
                    .subtract(timezoneOffset, "minutes")
                    .format("YYYY-MM-DD, HH:MM")
                }
              />
            ) : orderData?.paid ? (
              <Alert
                type={"success"}
                message={
                  t("ThisInvoiceWasPaidOn") +
                  " " +
                  dayjs
                    .utc(orderData?.paid)
                    .subtract(timezoneOffset, "minutes")
                    .format("YYYY-MM-DD, HH:MM")
                }
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row
          className="order-footer"
          align={"stretch"}
          justify={"space-between"}
          style={{
            fontSize: "12px"
          }}
        >
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap"
            }}
          >
            <Text italic>{t("Address")}</Text>
            <Text>MOBITRON AB</Text>
            <Text>Hunneryds Gård 1</Text>
            <Text>561 46 HUSKVARNA</Text>
            <Text>{t("Sweden")}</Text>
            <br />
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap"
            }}
          >
            <Text italic>{t("Phone")}</Text>
            <Text>+46-(0)36-512 25</Text>
            <br />
            <Text italic>{t("eMail")}</Text>
            <Text>info@mobitron.com</Text>
            <Text italic>Internet</Text>
            <Text>www.mobitron.com</Text>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap"
            }}
          >
            <Text italic>Bank</Text>
            <Text>Swedbank AB</Text>
            <Text italic>Bank giro service</Text>
            <Text>5793-0257</Text>
            <Text italic>{t("VATNo")}</Text>
            <Text>SE556430468001</Text>
            <Text italic>{t("CorporateIdentityNo")}</Text>
            <Text>556430-4680</Text>
          </Col>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap"
            }}
          >
            <Text>SEK&nbsp;&nbsp;IBAN SE58 8000 0815 0500 45196649</Text>
            <Text>EUR&nbsp;&nbsp;IBAN SE81 8000 0815 0503 76733408</Text>
            <Text>USD&nbsp;&nbsp;IBAN SE17 8000 0815 0523 70227635</Text>
            <Text>
              BIC&nbsp;/&nbsp;Swift: SWEDSESS&nbsp;&nbsp;&nbsp;&nbsp;8150-5
              45196649
            </Text>
          </Col>
        </Row>
      </>
    );
  };
  const windowWidth = window.innerWidth;

  const documentProps: DocumentProps = {
    documentTitle: t("Order"),
    fileName: `mobitron_order_${orderData?.id}.pdf`
  };

  const itemHeader: ItemHeaderData = {
    itemType: "Invoice",
    itemTitle: `${t("InvoiceNo")} ${orderData?.invoiceNumber}`
  };

  return (
    <>
      <StandardModal
        title={`${t("InvoiceNo")} ${orderData?.invoiceNumber}`}
        open={modals.isOrderDetailsModalOpen}
        onCancel={() => dispatch(closeOrderDetailsModal())}
        closable={true}
        width={windowWidth - 48}
        zIndex={1045}
        footer={null}
        destroyOnClose={true}
        style={{
          top: "72px"
        }}
        styles={{
          content: { padding: 0 },
          body: { overflow: "auto", outline: "none" }
        }}
        extraButtons={[
          <ExportPdfButton
            key={"ExportAsPDF"}
            onClick={startExport}
            disabled={isUndefined(tableData)}
          >
            {t("ExportAsPDF")}
          </ExportPdfButton>
        ]}
      >
        <div
          style={{
            overflowY: "scroll",
            overflowX: "auto",
            height: "calc(100vh - 156px)",
            width: "100%"
          }}
        >
          <div
            style={{ maxWidth: "780px", margin: "0 auto", padding: size.l2 }}
          >
            <Row style={{ paddingBottom: size.m1 }}>
              <Col span={16}>
                <MobitronBigLogo style={{ width: "325px", height: "100px" }} />
              </Col>
              <Col span={8}>
                <Title style={{ paddingTop: size.l1 }}>
                  <Text strong>{t("Order")}</Text>
                </Title>
              </Col>
            </Row>
            <InvoiceContent />
          </div>
        </div>
      </StandardModal>
      {isExporting && (
        <PdfExportComponent
          ComponentBody={InvoiceContent}
          componentProps={{ orderData }}
          documentProps={documentProps}
          itemHeader={itemHeader}
          reportExportDone={finishExport}
        />
      )}
    </>
  );
};

export default OrderDetailsModal;
