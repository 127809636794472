import IDate from "./IDate";
import { Buffer } from "buffer";

export type RunningStatusTypes =
  | "btnPress"
  | "tampering"
  | "recStart"
  | "recEndNormal"
  | "recEndMemoryFull"
  | "recEndWriteError";

export type DtExtraValueReponse = {
  extraValueId: number;
  timestamp: IDate;
  value: number;
};

export type DtDataLink = {
  alarmDt: number;
  linkSource: number;
  alarmTimestamp: number;
};

export type DTStatusLink = {
  alarmDt: number;
  linkSource: number;
  alarmTimestamp: number;
};

// Generell sensor som inte har någon specifik parser
export type DTExternalSensor = {
  sensorType: number;
  sensorId: number;
  data: number[];
};

export type DTExternalTimer = {
  sensorId: number;
  timerTime: number;
};

export type DTExternalIO = {
  changed: boolean[]; // Always 8 elements long
  state: boolean[]; // Always 8 elements long
};

export type DTExternalTemp = {
  sensorId: number;
  sensorSerial: string;
  temp: number;
};

export type DTExternalRh = {
  sensorId: number;
  sensorSerial: string;
  rh: number;
};

export const dtHasEsti = (data: any): data is { sensorId: any } => {
  return data ? data.hasOwnProperty("sensorId") : false;
};

/** Model for a parsed dt-block */
export interface IParsedDtBlock {
  timestamp: IDate;
  dts: {
    id: number;
    data: Buffer;
  }[];
}
