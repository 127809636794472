import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HelpModalPage } from "../components/HelpModal/HelpModal";
import { StoreApi } from "./store";

/** Main interface for this state-slice */
export interface StateHelp {
  isHelpModeActive: boolean;

  isHelpModalOpen: boolean;
  currentPage: HelpModalPage;

  quickTourProgress: number;
  isQuickTourModalOpen: boolean;
}

/** Initial state for this slice */
const initialState: StateHelp = {
  isHelpModeActive: false,

  isHelpModalOpen: false,
  currentPage: "quickTour",

  isQuickTourModalOpen: true,
  quickTourProgress: 0
};

export const slice = createSlice({
  name: "Help",
  initialState,
  reducers: {
    closeAllHelpModals: (state) => {
      state.isHelpModalOpen = false;
      // state.isQuickTourModalOpen = false;
    },
    openHelpModal: (state) => {
      state.isHelpModalOpen = true;
    },
    closeHelpModal: (state) => {
      state.isHelpModalOpen = false;
    },
    setCurrentHelpPage: (state, action: PayloadAction<HelpModalPage>) => {
      state.currentPage = action.payload;
    },

    setQuickTourModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isQuickTourModalOpen = action.payload;
    },
    setQuickTourProgress: (state, action) => {
      state.quickTourProgress = action.payload;
    },
    increaseQuickTourProgress: (state) => {
      state.quickTourProgress += 1;
    },
    decreaseQuickTourProgress: (state) => {
      state.quickTourProgress -= 1;
    },

    startHelpMode: (state) => {
      state.isHelpModeActive = true;
    },
    stopHelpMode: (state) => {
      state.isHelpModeActive = false;
    },
    toggleHelpMode: (state) => {
      state.isHelpModeActive = !state.isHelpModeActive;
    }
  }
});

export const {
  closeAllHelpModals,

  openHelpModal,
  closeHelpModal,
  setCurrentHelpPage,

  setQuickTourProgress,
  increaseQuickTourProgress,
  decreaseQuickTourProgress,

  startHelpMode,
  stopHelpMode,
  toggleHelpMode,
  setQuickTourModalOpen
} = slice.actions;

export const selectHelp = (state: StoreApi) => state.help;

export default slice.reducer;
