import { Button, Col, Input, Row, Switch, Tooltip, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectExtInputs,
  selectExtOutputs,
  setExtInputDescription,
  setExtInputSaveAlarms,
  setExtInputUsed,
  setExtOutputDescription,
  setExtOutputInitialState,
  setExtOutputSaveAlarms,
  setExtOutputUsed,
  toggleExtInputTriggOnImpact,
  toggleExtInputTriggOnReset
} from "../../state/openParxSlice";
import { ParamsCardSubHeader } from "../Common/CommonFonts";
import {
  CommonParamsInputCard,
  CommonParamsMainContentRow
} from "../Common/CommonParametersComponents";
import { size } from "../../helpers/pageHelper";
import { getNumberOfIO } from "../../helpers/parsers/parseSystemInfoHelper";

const { Text } = Typography;

interface AvailableFeatures {
  hasExtIO: boolean;
  extIOCfg: number;
}

interface IProps {
  features: AvailableFeatures;
}

const ParamsBuilderExtIO: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { hasExtIO, extIOCfg } = props.features;
  const inputParams = useSelector(selectExtInputs);
  const outputParams = useSelector(selectExtOutputs);
  const numberOfIO = getNumberOfIO(extIOCfg);
  const availableInputs = inputParams.slice(0, numberOfIO);
  const availableOutputs = outputParams.slice(0, numberOfIO);

  const dispatch = useDispatch();

  const spans = {
    toggle: 5,
    input: 9
  };
  const renderInputs = () => {
    return (
      <CommonParamsInputCard
        title={t("ExternalInputs")}
        width={1}
        height={3}
        style={{ textAlign: "center" }}
      >
        {/* titles */}
        {availableInputs.length > 0 && (
          <Row justify="space-between" align="middle">
            {/* ghost element */}
            <Col span={spans.toggle}>
              <ParamsCardSubHeader>{t("UseInput")}</ParamsCardSubHeader>
            </Col>
            <Col span={spans.input}>
              <ParamsCardSubHeader>{t("NameOfInput")}</ParamsCardSubHeader>
            </Col>
            <Col span={spans.toggle}>
              <ParamsCardSubHeader>{t("genSave")}</ParamsCardSubHeader>
            </Col>
            <Col span={spans.toggle}>
              <ParamsCardSubHeader>{t("TriggOn")}</ParamsCardSubHeader>
            </Col>
          </Row>
        )}
        {availableInputs.map((input, index) => (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: size.s1 }}
            key={index}
          >
            <Col span={spans.toggle}>
              <Switch
                checked={input.used}
                onChange={(checked) =>
                  dispatch(setExtInputUsed({ index, used: checked }))
                }
              />
            </Col>
            <Col span={spans.input}>
              <Input
                disabled={!input.used}
                value={input.description}
                onChange={(e) =>
                  dispatch(
                    setExtInputDescription({
                      index,
                      description: e.target.value
                    })
                  )
                }
                onBlur={(e) =>
                  dispatch(
                    setExtInputDescription({
                      index,
                      description:
                        e.target.value === ""
                          ? t("Input") + " " + (index + 1)
                          : e.target.value
                    })
                  )
                }
                maxLength={24}
              />
            </Col>
            <Col span={spans.toggle}>
              <Tooltip title={t("SaveChangesToData")} placement="right">
                <Switch
                  disabled={!input.used}
                  checked={input.saveTrigg}
                  onChange={(checked) =>
                    dispatch(
                      setExtInputSaveAlarms({ index, saveAlarms: checked })
                    )
                  }
                />
              </Tooltip>
            </Col>
            <Col span={spans.toggle}>
              <Button
                size="small"
                disabled={!input.used}
                type={input.triggOnImpact ? "primary" : "default"}
                onClick={() => dispatch(toggleExtInputTriggOnImpact({ index }))}
              >
                1
              </Button>
              <Button
                size="small"
                disabled={!input.used}
                type={input.triggOnReset ? "primary" : "default"}
                onClick={() => dispatch(toggleExtInputTriggOnReset({ index }))}
              >
                0
              </Button>
            </Col>
          </Row>
        ))}
        {(availableInputs.length === 0 || !hasExtIO) && (
          <Text type="secondary">{t("NoExternalInputsAvailable")}</Text>
        )}
      </CommonParamsInputCard>
    );
  };
  const renderOutputs = () => {
    return (
      <CommonParamsInputCard
        title={t("ExternalOutputs")}
        width={1}
        height={3}
        style={{ textAlign: "center" }}
      >
        {/* titles */}
        {availableOutputs.length > 0 && (
          <Row justify="space-between" align="middle">
            {/* ghost element */}
            <Col span={spans.toggle}>
              <ParamsCardSubHeader>{t("UseOutput")}</ParamsCardSubHeader>
            </Col>
            <Col span={spans.input}>
              <ParamsCardSubHeader>{t("NameOfOutput")}</ParamsCardSubHeader>
            </Col>
            <Col span={spans.toggle}>
              <ParamsCardSubHeader>{t("genSave")}</ParamsCardSubHeader>
            </Col>
            <Col span={spans.toggle}>
              <ParamsCardSubHeader>{t("StartAs")}</ParamsCardSubHeader>
            </Col>
          </Row>
        )}
        {availableOutputs.map((output, index) => (
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: size.s1 }}
            key={index}
          >
            <Col span={spans.toggle}>
              <Switch
                checked={output.used}
                onChange={(checked) =>
                  dispatch(setExtOutputUsed({ index, used: checked }))
                }
              />
            </Col>
            <Col span={spans.input}>
              <Input
                disabled={!output.used}
                value={output.description}
                onChange={(e) =>
                  dispatch(
                    setExtOutputDescription({
                      index,
                      description: e.target.value
                    })
                  )
                }
                onBlur={(e) =>
                  dispatch(
                    setExtOutputDescription({
                      index,
                      description:
                        e.target.value === ""
                          ? t("Output") + " " + (index + 1)
                          : e.target.value
                    })
                  )
                }
                maxLength={24}
              />
            </Col>
            <Col span={spans.toggle}>
              <Tooltip title={t("SaveChangesToData")} placement="right">
                <Switch
                  disabled={!output.used}
                  checked={output.saveTrigg}
                  onChange={(checked) =>
                    dispatch(
                      setExtOutputSaveAlarms({ index, saveAlarms: checked })
                    )
                  }
                />
              </Tooltip>
            </Col>
            <Col span={spans.toggle}>
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                disabled={!output.used}
                checked={output.initialStateOn}
                onChange={(checked) =>
                  dispatch(
                    setExtOutputInitialState({ index, initialState: checked })
                  )
                }
                style={{
                  backgroundColor: output.initialStateOn ? "#1890ff" : "#f5222d"
                }}
              />
            </Col>
          </Row>
        ))}
        {(availableOutputs.length === 0 || !hasExtIO) && (
          <Text type="secondary">{t("NoExternalOutputsAvailable")}</Text>
        )}
      </CommonParamsInputCard>
    );
  };

  return (
    <CommonParamsMainContentRow>
      <Col order={1}>{renderInputs()}</Col>
      <Col order={2}>{renderOutputs()}</Col>
    </CommonParamsMainContentRow>
  );
};

export default ParamsBuilderExtIO;
