import React from "react";
import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { renderStatus } from "../../pages/ProjectsPage";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

interface IProps {
  projectStatus?: number;
}
const StatusTabTable = (props: IProps) => {
  const { t } = useTranslation();
  const { projectStatus } = props;

  let statusData: { status: number }[] = [];
  if (projectStatus) {
    statusData = [{ status: projectStatus }];
  }

  const statusColumns: ColumnsType<{ status: number }> = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: number) => {
        return (
          <Text>
            {t("Current")} {": "} {renderStatus(t, status).string}
          </Text>
        );
      }
    }
  ];

  return (
    <Table columns={statusColumns} dataSource={statusData} pagination={false} />
  );
};

export default StatusTabTable;
