import React, { CSSProperties } from "react";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectMapProvider } from "../../state/sessionSlice";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { size } from "../../helpers/pageHelper";
const { Link } = Typography;

interface IProps {
  lat: number;
  lon: number;
  text: boolean;
  color?: string;
  linkStyle?: CSSProperties;
  formFactor?: "button" | "link";
}
export const MapProviderLink = (props: IProps) => {
  const { t } = useTranslation();
  const mapProvider = useSelector(selectMapProvider);
  const { lat, lon, text, color, linkStyle, formFactor } = props;

  const urlSwitch = () => {
    switch (mapProvider) {
      case "google":
        return `https://www.google.com/maps/place/${lat},${lon}/@${lat},${lon},12z`;
      case "openStreetMap":
        return `https://www.openstreetmap.org/?mlat=${lat}&mlon=${lon}#map=12/${lat}/${lon}`;
      // case "hereWeGo":
      //   return `https://share.here.com/l/${lat},${lon}?z=13&p=yes`;
      default:
        return "";
    }
  };

  const titleSwitch = () => {
    switch (mapProvider) {
      case "google":
        return t("OpenLocationInGoogleMaps");
      case "openStreetMap":
        return t("OpenLocationInOpenStreetMap");
      // case "hereWeGo":
      //   return ("Open location in HERE WeGo");
      default:
        return "";
    }
  };

  const textSwitch = () => {
    switch (mapProvider) {
      case "google":
        return t("GoogleMaps");
      case "openStreetMap":
        return t("OpenStreetMap");
      // case "hereWeGo":
      //   return ("HERE WeGo");
      default:
        return "";
    }
  };

  if (formFactor === "button") {
    return (
      <NormalButtonSmall
        icon={<EnvironmentOutlined />}
        href={urlSwitch()}
        title={titleSwitch()}
        target="blank"
      >
        {text && textSwitch()}
      </NormalButtonSmall>
    );
  } else {
    return (
      <Link
        style={{
          padding: text ? 0 : size.s1,
          color: color ? color : "#006600",
          ...linkStyle
        }}
        href={urlSwitch()}
        title={titleSwitch()}
        target="_blank"
      >
        <EnvironmentOutlined /> {text && textSwitch()}
      </Link>
    );
  }
};
