import React from "react";
import "./renderer/styles/index.css";
import App from "./renderer/App";
import { I18nextProvider } from "react-i18next";
import i18n from "./renderer/lib/i18n";
import { Provider } from "react-redux";
import store from "./renderer/state/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";

const persistor = persistStore(store);
const antdConfig = {
  hashed: false,
  token: {
    colorPrimary: "#409c46",
    colorLink: "#409c46",
    borderRadius: 2
  }
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider theme={antdConfig}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </ConfigProvider>
    </PersistGate>
  </Provider>
);
