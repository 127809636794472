import {
  ApartmentOutlined,
  DiffOutlined,
  FormOutlined,
  HomeOutlined,
  LineChartOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ToolOutlined
} from "@ant-design/icons";
import { Col, Menu, Row } from "antd";
import { isNil, isUndefined } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  useGetAllProjectsQuery,
  useGetProjectInvitesQuery
} from "../../state/cargologRestApi";
import { openHelpModal } from "../../state/helpSlice";
import { getUser } from "../../state/sessionSlice";
import { openSettingsModal, setCurrentPage } from "../../state/settingsSlice";
import "../../styles/App.css";
import "../../styles/animation.css";
import {
  default as colors,
  default as commonColors
} from "../../styles/commonColors";
import mobitronColors from "../../styles/mobitronColors";
import { SmallText } from "../Common/CommonFonts";
import HelpModal from "../HelpModal/HelpModal";
import {
  CheckUserRightsAccess,
  LicenseAccess
} from "../MicroComponents/LicenseAccess";
import ChangeEmailModal from "../Modals/ChangeEmailModal";
import ChangePasswordModal from "../Modals/ChangePasswordModal";
import EditInvoiceInfoModal from "../Modals/EditInvoiceInfoModal";
import EditUserCompanyModal from "../Modals/EditUserCompanyModal";
import EditUserNameModal from "../Modals/EditUserNameModal";
import EditUserPhoneModal from "../Modals/EditUserPhoneModal";
import SessionEndedModal from "../Modals/SessionEndedModal";
import EditCompanyModal from "../SettingsModal/EditCompanyModal";
import GraphAxisScaleModal from "../SettingsModal/GraphAxisScaleModal";
import SettingsModal from "../SettingsModal/SettingsModal";
import VectorSettingsModal from "../SettingsModal/VectorSettingsModal";

/** Common style from menu item titles */
const menuItemTitleStyle: React.CSSProperties = {
  fontSize: "12px",
  lineHeight: "20px",
  textAlign: "center",
  color: "white"
};

interface LocalMenuItemProps {
  icon: any;
  title?: string;
  iconStyle?: React.CSSProperties;
}
/** Local component used to render icon + title */
const SidebarMenuItem: React.FC<LocalMenuItemProps> = (props) => {
  const { icon, title, iconStyle } = props;

  const Icon = icon;

  const menuIconStyle: React.CSSProperties = {
    fontSize: "20px",
    marginRight: 0,
    color: "white"
  };

  return (
    <Row justify="center" align="middle" style={{ padding: 2, height: "100%" }}>
      <Col>
        <Row justify="center">
          <Icon style={{ ...menuIconStyle }} {...iconStyle} />
        </Row>
        {title && (
          <Row justify="center">
            <SmallText style={menuItemTitleStyle}>{title}</SmallText>
          </Row>
        )}
      </Col>
    </Row>
  );
};

const Sidebar: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navLocation = useLocation();

  const handleLowerMenuClick = (event: any) => {
    switch (event.key) {
      case "settings":
        dispatch(setCurrentPage("general"));
        dispatch(openSettingsModal());
        break;
      case "help":
        dispatch(openHelpModal());
        break;
      default:
        break;
    }
  };

  const { data: projectInvites } = useGetProjectInvitesQuery();
  const { data: projects } = useGetAllProjectsQuery();
  const user = useSelector(getUser);

  const userProjectInvites = !isUndefined(projectInvites)
    ? projectInvites.filter(
        (invite) => invite.email === user.email && isNil(invite.answered)
      )
    : [];

  const activeProjects = !isUndefined(projects)
    ? projects?.filter((project) =>
        project.projectUsers?.find((e) => e.user.userId === user.userId)
      )
    : [];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        backgroundColor: colors.menuColor,
        overflowY: "hidden"
      }}
    >
      {/* top part of menu */}
      <Menu
        mode="vertical"
        selectedKeys={[navLocation.pathname]}
        style={{
          width: "100%",
          backgroundColor: colors.menuColor,
          borderRight: 0
        }}
        className="custom"
      >
        <Menu.Item title="Ctrl + 1" key="/landing" style={menuItemStyle}>
          <SidebarMenuItem icon={HomeOutlined} title={t("Dashboard")} />
          <NavLink to="/landing" />
        </Menu.Item>

        {/* Check available page paths in Routes.tsx */}
        {LicenseAccess(
          "ACB",
          <Menu.Item title="Ctrl + 2" key="/graph" style={menuItemStyle}>
            <SidebarMenuItem
              icon={LineChartOutlined}
              title={t("sbGraphMainMenuTitle")}
            />
            <NavLink to="/graph" />
          </Menu.Item>
        )}

        {LicenseAccess(
          "ACC",
          <Menu.Item title="Ctrl + 3" key="/compare" style={menuItemStyle}>
            <SidebarMenuItem
              icon={DiffOutlined}
              title={t("sbCompareMainMenuTitle")}
            />
            <NavLink to="/compare" />
          </Menu.Item>
        )}

        {LicenseAccess(
          "ADE",
          <Menu.Item title="Ctrl + 4" key="/params" style={menuItemStyle}>
            <SidebarMenuItem icon={FormOutlined} title={t("sbParamsTitle")} />
            <NavLink to="/params" />
          </Menu.Item>
        )}

        {LicenseAccess(
          "ABA",
          <Menu.Item title="Ctrl + 5" key="/devices" style={menuItemStyle}>
            <SidebarMenuItem
              icon={ProfileOutlined}
              title={t("sbDevicesTitle")}
            />
            <NavLink to="/devices" />
          </Menu.Item>
        )}

        {CheckUserRightsAccess("ADI") ||
        userProjectInvites.length > 0 ||
        (activeProjects.length > 0 &&
          user.companyId !== activeProjects.find((e) => e.companyId)) ? (
          <Menu.Item key="/projects" style={menuItemStyle}>
            <SidebarMenuItem icon={ScheduleOutlined} title={t("Projects")} />
            <NavLink to="/projects" />
          </Menu.Item>
        ) : (
          <></>
        )}

        {LicenseAccess(
          "AFA",
          <Menu.Item title="Ctrl + 6" key="/admin" style={menuItemStyle}>
            <SidebarMenuItem icon={ApartmentOutlined} title={t("sbAdmin")} />
            <NavLink to="/admin" />
          </Menu.Item>
        )}

        {LicenseAccess(
          "MOB",
          <Menu.Item key="/mobitronAdmin" style={menuItemStyle}>
            <SidebarMenuItem icon={ToolOutlined} title={t("sbMobitron")} />
            <NavLink to="/mobitronAdmin" />
          </Menu.Item>
        )}
      </Menu>
      <EditCompanyModal />
      <EditInvoiceInfoModal />
      <SettingsModal />
      <EditUserCompanyModal />
      <EditUserNameModal />
      <EditUserPhoneModal />
      <ChangePasswordModal />
      <ChangeEmailModal />
      <GraphAxisScaleModal />
      <HelpModal />
      <SessionEndedModal />
      <VectorSettingsModal />

      {/* lower part of menu */}
      <Menu
        mode="vertical"
        selectedKeys={[navLocation.pathname]}
        className="custom"
        selectable={false}
        onClick={handleLowerMenuClick}
        style={{
          width: "100%",
          backgroundColor: colors.menuColor,
          borderRight: 0
        }}
      >
        <Menu.Item title="Ctrl + 0" key="settings" style={menuItemStyle}>
          <SidebarMenuItem
            icon={SettingOutlined}
            title={t("sbSettingsMainMenuTitle")}
          />
        </Menu.Item>

        <Menu.Item key="help" style={menuItemStyle}>
          <SidebarMenuItem icon={QuestionCircleOutlined} title={t("sbHelp")} />
        </Menu.Item>
      </Menu>
    </div>
  );
};

const menuItemStyle: React.CSSProperties = {
  height: "56px"
};

export default Sidebar;
