import React, { useState } from "react";
import { Input, Row, Select, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLicensePriceState,
  setLicensePriceUserRights,
  setLicenseType
} from "../../state/licensePriceSlice";
import {
  checkBoxData,
  eLabelData,
  ITableData
} from "../../constants/userRights";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { size } from "../../helpers/pageHelper";
import { SmallText } from "../Common/CommonFonts";

const { Option } = Select;

interface IProps {}
const CreateLicenseModalStage3: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const licensePriceState = useSelector(selectLicensePriceState);
  const licenseType = licensePriceState.licenseType ?? 1;

  const checkBoxColumns: ColumnsType<ITableData> = [
    {
      title: t("licensePriceUserRights"),
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    }
  ];

  const [stringValue, setStringValue] = useState("");

  const filteredData = (licenseType === 1 ? checkBoxData : eLabelData).filter(
    (e) => {
      const dataContainsInput =
        stringValue === "" ||
        e.name.toLowerCase().includes(stringValue.toLowerCase());
      return dataContainsInput;
    }
  );

  const [select, setSelect] = useState({
    userRights: []
  });

  const { userRights } = select;

  const selectedRows = {
    userRights,
    onChange: (userRights: any) => {
      setSelect({
        ...select,
        userRights: userRights
      });
    }
  };
  dispatch(setLicensePriceUserRights(select.userRights));

  return (
    <>
      <Row style={{ paddingBottom: size.s1 }}>
        <Select
          style={{ width: "100%" }}
          value={licenseType}
          onChange={(e) => dispatch(setLicenseType(e))}
          bordered={false}
        >
          <Option value={1}>
            <SmallText strong>{t("cargolog3rdGen")}</SmallText>
          </Option>
          <Option value={2}>
            <SmallText strong>{t("cargologEasy")}</SmallText>
          </Option>
        </Select>
      </Row>
      <Row>
        <Input
          style={{ width: "100%" }}
          placeholder={t("licensePriceFilterUserRights")}
          onChange={(e) => setStringValue(e.target.value)}
        />
      </Row>
      <Table
        columns={checkBoxColumns}
        dataSource={filteredData}
        size={"small"}
        bordered
        rowSelection={selectedRows}
        scroll={{ x: "0", y: "calc(72vh - 380px)" }}
        pagination={false}
      />
    </>
  );
};

export default CreateLicenseModalStage3;
