import { CalculatorOutlined } from "@ant-design/icons";
import { Col, InputNumber, Row, Space, Statistic, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SlopeCalc } from "../../models/ViewModelRecordingParameters/VMPressureParams";
import {
  selectSlopeCalc,
  setPressureSlopeValue,
  setSlopeCalcPressure1,
  setSlopeCalcPressure2,
  setSlopeCalcTemp1,
  setSlopeCalcTemp2
} from "../../state/openParxSlice";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import StandardModal from "../Common/StandardModal";
import { ParamsCardSubHeader } from "../Common/CommonFonts";
import SlopeCalculatorGraph from "./SlopeCalculatorGraph";
import { size } from "../../helpers/pageHelper";

const { Text } = Typography;

const calculateSlope = (slopeCalc: SlopeCalc) => {
  const { temp1, temp2, pressure1, pressure2 } = slopeCalc;
  return (pressure2 - pressure1) / (temp2 - temp1);
};

const SlopeCalculatorModal = (): React.JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const slopeCalc = useSelector(selectSlopeCalc);
  const defaultSlope = calculateSlope(slopeCalc);

  const [slopeCalcOpen, setSlopeCalcOpen] = React.useState(false);
  const [slope, setSlope] = React.useState(defaultSlope);

  // Update temp1
  const temp1Changed = (value: number | null) => {
    if (value === null) return;
    dispatch(setSlopeCalcTemp1(value));
    setSlope(calculateSlope({ ...slopeCalc, temp1: value }));
  };

  // Update temp2
  const temp2Changed = (value: number | null) => {
    if (value === null) return;
    dispatch(setSlopeCalcTemp2(value));
    setSlope(calculateSlope({ ...slopeCalc, temp2: value }));
  };

  // Update pressure1
  const pressure1Changed = (value: number | null) => {
    if (value === null) return;
    dispatch(setSlopeCalcPressure1(value));
    setSlope(calculateSlope({ ...slopeCalc, pressure1: value }));
  };

  // Update pressure2
  const pressure2Changed = (value: number | null) => {
    if (value === null) return;
    dispatch(setSlopeCalcPressure2(value));
    setSlope(calculateSlope({ ...slopeCalc, pressure2: value }));
  };

  const applySlope = () => {
    dispatch(setPressureSlopeValue(slope));
    setSlopeCalcOpen(false);
  };

  return (
    <>
      <NormalButton
        onClick={() => setSlopeCalcOpen(true)}
        icon={<CalculatorOutlined />}
      >
        {t("SlopeCalculator")}
      </NormalButton>
      <StandardModal
        open={slopeCalcOpen}
        onCancel={() => setSlopeCalcOpen(false)}
        title={t("SlopeCalculator")}
        footer={[
          <NormalButton onClick={() => setSlopeCalcOpen(false)}>
            {t("genCancel")}
          </NormalButton>,
          <PrimaryButton onClick={applySlope}>{t("ApplySlope")}</PrimaryButton>
        ]}
      >
        <Space
          direction="vertical"
          size={"large"}
          style={{ marginBottom: size.m1 }}
        >
          <Text>{t("SlopeCalculationExplanation")}</Text>
          <Row justify={"center"}>
            <SlopeCalculatorGraph />
          </Row>
          <Row justify={"space-evenly"}>
            <Col>
              <Space direction={"vertical"}>
                <ParamsCardSubHeader>{t("Temp1")}</ParamsCardSubHeader>
                <InputNumber
                  value={slopeCalc.temp1}
                  onChange={(value) => temp1Changed(value)}
                  suffix="°C"
                />
                <ParamsCardSubHeader>{t("Pressure1")}</ParamsCardSubHeader>
                <InputNumber
                  value={slopeCalc.pressure1}
                  onChange={(value) => pressure1Changed(value)}
                  suffix="mbar"
                />
              </Space>
            </Col>
            <Col>
              <Space direction={"vertical"}>
                <ParamsCardSubHeader>{t("Temp2")}</ParamsCardSubHeader>
                <InputNumber
                  value={slopeCalc.temp2}
                  onChange={(value) => temp2Changed(value)}
                  suffix="°C"
                />
                <ParamsCardSubHeader>{t("Pressure2")}</ParamsCardSubHeader>
                <InputNumber
                  value={slopeCalc.pressure2}
                  onChange={(value) => pressure2Changed(value)}
                  suffix="mbar"
                />
              </Space>
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col>
              <Statistic
                title={t("CalculatedSlope") + " (mbar/°C)"}
                value={slope}
                precision={3}
                style={{ textAlign: "center" }}
              />
            </Col>
          </Row>
        </Space>
      </StandardModal>
    </>
  );
};

export default SlopeCalculatorModal;
