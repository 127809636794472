export const commonAxisStyle = {
  tickLabels: { fontSize: 14, fontFamily: "Arial" },
  axisLabel: { fontSize: 14, padding: 40, angle: 0, fontFamily: "Arial" }
};

export const scoreAxisStyle = {
  axis: { stroke: "#756f6a" },
  axisLabel: { fontSize: 14, padding: 40, angle: 0, fontFamily: "Arial" },
  grid: { stroke: "none" },
  ticks: { stroke: "none" },
  tickLabels: { fill: "none" }
};
