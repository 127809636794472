import React from "react";
import { Table, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { timestampFormat } from "../../constants/defaultComponentsProps";
import { AngleData, setNewCursorPosition } from "../../state/openDatxSlice";
import Link from "antd/es/typography/Link";
import { useDispatch } from "react-redux";
import { createTzDate } from "../../helpers/dateHelper";
const { Text } = Typography;

interface IProps {
  data: AngleData[];
  timezone: string;
}
const AngleTable = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, timezone } = props;

  const selectCursorPosition = (timestamp: number) => {
    dispatch(setNewCursorPosition(timestamp));
  };

  const tableColumns = [
    {
      title: t("Timestamp"),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (time: number) => {
        return (
          <Link onClick={() => selectCursorPosition(time)}>
            {createTzDate(time, timezone).format(timestampFormat)}
          </Link>
        );
      }
    },
    {
      title: `Φ ${t("RollAngle")}`,
      dataIndex: "xAngle",
      key: "xAngle",
      render: (xAngle: number) => <Text>{xAngle}°</Text>
    },
    {
      title: `Θ ${t("NickAngle")}`,
      dataIndex: "yAngle",
      key: "yAngle",
      render: (yAngle: number) => <Text>{yAngle}°</Text>
    },
    {
      title: `Ψ ${t("YawAngle")}`,
      dataIndex: "zAngle",
      key: "zAngle",
      render: (zAngle: number) => <Text>{zAngle}°</Text>
    }
  ];

  return (
    <Table
      columns={tableColumns}
      dataSource={data}
      size="small"
      tableLayout="auto"
      pagination={false}
    />
  );
};

export default AngleTable;
