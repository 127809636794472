import React from "react";
import {
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryLine
} from "victory";
import { iconColors } from "../../constants/colors";
import { VMDvaData } from "../../helpers/dataModelHelper";
import {
  commonPrimaryGraphProps,
  createDefaultDvaYAxisDomain,
  dvaSampleToMsTickFormaterWithPostfix,
  ZoomDimension,
  zoomDimensionToVictoryProp
} from "../../helpers/graphHelper";
import { StateZoomDomain } from "../../state/openDatxSlice";
import { commonAxisStyle } from "../../styles/graphStylesCommon";
import { size } from "../../helpers/pageHelper";

interface IProps {
  dvaDataBlock: VMDvaData;
  width: number;
  height: number;
  zoomDomain?: StateZoomDomain;
  zoomDimension?: ZoomDimension;

  handleZoom: (domain: StateZoomDomain) => void;
  resetZoom: () => void;
}
const DvaOverviewGraph = (props: IProps) => {
  const { dvaDataBlock, zoomDomain, zoomDimension } = props;

  const yDomain = createDefaultDvaYAxisDomain(props.dvaDataBlock);
  // const axisValue = yDomain[0];

  const defaultDomain: StateZoomDomain = {
    x: [0, dvaDataBlock.data.length],
    y: yDomain
  };

  //TODO: keep this??
  /** Checks if a domain is equivalent with the entire domain */
  const isZoomReset = (domain: StateZoomDomain): boolean =>
    domain.x[0] <= defaultDomain.x[0] &&
    domain.x[1] >= defaultDomain.x[1] &&
    //todo: is this correct?
    domain.y[0] === yDomain[0] &&
    domain.y[1] === yDomain[1];

  /** Updates the store with the new domain. Since Victory triggers the same
   * event for zoom/pan as reset, a check to determine the kind of event is made */
  const handleBrushZoom = (newDomain: StateZoomDomain) => {
    // console.log("new domain here: ", newDomain);
    // console.log("default domain here: ", defaultDomain);
    isZoomReset(newDomain) ? props.resetZoom() : props.handleZoom(newDomain);
  };

  return (
    <VictoryChart
      width={props.width}
      height={props.height}
      domainPadding={commonPrimaryGraphProps.domainPadding}
      padding={{ right: size.m1, bottom: size.l3, left: 50, top: size.l1 }}
      domain={defaultDomain}
      containerComponent={
        <VictoryBrushContainer
          brushDimension={zoomDimensionToVictoryProp(zoomDimension)}
          brushDomain={zoomDomain ?? defaultDomain}
          onBrushDomainChangeEnd={handleBrushZoom as any}
        />
      }
    >
      {/* x-axis show values) */}
      <VictoryAxis
        axisValue={yDomain[0]}
        tickCount={20}
        tickFormat={dvaSampleToMsTickFormaterWithPostfix}
        fixLabelOverlap
        style={commonAxisStyle}
      />

      {/* x-axis show no values) */}
      <VictoryAxis axisValue={0} tickFormat={[]} style={commonAxisStyle} />

      {/* x-axis acceleration data */}
      <VictoryLine
        name="dvaOverviewXAlarm"
        data={props.dvaDataBlock.data}
        y="xAlarm"
        style={{ data: { stroke: iconColors.xAcc } }}
      />

      {/* y-axis acceleration data */}
      <VictoryLine
        name="dvaOverviewYAlarm"
        data={props.dvaDataBlock.data}
        y="yAlarm"
        style={{ data: { stroke: iconColors.yAcc } }}
      />

      {/* z-axis acceleration data */}
      <VictoryLine
        name="dvaOverviewZAlarm"
        data={props.dvaDataBlock.data}
        y="zAlarm"
        style={{ data: { stroke: iconColors.zAcc } }}
      />
    </VictoryChart>
  );
};

export default DvaOverviewGraph;
