import React from "react";
import {
  Card,
  Col,
  InputNumber,
  Row,
  RowProps,
  Switch,
  Typography
} from "antd";
import commonColors from "../../styles/commonColors";
import { useTranslation } from "react-i18next";
import { ParamsCardParagraph, SmallTitle } from "./CommonFonts";
import { isNil } from "lodash-es";
import { WarningAlert } from "./CommonAlerts";
import { size } from "../../helpers/pageHelper";

/**
 * A module containing commonly used types, functions and components for create
 * recording parameter views.
 */

/** There are 3 sizes to choose from for width/height. 1 is the smallest and 3
 * covers the maximum amount of area */
type UnitSize = 1 | 2 | 3;

/** Width unit in px */
const widthUnit = 440;
/** Height unit in px */
const heightUnit = 130;

/** Content padding between input cards */
const contentPadding = 4;

/**
 * Determine if the current container will fit content of {UnitSize} 3.
 * Usefull to determine the order of content depending on container width.
 * @param width
 */
export const willFitWidth3 = (width: number | null | undefined) =>
  isNil(width) ? false : width > widthUnit * 3 ? false : true;

/**
 * Determine if the current container width fill fit content of {UnitSize} 2.
 * Usefull to determine the order of content depending on container width.
 * @param width
 */
export const willFitWidth2 = (width: number | null | undefined) =>
  isNil(width) ? false : width > widthUnit * 2 + contentPadding ? false : true;

// Components

interface CommonParamsInputCardProps {
  title: string;
  style?: React.CSSProperties;
  //based on 3
  width?: UnitSize;
  height?: UnitSize;
  disabled?: boolean;
  useToggle?: true;
  toggleDisabled?: boolean;
  toggleChecked?: boolean;
  toggleChangeAction?: Function;
  children: React.ReactNode;
}
/**
 * Common card style component that should be used for recording parameter inputs.
 * @param CommonParamsInputCardProps
 */
export const CommonParamsInputCard: React.FC<CommonParamsInputCardProps> = (
  props
) => {
  const width = `${
    props?.width
      ? props.width * widthUnit + (props.width - 1) * contentPadding
      : widthUnit
  }px`;

  const height = `${
    props?.height
      ? props.height * heightUnit + (props.height - 1) * contentPadding
      : heightUnit
  }px`;

  return (
    <Card
      styles={{
        body: {
          padding: size.m1
        }
      }}
      style={{
        width,
        height,
        backgroundColor: commonColors.buttonColor,
        ...props.style
      }}
    >
      <Row justify="space-between">
        <Typography.Title
          level={5}
          style={{
            color: props?.disabled ? commonColors.disabledText : "black",
            marginTop: 0,
            marginBottom: size.s2
          }}
        >
          {props.title}
        </Typography.Title>
        {props?.useToggle ? (
          <Switch
            size="small"
            disabled={props.disabled || props.toggleDisabled}
            checked={props.toggleChecked}
            onClick={() => props.toggleChangeAction?.()}
          />
        ) : (
          <div />
        )}
      </Row>
      <div>{props.children}</div>
    </Card>
  );
};

/**
 * Alternative card for recording parameter inputs.
 * @param CommonParamsInputCardProps
 */
export const ParamsInputCard: React.FC<CommonParamsInputCardProps> = (
  props
) => {
  return (
    <Card
      styles={{
        body: {
          padding: size.m1
        }
      }}
      style={{
        backgroundColor: commonColors.buttonColor,
        ...props.style
      }}
    >
      <Row justify="space-between">
        <Typography.Title
          level={5}
          style={{
            color: props?.disabled ? commonColors.disabledText : "black",
            marginTop: 0,
            marginBottom: size.s2
          }}
        >
          {props.title}
        </Typography.Title>
        {props?.useToggle ? (
          <Switch
            size="small"
            disabled={props.disabled || props.toggleDisabled}
            checked={props.toggleChecked}
            onClick={(e) => props.toggleChangeAction?.(e)}
          />
        ) : (
          <div />
        )}
      </Row>
      <div>{props.children}</div>
    </Card>
  );
};

interface ParamInputCardContainerProps {
  width: UnitSize;
  height: UnitSize | 4;
  children: React.ReactNode;
}
/**
 * Utility component that can be used to group multiple "CommonParamInputCard"
 * that should always be together
 * @param props
 */
export const ParamsInputCardContainer: React.FC<
  ParamInputCardContainerProps
> = (props) => {
  const width = `${props.width * widthUnit}px`;
  const height = `${props.height * heightUnit}px`;

  return (
    <CommonParamsMainContentRow
      style={{
        width,
        height,
        margin: 0
      }}
    >
      {props.children}
    </CommonParamsMainContentRow>
  );
};

interface SendGsmAlarmCardProps {
  hasLte: boolean;
  sendGsmTextContent: string;
  isParentParamsActive: boolean;
  useLte: boolean;
  toggleUseLte: Function;
  ignoreCount: number;
  setIgnoreCount: Function;
}

/**
 * Common parameters input card used to toggle send gsm that is used in multiple
 * recording channels
 * Note: This is not supported in fat100 yet.
 * @param props
 */
export const SendGsmAlarmCard = (props: SendGsmAlarmCardProps) => {
  const { t } = useTranslation();

  const {
    hasLte,
    sendGsmTextContent,
    isParentParamsActive,
    useLte,
    toggleUseLte,
    ignoreCount,
    setIgnoreCount
  } = props;

  /** Style used for input fields */
  const inputFieldStyle: React.CSSProperties = {
    margin: size.s2
  };

  return (
    <CommonParamsInputCard
      title={t("cpAccSendGsmAlarmTitle")}
      disabled={!hasLte}
      useToggle
      toggleChecked={useLte && hasLte}
      toggleDisabled={!isParentParamsActive}
      toggleChangeAction={toggleUseLte}
      width={1}
      height={2}
    >
      <Row>
        <ParamsCardParagraph disabled={!hasLte}>
          {sendGsmTextContent}
        </ParamsCardParagraph>
      </Row>
      {useLte && hasLte && (
        <Row style={{ width: "100%" }}>
          <WarningAlert
            style={{}}
            message={<>{t("LTEConnectionCanBeAlteredInScheduler")}</>}
          />
        </Row>
      )}
      <Row style={{ paddingBlock: size.s2 }}>
        <SmallTitle disabled={!hasLte}>
          {t("cpAccIgnoreAlarmsTitle")}
        </SmallTitle>
      </Row>
      <Row justify="space-between" wrap={false}>
        <Col flex={1}>
          <ParamsCardParagraph disabled={!hasLte}>
            {t("cpAccIgnoreAlarmsContent")}
          </ParamsCardParagraph>
        </Col>
        <Col flex={"100px"}>
          <Row wrap={false} align="middle" justify="end">
            <ParamsCardParagraph
              disabled={!hasLte}
              strong
              style={{ marginBottom: 0, whiteSpace: "nowrap" }}
            >
              {t("genRepatNTimes")}
            </ParamsCardParagraph>
            <InputNumber
              disabled={!useLte || !hasLte}
              style={inputFieldStyle}
              value={ignoreCount}
              onChange={(v) => setIgnoreCount(v)}
              min={0}
            />
          </Row>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );
};

/**
 * Main container that should be used for create recording parameters viwes
 * @param props
 */
export const CommonParamsMainContentRow: React.FC<RowProps> = (props) => (
  <Row
    wrap
    style={{ width: "100%", maxWidth: 3 * (widthUnit + contentPadding) }}
    gutter={[contentPadding, contentPadding]}
    {...props}
  >
    {props.children}
  </Row>
);

/**
 * Common row component that can be used for input fields. It consist of a
 * normal antd-row with some styling
 * @param props
 */
export const CommonParamsInputRow: React.FC<RowProps> = (props) => (
  <Row justify="space-between" align="middle" style={{ marginBlock: size.s2 }}>
    {props.children}
  </Row>
);
