import React from "react";
import { Col, Row, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { saveAsParx } from "../../helpers/paramsHelper";
import { HeaderTitle } from "../Common/CommonFonts";
import { NormalButtonSmall, SaveButton } from "../Common/CommonButtons";
import {
  selectTargetDevice,
  setUseDefaultTargetDevice
} from "../../state/paramsPageSlice";
import { selectRecordingParameters } from "../../state/openParxSlice";
import { Help } from "../MicroComponents/Help";
import ParamsBuilderStats from "./ParamsBuilderStats";

interface IProps {
  contentWidth: number;
}
const ParamsPageHeader: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const vmRecParams = useSelector(selectRecordingParameters);
  const targetDevice = useSelector(selectTargetDevice);
  const hasSerial = targetDevice.serial && targetDevice.serial !== "default";

  const dispatch = useDispatch();

  const handleSaveParx = () => {
    saveAsParx(vmRecParams, targetDevice);
  };

  let serial = targetDevice.serial;
  if (serial === "default") {
    serial = t("NoDeviceSelected");
  } else if (serial === undefined) {
    serial = t("none");
  }

  return (
    <>
      {/* outer container, makes sure that the inner conatiner is centered */}
      <Row
        justify="center"
        align="middle"
        style={{
          width: "100%",
          height: "100%"
        }}
      >
        {/* inner container with a max width */}
        <Row
          justify="space-between"
          style={{
            width: "100%",
            maxWidth: props.contentWidth
          }}
        >
          {/* left column of content */}
          <Col>
            <Space align="center" style={{ height: "100%" }}>
              <HeaderTitle strong>{`${t("serialIdTitle")}: `} </HeaderTitle>
              <HeaderTitle type="secondary">{serial}</HeaderTitle>
              {hasSerial && (
                <NormalButtonSmall
                  icon={<CloseOutlined />}
                  onClick={() => dispatch(setUseDefaultTargetDevice())}
                />
              )}
            </Space>
          </Col>
          <ParamsBuilderStats></ParamsBuilderStats>
          {/* right column of content */}
          <Col>
            <Space align="center" style={{ height: "100%" }}>
              {Help(
                <SaveButton
                  title={t("SaveParametersAsPARXFile")}
                  onClick={handleSaveParx}
                >
                  {t("SaveAs")}
                </SaveButton>,
                t("ClickHereToSaveParamsAsAFile"),
                "left"
              )}
            </Space>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default ParamsPageHeader;
