import { Col, InputNumber, Row, Space, Switch } from "antd";
import { isNull } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { maxAngleValue, minAngleValue } from "../../constants/params-defaults";
import {
  selectAngle,
  setAngleIgnoreCount,
  setAngleXAlarm,
  setAngleYAlarm,
  setAngleZAlarm,
  toggleAngle,
  toggleAngleStoreGps,
  toggleAngleUseLte,
  toggleAutoHorizon
} from "../../state/openParxSlice";
import {
  ParamsCardParagraph,
  ParamsCardSubHeader
} from "../Common/CommonFonts";
import {
  CommonParamsInputCard,
  CommonParamsInputRow,
  CommonParamsMainContentRow,
  SendGsmAlarmCard,
  willFitWidth2,
  willFitWidth3
} from "../Common/CommonParametersComponents";
import { useResizeDetector } from "react-resize-detector";
import { size } from "../../helpers/pageHelper";

interface AvailableAngleFeatures {
  hasGps: boolean;
  hasLte: boolean;
}

interface IProps {
  features: AvailableAngleFeatures;
}

const ParamsBuilderAngle: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const angleParams = useSelector(selectAngle);
  const dispatch = useDispatch();
  const { width, ref } = useResizeDetector();
  const componentWidth = width ?? window.innerWidth;

  const mainToggle = angleParams.useAngle;
  const storeGps = angleParams.channelBits.GPS ? true : false && mainToggle;
  const useLte = angleParams.channelBits.LTE ? true : false && mainToggle;
  const ignoreCount = angleParams.IgnoreCountLTE;

  const alarmLvlSpacing = { title: 12, input: 12 };

  const renderMainToggle = () => (
    <Row align="middle" style={{ marginBottom: size.s2 }}>
      <Space>
        <Switch
          checked={angleParams.useAngle}
          onChange={() => dispatch(toggleAngle())}
        />
        <ParamsCardSubHeader>{t("cpAngleMainToggleTitle")}</ParamsCardSubHeader>
      </Space>
    </Row>
  );

  const renderAlarmLevel = (withDescription: boolean) => (
    <CommonParamsInputCard
      title={t("cpAngleAlarmLevelTitle")}
      width={withDescription ? 2 : 1}
      height={2}
    >
      <Row justify="space-between" wrap={false}>
        <Col flex="auto">
          <ParamsCardParagraph>
            {t("cpAngleAlarmLevelContent")}
          </ParamsCardParagraph>
        </Col>

        <Col flex="300px" style={{ textAlign: "center" }}>
          {/* Title row */}
          <CommonParamsInputRow>
            <Col span={alarmLvlSpacing.title} />
            <Col span={alarmLvlSpacing.input}>
              <ParamsCardSubHeader>
                {t("cpAngleAlarmLevelSubTitle")}
              </ParamsCardSubHeader>
            </Col>
          </CommonParamsInputRow>
          {/* x alarm input*/}
          <CommonParamsInputRow>
            <Col span={alarmLvlSpacing.title}>
              <ParamsCardSubHeader>{t("genXAxis")}</ParamsCardSubHeader>
            </Col>
            <Col span={alarmLvlSpacing.input}>
              <InputNumber
                disabled={!angleParams.useAngle}
                value={angleParams.params.xAlarmLevel}
                onChange={(val) =>
                  isNull(val) ? {} : dispatch(setAngleXAlarm(Number(val)))
                }
                min={minAngleValue}
                max={maxAngleValue}
              />
            </Col>
          </CommonParamsInputRow>

          {/* y alarm input*/}
          <CommonParamsInputRow>
            <Col span={alarmLvlSpacing.title}>
              <ParamsCardSubHeader>{t("genYAxis")}</ParamsCardSubHeader>
            </Col>
            <Col span={alarmLvlSpacing.input}>
              <InputNumber
                disabled={!angleParams.useAngle}
                value={angleParams.params.yAlarmLevel}
                onChange={(val) =>
                  isNull(val) ? {} : dispatch(setAngleYAlarm(Number(val)))
                }
                min={minAngleValue}
                max={maxAngleValue}
              />
            </Col>
          </CommonParamsInputRow>

          {/* z alarm input*/}
          <CommonParamsInputRow>
            <Col span={alarmLvlSpacing.title}>
              <ParamsCardSubHeader>{t("genZAxis")}</ParamsCardSubHeader>
            </Col>
            <Col span={alarmLvlSpacing.input}>
              <InputNumber
                disabled={!angleParams.useAngle}
                value={angleParams.params.zAlarmLevel}
                onChange={(val) =>
                  isNull(val) ? {} : dispatch(setAngleZAlarm(Number(val)))
                }
                min={minAngleValue}
                max={maxAngleValue}
              />
            </Col>
          </CommonParamsInputRow>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );

  const renderStoreGps = () => (
    <CommonParamsInputCard
      title={t("cpAngleStoreGpsPosTitle")}
      disabled={!props.features.hasGps}
      width={1}
      height={2}
      useToggle
      toggleChecked={storeGps && props.features.hasGps}
      toggleDisabled={!mainToggle}
      toggleChangeAction={() => dispatch(toggleAngleStoreGps())}
    >
      <Row>
        <ParamsCardParagraph disabled={!props.features.hasGps}>
          {t("cpAngleStoreGpsPosContent")}
        </ParamsCardParagraph>
      </Row>
    </CommonParamsInputCard>
  );

  const renderAngleOffset = (withDescription: boolean) => (
    <CommonParamsInputCard
      title={t("cpAngleRefAngleTitle")}
      width={withDescription ? 2 : 1}
      height={2}
      useToggle
      toggleChecked={angleParams.useAutoHorizon && mainToggle}
      toggleChangeAction={() => dispatch(toggleAutoHorizon())}
      toggleDisabled={!mainToggle}
    >
      <Row justify="space-between" wrap={false}>
        <ParamsCardParagraph>{t("cpAngleRefAngleContent")}</ParamsCardParagraph>
      </Row>
    </CommonParamsInputCard>
  );

  const renderGsmAlarm = () => (
    <SendGsmAlarmCard
      hasLte={props.features.hasLte}
      sendGsmTextContent={t("cpAngleSendGsmAlarmContent")}
      isParentParamsActive={mainToggle}
      useLte={useLte}
      toggleUseLte={() => dispatch(toggleAngleUseLte())}
      ignoreCount={ignoreCount}
      setIgnoreCount={(v: number) => dispatch(setAngleIgnoreCount(v))}
    />
  );

  return (
    <div ref={ref}>
      {renderMainToggle()}
      <CommonParamsMainContentRow>
        {/* alarm level content */}
        <Col order={1}>{renderAlarmLevel(!willFitWidth2(componentWidth))}</Col>
        {/* store gps position content. Order explanation: if small screen size, put element below "alarm" */}
        <Col order={willFitWidth3(componentWidth) ? 3 : 2}>
          {renderStoreGps()}
        </Col>
        {/* Angle offset content */}
        <Col order={willFitWidth3(componentWidth) ? 2 : 3}>
          {renderAngleOffset(!willFitWidth2(componentWidth))}
        </Col>
        {/* send gsm alarm content */}
        <Col order={4}>{renderGsmAlarm()}</Col>
      </CommonParamsMainContentRow>
    </div>
  );
};

export default ParamsBuilderAngle;
