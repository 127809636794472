import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Typography } from "antd";
import {
  CaretRightOutlined,
  DownOutlined,
  PauseOutlined,
  UpOutlined,
  VerticalRightOutlined
} from "@ant-design/icons";
import { LatLngTuple } from "leaflet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setMarkedMapKeys,
  selectProcessedGpsData
} from "../../state/openDatxSlice";
import MoveableCard from "../Common/MoveableCard";
import { emptyFunction, ExtraButtonItem } from "../Common/MoveableCardProps";
import MapTable from "./MapTable";
import { throttle, isUndefined } from "lodash-es";
import { SmallTitle } from "../Common/CommonFonts";
import { size } from "../../helpers/pageHelper";
import DashboardMap from "../../assets/img/DashboardMap.png";
import "../../styles/customLeaflet.css";
import { useResizeDetector } from "react-resize-detector";
import MapDashboardContent from "./MapDashboardContent";

const { Text } = Typography;

export interface ReportsGpsPosData {
  key: number;
  path: [number, number];
  status?: number;
}

export interface ReportsDeviceData {
  firstPos?: FirstPos;
  lastPos?: LastPos;
  serialNumber: string;
  gpsPosData: ReportsGpsPosData[];
  alarms?: number;
  project?: {
    id: string;
    name: string;
  };
  key: string;
}

interface FirstPos {
  firstPath?: LatLngTuple;
  firstStatus?: number;
}

interface LastPos {
  lastPath: LatLngTuple;
  lastStatus?: number;
}

interface IProps {
  fileId: string;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}
const MapDashboardCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fileId, dragHandle, closeCard } = props;

  const { width, height, ref } = useResizeDetector();

  const {
    gpsData,
    positionsData,
    outerBounds,
    firstPositionKey,
    lastPositionKey,
    markedMapKeys,
    timezone
  } = useSelector(selectProcessedGpsData(fileId));

  const [sortState, setSortState] = useState<"asc" | "desc" | "default">(
    "default"
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Set sort direction based on sort state
  useEffect(() => {
    if (sortState === "default") {
      setSortDirection("asc");
    } else if (sortState === "desc") {
      setSortDirection("desc");
    } else if (sortState === "asc") {
      setSortDirection("asc");
    }
  }, [sortState]);

  /** Switching between sorting states like it's done in AntD tables. */
  const onSort = () => {
    if (sortState === "default") {
      setSortState("desc");
    } else if (sortState === "desc") {
      setSortState("asc");
    } else if (sortState === "asc") {
      setSortState("default");
    }
  };

  const sortedTableData = gpsData?.sort((a, b) => {
    if (sortDirection === "asc") {
      return a.key - b.key;
    } else if (sortDirection === "desc") {
      return b.key - a.key;
    }
    return b.key - a.key;
  });

  // The next key that has rowType: "gpsPosition"
  const getNextKey = (currentKey: number) => {
    let nextKey =
      sortDirection === "desc"
        ? sortedTableData.length - 1 - currentKey + 1
        : currentKey + 1;
    let foundKey = false;

    while (!foundKey && nextKey < sortedTableData.length) {
      if (sortedTableData[nextKey].rowType.includes("gpsPosition")) {
        foundKey = true;
      } else {
        nextKey++;
      }
    }

    const returnNextkey =
      sortDirection === "desc" ? sortedTableData.length - 1 - nextKey : nextKey;

    return foundKey ? returnNextkey : firstPositionKey;
  };

  // The previous key that has rowType: "gpsPosition"
  const getPrevKey = (currentKey: number) => {
    let prevKey =
      sortDirection === "desc"
        ? sortedTableData.length - 1 - currentKey - 1
        : currentKey - 1;
    let foundKey = false;

    while (!foundKey && prevKey >= 0) {
      if (sortedTableData[prevKey].rowType.includes("gpsPosition")) {
        foundKey = true;
      } else {
        prevKey--;
      }
    }

    const returnPrevKey =
      sortDirection === "desc" ? sortedTableData.length - 1 - prevKey : prevKey;

    return foundKey ? returnPrevKey : lastPositionKey;
  };

  const isMarkedKey = markedMapKeys.length === 1;
  const isFirstKey = markedMapKeys[0] === firstPositionKey;
  const isLastKey = markedMapKeys[0] === lastPositionKey;

  const getFirstKeyMark = (): number[] => {
    return !isUndefined(firstPositionKey) ? [firstPositionKey] : [];
  };

  const getLastKeyMark = (): number[] => {
    return !isUndefined(lastPositionKey) ? [lastPositionKey] : [];
  };

  const prevPos = useCallback(() => {
    const prevKey = getPrevKey(markedMapKeys[0]);
    const markedKeys = prevKey !== undefined ? [prevKey] : [];
    dispatch(setMarkedMapKeys({ fileId, markedKeys }));
  }, [markedMapKeys, dispatch, fileId, sortDirection]);

  const nextPos = useCallback(() => {
    if (markedMapKeys.length === 0) {
      const markedKeys = getFirstKeyMark();
      dispatch(setMarkedMapKeys({ fileId, markedKeys }));
    } else {
      const nextKey = getNextKey(markedMapKeys[0]);
      const markedKeys = nextKey !== undefined ? [nextKey] : [];
      dispatch(setMarkedMapKeys({ fileId, markedKeys }));
    }
  }, [markedMapKeys, dispatch, fileId, sortDirection]);

  const lastPos = () => {
    const markedKeys = getLastKeyMark();
    dispatch(setMarkedMapKeys({ fileId, markedKeys }));
  };

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const startRoute = () => {
    if (markedMapKeys.length === 0) {
      const markedKeys = getFirstKeyMark();
      dispatch(setMarkedMapKeys({ fileId, markedKeys }));
    } else {
      const nextKey = getNextKey(markedMapKeys[0]);
      const markedKeys = nextKey !== undefined ? [nextKey] : getFirstKeyMark();
      dispatch(setMarkedMapKeys({ fileId, markedKeys }));
    }

    setIsPlaying(true);
  };

  const pauseRoute = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  /** Function attached to keyboard arrow-keys events.
   * Used to move GPS position in map. */
  const keyDownCallback = useMemo(
    () =>
      throttle((evt: KeyboardEvent) => {
        if (evt.key === "ArrowDown") {
          evt.preventDefault();
          if (sortDirection === "asc" && !isLastKey) {
            nextPos();
          }
          if (sortDirection === "desc" && !isFirstKey) {
            nextPos();
          }
        } else if (evt.key === "ArrowUp") {
          evt.preventDefault();
          if (sortDirection === "asc" && !isFirstKey) {
            prevPos();
          }
          if (sortDirection === "desc" && !isLastKey) {
            prevPos();
          }
        } else if (evt.key === "Space") {
          evt.preventDefault();
          pauseRoute();
        }
      }, 200),
    [nextPos, prevPos, pauseRoute]
  );

  useEffect(() => {
    if (!isPlaying) {
      document.addEventListener("keydown", keyDownCallback, false);
      /** clean up when component unmounts */
      return () => {
        document.removeEventListener("keydown", keyDownCallback, false);
      };
    }
  }, [keyDownCallback]);

  // Adjust table width to fit the table with scrollbar
  const tableWidth = width ? Math.floor(width / 2) : 0;

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      tooltip:
        sortDirection === "desc"
          ? t("DisabledInDescendingOrder")
          : !isPlaying
            ? t("PlayTheRouteOnTheMap")
            : t("PauseTheRouteOnTheMap"),
      button: "custom",
      disabled: sortDirection === "desc",
      action: !isPlaying ? startRoute : pauseRoute,
      icon: !isPlaying ? <CaretRightOutlined /> : <PauseOutlined />
    },
    {
      title: "",
      tooltip:
        sortDirection === "desc"
          ? t("SelectNextPosition")
          : t("SelectPreviousPosition"),
      button: "custom",
      action: prevPos,
      disabled:
        (sortDirection === "asc" ? isFirstKey : isLastKey) ||
        !isMarkedKey ||
        isPlaying,
      icon: <UpOutlined />
    },
    {
      title: "",
      tooltip:
        sortDirection === "desc"
          ? t("SelectPreviousPosition")
          : t("SelectNextPosition"),
      button: "custom",
      action: nextPos,
      disabled:
        (sortDirection === "asc" ? isLastKey : isFirstKey) ||
        !isMarkedKey ||
        isPlaying,
      icon: <DownOutlined />
    },
    {
      title: "",
      tooltip: t("SelectLastPosition"),
      button: "custom",
      action: lastPos,
      disabled: isLastKey || isPlaying,
      icon: (
        <VerticalRightOutlined rotate={sortDirection === "asc" ? 270 : 90} />
      )
    },
    {
      title: "",
      button: "close",
      action: emptyFunction
    }
  ];

  // If saved card-size is smaller than its original size the map bounds messes up. invalidateSize prevents that.
  // This code also causes the application to crash when switching between DATX files as it tries to access a map that no longer exist.
  // useEffect(() => {
  //   mapState?.invalidateSize();
  // }, [currentHeight, currentWidth]);

  return (
    <>
      <MoveableCard
        title={t("Map")}
        dragHandle={dragHandle}
        closeMe={closeCard}
        extraButtons={extraButtons}
        fileId={fileId}
      >
        <Row
          style={{ height: "100%", width: "100%", overflow: "hidden" }}
          id={"current"}
          ref={ref}
        >
          <Col span={12}>
            {outerBounds.length > 0 ? (
              <MapDashboardContent
                fileId={fileId}
                positionsData={positionsData}
                outerBounds={outerBounds}
                firstPositionKey={firstPositionKey}
                lastPositionKey={lastPositionKey}
                markedMapKeys={markedMapKeys}
                height={height ?? 0}
                width={width ?? 0}
                setIsPlaying={setIsPlaying}
                isPlaying={isPlaying}
                nextPos={nextPos}
                getFirstKeyMark={getFirstKeyMark}
                getLastKeyMark={getLastKeyMark}
              />
            ) : (
              // NO GPS DATA
              <div>
                <Row
                  justify="center"
                  align="middle"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "220px",
                    zIndex: 1040
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.12)",
                      padding: size.m1,
                      borderRadius: size.m1,
                      boxShadow: "0px 0px 75px 10px rgba(0,0,0,0.12)"
                    }}
                  >
                    <Row justify="center">
                      <SmallTitle style={{ marginBottom: 0 }}>
                        {t("LocationsNotFound")}
                      </SmallTitle>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                      <Text>{t("ThereAreNoGPSPositionsInThisFile")}</Text>
                    </Row>
                  </div>
                </Row>
                <div
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    height: "220px"
                  }}
                >
                  <img
                    src={DashboardMap}
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      filter: "blur(6px)",
                      zIndex: 1035
                    }}
                  />
                </div>
              </div>
            )}
          </Col>
          <Col span={12}>
            {height && (
              <MapTable
                fileId={fileId}
                tableData={gpsData}
                markedMapKeys={markedMapKeys}
                isPlaying={isPlaying}
                tableHeight={height}
                tableWidth={tableWidth}
                timezone={timezone}
                sortDirection={sortDirection}
                sortState={sortState}
                onSort={onSort}
              />
            )}
          </Col>
        </Row>
      </MoveableCard>
    </>
  );
};

export default MapDashboardCard;
