import React from "react";
import { Row, Col, Tabs } from "antd";
import {
  AuditOutlined,
  CreditCardOutlined,
  ShoppingOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import AdminUsers from "../components/AdminPage/AdminUsers";
import LicenseStore from "../components/AdminPage/LicenseStore";
import AdminLicenses from "../components/AdminPage/AdminLicenses";
import AdminOrders from "../components/AdminPage/AdminOrders";
import { LicenseAccess } from "../components/MicroComponents/LicenseAccess";
import Online from "../components/MicroComponents/Online";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentAdminTab,
  selectNavigation,
  setCurrentAdminTab
} from "../state/navigationSlice";
import { size } from "../helpers/pageHelper";
import LicenseRenewalModal from "../components/AdminPage/LicenseRenewalModal";

const { TabPane } = Tabs;

const AdminPage = () => {
  const { t } = useTranslation();
  const iconStyle = { marginRight: size.s1 };
  const navigation = useSelector(selectNavigation);
  const currentTab = navigation.currentAdminTab;
  const dispatch = useDispatch();
  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify="center" style={{ padding: size.m1, paddingTop: 0 }}>
            <Tabs
              centered
              size="large"
              style={{ width: "100%" }}
              tabBarStyle={{
                marginBottom: 0,
                marginInline: size.m1
              }}
              activeKey={currentTab}
              onChange={(key) =>
                dispatch(setCurrentAdminTab(key as CurrentAdminTab))
              }
              destroyInactiveTabPane={true}
            >
              {LicenseAccess(
                "AFA",
                <TabPane
                  tab={
                    <span>
                      <TeamOutlined style={iconStyle} />
                      {t("Users")}
                    </span>
                  }
                  key="users"
                >
                  <Online>
                    <AdminUsers />
                  </Online>
                </TabPane>
              )}
              {LicenseAccess(
                "AFB",
                <TabPane
                  tab={
                    <>
                      <AuditOutlined style={iconStyle} />
                      {t("Licenses")}
                    </>
                  }
                  key="licenses"
                >
                  <Online>
                    <AdminLicenses />
                  </Online>
                </TabPane>
              )}
              {LicenseAccess(
                "AFB",
                <TabPane
                  tab={
                    <>
                      <CreditCardOutlined style={iconStyle} />
                      {t("Invoices")}
                    </>
                  }
                  key="invoices"
                >
                  <Online>
                    <AdminOrders />
                  </Online>
                </TabPane>
              )}
              {LicenseAccess(
                "AFB",
                <TabPane
                  tab={
                    <>
                      <ShoppingOutlined style={iconStyle} />
                      {t("BuyLicenses")}
                    </>
                  }
                  key="store"
                >
                  <Online>
                    <LicenseStore />
                  </Online>
                </TabPane>
              )}
            </Tabs>
          </Row>
        </Col>
      </Row>
      <LicenseRenewalModal />
    </>
  );
};

export default AdminPage;
