import React from "react";
import { Card, Tooltip, Badge } from "antd";
import {
  VictoryScatter,
  VictoryVoronoiContainer,
  VictoryTooltip,
  FlyoutProps,
  VictoryScatterProps,
  VictoryGroup,
  VictoryTheme,
  VictoryBar
} from "victory";
import { EventPropTypeInterface } from "victory-core";
import { iconColors } from "../../constants/colors";
import {
  DataFilterStates,
  setNewCursorPosition
} from "../../state/openDatxSlice";
import {
  commonPrimaryGraphProps,
  getCanvasOffset,
  isItemWithinDomain
} from "../../helpers/graphHelper";
import { isNil, isEmpty } from "lodash-es";
import { Optional } from "../../utils/utilTypes";
import { dateToUnix } from "../../helpers/dateHelper";
import { useDispatch } from "react-redux";
import { ExtTimerIcon } from "../../icons";
import { DTExternalTimer } from "../../models/DTTypes";
import { toMsStr } from "../../helpers/dataModelHelper";

export interface ExternalTimersGraphData {
  dataFilters: DataFilterStates;
  externalTimers?: ExternalTimer[];
}

/** Data type for External Timer symbols in status graph */
export interface ExternalTimer {
  x: Date;
  y: number;
  data: DTExternalTimer;
  occurrences?: number;
}

interface TypedTimerDatumProps {
  datum?: ExternalTimer;
}

/** The height of the component. Can be used for various calculations in the component */
const compHeight = 50;

/** Determina if obj is of type ExternalTimer */
const isExternalTimer = (obj: any): obj is ExternalTimer => {
  const maybeExternalIO = obj as ExternalTimer;

  return (
    !isNil(maybeExternalIO.data) &&
    !isNil(maybeExternalIO.data.sensorId) &&
    !isNil(maybeExternalIO.data.timerTime) &&
    !isNil(maybeExternalIO.x) &&
    !isNil(maybeExternalIO.y)
  );
};

/** Local component used to render output icon in graph */
const RenderTimerIcon: React.FC<VictoryScatterProps & TypedTimerDatumProps> = (
  props
) => {
  const { x, y, datum } = props;

  //if any of the used values from props is undefined, we can't continue
  if (isNil(x) || isNil(y) || isNil(datum)) {
    return null;
  }

  const { occurrences } = datum;

  const width = 60;
  const height = compHeight - 2;
  const midX = Number(x) - 25;

  return (
    <g style={{ pointerEvents: "none" }} x={midX} y={Number(y)}>
      <foreignObject x={midX} y={2} width={width} height={height}>
        <div style={{ width: width, height: height, padding: 10 }}>
          <Badge
            count={occurrences ?? 0}
            style={{ backgroundColor: iconColors.timer }}
          >
            <ExtTimerIcon style={{ fontSize: "2em" }} />
          </Badge>
        </div>
      </foreignObject>
    </g>
  );
};

/** Local component used to render graph tooltips */
const RenderTooltip: React.FC<FlyoutProps & TypedTimerDatumProps> = (props) => {
  const { x, y, datum } = props;

  //if any of the used values from props is undefined, we can't continue
  if (isNil(x) || isNil(y) || isNil(datum)) {
    return null;
  }

  if (isExternalTimer(datum)) {
    return renderTimerTooltip(datum, x, y);
  }

  return null;
};

/** Render IO data tooltip as a component */
const renderTimerTooltip = (datum: ExternalTimer, x: number, y: number) => {
  const { timerTime } = datum.data;
  return (
    <g style={{ pointerEvents: "none" }} y={0} x={x}>
      <foreignObject x={x} y={y} width={compHeight} height={compHeight}>
        <Tooltip
          getPopupContainer={(triggerNode: HTMLElement) =>
            document.getElementById("primarygraph") ||
            (triggerNode.parentNode as HTMLElement)
          }
          placement="bottom"
          open={true}
          title={() => <p style={{ marginBottom: 2 }}>{toMsStr(timerTime)}</p>}
        />
      </foreignObject>
    </g>
  );
};

/**
 * Helper function that makes sure that timer data don't overlap in graph and
 * don't include data points outside the domain
 * @param data
 * @param domain
 * @param maxDataPoints Max amount of data points in the returned array
 */
export const prepareTimerDataForGraph = (
  data: Optional<ExternalTimer[]>,
  domain: [Date, Date],
  maxDataPoints: number
) => {
  if (isNil(data)) {
    return undefined;
  }

  /** total amount of seconds for domain */
  const diff = (domain[1].getTime() - domain[0].getTime()) / 1e3;

  /** how many seconds space 1 data unit takes */
  const unit = diff / maxDataPoints;

  return data.reduce((arr: ExternalTimer[], item, index) => {
    //outside of domain
    if (!isItemWithinDomain(item, domain)) {
      return arr;
    }

    /** Previously added value */
    const prev = arr.pop();

    if (isNil(prev)) {
      return [item];
    }

    /** amount of seconds between 2 data points */
    const innerDiff = dateToUnix(item.x) - dateToUnix(prev.x);

    /** if 2 timers is too close group them together */
    if (innerDiff < unit / 2) {
      const highest = prev.data.timerTime > item.data.timerTime ? prev : item;

      /** this data point now includes more than one */
      const occurrences = prev?.occurrences ? prev.occurrences + 1 : 2;
      arr.push({ ...highest, occurrences });
    } else {
      arr.push(prev, item);
    }

    return arr;
  }, []);
};

interface IProps {
  data: ExternalTimersGraphData;
  zoomDomain: { x: [Date, Date] };
  width: number;
  height: number;
}
const ExternalTimersGraph: React.FC<IProps> = (props) => {
  const { data, zoomDomain } = props;
  const dispatch = useDispatch();

  /** Max ammount of data points in the graph */
  const maxDataPoints = props.width > 1500 ? 20 : props.height > 1000 ? 15 : 8;

  // Pixles per ms ratio
  const pixlesInMs =
    props.width / (zoomDomain.x[1].getTime() - zoomDomain.x[0].getTime());

  const getBarWidth = (timerTime: number) => {
    const width = timerTime * pixlesInMs;
    return width > 1.5 ? width : 1.5;
  };

  // Data to render in graph
  const extTimerData = !data.dataFilters.extTimer.dataToggle.isActive
    ? []
    : prepareTimerDataForGraph(
        data.externalTimers,
        zoomDomain.x,
        maxDataPoints
      );

  //Variables determining which data the graph should render
  const shouldRenderExternalTimers = !isEmpty(extTimerData);

  const leftOffset = getCanvasOffset(props.data.dataFilters);

  const statusEvents: EventPropTypeInterface<"parent", ""> = {
    target: "parent",
    eventHandlers: {
      onMouseDown: () => ({
        target: "parent",
        mutation: (targetProps) => {
          const { activePoints } = targetProps;
          if (activePoints.length > 0) {
            dispatch(setNewCursorPosition(dateToUnix(activePoints[0].x)));
          }
        }
      })
    }
  };
  return (
    <Card
      style={{
        width: "100%",
        height: `${compHeight}px`,
        padding: "0px 0px 0px 0px",
        marginTop: "10px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset"
      }}
      styles={{
        body: {
          padding: "0 0 0 0",
          height: "100%",
          marginLeft: `${leftOffset - commonPrimaryGraphProps.chartPadding.right}px`
        }
      }}
    >
      <VictoryGroup
        standalone={true}
        events={[statusEvents]}
        width={props.width}
        height={props.height}
        scale="time"
        domain={{ x: props.zoomDomain.x, y: [-1, 6] }}
        //same padding/domain padding as primary graph
        domainPadding={commonPrimaryGraphProps.domainPadding}
        padding={{
          left: commonPrimaryGraphProps.chartPadding.right,
          right: commonPrimaryGraphProps.chartPadding.right
        }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={() => " "}
            name="tooltipContainer"
            labelComponent={
              <VictoryTooltip flyoutComponent={<RenderTooltip />} />
            }
          />
        }
        theme={VictoryTheme.material}
      >
        {shouldRenderExternalTimers && (
          <VictoryBar
            name="externalTimersBars"
            data={extTimerData}
            y={() => 0}
            y0={() => -1}
            style={{ data: { fill: iconColors.timer } }}
            barWidth={(d) => getBarWidth(d.datum.data.timerTime)}
            alignment="end"
          />
        )}

        {/* externalTimers */}
        {shouldRenderExternalTimers && (
          <VictoryScatter
            name="externalTimers"
            data={extTimerData}
            dataComponent={<RenderTimerIcon />}
            size={8}
          />
        )}
      </VictoryGroup>
    </Card>
  );
};

export default React.memo(ExternalTimersGraph);
