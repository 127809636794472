import mobitronColors from "./mobitronColors";

const commonColors = {
  menuColor: mobitronColors.altDarkGreen,
  subMenuColor: "#ffffff",
  bg: "#EAEAEA",
  disabledText: "#ACABAB",
  buttonColor: "#FFFFFF",
  buttonText: "#6a936a",
  darkGrayText: "#595959",
  paramsCardBg: "#A8AAAD",
  disabledTextOnDarkBg: "#BFBFBF",
  fat100Yellow: "#EED813",
  fat100Green: "#38b740",
  axisGray: "#90a4ae"
};

export default commonColors;
