import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { Checkbox, Col, Input, Popover, Row, Space, Typography } from "antd";
import { Buffer } from "buffer";
import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toGStr } from "../../helpers/dataModelHelper";
import {
  parseDtAcc,
  parseDtAngle,
  parseDtPressure,
  parseDtRh,
  parseDtTemp
} from "../../helpers/parsers/parseDataTypesHelper";
import {
  AccGeneral,
  AngleIcon,
  PressureIcon,
  RhIcon,
  TempIcon
} from "../../icons";
import {
  DT_Acc,
  DT_Angle,
  DT_Pressure,
  DT_Rh,
  DT_Temp
} from "../../models/FAT100DataTypes";
import {
  ProjectAlarms,
  useGetProjectAlarmQuery
} from "../../state/cargologRestApi";
import { projectsState } from "../../state/projectSlice";
import { NormalButton } from "../Common/CommonButtons";
import { size } from "../../helpers/pageHelper";
import AlarmsTabTable from "./AlarmsTabTable";

const { Text, Title } = Typography;

interface AlarmMetaData {
  sensor: number;
  serial?: string;
  timestamp: string;
  parameterId: string;
  fileName: string;
}

interface AccAlarmData {
  sensorname: "acc";
  xAcc: number;
  yAcc: number;
  zAcc: number;
  duration: number;
}

interface TempAlarmData {
  sensorname: "temp";
  temp: number;
}

interface RhAlarmData {
  sensorname: "rh";
  rh: number;
}

interface AngleAlarmData {
  sensorname: "angle";
  xAngle: number;
  yAngle: number;
  zAngle: number;
}

interface PressureAlarmData {
  sensorname: "pressure";
  pressureRaw: number;
  pressureComp: number;
}

export type AlarmData =
  | AccAlarmData
  | TempAlarmData
  | RhAlarmData
  | AngleAlarmData
  | PressureAlarmData
  | undefined;

export interface AlarmRow extends AlarmMetaData {
  data: AlarmData;
}

export const renderAlarmSensor = (alarmSensor: number, t: TFunction) => {
  switch (alarmSensor) {
    case DT_Acc:
      return (
        <Text>
          <AccGeneral /> {t("AccSensor")}
        </Text>
      );
    case DT_Temp:
      return (
        <Text>
          <TempIcon /> {t("TempSensor")}
        </Text>
      );
    case DT_Rh:
      return (
        <Text>
          <RhIcon /> {t("RhSensor")}
        </Text>
      );
    case DT_Angle:
      return (
        <Text>
          <AngleIcon /> {t("AngleSensor")}
        </Text>
      );
    case DT_Pressure:
      return (
        <Text>
          <PressureIcon />
          {t("PressureSensor")}
        </Text>
      );
  }
};

export const round2Decimals = (v: number) => Math.round(v * 100) / 100;

export const accString = (data?: number) =>
  data ? toGStr(Math.abs(data)) : "N/A";

export const getAlarmData = (alarm: ProjectAlarms): AlarmData => {
  switch (alarm.type) {
    case DT_Acc:
      const accData = parseDtAcc(Buffer.from(alarm.data));
      const duration = Math.max(
        accData.xAcc[1],
        accData.yAcc[1],
        accData.zAcc[1]
      );
      return {
        sensorname: "acc",
        xAcc: accData.xAcc[0],
        yAcc: accData.yAcc[0],
        zAcc: accData.zAcc[0],
        duration
      };
    case DT_Temp:
      return { sensorname: "temp", temp: parseDtTemp(Buffer.from(alarm.data)) };
    case DT_Rh:
      return { sensorname: "rh", rh: parseDtRh(Buffer.from(alarm.data)) };
    case DT_Angle:
      const angleData = parseDtAngle(Buffer.from(alarm.data));
      return {
        sensorname: "angle",
        xAngle: angleData[0],
        yAngle: angleData[1],
        zAngle: angleData[2]
      };
    case DT_Pressure:
      const pressureData = parseDtPressure(Buffer.from(alarm.data));
      return {
        sensorname: "pressure",
        pressureRaw: pressureData[0],
        pressureComp: pressureData[1]
      };
  }
};

const AlarmsTab = () => {
  const { t } = useTranslation();

  const { selectedProjectId } = useSelector(projectsState);
  const { data: projectAlarms, isLoading: loadingAlarms } =
    useGetProjectAlarmQuery(
      selectedProjectId ? { id: selectedProjectId } : skipToken
    );

  // Filters row
  const [searchQuery, setSearchQuery] = useState<string>("");
  const selectableDevices = projectAlarms
    ? [...new Set(projectAlarms.map((alarm) => alarm.serial))]
    : [];
  const [filterDeviceNames, setFilterDeviceNames] = useState<string[]>([]);
  const selectableSensors = projectAlarms
    ? [...new Set(projectAlarms.map((alarm) => alarm.type))]
    : [];
  const [filterSensors, setFilterSensors] = useState<number[]>([]);

  // Main data parsing and filtering
  const alarmsData: AlarmRow[] = projectAlarms
    ? projectAlarms
        .map((alarm, index) => ({
          key: index,
          sensor: alarm.type,
          serial: alarm.serial,
          timestamp: alarm.dateTime,
          parameterId: alarm.parameterId,
          fileName: alarm.fileName,
          data: getAlarmData(alarm)
        }))
        .filter(
          (alarm) =>
            (filterDeviceNames.length === 0 ||
              filterDeviceNames.find(
                (deviceName) => deviceName === alarm.serial
              )) &&
            (filterSensors.length === 0 ||
              filterSensors.find(
                (sensorValue) => sensorValue === alarm.sensor
              )) &&
            (searchQuery === "" ||
              alarm.serial.toLowerCase().includes(searchQuery.toLowerCase()))
        )
        .sort((a, b) => {
          if (a.timestamp < b.timestamp) return 1;
          if (a.timestamp > b.timestamp) return -1;
          return 0;
        })
    : [];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Title style={{ marginBlock: 0, paddingLeft: size.m2 }} level={5}>
            {t("Alarms")}
          </Title>
        </Col>
        <Col>
          <Space>
            <Popover
              placement="bottom"
              trigger="click"
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <Checkbox.Group
                  onChange={(v) => setFilterDeviceNames(v as string[])}
                  style={{ flexDirection: "column" }}
                >
                  {selectableDevices.length > 0 &&
                    selectableDevices.map((deviceName) => (
                      <Checkbox
                        defaultChecked={true}
                        value={deviceName}
                        checked={
                          filterDeviceNames &&
                          filterDeviceNames?.includes(deviceName)
                            ? true
                            : false
                        }
                        key={deviceName}
                      >
                        {deviceName}
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              }
            >
              <NormalButton
                icon={<FilterOutlined />}
                style={{
                  marginBottom: size.m2
                }}
                active={filterDeviceNames.length > 0}
              >
                {t("SerialNumber")}
              </NormalButton>
            </Popover>
            <Popover
              placement="bottom"
              trigger="click"
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              content={
                <Checkbox.Group
                  onChange={(v) => setFilterSensors(v as number[])}
                  style={{ flexDirection: "column" }}
                >
                  {selectableSensors.length > 0 &&
                    selectableSensors.map((sensorValue, index) => (
                      <Checkbox
                        defaultChecked={true}
                        value={sensorValue}
                        checked={
                          filterSensors && filterSensors?.includes(sensorValue)
                            ? true
                            : false
                        }
                        key={index}
                      >
                        {renderAlarmSensor(sensorValue, t)}
                      </Checkbox>
                    ))}
                </Checkbox.Group>
              }
            >
              <NormalButton
                icon={<FilterOutlined />}
                style={{
                  marginBottom: size.m2
                }}
                active={filterSensors.length > 0}
              >
                {t("Sensor")}
              </NormalButton>
            </Popover>
            <Input
              placeholder={t("Search")}
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchQuery(e.target.value)}
              allowClear
              style={{ marginBottom: size.m2 }}
            />
          </Space>
        </Col>
      </Row>
      <AlarmsTabTable
        alarmsData={alarmsData}
        loadingAlarms={loadingAlarms}
        isExport={false}
      />
    </>
  );
};

export default AlarmsTab;
