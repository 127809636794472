import React from "react";
import { LineChartOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";
import { SmallText } from "../Common/CommonFonts";

export const GraphEmpty = () => {
  const { t } = useTranslation();

  return (
    <>
      <Avatar shape="square" size={64} icon={<LineChartOutlined />} />
      <SmallText strong style={{ padding: size.m1 }}>
        {t("NoGraphSelectedSelectADva")}
      </SmallText>
    </>
  );
};
