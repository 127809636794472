import React, { useEffect, useState } from "react";
import mobiColors from "../../styles/mobitronColors";
import { Collapse, List, Typography, Row, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  closeOpenDatxFile,
  selectAllOpenDatx,
  setActiveDatxFile
} from "../../state/openDatxSlice";
import { ellipsisIfLongTextStyle } from "../Common/CommonFonts";
import { NormalButtonSmall } from "../Common/CommonButtons";

interface IProps {}
const OpenFiles: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { activeFileId, openFiles } = useSelector(selectAllOpenDatx);
  const dispatch = useDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    //automaticly opens the menu when the first file is opened
    if (openFiles?.length === 1) {
      setIsMenuOpen(true);
    }
  }, [openFiles]);

  return (
    <Collapse
      activeKey={isMenuOpen ? ["openFiles"] : undefined}
      expandIconPosition="end"
      ghost
      onChange={(_) => {
        setIsMenuOpen((z) => !z);
      }}
    >
      <Collapse.Panel
        header={t("openFilesGraph")}
        key="openFiles"
        className="custom"
      >
        {openFiles?.length > 0 && (
          <List
            size="small"
            split={false}
            dataSource={openFiles}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                style={{ paddingTop: 0, paddingRight: 0, paddingLeft: 0 }}
              >
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ width: "100%" }}
                  wrap={false}
                >
                  <Tooltip placement="right" title={item.filePath}>
                    <Typography.Link
                      onClick={() => dispatch(setActiveDatxFile(item.id))}
                      style={{
                        ...ellipsisIfLongTextStyle,
                        maxWidth: "85%",
                        color:
                          item.id === activeFileId
                            ? mobiColors.altDarkGreen
                            : "black"
                      }}
                    >
                      {item.filePath}
                    </Typography.Link>
                  </Tooltip>
                  <NormalButtonSmall
                    icon={<CloseOutlined title={t("closeFileTip")} />}
                    onClick={() => dispatch(closeOpenDatxFile(item.id))}
                  />
                </Row>
              </List.Item>
            )}
          />
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default OpenFiles;
