import React, { useEffect, useState } from "react";
import {
  IParsedGPSData,
  selectDatxOptionalFiltersById,
  setMarkedMapKeys
} from "../../state/openDatxSlice";
import {
  CircleMarker,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMapEvents
} from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import L, { LatLngTuple, Map } from "leaflet";
import { HomeTwoTone } from "@ant-design/icons";
import ReactDOMServer from "react-dom/server";
import { deviceIcon } from "../DevicesPage/DeviceMap";
import { connectionColors } from "../../constants/colors";

interface IClusterData {
  position: LatLngTuple; // [latitude, longitude]
  numberOfPoints: number; // number of points in cluster
  key: number;
}

interface IProps {
  fileId: string;
  positionsData: IParsedGPSData[];
  outerBounds: LatLngTuple[];
  firstPositionKey?: number;
  lastPositionKey?: number;
  markedMapKeys: number[];
  width: number;
  height: number;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isPlaying: boolean;
  nextPos: () => void;
  getFirstKeyMark: () => number[];
  getLastKeyMark: () => number[];
}
const MapDashboardContent: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const {
    fileId,
    positionsData,
    outerBounds,
    firstPositionKey,
    lastPositionKey,
    markedMapKeys,
    height,
    setIsPlaying,
    isPlaying
  } = props;

  const filters = useSelector(selectDatxOptionalFiltersById(fileId));
  const [mapState, setMapState] = useState<Map | null>(null);
  const [clusterData, setClusterData] = useState<IClusterData[]>([]);
  const [duplicatePositionKeys, setDuplicatePositionKeys] = useState<number[]>(
    []
  );

  // Get the absolute distance between two positions in display pixels
  const getDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ) => {
    const p1 = mapState?.latLngToContainerPoint([lat1, lng1]) || { x: 0, y: 0 };
    const p2 = mapState?.latLngToContainerPoint([lat2, lng2]) || { x: 0, y: 0 };
    return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
  };

  // Get clustered data
  const getClusteredData = () => {
    const clusterDistance = 20 * 20; // Squared in order to avoid using Math.sqrt
    let clusters: IClusterData[] = [];
    let duplicatePositions: number[] = [];
    // If two or more locations are within clusterDistance px of each other, add them to the same cluster.
    positionsData.forEach((data) => {
      if (data.position?.latitude && data.position?.longitude) {
        let index = 0;
        let foundCluster = false;
        const lat1 = data.position.latitude;
        const lng1 = data.position.longitude;
        while (!foundCluster && index < clusters.length) {
          const lat2 = clusters[index].position[0];
          const lng2 = clusters[index].position[1];
          if (getDistance(lat1, lng1, lat2, lng2) < clusterDistance) {
            clusters[index].numberOfPoints++;
            duplicatePositions.push(data.key);
            foundCluster = true;
          } else {
            index++;
          }
        }
        if (!foundCluster) {
          clusters.push({
            position: [data.position.latitude, data.position.longitude],
            numberOfPoints: 1,
            key: data.key
          });
        }
      }
    });
    // Remove clusters with only one point in it
    clusters = clusters.filter((cluster) => cluster.numberOfPoints > 1);
    return { clusters, duplicatePositions };
  };

  const updateClusters = () => {
    const { clusters, duplicatePositions } = getClusteredData();
    setClusterData(clusters);
    setDuplicatePositionKeys(duplicatePositions);
  };
  const MapHook = () => {
    const map = useMapEvents({
      zoomend: () => {
        updateClusters();
      },
      mousedown: (e) => {
        // Prevents CC from crashing when "click-dragging" with scroll wheel button in the map
        if (e.originalEvent.button === 1) {
          e.originalEvent.preventDefault();
          return false;
        }
      }
    });
    return null;
  };
  useEffect(() => {
    updateClusters();
    // Fix for map not updating size correctly and missing tiles
    setTimeout(() => {
      mapState?.invalidateSize();
    }, 100);
  }, [positionsData]);

  const curPos = positionsData.find((pos) => pos.key === markedMapKeys[0]);
  let selectedLatLong: LatLngTuple | undefined = curPos?.position
    ? [curPos.position.latitude, curPos.position.longitude]
    : undefined;

  let center: LatLngTuple = selectedLatLong ?? [14, 14];
  useEffect(() => {
    if (isPlaying === true) {
      setTimeout(() => {
        props.nextPos();
      }, 300);
    }
  }, [isPlaying, markedMapKeys, props.nextPos]);

  // Set isPlaying to false when markedMapKeys has reached the end of the array
  if (markedMapKeys[0] === positionsData.length - 1 && isPlaying === true) {
    setIsPlaying(false);
  }

  // Only re-centering map when markedMapKey is outside of map view
  useEffect(() => {
    if (
      selectedLatLong &&
      mapState?.getBounds()?.contains(selectedLatLong) === false
    ) {
      mapState?.panTo(selectedLatLong);
    }
  }, [selectedLatLong]);

  const isFiltered = filters.gps.hideSensorData || filters.gps.hideScheduleData;

  /** Map has a minHeight of 200px or else the same px-height as its row */
  const mapHeight = () => {
    if (height) {
      if (height < 200) {
        return 200;
      } else {
        return height;
      }
    } else {
      return "100%";
    }
  };

  const linePositions: LatLngTuple[] = positionsData
    .filter((pos) => pos.position)
    .map((pos) => {
      return [pos.position!.latitude, pos.position!.longitude];
    });

  return (
    <>
      <MapContainer
        style={{
          width: "100%",
          height: mapHeight()
        }}
        keyboard={false}
        center={outerBounds.length <= 1 ? center : undefined}
        bounds={outerBounds.length > 1 ? outerBounds : undefined}
        zoom={11}
        scrollWheelZoom={true}
        ref={setMapState}
      >
        <MapHook />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={20}
        />

        {/* Polyline */}
        <Polyline
          pathOptions={{ opacity: 0.3 }}
          positions={linePositions}
          color={"#006600"}
          pane="overlayPane"
        />

        {/* Dots, start and stop */}
        {positionsData.length > 0 &&
          positionsData.map((position, index) => {
            // Start marker
            if (position.key === firstPositionKey && !isFiltered) {
              const startMarker = new L.DivIcon({
                iconAnchor: [10, 10],
                popupAnchor: [0.5, -10],
                html: ReactDOMServer.renderToString(
                  <HomeTwoTone
                    twoToneColor="#006600"
                    style={{ fontSize: 20 }}
                  />
                ),
                className: "custom-icon"
              });
              return (
                <Marker
                  key={index}
                  position={[
                    position.position!.latitude,
                    position.position!.longitude
                  ]}
                  icon={startMarker}
                  pane={"markerPane"}
                  eventHandlers={{
                    click: () => {
                      dispatch(
                        setMarkedMapKeys({
                          fileId,
                          markedKeys: props.getFirstKeyMark()
                        })
                      );
                    }
                  }}
                ></Marker>
              );
            }
            // End marker
            else if (position.key === lastPositionKey && !isFiltered) {
              return (
                <Marker
                  key={index}
                  position={[
                    position.position!.latitude,
                    position.position!.longitude
                  ]}
                  icon={deviceIcon}
                  pane={"markerPane"}
                  // zIndexOffset={100000}
                  eventHandlers={{
                    click: () => {
                      dispatch(
                        setMarkedMapKeys({
                          fileId,
                          markedKeys: props.getLastKeyMark()
                        })
                      );
                    }
                  }}
                ></Marker>
              );
            }
            // Normal markers
            else if (!duplicatePositionKeys.includes(position.key)) {
              return (
                <div style={{ zIndex: 40 }} key={index}>
                  <CircleMarker
                    key={position.key}
                    center={[
                      position.position!.latitude,
                      position.position!.longitude
                    ]}
                    radius={3}
                    color={position.alarm ? connectionColors.sensor : "#006600"}
                    eventHandlers={{
                      click: () => {
                        dispatch(
                          setMarkedMapKeys({
                            fileId,
                            markedKeys: [position.key]
                          })
                        );
                      }
                    }}
                  ></CircleMarker>
                </div>
              );
            } else {
              return <React.Fragment key={index}></React.Fragment>;
            }
          })}

        {/* Marked position */}
        {curPos ? (
          <CircleMarker
            key={curPos.key}
            center={selectedLatLong!}
            color={curPos.alarm ? connectionColors.sensor : "#006600"}
            pane={"popupPane"}
          ></CircleMarker>
        ) : null}

        {/* Cluster position */}
        {clusterData &&
          clusterData.length > 0 &&
          clusterData.map((cluster) => {
            let pillW = 16;
            if (cluster.numberOfPoints < 10) {
            } else if (cluster.numberOfPoints < 100) {
              pillW = 20;
            } else if (cluster.numberOfPoints < 1000) {
              pillW = 24;
            } else if (cluster.numberOfPoints < 10000) {
              pillW = 28;
            } else {
              pillW = 32;
            }

            const customMarker = new L.DivIcon({
              className: "counter-icon",
              iconSize: [pillW * 2, 32],
              iconAnchor: [pillW, 16],
              html: cluster.numberOfPoints.toString()
            });

            return (
              <Marker
                key={cluster.key}
                opacity={0.8}
                position={cluster.position}
                icon={customMarker}
                zIndexOffset={cluster.numberOfPoints}
                eventHandlers={{
                  click: () => {
                    dispatch(
                      setMarkedMapKeys({
                        fileId,
                        markedKeys: [cluster.key]
                      })
                    );
                  }
                }}
              ></Marker>
            );
          })}
      </MapContainer>
    </>
  );
};

export default MapDashboardContent;
