import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Row } from "antd";
import CustomColorButtonContainer from "../MicroComponents/CustomColorButtonContainer";
import {
  setLicensePriceName,
  setLicensePriceDescription,
  selectLicensePriceState
} from "../../state/licensePriceSlice";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";

interface IProps {}
const EditLicensePriceModalStage1: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const licenseModals = useSelector(selectLicensePriceState);
  const selectedLicensePrice = licenseModals.licensePriceToEdit;

  const licensePriceName = selectedLicensePrice?.name;
  const licensePriceDescription = selectedLicensePrice?.description;
  // const licensePriceColor = selectedLicensePrice?.color;

  return (
    <>
      <Form.Item label={t("tableStoreAdminName")}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Input
            onChange={(e) => dispatch(setLicensePriceName(e.target.value))}
            style={{ width: "100%" }}
            defaultValue={licensePriceName}
          />
        </div>
      </Form.Item>

      <Form.Item label={t("tableStoreAdminDesc")}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Input.TextArea
            autoSize={{ minRows: 6, maxRows: 6 }}
            onChange={(e) =>
              dispatch(setLicensePriceDescription(e.target.value))
            }
            style={{ width: "100%" }}
            defaultValue={licensePriceDescription}
          />
        </div>
      </Form.Item>
      <Form.Item label={t("licensePriceColor")}>
        <Row justify="center" style={{ marginBottom: size.l1 }}>
          <Row justify="space-between" style={{ width: "100%" }}>
            <CustomColorButtonContainer />
          </Row>
        </Row>
      </Form.Item>
    </>
  );
};
export default EditLicensePriceModalStage1;
