import { isNil, isUndefined } from "lodash-es";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { defaultStartLayout } from "../helpers/layoutHelper";
import {
  cargologRestApi,
  useSaveReportSettingsMutation
} from "../state/cargologRestApi";
import { selectSyncableReports } from "../state/openDatxSlice";
import { setReportsFromApi } from "../state/persistantStateSlice";
import { getTokens, sessionState } from "../state/sessionSlice";

const defaultSyncStatus = {
  active: "Standard",
  available: {
    Standard: {
      name: "Standard",
      isReadOnly: true,
      layouts: defaultStartLayout,
      originalLayouts: defaultStartLayout
    }
  }
};

// Check if the returned data is a report object
const isReport = (report: any): boolean => {
  return (
    !isNil(report) &&
    typeof report.active === "string" &&
    typeof report.available === "object"
  );
};

// Sync report layouts with the server
const useSyncReportLayouts = () => {
  const dispatch = useDispatch();
  const { networkStatus } = useSelector(sessionState);
  // The remote state as a string to avoid unnecessary syncs
  const [currentSync, setCurrentSync] = useState(
    JSON.stringify(defaultSyncStatus)
  );

  // Sync up to the server on report layout changes
  const [saveReportSettings] = useSaveReportSettingsMutation();
  const { activeFileId, reports } = useSelector(selectSyncableReports);
  // The local state as a string to compare with the remote state (currentSync)
  const reportsString = JSON.stringify(reports);
  useEffect(() => {
    if (
      networkStatus === "online" &&
      activeFileId !== undefined && // sync when a file is open
      reportsString.length > 0 && // sync when there are reports
      reportsString !== currentSync
    ) {
      // sync when there are changes
      console.log("↑ Report settings to server:", reports);
      setCurrentSync(reportsString);
      saveReportSettings(reportsString);
    }
  }, [reports]);

  // Sync down from the server
  const { accessToken } = useSelector(getTokens);
  const [trigger, result] =
    cargologRestApi.endpoints.getReportSettings.useLazyQuery();
  useEffect(() => {
    // If the server returns null or invalid data, abort sync down and set default report layout
    if (!isReport(result.data)) {
      console.log("Invalid report settings data from server");
      setCurrentSync("");
      // If the local state is corrupted, set the default state
      if (!reportsString.includes("Standard")) {
        console.log(
          "↑ Synking default report settings to server:",
          defaultSyncStatus
        );
        dispatch(setReportsFromApi(defaultSyncStatus));
      }
      return;
    }
    const remoteReportLayoutsString = JSON.stringify(result.data);

    if (
      !isUndefined(accessToken) && // user is signed in
      !isUndefined(result.data) && // server has responded
      remoteReportLayoutsString !== reportsString
    ) {
      // server has new data
      console.log("↓ Report settings from server:", result.data);
      setCurrentSync(remoteReportLayoutsString);
      dispatch(setReportsFromApi(result.data));
    }
  }, [result]);

  // Fetch report settings when accessToken changes (login, logout, impersonate)
  useEffect(() => {
    if (!isUndefined(accessToken)) {
      console.log("accesToken changed → refetching report settings ");
      trigger();
    }
  }, [accessToken]);
};

export default useSyncReportLayouts;
