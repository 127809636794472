const mobitronColors = {
  primaryRed: "#ee2624",
  primaryGray: "#7e7e7d",
  altGray: "#d9d9d9",
  altDarkGreen: "#409c46",
  altLightGreen: "#8bca83",
  softDarkGreen: "#7f917f"
};

//Grön:       #409c46
//Ljusgrön:   #8bca83
//Ljus grå:   #d9d9d9
//Mellan grå: #ACABAB
//Mörk grå:   #7e7e7d
//Röd:        #ee2624

export default mobitronColors;
