import * as React from "react";
import type { SVGProps } from "react";
const SvgZAccIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 892.89 892.07"
    {...props}
  >
    <path
      fill={props.fill === undefined ? "#231f20" : props.fill}
      d="M0 892.07v-71L204.75 568.2H23.13v-66h285.32v61.16L94.93 826.4H316.7v65.67Z"
    />
    <path
      fill={props.fill === undefined ? "#8fc640" : props.fill}
      d="M855.63 540.53a37.24 37.24 0 0 1-26.35-10.86L361.51 64.17a37 37 0 0 1 0-52.44 37.4 37.4 0 0 1 52.7 0L882 477.23a37.08 37.08 0 0 1-26.35 63.3Z"
    />
    <path
      fill="none"
      stroke={props.fill === undefined ? "#8fc640" : props.fill}
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={72}
      d="M386.2 429.48V36h394.3"
    />
  </svg>
);
export default SvgZAccIcon;
