import React, { useRef, useState } from "react";
import { Checkbox, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  stopHelpMode,
  increaseQuickTourProgress,
  decreaseQuickTourProgress,
  selectHelp,
  setQuickTourProgress
} from "../../state/helpSlice";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
import { SmallText, SmallTitle } from "../Common/CommonFonts";
import { size } from "../../helpers/pageHelper";
import { useNavigate } from "react-router";
import { GhostButton, QuickTourButton } from "../Common/CommonButtons";
import { setCurrentAdminTab } from "../../state/navigationSlice";
import { CloseOutlined } from "@ant-design/icons";
import { insertIf } from "../../utils/generalUtils";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import {
  selectShowQuickTourAtStartup,
  setShowQuickTourAtStartup,
  toggleShowQuickTourAtStartup
} from "../../state/sessionSlice";
import { useTranslation } from "react-i18next";

const QuickTourModal = () => {
  const { isHelpModeActive, quickTourProgress, isQuickTourModalOpen } =
    useSelector(selectHelp);
  const showQuickTourAtStartup = useSelector(selectShowQuickTourAtStartup);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 48,
    bottom: 0,
    right: 0
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y + 48,
      bottom: clientHeight - (targetRect.bottom - uiData.y)
    });
  };

  const quickTourStart = () => {
    navigate("/landing");
  };

  const licenseStore = () => {
    navigate("/admin");
    dispatch(setCurrentAdminTab("store"));
  };

  const reports = () => {
    navigate("/graph");
  };

  const setupDevice = () => {
    navigate("/params");
  };

  const playlist = [
    () => quickTourStart(),
    ...insertIf(CheckUserRightsAccess("AFA"), () => licenseStore()),
    ...insertIf(CheckUserRightsAccess("ACB"), () => reports()),
    ...insertIf(CheckUserRightsAccess("ADE"), () => setupDevice()),
    () => quickTourStart()
  ];

  const footer = () => {
    return (
      <>
        {quickTourProgress === 0 && (
          <Row
            justify="space-between"
            style={{
              paddingTop: size.m1
            }}
          >
            <Col span={12} style={{ textAlign: "start" }}>
              <QuickTourButton
                style={{ background: "rgba(255,255,255,0.15)", border: 0 }}
                onClick={() => {
                  dispatch(stopHelpMode());
                }}
                size="small"
              >
                {t("RemindMeLater")}
              </QuickTourButton>
            </Col>
            <Col span={12} style={{ textAlign: "end" }}>
              <QuickTourButton
                style={{ background: "rgba(255,255,255,0.15)", border: 0 }}
                onClick={() => {
                  dispatch(setShowQuickTourAtStartup(false));
                  dispatch(stopHelpMode());
                }}
                size="small"
              >
                {t("DontShowAgain")}
              </QuickTourButton>
            </Col>
          </Row>
        )}
        {quickTourProgress === playlist.length - 1 ? (
          <Row justify="center" style={{ paddingTop: size.m1 }}>
            <Checkbox
              checked={showQuickTourAtStartup}
              onChange={() => dispatch(toggleShowQuickTourAtStartup())}
              style={{ color: "white" }}
            >
              {t("ShowQuickTourAtStartup")}
            </Checkbox>
          </Row>
        ) : (
          <></>
        )}
        <Row
          justify="space-between"
          style={{
            position: "sticky",
            bottom: 0,
            paddingTop: size.l2,
            alignItems: "center"
          }}
        >
          {quickTourProgress > 0 ? (
            <Col span={8} style={{ textAlign: "start" }}>
              <QuickTourButton
                onClick={() => {
                  playlist[quickTourProgress - 1]();
                  dispatch(decreaseQuickTourProgress());
                }}
                size="small"
              >
                {t("Back")}
              </QuickTourButton>
            </Col>
          ) : (
            <Col span={8}></Col>
          )}

          <Col span={8}>
            {quickTourProgress + 1}/{playlist.length}
          </Col>

          {quickTourProgress < playlist.length - 1 ? (
            <Col span={8} style={{ textAlign: "end" }}>
              <QuickTourButton
                size="small"
                onClick={() => {
                  playlist[quickTourProgress + 1]();
                  dispatch(increaseQuickTourProgress());
                }}
              >
                {t("Next")}
              </QuickTourButton>
            </Col>
          ) : (
            <Col span={8} style={{ textAlign: "end" }}>
              <QuickTourButton
                size="small"
                onClick={() => {
                  dispatch(stopHelpMode());
                  dispatch(setQuickTourProgress(0));
                }}
              >
                {t("Done")}
              </QuickTourButton>
            </Col>
          )}
        </Row>
      </>
    );
  };
  interface IMainContent {
    title: string;
    text: string;
  }

  const mainContent = (props: IMainContent) => {
    const { title, text } = props;
    return (
      <>
        <Row justify="center">
          <SmallTitle style={{ color: "white" }}>{title}</SmallTitle>
        </Row>
        <Row justify="center" style={{ height: "100%" }}>
          <SmallText style={{ color: "white" }}>{text}</SmallText>
        </Row>
      </>
    );
  };

  const quickTourPages: Array<JSX.Element> = [
    <>
      {mainContent({
        title: t("WelcomeToTheQuickTour"),
        text: t("FollowTheStepsOfTheQuickTour")
      })}
    </>,
    ...insertIf(
      CheckUserRightsAccess("AFA"),
      <>
        {/* In License Store */}
        {mainContent({
          title: t("LicenseStore"),
          text: t("InTheLicenseStoreYouCan")
        })}
      </>
    ),
    ...insertIf(
      CheckUserRightsAccess("ACB"),
      <>
        {/* In Report Page */}
        {mainContent({
          title: t("ReportsPage"),
          text: t("TheReportsPageLetsYou")
        })}
      </>
    ),
    ...insertIf(
      CheckUserRightsAccess("ADE"),
      <>
        {/* In Setup Device */}
        {mainContent({
          title: t("SetupDevice"),
          text: t("InTheSetupYouCreate")
        })}
      </>
    ),
    <>
      {/* Congratulations */}
      {mainContent({
        title: t("Congratulations"),
        text: t("YouHaveFinishedTheQuickTour")
      })}
    </>
  ];

  return (
    <>
      {isQuickTourModalOpen ? (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div
            className="helpPopupModal"
            ref={draggleRef}
            style={{
              width: "300px",
              height: "auto",
              cursor: "move",
              visibility: isHelpModeActive ? "visible" : "hidden",
              backdropFilter: "blur(5px)",
              textAlign: "center"
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
          >
            <div style={{ position: "absolute", top: "2px", right: 0 }}>
              <GhostButton
                size="small"
                style={{ color: "white" }}
                onClick={() => {
                  dispatch(stopHelpMode());
                }}
              >
                <CloseOutlined />
              </GhostButton>
            </div>
            <div
              style={{
                height: "auto",
                padding: size.l2
              }}
            >
              {quickTourPages[quickTourProgress]}
              {footer()}
            </div>
          </div>
        </Draggable>
      ) : (
        <></>
      )}
    </>
  );
};

export default QuickTourModal;
