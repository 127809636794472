import React, { useState } from "react";
import { Input, Row, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLicensePriceState,
  setLicensePriceUserRights
} from "../../state/licensePriceSlice";
import { useTranslation } from "react-i18next";
import {
  checkBoxData,
  eLabelData,
  ITableData
} from "../../constants/userRights";
import { ColumnsType } from "antd/lib/table";
import { SmallText } from "../Common/CommonFonts";
import { size } from "../../helpers/pageHelper";

interface IProps {}
const EditLicensePriceModalStage3: React.FC<IProps> = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const licensePriceState = useSelector(selectLicensePriceState);

  const selectedLicensePrice = licensePriceState.licensePriceToEdit;
  const licensePriceUserRights = selectedLicensePrice?.userRights;
  const licensePriceType = selectedLicensePrice?.deviceType;

  const checkBoxColumns: ColumnsType<ITableData> = [
    {
      title: t("licensePriceUserRights"),
      dataIndex: "name",
      key: "name",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    }
  ];

  const [stringValue, setStringValue] = useState("");

  const filteredData = (
    licensePriceType === 1 ? checkBoxData : eLabelData
  ).filter((e) => {
    const dataContainsInput =
      stringValue === "" ||
      e.name.toLowerCase().includes(stringValue.toLowerCase());
    return dataContainsInput;
  });

  const [select, setSelect] = useState({
    userRights: licensePriceUserRights
  });

  const { userRights } = select;

  const selectedRows = {
    onChange: (userRights: any) => {
      setSelect({
        ...select,
        userRights: userRights
      });
    },
    selectedRowKeys: userRights
  };
  dispatch(setLicensePriceUserRights(select.userRights));

  return (
    <>
      <Row style={{ padding: size.m1 }}>
        <SmallText type="secondary">
          {t("LicenseType")}:
          <SmallText strong type="secondary">
            {licensePriceType === 1
              ? " " + t("cargolog3rdGen")
              : " " + t("cargologEasy")}
          </SmallText>
        </SmallText>
      </Row>
      <Row>
        <Input
          style={{ width: "100%" }}
          placeholder={t("licensePriceFilterUserRights")}
          onChange={(e) => setStringValue(e.target.value)}
        />
      </Row>
      <Table
        columns={checkBoxColumns}
        dataSource={filteredData}
        size={"small"}
        bordered
        rowSelection={selectedRows}
        scroll={{ x: "0", y: "calc(72vh - 380px)" }}
        pagination={false}
      />
    </>
  );
};

export default EditLicensePriceModalStage3;
