import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { sessionState } from "../../state/sessionSlice";
import { SmallTitle } from "../Common/CommonFonts";

type IProps = {
  children: React.ReactNode;
};

const Online: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const sessions = useSelector(sessionState);
  return sessions.networkStatus === "online" ? (
    <> {props.children} </>
  ) : (
    <SmallTitle
      type="secondary"
      style={{
        padding: size.xl1,
        textAlign: "center"
      }}
    >
      {t("ContentNotAvailableInOfflineMode")}
    </SmallTitle>
  );
};

export default Online;
