import { FilePdfOutlined } from "@ant-design/icons";
import {
  DocumentProps,
  ItemHeaderData,
  TransportHeaderData
} from "../../helpers/pdf/pdfInterfaces";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import { VMRecordingParameters } from "../../models/ViewModelRecordingParameters/VMRecordingParameters";
import ParamsPreview from "../ParamsPage/ParamsPreview";
import { PdfExportComponent, useExportPdfHook } from "../PrintExport/pdfExport";
import { NormalButton } from "../Common/CommonButtons";
import { useTranslation } from "react-i18next";

interface IProps {
  systemInfo: GeneralSystemInfo;
  recordingParameters: VMRecordingParameters;
  transportHeader: TransportHeaderData;
}

const ExportRecParamsPrevPdfButton = (props: IProps) => {
  const { t } = useTranslation();

  const { isExporting, startExport, finishExport } = useExportPdfHook();

  const { systemInfo, recordingParameters, transportHeader } = props;

  const documentProps: DocumentProps = {
    documentTitle: t("RecordingParameters"),
    fileName: "recording_parameters.pdf"
  };

  const itemHeader: ItemHeaderData = {
    itemType: "RecordingParameters",
    itemTitle: t("RecordingParameters")
  };

  return (
    <>
      {isExporting && (
        <PdfExportComponent
          ComponentBody={ParamsPreview}
          componentProps={{
            systemInfo,
            recordingParameters
          }}
          documentProps={documentProps}
          itemHeader={itemHeader}
          transportHeader={transportHeader}
          reportExportDone={finishExport}
        />
      )}
      <NormalButton icon={<FilePdfOutlined />} onClick={startExport}>
        PDF
      </NormalButton>
    </>
  );
};

export default ExportRecParamsPrevPdfButton;
