import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closeEditCompanyMobitronModal,
  closeRemoveCompanyMobitronModal,
  modalsState
} from "../../state/modalsSlice";
import { Typography, notification } from "antd";
import { useMobitronDeleteCompanyMutation } from "../../state/cargologRestApi";
import StandardModal from "../Common/StandardModal";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";

const RemoveCompanyMobitronModal = () => {
  const { Text } = Typography;
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [deleteCompany] = useMobitronDeleteCompanyMutation();

  const selectedCompanyId = modals.editCompanyMobitronModal.companyId;
  const selectedCompanyName = modals.editCompanyMobitronModal.companyName;

  const handleDeleteCompany = async (companyId: string) => {
    const result: any = await deleteCompany({ companyId });
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToDeleteCompany"),
        description: errorDescription
      });
    } else {
      notification.success({ message: t("SuccessfullyDeletedCompany") });
      dispatch(closeRemoveCompanyMobitronModal());
      dispatch(closeEditCompanyMobitronModal());
    }
  };

  return (
    <>
      <StandardModal
        title={t("DeleteCompany")}
        open={modals.isRemoveCompanyMobitronModalOpen}
        onCancel={() => dispatch(closeRemoveCompanyMobitronModal())}
        closable={true}
        destroyOnClose={true}
        footer={[
          <NormalButton
            htmlType="submit"
            onClick={() => dispatch(closeRemoveCompanyMobitronModal())}
          >
            {t("Cancel")}
          </NormalButton>,
          <PrimaryButton
            danger
            htmlType="submit"
            onClick={() => handleDeleteCompany(selectedCompanyId!)}
          >
            {t("DeleteCompany")}
          </PrimaryButton>
        ]}
      >
        {`${t("AreYouSureYouWantToDelete")} ${t("TheCompany")} `}
        <Text strong>{selectedCompanyName}</Text>?
      </StandardModal>
    </>
  );
};

export default RemoveCompanyMobitronModal;
