import React from "react";
import { Typography, Row } from "antd";
import { useTranslation } from "react-i18next";
import { ProjectWithUserRights } from "../../state/cargologRestApi";
import { CheckProjectRightsAccess } from "../MicroComponents/LicenseAccess";
import { size } from "../../helpers/pageHelper";
import { useDispatch, useSelector } from "react-redux";
import {
  openArchiveProjectModal,
  openRecoverProjectModal
} from "../../state/projectSlice";
import { renderStatus } from "../../pages/ProjectsPage";
import { getUser } from "../../state/sessionSlice";
import { PrimaryButton } from "../Common/CommonButtons";
import { WarningAlert } from "../Common/CommonAlerts";

const { Text } = Typography;

interface IProps {
  project?: ProjectWithUserRights;
}
const StatusTab = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { project } = props;
  const user = useSelector(getUser);

  return (
    <>
      <Row style={{ paddingTop: size.m1 }}>
        <Text>
          {t("CurrentProjectStatus")}:{" "}
          <Text strong>{renderStatus(t, project?.status).string}</Text>
        </Text>
      </Row>

      {project?.status !== 3 &&
      project?.userRights &&
      CheckProjectRightsAccess(project?.userRights, "ADL") &&
      project.companyId === user.companyId ? (
        <>
          <Row style={{ paddingTop: size.m1 }}>
            <PrimaryButton
              danger
              onClick={() => dispatch(openArchiveProjectModal())}
            >
              {t("ArchiveProject")}
            </PrimaryButton>
          </Row>
          <Row style={{ paddingTop: size.m1 }}>
            <WarningAlert message={t("ArchiveProjectWarningExplanation")} />
          </Row>
        </>
      ) : (
        project?.userRights &&
        CheckProjectRightsAccess(project.userRights, "ADL") &&
        project.companyId === user.companyId && (
          <Row style={{ paddingTop: size.m1 }}>
            <PrimaryButton onClick={() => dispatch(openRecoverProjectModal())}>
              {t("RecoverProject")}
            </PrimaryButton>
          </Row>
        )
      )}
    </>
  );
};

export default StatusTab;
