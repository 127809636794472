import React from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Row } from "antd";
import CustomColorButtonContainer from "../MicroComponents/CustomColorButtonContainer";
import {
  setLicensePriceName,
  setLicensePriceDescription
} from "../../state/licensePriceSlice";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";

interface IProps {}
const CreateLicenseModalStage1: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <Form.Item label={t("Name")}>
        <Input
          onChange={(e) => dispatch(setLicensePriceName(e.target.value))}
        />
      </Form.Item>

      <Form.Item label={t("tableStoreAdminDesc")}>
        <Input.TextArea
          autoSize={{ minRows: 6, maxRows: 6 }}
          onChange={(e) => dispatch(setLicensePriceDescription(e.target.value))}
        />
      </Form.Item>
      <Form.Item label={t("licensePriceColor")}>
        <Row justify="center" style={{ marginBottom: size.l1 }}>
          <Row justify="space-between" style={{ width: "100%" }}>
            <CustomColorButtonContainer />
          </Row>
        </Row>
      </Form.Item>
    </>
  );
};

export default CreateLicenseModalStage1;
