import * as React from "react";
import type { SVGProps } from "react";
const SvgPressureRawIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 141.73 141.73"
    {...props}
  >
    <path
      d="M92.166 36.465a92 92 0 0 0-19.01 1.824l.209 16.213a76.2 76.2 0 0 1 22.23 2.985l6.244-20.412a93 93 0 0 0-9.674-.61zm14.803 1.33-6.508 21.271a78.4 78.4 0 0 1 20.275 10.92l15.543-21.95a93 93 0 0 0-29.31-10.24zm-38.799 1.64a90.4 90.4 0 0 0-24.85 10.67l3.303 9.805a74 74 0 0 1 21.746-5.156l-.2-15.318zm72.439 11.136L124.65 73.11c5.923 5.031 11.233 11.037 15.697 17.964l25.014-17.936c-7.063-9.244-15.484-16.782-24.752-22.568M38.999 52.94c-7.023 4.93-13.529 10.996-19.307 18.197l3.154 4.088c5.938-5.574 12.404-9.992 19.188-13.283zM16.563 75.266C9.668 84.834 3.987 96.193.001 109.428 5.042 97.032 11.64 86.854 19.267 78.772zm151.74 1.918L142.94 95.37c3.004 5.329 5.5 11.167 7.453 17.443 10.944-3.211 20.38-5.974 30.893-9.04-3.235-9.93-7.648-18.804-12.982-26.59zm15.213 34.36c-10.06 5.005-19.548 9.711-29.914 14.843.59 3.642 1.008 7.405 1.228 11.295h32.4c-.408-9.336-1.691-18.048-3.714-26.137z"
      transform="scale(.74999)"
      fill={props.fill === undefined ? "#574e57" : props.fill}
    />
    <path
      d="M141.63 78.675c-2.007.027-48.369 14.014-65.661 19.251a10.19 10.19 0 0 1 4.7 8.585 10.2 10.2 0 0 1-.383 2.606c16.363-7.92 61.594-29.868 61.407-30.427q-.005-.015-.063-.014z"
      fill={props.fill === undefined ? "#574e57" : props.fill}
    />
    <circle
      cx={70.483}
      cy={106.51}
      r={7.64}
      fillRule="evenodd"
      fill={props.fill === undefined ? "#574e57" : props.fill}
    />
    <g
      aria-label="R"
      fill={props.fill === undefined ? "#574e57" : props.fill}
      strokeWidth={0.74999}
    >
      <path
        d="M53.806 114.15h-6.661L34.237 98.806h-7.232v15.344h-5.132V75.556h10.808q3.5 0 5.832.467 2.333.44 4.199 1.607 2.1 1.321 3.266 3.343 1.192 1.996 1.192 5.08 0 4.173-2.1 6.999-2.099 2.799-5.78 4.224zM41.805 86.416q0-1.659-.596-2.929-.57-1.296-1.918-2.177-1.115-.752-2.644-1.037-1.53-.31-3.603-.31h-6.039v14.566h5.184q2.436 0 4.25-.415 1.815-.44 3.085-1.607 1.166-1.088 1.71-2.488.57-1.425.57-3.603z"
        fill={props.fill === undefined ? "#574e57" : props.fill}
      />
    </g>
  </svg>
);
export default SvgPressureRawIcon;
