import React from "react";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import commonColors from "../../styles/commonColors";

/* interface TextProps {
  style?: React.CSSProperties;
} */

export const SmallText: React.FC<TextProps> = (props) => {
  return <Typography.Text {...props}>{props.children}</Typography.Text>;
};

/** Sometimes it's a better choice to insert styling rather than rendering a
 * component, if that the case, this object can be used. If not, please see
 * "LargeTitle"-component in the same file */
export const largeTitleStyle: React.CSSProperties = {
  fontSize: "24px",
  marginTop: 0
};

export const modalHeadingStyle: React.CSSProperties = {
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: 500
};

/** Shortens text and adds "…" (Ellipsis) if text is wider than its container */
export const ellipsisIfLongTextStyle: React.CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
};

/** Large title that can be used through out the application (except for cards headers)*/
export const LargeTitle: React.FC<TextProps> = (props) => {
  return (
    <Typography.Title level={3} style={largeTitleStyle} {...props}>
      {props.children}
    </Typography.Title>
  );
};

export const SmallTitle: React.FC<TextProps> = (props) => {
  return (
    <Typography.Title
      level={5}
      {...props}
      style={{
        marginBlock: 0,
        ...props.style
      }}
    >
      {props.children}
    </Typography.Title>
  );
};

export const HeaderTitle: React.FC<TextProps> = (props) => {
  return (
    <Typography.Title
      level={5}
      style={{
        marginBlock: 0
      }}
      {...props}
    >
      {props.children}
    </Typography.Title>
  );
};

/** Large title to be used in card headers */
export const LargeHeaderTitle: React.FC<TextProps> = (props) => {
  return (
    <Typography.Title
      level={4}
      style={{
        color: "#807D79",
        marginBlock: 0
      }}
      {...props}
    >
      {props.children}
    </Typography.Title>
  );
};

export const NormalParagraph: React.FC<TextProps> = (props) => {
  return (
    <Typography.Paragraph {...props}>{props.children}</Typography.Paragraph>
  );
};

/** A small header intended to be used in the "create parameters view" */
export const ParamsCardSubHeader: React.FC<TextProps> = (props) => {
  return (
    <SmallText
      strong
      style={props.disabled ? paramsCardDisabledStyle : paramsCardNormalStyle}
      {...props}
    >
      {props.children}
    </SmallText>
  );
};

/** A paragraph intended to be used in the "create parameters view" */
export const ParamsCardParagraph: React.FC<TextProps> = (props) => {
  return (
    <Typography.Paragraph
      style={props.disabled ? paramsCardDisabledStyle : paramsCardNormalStyle}
      {...props}
    >
      {props.children}
    </Typography.Paragraph>
  );
};

//common params view styles
const paramsCardNormalStyle: React.CSSProperties = {
  fontSize: "14px",
  textAlign: "left"
};
const paramsCardDisabledStyle: React.CSSProperties = {
  ...paramsCardNormalStyle,
  color: commonColors.disabledText
};
