import dayjs from "dayjs";
import { Buffer } from "buffer";

/** Saves the PARX buffer as a file */
export const saveParx = async (parxBuffer: ArrayBuffer) => {
  const dateString = dayjs().format("YYYYMMDD-HHmmss");
  const parxBlob = new Blob([parxBuffer], { type: "text/plain" });
  const url = URL.createObjectURL(parxBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = dateString + ".parx";
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
    link.parentNode?.removeChild(link);
  }, 0);
};

/** Saves the PDF buffer as a file */
export const savePdf = async (data: Buffer) => {
  const dateString = dayjs().format("YYYYMMDD-HHmmss");
  const pdfBlob = new Blob([data], { type: "application/pdf" });
  const url = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = dateString + ".pdf";
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
    link.parentNode?.removeChild(link);
  }, 0);
};

/** Saves the CSV string as a file */
export const saveAsCsv = async (data: string) => {
  const dateString = dayjs().format("YYYYMMDD-HHmmss");
  const csvBlob = new Blob([data], { type: "text/csv" });
  const url = URL.createObjectURL(csvBlob);
  const link = document.createElement("a");
  link.href = url;
  link.download = dateString + ".csv";
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
    link.parentNode?.removeChild(link);
  }, 0);
};
