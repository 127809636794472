// import { Button } from "antd";
import { NormalButton } from "../Common/CommonButtons";

// import IconWrapper from "@ant-design/icons";
import React from "react";
import { DataToggleState } from "../../state/openDatxSlice";
import commonColors from "../../styles/commonColors";
import { Tooltip } from "antd";

interface IProps {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  state: DataToggleState;
  onClick: () => void;
  tooltip?: string;
  activeChannelColor?: string;
}

const DataFilterToggle: React.FC<IProps> = (props) => {
  const Icon = props.icon;

  const activeBg = "#d9d9d9";
  const inactiveBg = "#ffffff";

  const baseIconProps = {
    width: "22px",
    height: "22px"
  };

  /** props for the svg icon */
  const iconProps = props.state.isActive
    ? { ...baseIconProps, fill: props.activeChannelColor }
    : { ...baseIconProps, fill: commonColors.disabledTextOnDarkBg };

  /** channel has been used and is active in the graph right now */
  const usedAndActive: React.CSSProperties = {
    borderColor: activeBg
  };

  /** channel has been used but is not active in the graph right now */
  const usedAndNotActive: React.CSSProperties = {
    borderColor: inactiveBg
  };

  /** channel has not been used and can therefor not be activated */
  const notUsed: React.CSSProperties = {
    borderColor: "white",
    boxShadow: "none"
  };

  const activeStyle = !props.state.isUsed
    ? notUsed
    : props.state.isActive
      ? usedAndActive
      : usedAndNotActive;

  const toggleButton = (
    <NormalButton
      icon={<Icon {...iconProps} />}
      disabled={!props.state.isUsed}
      size={"middle"}
      onClick={() => props.onClick()}
      style={{
        ...activeStyle
      }}
    />
  );

  if (props.tooltip) {
    return (
      <Tooltip title={props.tooltip} placement="bottom">
        {toggleButton}
      </Tooltip>
    );
  }
  return toggleButton;
};

export default DataFilterToggle;
