import { Col, Collapse, InputNumber, Row, Space, Switch } from "antd";
import { isNull } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSchedulerByIndex,
  setSchedulerGpsAlwaysOn,
  setSchedulerGpsIntervalDays,
  setSchedulerGpsIntervalHours,
  setSchedulerGpsIntervalMinutes,
  setSchedulerUseGpsInterval
} from "../../state/openParxSlice";
import { WarningAlert } from "../Common/CommonAlerts";
import {
  ParamsCardParagraph,
  ParamsCardSubHeader
} from "../Common/CommonFonts";
import { ParamsInputCard } from "../Common/CommonParametersComponents";
import ResetPanelBodyPadding from "../MicroComponents/ResetPanelBodyPadding";
import { size } from "../../helpers/pageHelper";
const { Panel } = Collapse;

interface AvailableGpsFeatures {
  hasGps: boolean;
}

interface IProps {
  index: number;
  features: AvailableGpsFeatures;
}

const ParamsBuilderGps: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { index } = props;
  const scheduler = useSelector(selectSchedulerByIndex(index));
  const dispatch = useDispatch();
  const {
    useGpsInterval,
    alwaysOn,
    intervalDays,
    intervalHours,
    intervalMinutes,
    flightModeGps
  } = scheduler.GpsParams;
  const { flightMode } = scheduler;
  const hasGps = props.features.hasGps;
  const mainToggle = hasGps && useGpsInterval && !flightModeGps && !flightMode;
  const gpsDisabled = !hasGps || flightModeGps || flightMode;

  //the max interval for a period is exacly 28 days in ms. Therefore if 28 days
  //is set, hours and minututes can't be more than 0
  const maxHours = intervalDays === 28 ? 0 : 23;
  const maxMinutes = intervalDays === 28 ? 0 : 59;

  /** Style used for input fields */
  const inputFieldStyle: React.CSSProperties = {
    margin: size.s2
  };

  // Render functions

  const renderMainToggle = () => (
    <Row align="middle" style={{ marginBottom: size.s2 }}>
      <Space>
        <Switch
          size="small"
          style={{ marginBottom: size.s1 }}
          checked={mainToggle}
          disabled={gpsDisabled}
          onChange={(value) =>
            dispatch(setSchedulerUseGpsInterval({ index, value }))
          }
        />
        <ParamsCardSubHeader>{t("cpGpsMainToggleTitle")}</ParamsCardSubHeader>
      </Space>
    </Row>
  );

  const renderLogInterval = () => (
    <ParamsInputCard
      title={t("cpGpsLogIntervalTitle")}
      style={{ marginBottom: size.s2 }}
    >
      <ParamsCardParagraph>{t("cpGpsLogIntervalContent")}</ParamsCardParagraph>

      {/* titles */}
      <Row>
        <Col span={8}>
          <ParamsCardSubHeader style={{ marginLeft: size.s2 }}>
            {t("genDays")}
          </ParamsCardSubHeader>
        </Col>
        <Col span={8}>
          <ParamsCardSubHeader style={{ marginLeft: size.s2 }}>
            {t("genHours")}
          </ParamsCardSubHeader>
        </Col>
        <Col span={8}>
          <ParamsCardSubHeader style={{ marginLeft: size.s2 }}>
            {t("genMinutes")}
          </ParamsCardSubHeader>
        </Col>
      </Row>
      {/* input-fields */}
      <Row>
        <Col span={8}>
          <InputNumber
            disabled={!mainToggle}
            value={intervalDays}
            onChange={(val) =>
              isNull(val)
                ? {}
                : dispatch(
                    setSchedulerGpsIntervalDays({ index, value: Number(val) })
                  )
            }
            min={0}
            max={28}
            style={inputFieldStyle}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            disabled={!mainToggle}
            value={intervalHours}
            onChange={(val) =>
              isNull(val)
                ? {}
                : dispatch(
                    setSchedulerGpsIntervalHours({ index, value: Number(val) })
                  )
            }
            min={0}
            max={maxHours}
            style={inputFieldStyle}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            disabled={!mainToggle}
            value={intervalMinutes}
            onChange={(val) =>
              isNull(val)
                ? {}
                : dispatch(
                    setSchedulerGpsIntervalMinutes({
                      index,
                      value: Number(val)
                    })
                  )
            }
            min={0}
            max={maxMinutes}
            style={{ ...inputFieldStyle, marginRight: 0 }}
          />
        </Col>
      </Row>
    </ParamsInputCard>
  );

  const renderNeverSleep = () => (
    <ParamsInputCard
      title={t("cpGpsNeverSleepTitle")}
      useToggle
      toggleChecked={alwaysOn && !gpsDisabled}
      toggleDisabled={gpsDisabled}
      toggleChangeAction={(value: boolean) =>
        dispatch(setSchedulerGpsAlwaysOn({ index, value }))
      }
    >
      <ParamsCardParagraph>{t("cpGpsNeverSleepContent")}</ParamsCardParagraph>
      <WarningAlert message={t("cpGpsNeverSleepWarning")} />
    </ParamsInputCard>
  );

  return (
    <>
      {renderMainToggle()}
      {/* logging interval content */}
      {renderLogInterval()}
      <Collapse collapsible="header" ghost>
        <Panel header={t("advanced")} key="advanced">
          <ResetPanelBodyPadding>{renderNeverSleep()}</ResetPanelBodyPadding>
        </Panel>
      </Collapse>
    </>
  );
};

export default ParamsBuilderGps;
