import React from "react";
import { Card, Row, Col, Select } from "antd";
import { size } from "../../helpers/pageHelper";
import { SmallText } from "../Common/CommonFonts";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash-es";
const { Option } = Select;

const LanguageCard = () => {
  const getLang = (s: string) => {
    if (isUndefined(s)) return "en";
    return s;
  };
  const { t, i18n } = useTranslation();
  const selectAndButtonWidth = 190;
  return (
    <Card style={{ marginBottom: size.m1 }}>
      <Row style={{ marginBottom: size.m1 }}>
        <SmallText strong>{t("Language")}</SmallText>
      </Row>
      <Row align="middle" style={{ paddingLeft: size.m1 }}>
        <Col span={12}>
          <SmallText>{t("SelectLanguageTooltip")}: </SmallText>
        </Col>
        <Col>
          <Select
            style={{ minWidth: selectAndButtonWidth, textAlign: "center" }}
            defaultValue={getLang(i18n.language)}
            onChange={(language) => {
              if (!(getLang(i18n.language) === language))
                i18n.changeLanguage(language);
            }}
            // Keeps Dropdown in place when scrolling
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            <Option value="sv">{t("Swedish")}</Option>
            <Option value="en">{t("English")}</Option>
          </Select>
        </Col>
      </Row>
    </Card>
  );
};

export default LanguageCard;
