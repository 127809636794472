//===== Kind of data
export const KIND_MEMSTART = 0x0001; // start of formated flash marker
export const KIND_DEVCONFIG = 0x0002; // device config
export const KIND_RECPARAMS = 0x0003; // recording parameters
export const KIND_RECORDINGDATA = 0x0005; // recorded sensor values etc
export const KIND_DVADATA = 0x0006; // recorded DVA data
export const KIND_LASTSTATUS = 0x0100; // latest AlarmStatus, DeviceStatus and BatteryStatus.
export const KIND_LASTDATAREAD = 0x0101; // latest address data read from when requesting "new data"
export const KIND_STARTUP = 0x0102; // Timestamp for example when startup is completed or just record that unit is running
export const KIND_DATXHEADER = 0x1001; // DATX header
export const KIND_PARXHEADER = 0x1002; // PARX header
export const KIND_FREEFLASH = 0xffff; // empty flash marker
export const KINDBIT_DEVCFG = 0x0001; // Device config
export const KINDBIT_RECPARAMS = 0x0002; // Recording parameters
export const KINDBIT_RECDATA = 0x0010; // Recorded Data exluding DVA
export const KINDBIT_DVA = 0x0020; // Recorded DVA
export const KINDBIT_STATUS = 0x1000; // Status data including Alarms, Devicestatus and Battery status
export const KINDBIT_STARTUP = 0x8000; // Timestamp
export const KINDBITS_DATX =
  KINDBIT_RECPARAMS | KINDBIT_RECDATA | KINDBIT_STATUS | KINDBIT_STARTUP;

export const KIND_SYSTEMINFO = 0x0007; // system information, NOTE! SYSTEMINFO_LEN holds the data length
