import React from "react";
import { useSelector } from "react-redux";
import {
  VictoryAxis,
  VictoryBrushContainer,
  VictoryChart,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictorySelectionContainer,
  VictoryTheme
} from "victory";
import { iconColors } from "../../constants/colors";
import { unix2Date, VMDvaData } from "../../helpers/dataModelHelper";
import {
  createDefaultDvaYAxisDomain,
  dateAxisFormater,
  generateTickValues,
  toLowHighOrder,
  yAxisFormater,
  ZoomDimension
} from "../../helpers/graphHelper";
import { timezoneSelector } from "../../helpers/timezoneSelector";
import {
  ChannelTypes,
  CompareStateZoomDomain
} from "../../state/compareGraphsSlice";
import { selectGraphAxisTickCountTime } from "../../state/persistantStateSlice";
import {
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import commonColors from "../../styles/commonColors";
import { commonAxisStyle } from "../../styles/graphStylesCommon";
import { size } from "../../helpers/pageHelper";

interface IProps {
  fileId: string;
  width: number;
  height: number;
  dvaDataBlock: VMDvaData;
  timezone: string;
  channelTypes: ChannelTypes[];
  zoomDimension?: ZoomDimension;
  zoomDomain?: CompareStateZoomDomain;
  handleZoom: (domain: CompareStateZoomDomain) => void;
  resetZoom: () => void;
}

const CompareDvaGraph: React.FC<IProps> = (props) => {
  const { dvaDataBlock, zoomDomain, channelTypes, timezone } = props;

  const yDomain = createDefaultDvaYAxisDomain(dvaDataBlock);

  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);

  const timeScaleSteps = useSelector(selectGraphAxisTickCountTime);

  if (!dvaDataBlock) return <p>no dva data</p>;

  const defaultDomain: CompareStateZoomDomain = {
    x: [0, dvaDataBlock.data.length],
    y: yDomain
  };

  /** Checks if a domain is equivalent with the entire domain */
  const isZoomReset = (domain: CompareStateZoomDomain): boolean => {
    const check =
      domain.x[0] <= defaultDomain.x[0] &&
      domain.x[1] >= defaultDomain.x[1] &&
      //todo: is this correct?
      domain.y[0] === yDomain[0] &&
      domain.y[1] === yDomain[1];
    return check;
  };
  /** Updates the store with the new domain. Since Victory triggers the same
   * event for zoom/pan as reset, a check to determine the kind of event is made */
  const handleDomain = (domain: any) => {
    isZoomReset(domain) ? props.resetZoom() : props.handleZoom(domain);
  };

  const handleZoomSelection = (bounds: CompareStateZoomDomain) => {
    const fixedBoundsX: [number, number] =
      bounds.x[0] < 0 ? [0, bounds.x[1]] : bounds.x;

    // If user selects from right to left, the first value will be larger than the seconds value, causing a bugged selection. {toLowHighOrder} solves that
    const newXDomain: CompareStateZoomDomain["x"] =
      toLowHighOrder(fixedBoundsX);
    const newYDomain: CompareStateZoomDomain["y"] = toLowHighOrder(bounds.y);
    props.handleZoom?.({ x: newXDomain, y: newYDomain });
  };

  const dvaData = dvaDataBlock.data.map((e, index) => {
    return {
      xAlarm: e.xAlarm,
      yAlarm: e.yAlarm,
      zAlarm: e.zAlarm,
      time: unix2Date(dvaDataBlock!.start + index / 700)
    };
  });

  const { axisGray } = commonColors;

  const graphContent = () => {
    return (
      <VictoryGroup>
        {/* x-axis data */}
        {channelTypes.includes("x") ? (
          <VictoryLine
            name="dvaXAlarm"
            data={dvaData}
            y="xAlarm"
            x="time"
            style={{
              data: {
                stroke: iconColors.xAcc,
                strokeWidth: 2
              }
            }}
          />
        ) : null}
        {/* y-axis data */}
        {channelTypes.includes("y") ? (
          <VictoryLine
            name="dvaYAlarm"
            data={dvaData}
            y="yAlarm"
            x="time"
            style={{
              data: {
                stroke: iconColors.yAcc,
                strokeWidth: 2
              }
            }}
          />
        ) : null}

        {/* z-axis data */}
        {channelTypes.includes("z") ? (
          <VictoryLine
            name="dvaZAlarm"
            data={dvaData}
            y="zAlarm"
            x="time"
            style={{
              data: {
                stroke: iconColors.zAcc,
                strokeWidth: 2
              }
            }}
          />
        ) : null}
      </VictoryGroup>
    );
  };

  return (
    <>
      <VictoryChart
        name="dvaGraph"
        width={props.width}
        height={props.height}
        scale={{ x: "time" }}
        padding={{ top: size.l1, left: 50, right: 50, bottom: size.l2 }}
        domain={{ x: zoomDomain?.x, y: yDomain }}
        theme={VictoryTheme.material}
        domainPadding={{ x: [0, 5], y: [0, 0] }}
        containerComponent={
          <VictorySelectionContainer
            selectionDimension="x"
            allowSelection
            onSelection={(points, bounds: any, props) => {
              handleZoomSelection(bounds as any);
            }}
          />
        }
      >
        {/* x-axis show no values) */}
        <VictoryAxis
          axisValue={0}
          tickFormat={[]}
          tickCount={timeScaleSteps}
          style={{
            ...commonAxisStyle,
            axis: {
              stroke: axisGray,
              strokeWidth: 1.5
            }
          }}
        />

        {/* x-axis(based on time, show values) */}
        <VictoryAxis
          tickFormat={(t, _i, arr) =>
            dateAxisFormater(
              t,
              arr,
              timezoneSelector(timezone, timezoneState, timezoneToggle)
            )
          }
          fixLabelOverlap
          tickCount={timeScaleSteps === 0 ? undefined : timeScaleSteps}
          axisValue={yDomain[0]}
          style={{
            ...commonAxisStyle,
            axis: {
              stroke: "black",
              strokeWidth: 1.5
            },
            grid: {
              stroke: axisGray,
              strokeWidth: 0
            }
          }}
        />

        {/* y-axis for dva data */}
        <VictoryAxis
          dependentAxis
          offsetX={50}
          label={"g"}
          fixLabelOverlap
          style={commonAxisStyle}
          axisLabelComponent={
            <VictoryLabel y={16} dx={39} textAnchor="middle" />
          }
          tickValues={generateTickValues(19, zoomDomain?.y ?? yDomain)}
          tickFormat={(t) => yAxisFormater(t, yDomain)}
        />
        {graphContent()}
      </VictoryChart>

      <VictoryChart
        width={props.width}
        height={90}
        scale={{ x: "time" }}
        padding={{ top: 0, left: 50, right: 50, bottom: size.l3 }}
        domainPadding={{ x: [5, -10], y: 5 }}
        theme={VictoryTheme.material}
        containerComponent={
          <VictoryBrushContainer
            brushDimension={"x"}
            brushDomain={zoomDomain ?? defaultDomain}
            onBrushDomainChangeEnd={(bounds: any) =>
              handleDomain(bounds as any)
            }
          />
        }
      >
        <VictoryAxis
          tickFormat={[]}
          axisValue={0}
          style={{
            axis: {
              stroke: axisGray,
              strokeWidth: 1.5
            }
          }}
        />
        {graphContent()}
      </VictoryChart>
    </>
  );
};

export default CompareDvaGraph;
