import { Col, Collapse, InputNumber, Row, Space, Switch } from "antd";
import { isNull } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSchedulerByIndex,
  setSchedulerLteAlwaysOn,
  setSchedulerLteIntervalDays,
  setSchedulerLteIntervalHours,
  setSchedulerLteIntervalMinutes,
  setSchedulerLteRequireGps,
  setSchedulerUseLteInterval
} from "../../state/openParxSlice";
import { WarningAlert } from "../Common/CommonAlerts";
import {
  ParamsCardParagraph,
  ParamsCardSubHeader
} from "../Common/CommonFonts";
import { ParamsInputCard } from "../Common/CommonParametersComponents";
import ResetPanelBodyPadding from "../MicroComponents/ResetPanelBodyPadding";
import { size } from "../../helpers/pageHelper";
const { Panel } = Collapse;

interface AvailableLteFeatures {
  hasLte: boolean;
  hasGps: boolean;
}

interface IProps {
  index: number;
  features: AvailableLteFeatures;
}

const ParamsBuilderLte: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { index } = props;
  const scheduler = useSelector(selectSchedulerByIndex(index));
  const dispatch = useDispatch();
  const {
    useLteInterval,
    alwaysOn,
    GpsRequired,
    intervalDays,
    intervalHours,
    intervalMinutes,
    flightModeLte
  } = scheduler.LteParams;
  const { flightMode } = scheduler;
  const { hasLte, hasGps } = props.features;
  const mainToggle = hasLte && useLteInterval && !flightModeLte && !flightMode;
  const lteDisabled = !hasLte || flightModeLte || flightMode;

  //the max interval for a period is exacly 28 days in ms. Therefore if 28 days
  //is set, hours and minututes can't be more than 0
  const maxHours = intervalDays === 28 ? 0 : 23;
  const maxMinutes = intervalDays === 28 ? 0 : 59;

  /** Style used for input fields */
  const inputFieldStyle: React.CSSProperties = {
    margin: size.s2
  };

  // Render functions

  const renderMainToggle = () => (
    <Row align="middle" style={{ marginBottom: size.s2 }}>
      <Space>
        <Switch
          size="small"
          style={{ marginBottom: size.s1 }}
          checked={mainToggle}
          disabled={lteDisabled}
          onChange={(value) =>
            dispatch(setSchedulerUseLteInterval({ index, value }))
          }
        />
        <ParamsCardSubHeader>{t("cpLteMainToggleTitle")}</ParamsCardSubHeader>
      </Space>
    </Row>
  );

  const renderLogInterval = () => (
    <ParamsInputCard
      title={t("cpLteLogIntervalTitle")}
      style={{ marginBottom: size.s2 }}
    >
      <ParamsCardParagraph>{t("cpLteLogIntervalContent")}</ParamsCardParagraph>

      {/* titles */}
      <Row>
        <Col span={8}>
          <ParamsCardSubHeader style={{ marginLeft: size.s2 }}>
            {t("genDays")}
          </ParamsCardSubHeader>
        </Col>
        <Col span={8}>
          <ParamsCardSubHeader style={{ marginLeft: size.s2 }}>
            {t("genHours")}
          </ParamsCardSubHeader>
        </Col>
        <Col span={8}>
          <ParamsCardSubHeader style={{ marginLeft: size.s2 }}>
            {t("genMinutes")}
          </ParamsCardSubHeader>
        </Col>
      </Row>
      {/* input-fields */}
      <Row>
        <Col span={8}>
          <InputNumber
            disabled={!mainToggle}
            value={intervalDays}
            onChange={(val) =>
              isNull(val)
                ? {}
                : dispatch(
                    setSchedulerLteIntervalDays({ index, value: Number(val) })
                  )
            }
            min={0}
            max={28}
            style={inputFieldStyle}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            disabled={!mainToggle}
            value={intervalHours}
            onChange={(val) =>
              isNull(val)
                ? {}
                : dispatch(
                    setSchedulerLteIntervalHours({ index, value: Number(val) })
                  )
            }
            min={0}
            max={maxHours}
            style={inputFieldStyle}
          />
        </Col>
        <Col span={8}>
          <InputNumber
            disabled={!mainToggle}
            value={intervalMinutes}
            onChange={(val) =>
              isNull(val)
                ? {}
                : dispatch(
                    setSchedulerLteIntervalMinutes({
                      index,
                      value: Number(val)
                    })
                  )
            }
            min={0}
            max={maxMinutes}
            style={{ ...inputFieldStyle, marginRight: 0 }}
          />
        </Col>
      </Row>
    </ParamsInputCard>
  );

  const renderGpsRequired = () => (
    <ParamsInputCard
      title={t("cpLteGpsRequiredTitle")}
      disabled={!hasGps}
      width={1}
      height={1}
      useToggle
      toggleChecked={GpsRequired}
      toggleDisabled={!mainToggle || !hasGps}
      toggleChangeAction={(value: boolean) =>
        dispatch(setSchedulerLteRequireGps({ index, value }))
      }
    >
      <ParamsCardParagraph disabled={!hasGps}>
        {t("cpLteGpsRequiredContent")}
      </ParamsCardParagraph>
    </ParamsInputCard>
  );

  const renderNeverSleep = () => (
    <ParamsInputCard
      title={t("cpLteNeverSleepTitle")}
      useToggle
      toggleChecked={alwaysOn && !lteDisabled}
      toggleDisabled={lteDisabled}
      toggleChangeAction={(value: boolean) =>
        dispatch(setSchedulerLteAlwaysOn({ index, value }))
      }
    >
      <ParamsCardParagraph>{t("cpLteNeverSleepContent")}</ParamsCardParagraph>
      <WarningAlert message={t("cpLteNeverSleepWarning")} />
    </ParamsInputCard>
  );

  return (
    <>
      {renderMainToggle()}
      {/* logging interval content */}
      {renderLogInterval()}
      {/* GPS required content */}
      {hasGps && renderGpsRequired()}
      {/* never sleep content */}
      <Collapse collapsible="header" ghost>
        <Panel header={t("advanced")} key="advanced">
          <ResetPanelBodyPadding>{renderNeverSleep()}</ResetPanelBodyPadding>
        </Panel>
      </Collapse>
    </>
  );
};

export default ParamsBuilderLte;
