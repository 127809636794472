import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAccHistogramBins,
  updateHistogramBins
} from "../../state/openDatxSlice";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../../styles/rcSliderOverrides.css";
import { size } from "../../helpers/pageHelper";

interface IProps {
  fileId: string;
}
const AccHistogramBinSettings = (props: IProps) => {
  const { fileId } = props;

  const { bins, max } = useSelector(selectAccHistogramBins(props.fileId))!;

  const dispatch = useDispatch();

  const min = 0;

  /** Marks to be shown in the slider. The minimun (0) and maximum value will
   * always be present */
  const marks = bins.reduce(
    (resp: Record<number, string>, curr) => ({
      [curr]: curr.toString(),
      ...resp
    }),
    { 0: "0", [max]: max.toString() }
  );

  const onChange = (input: number | number[]) => {
    const bins = input as number[];
    dispatch(updateHistogramBins({ fileId, bins }));
  };

  return (
    <div style={{ padding: size.m1 }}>
      <Slider
        range
        min={min}
        max={max}
        step={0.1}
        marks={marks}
        defaultValue={bins}
        pushable={0.2 as any}
        onAfterChange={onChange}
      />
    </div>
  );
};

export default AccHistogramBinSettings;
