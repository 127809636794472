import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, InputNumber, Typography } from "antd";
import {
  setLicensePriceUsd3,
  setLicensePriceUsd6,
  setLicensePriceUsd12,
  setLicensePriceEur3,
  setLicensePriceEur6,
  setLicensePriceEur12,
  setLicensePriceSek3,
  setLicensePriceSek6,
  setLicensePriceSek12,
  selectLicensePriceState
} from "../../state/licensePriceSlice";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";

const { Text } = Typography;

interface IProps {}
const EditLicensePriceModalStage2: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const licenseModals = useSelector(selectLicensePriceState);
  const selectedLicensePrice = licenseModals.licensePriceToEdit;

  const eur3 = selectedLicensePrice?.licensePriceDefs[0]?.price ?? 0;
  const eur6 = selectedLicensePrice?.licensePriceDefs[1]?.price ?? 0;
  const eur12 = selectedLicensePrice?.licensePriceDefs[2]?.price ?? 0;

  const sek3 = selectedLicensePrice?.licensePriceDefs[3]?.price ?? 0;
  const sek6 = selectedLicensePrice?.licensePriceDefs[4]?.price ?? 0;
  const sek12 = selectedLicensePrice?.licensePriceDefs[5]?.price ?? 0;

  const usd3 = selectedLicensePrice?.licensePriceDefs[6]?.price ?? 0;
  const usd6 = selectedLicensePrice?.licensePriceDefs[7]?.price ?? 0;
  const usd12 = selectedLicensePrice?.licensePriceDefs[8]?.price ?? 0;

  return (
    <>
      <Row gutter={[size.m2, size.m2]}>
        <Col span={6}>
          <Form.Item label={" "}>
            <Text strong>3 </Text>
            {t("licensePriceMonths")}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"SEK"}>
            <InputNumber
              defaultValue={sek3}
              onChange={(value) => dispatch(setLicensePriceSek3(Number(value)))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"EUR"}>
            <InputNumber
              defaultValue={eur3}
              onChange={(value) => dispatch(setLicensePriceEur3(Number(value)))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"USD"}>
            <InputNumber
              defaultValue={usd3}
              onChange={(value) => dispatch(setLicensePriceUsd3(Number(value)))}
            />
          </Form.Item>
        </Col>
      </Row>
      {/*---------------------*/}
      <Row gutter={[size.m2, size.m2]}>
        <Col span={6}>
          <Form.Item>
            <Text strong>6 </Text>
            {t("licensePriceMonths")}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              defaultValue={sek6}
              onChange={(value) => dispatch(setLicensePriceSek6(Number(value)))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              defaultValue={eur6}
              onChange={(value) => dispatch(setLicensePriceEur6(Number(value)))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              defaultValue={usd6}
              onChange={(value) => dispatch(setLicensePriceUsd6(Number(value)))}
            />
          </Form.Item>
        </Col>
      </Row>
      {/*---------------------*/}
      <Row gutter={[size.m2, size.m2]}>
        <Col span={6}>
          <Form.Item>
            <Text strong>12 </Text>
            {t("licensePriceMonths")}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              defaultValue={sek12}
              onChange={(value) => dispatch(setLicensePriceSek12(value))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              defaultValue={eur12}
              onChange={(value) => dispatch(setLicensePriceEur12(value))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              defaultValue={usd12}
              onChange={(value) => dispatch(setLicensePriceUsd12(value))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
export default EditLicensePriceModalStage2;
