/**
 *
 * @param timezone File-specific timezone set in Filter Dashboard
 * @param timezoneState Global persistant timezone-state set in Graph Settings
 * @param timezoneToggle Toggles between using global or file-specific timezone
 *
 */
export const timezoneSelector = (
  timezone: string,
  timezoneState: string | undefined,
  timezoneToggle: boolean
) => {
  if (timezoneToggle && timezoneState) {
    return timezoneState;
  } else {
    return timezone;
  }
};
