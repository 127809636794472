import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CsvFormat,
  Currency,
  Language,
  MapProvider,
  SpeedUnit,
  TemperatureScale,
  UserSettings
} from "./cargologRestApi";
import { StoreApi } from "./store";

export type SyncStatus = "idle" | "syncingParameterIds" | "syncingDatxFiles";

export interface SessionState {
  initializeSwitcher: "appLayout" | "loginPage" | "registerWizard";
  networkStatus: "online" | "offline";
  reauthRequired: boolean;
  skipCheckToken: boolean;
  refreshLock: boolean;
  pendingRoute: string | undefined;
  sync: {
    status: SyncStatus;
    uploadedFiles: string[];
  };
  userSettings: UserSettings;
}

export const defaultUserSettings: UserSettings = {
  language: "en",
  currency: "SEK",
  temperatureScale: "C",
  speedUnit: "kmph",
  mapProvider: "google",
  csvFormat: "swe",
  useGlobalTimezone: false,
  globalTimezone: undefined,
  showNearValues: true,
  autoUploadDatx: true,
  showQuickTourAtStartup: false,
  defaultBattery: undefined
};

const initialState: SessionState = {
  initializeSwitcher: "loginPage",
  networkStatus: navigator.onLine ? "online" : "offline",
  reauthRequired: false,
  skipCheckToken: false,
  refreshLock: false,
  pendingRoute: undefined,
  sync: {
    status: "idle",
    uploadedFiles: []
  },
  userSettings: {
    ...defaultUserSettings
  }
};

export const slice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setInitalizeSwitcher: (
      state,
      action: PayloadAction<"appLayout" | "loginPage" | "registerWizard">
    ) => {
      state.initializeSwitcher = action.payload;
    },

    onlineNetworkStatus: (state) => {
      state.networkStatus = "online";
    },
    offlineNetworkStatus: (state) => {
      state.networkStatus = "offline";
    },
    setReauthRequired: (state, action: PayloadAction<boolean>) => {
      state.reauthRequired = action.payload;
    },
    setSkipCheckToken: (state, action: PayloadAction<boolean>) => {
      state.skipCheckToken = action.payload;
    },
    setRefreshLock: (state, action: PayloadAction<boolean>) => {
      state.refreshLock = action.payload;
    },
    setSyncStatus: (state, action: PayloadAction<SyncStatus>) => {
      state.sync.status = action.payload;
    },
    setUploadedFile: (state, action: PayloadAction<string>) => {
      state.sync.uploadedFiles.push(action.payload);
    },
    setPendingRoute: (state, action: PayloadAction<string | undefined>) => {
      state.pendingRoute = action.payload;
    },
    setUserSettingsFromApi: (state, action: PayloadAction<UserSettings>) => {
      state.userSettings = {
        ...defaultUserSettings,
        ...action.payload
      };
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.userSettings.language = action.payload;
    },
    setCurrency: (state, action: PayloadAction<Currency>) => {
      state.userSettings.currency = action.payload;
    },
    setTemperatureScale: (state, action: PayloadAction<TemperatureScale>) => {
      state.userSettings.temperatureScale = action.payload;
    },
    setSpeedUnit: (state, action: PayloadAction<SpeedUnit>) => {
      state.userSettings.speedUnit = action.payload;
    },
    setMapProvider: (state, action: PayloadAction<MapProvider>) => {
      state.userSettings.mapProvider = action.payload;
    },
    setCsvFormat: (state, action: PayloadAction<CsvFormat>) => {
      state.userSettings.csvFormat = action.payload;
    },
    setDefaultBattery: (state, action: PayloadAction<string>) => {
      state.userSettings.defaultBattery = action.payload;
    },
    toggleGlobalTimezoneState: (state) => {
      state.userSettings.useGlobalTimezone =
        !state.userSettings.useGlobalTimezone;
    },
    setGlobalTimezoneState: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.userSettings.globalTimezone = action.payload;
    },
    toggleShowNearValues: (state) => {
      state.userSettings.showNearValues = !state.userSettings.showNearValues;
    },
    toggleAutoUploadDatx: (state) => {
      state.userSettings.autoUploadDatx = !state.userSettings.autoUploadDatx;
    },
    setShowQuickTourAtStartup: (state, action: PayloadAction<boolean>) => {
      state.userSettings.showQuickTourAtStartup = action.payload;
    },
    toggleShowQuickTourAtStartup: (state) => {
      state.userSettings.showQuickTourAtStartup =
        !state.userSettings.showQuickTourAtStartup;
    }
  }
});

export const {
  setInitalizeSwitcher,
  onlineNetworkStatus,
  offlineNetworkStatus,
  setReauthRequired,
  setSkipCheckToken,
  setRefreshLock,
  setSyncStatus,
  setUploadedFile,
  setPendingRoute,
  setUserSettingsFromApi,
  setLanguage,
  setCurrency,
  setTemperatureScale,
  setSpeedUnit,
  setMapProvider,
  setCsvFormat,
  setDefaultBattery,
  toggleGlobalTimezoneState,
  setGlobalTimezoneState,
  toggleShowNearValues,
  toggleAutoUploadDatx,
  setShowQuickTourAtStartup,
  toggleShowQuickTourAtStartup
} = slice.actions;

export const getInitalizeSwitcher = (state: StoreApi) =>
  state.session.initializeSwitcher;

export const getUser = (state: StoreApi) => state.persistantState.tokenData;

export const getTokens = (state: StoreApi) =>
  state.persistantState.sessionTokens;

export const sessionState = (state: StoreApi) => state.session;

export const getSyncStatus = (state: StoreApi) => state.session.sync.status;

export const getUploadedFiles = (state: StoreApi) =>
  state.session.sync.uploadedFiles;

export const getPendingRoute = (state: StoreApi) => state.session.pendingRoute;

export const selectMyCurrency = (state: StoreApi) =>
  state.session.userSettings.currency;

export const selectMySpeed = (state: StoreApi) =>
  state.session.userSettings.speedUnit;

export const selectMapProvider = (state: StoreApi) =>
  state.session.userSettings.mapProvider;

export const selectAutoUploadDatx = (state: StoreApi) =>
  state.session.userSettings.autoUploadDatx;

export const selectTemperatureScale = (state: StoreApi) =>
  state.session.userSettings.temperatureScale;

export const selectShowNearValuesToggle = (state: StoreApi) =>
  state.session.userSettings.showNearValues;

export const selectGlobalTimezone = (state: StoreApi) =>
  state.session.userSettings.globalTimezone;

export const selectGlobalTimezoneToggle = (state: StoreApi) =>
  state.session.userSettings.useGlobalTimezone;

export const selectCsvFormat = (state: StoreApi) =>
  state.session.userSettings.csvFormat;

export const selectShowQuickTourAtStartup = (state: StoreApi) =>
  state.session.userSettings.showQuickTourAtStartup;

export const selectDefaultBattery = (state: StoreApi) =>
  state.session.userSettings.defaultBattery;

export default slice.reducer;
