import * as React from "react";
import type { SVGProps } from "react";
const SvgAngleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path
      fill="none"
      stroke={props.fill === undefined ? "#7c287d" : props.fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={72}
      d="M361.69 534.94a457.4 457.4 0 0 1 134.39 324.44"
    />
    <path
      fill="none"
      stroke={props.fill === undefined ? "#b9529f" : props.fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={72}
      d="M731.02 165.62 37.25 859.38H988.4"
    />
  </svg>
);
export default SvgAngleIcon;
