export interface VMLteSchdeuleParams {
  useLteInterval: boolean;
  alwaysOn: boolean;
  GpsRequired: boolean;
  intervalDays: number;
  intervalHours: number;
  intervalMinutes: number;
  flightModeLte: boolean;
}

export interface VMLteParams {
  LteBands: LteBands;
}

interface LteBands {
  Enable2G: boolean;
  Enable3G: boolean;
  Enable4G: boolean;
  Enable5G: boolean;
  Enable6G: boolean;
}

export const emptyLteParams: VMLteSchdeuleParams = {
  useLteInterval: true,
  alwaysOn: false,
  GpsRequired: false,
  intervalDays: 1,
  intervalHours: 0,
  intervalMinutes: 0,
  flightModeLte: false
};

export const disabledLteParams: VMLteSchdeuleParams = {
  ...emptyLteParams,
  useLteInterval: false
};
