import { ColumnType } from "antd/lib/table/interface";

/** Default props for antd tables where the column data is a number (or similar)
 * */
export const defaultTableColumnPropsNumber: ColumnType<any> = {
  // Same behaviour as in Windows (high to low dates and file size)
  sortDirections: ["descend", "ascend"],
  showSorterTooltip: false
};

/** Default props for antd tables where the column data is a string (or similar)
 * */
export const defaultTableColumnPropsString: ColumnType<any> = {
  // Same behaviour as in Windows (low to high for filenames)
  sortDirections: ["ascend", "descend"],
  showSorterTooltip: false
};

/** Default timestamp format that can be used with dayjs */
export const timestampFormat = "YYYY-MM-DD, HH:mm:ss";
