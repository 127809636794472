import { Card, Col, Divider, Row, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconColors } from "../../constants/colors";

import { useTranslation } from "react-i18next";
import { SmallText } from "../Common/CommonFonts";
import { isNil } from "lodash-es";
import { VMDvaData } from "../../helpers/dataModelHelper";
import { AccGeneral } from "../../icons";
import {
  removeDvaCursorPosition,
  selectDvaGraphTools
} from "../../state/openDatxSlice";
import { size } from "../../helpers/pageHelper";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { CloseOutlined } from "@ant-design/icons";

interface IProps {
  dvaDataBlock: VMDvaData;
  scoreValueHeight: number;
}
const DvaScoreValues: React.FC<IProps> = (props) => {
  const { dvaDataBlock, scoreValueHeight } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const graphTools = useSelector(selectDvaGraphTools);

  const titleHeight = 36;
  const contentHeight = scoreValueHeight - titleHeight;

  const scoreValues =
    !isNil(graphTools?.dvaScoreValuesCursorPosIndex) &&
    dvaDataBlock.data[graphTools!.dvaScoreValuesCursorPosIndex];

  if (scoreValues) {
    return (
      <>
        <Card styles={{ body: { padding: 0 } }}>
          <div style={{ height: titleHeight }}>
            <Row
              align="middle"
              justify="space-between"
              style={{ paddingRight: size.s1, paddingLeft: size.s2 }}
            >
              <Col>
                <SmallText strong>{t("ScoreValues")}</SmallText>
              </Col>
              <Col>
                <Space>
                  <NormalButtonSmall
                    icon={<CloseOutlined style={{ fontSize: "12px" }} />}
                    style={{ marginBlock: size.s1 }}
                    onClick={() => dispatch(removeDvaCursorPosition())}
                    title={t("CloseScoreValues")}
                  />
                </Space>
              </Col>
            </Row>
            <Divider style={{ marginTop: 0, marginBottom: size.s1 }} />
            {/* <Row style={{ paddingLeft: size.s2 }}>
              <Typography.Text strong>{t("CurrentPosition")}</Typography.Text>
            </Row>
            <Row style={{ paddingLeft: size.s2 }}>
              <Typography.Text ellipsis>
                {renderCurrentTimeStamp()} {renderCurrentTimeZone()}
              </Typography.Text>
            </Row>
            <Divider style={{ marginTop: size.s2, marginBottom: 0 }} /> */}
          </div>
          <div style={{ height: contentHeight, overflow: "auto" }}>
            <div style={{ paddingTop: size.m1, paddingLeft: size.s2 }}>
              <Row
                justify="space-between"
                align="middle"
                style={{ width: "100%", paddingBottom: size.s2 }}
              >
                <Col>
                  <Space>
                    <AccGeneral width="20px" height="20px" />
                    <SmallText strong>{t("generalAccTitle")}</SmallText>
                  </Space>
                </Col>
              </Row>

              <Row justify="space-between" style={{ width: "100%" }}>
                <Col style={{ minWidth: "45px" }}>
                  <Row justify="start">
                    <SmallText style={{ color: iconColors.xAcc }}>X</SmallText>
                  </Row>
                  <Row justify="start">
                    <SmallText>{scoreValues.xAlarm}g</SmallText>
                  </Row>
                </Col>
                <Col style={{ minWidth: "45px" }}>
                  <Row justify="start">
                    <SmallText style={{ color: iconColors.yAcc }}>Y</SmallText>
                  </Row>
                  <Row justify="start">
                    <SmallText>{scoreValues.yAlarm}g</SmallText>
                  </Row>
                </Col>
                <Col style={{ minWidth: "45px" }}>
                  <Row justify="start">
                    <SmallText style={{ color: iconColors.zAcc }}>Z</SmallText>
                  </Row>
                  <Row justify="start">
                    <SmallText>{scoreValues.zAlarm}g</SmallText>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Card>
      </>
    );
  } else {
    return <></>;
  }
};

export default React.memo(DvaScoreValues);
