import { Space, Tag } from "antd";
import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryTheme
} from "victory";
import { iconColors } from "../../constants/colors";
import { yAxisFormater } from "../../helpers/graphHelper";
import { max } from "lodash-es";
import { commonAxisStyle } from "../../styles/graphStylesCommon";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";

const HistogramLabel = (props: any) => {
  const { t } = useTranslation();
  return (
    <foreignObject {...props}>
      <Space>
        <Tag color={iconColors.xAcc}>{t("XAxis")}</Tag>
        <Tag color={iconColors.yAcc}>{t("YAxis")}</Tag>
        <Tag color={iconColors.zAcc}>{t("ZAxis")}</Tag>
      </Space>
    </foreignObject>
  );
};

export interface AccHistogramData {
  x: {
    x: string;
    y: number;
  };
  y: {
    x: string;
    y: number;
  };
  z: {
    x: string;
    y: number;
  };
}

interface IProps {
  data: AccHistogramData[];
  bins: string[];
  width: number;
  height: number;
}
const AccHistogramGraph: React.FC<IProps> = (props) => {
  const { data, bins, width, height } = props;
  const { t } = useTranslation();

  const barWidth = 8;

  // const chartPadding = 60;
  const chartPaddingX = 100;
  const chartPaddingY = 60;

  return (
    <div style={{ textAlign: "center" }}>
      <VictoryChart
        width={width}
        height={height}
        theme={VictoryTheme.material}
        padding={{
          left: chartPaddingX,
          bottom: chartPaddingY,
          top: chartPaddingY,
          right: 30
        }}
        categories={{ x: bins }}
      >
        {/* label */}
        <HistogramLabel y={16} />

        {/* x-axis */}
        <VictoryAxis
          offsetX={0}
          label={`${t("Shock")} (g)`}
          fixLabelOverlap
          style={{
            ...commonAxisStyle,
            axisLabel: { padding: size.l3, fontSize: 16 }
          }}
        />

        {/* y-axis */}
        <VictoryAxis
          dependentAxis
          offsetX={chartPaddingX}
          label={t("Occurrences")}
          style={{
            ...commonAxisStyle,
            axisLabel: {
              ...commonAxisStyle.axisLabel,
              padding: 60,
              angle: -90,
              fontSize: 16
            }
          }}
          fixLabelOverlap
          tickCount={20}
          tickFormat={(tick: number, _i, allTicks: number[]) => {
            const minVal = 0;
            const maxVal = max(allTicks);
            const tickCount = allTicks.length;

            return yAxisFormater(tick, [minVal, maxVal!], tickCount);
          }}
        />

        <VictoryGroup
          offset={20}
          colorScale={[iconColors.xAcc, iconColors.yAcc, iconColors.zAcc]}
        >
          <VictoryBar data={data.map((v) => v.x)} barWidth={barWidth} />
          <VictoryBar data={data.map((v) => v.y)} barWidth={barWidth} />
          <VictoryBar data={data.map((v) => v.z)} barWidth={barWidth} />
        </VictoryGroup>
      </VictoryChart>
    </div>
  );
};

export default AccHistogramGraph;
