import React from "react";

interface IProps {
  color: string;
  borderColor: string;
  value: string;
  active: boolean;
}
const CustomColorButton: React.FC<IProps> = ({
  color,
  borderColor,
  active
}: IProps) => {
  const standardStyle: React.CSSProperties = {
    height: "40px",
    width: "40px",
    borderRadius: "25px",
    backgroundColor: color,
    boxShadow: "none",
    cursor: "pointer",
    border: "2px solid" + borderColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  const activeStyle: React.CSSProperties = {
    height: "20px",
    width: "20px",
    borderRadius: "25px",
    backgroundColor: "#fff",
    border: "2px solid" + borderColor
  };

  return (
    <>
      <div style={standardStyle}>
        {active ? <div style={activeStyle} /> : ""}
      </div>
    </>
  );
};

export default CustomColorButton;
