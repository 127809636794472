import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreApi } from "./store";
import { LicensePrice } from "./cargologRestApi";

export interface LicensePriceState {
  name: string;
  description: string;
  prices: {
    usd3?: number;
    usd6?: number;
    usd12?: number;
    eur3?: number;
    eur6?: number;
    eur12?: number;
    sek3?: number;
    sek6?: number;
    sek12?: number;
  };
  color: string;
  userRights?: [string];
  licensePriceToRemove?: LicensePrice;
  licensePriceToEdit?: LicensePrice;
  licenseType: number;
}

const initialState: LicensePriceState = {
  name: "",
  description: "",
  color: "green",
  prices: {
    usd3: undefined,
    usd6: undefined,
    usd12: undefined,
    eur3: undefined,
    eur6: undefined,
    eur12: undefined,
    sek3: undefined,
    sek6: undefined,
    sek12: undefined
  },
  userRights: undefined,
  licensePriceToRemove: undefined,
  licensePriceToEdit: undefined,
  licenseType: 1
};

export const slice = createSlice({
  name: "licenseModal",
  initialState,
  reducers: {
    setLicensePriceName: (state, action) => {
      state.name = action.payload;
    },
    setLicensePriceDescription: (state, action) => {
      state.description = action.payload;
    },
    setLicensePriceUsd3: (state, action) => {
      state.prices.usd3 = action.payload;
    },
    setLicensePriceUsd6: (state, action) => {
      state.prices.usd6 = action.payload;
    },
    setLicensePriceUsd12: (state, action) => {
      state.prices.usd12 = action.payload;
    },
    setLicensePriceEur3: (state, action) => {
      state.prices.eur3 = action.payload;
    },
    setLicensePriceEur6: (state, action) => {
      state.prices.eur6 = action.payload;
    },
    setLicensePriceEur12: (state, action) => {
      state.prices.eur12 = action.payload;
    },
    setLicensePriceSek3: (state, action) => {
      state.prices.sek3 = action.payload;
    },
    setLicensePriceSek6: (state, action) => {
      state.prices.sek6 = action.payload;
    },
    setLicensePriceSek12: (state, action) => {
      state.prices.sek12 = action.payload;
    },
    setLicensePriceColor: (state, action) => {
      state.color = action.payload;
    },
    setLicensePriceUserRights: (state, action) => {
      state.userRights = action.payload;
    },
    setLicensePriceToRemove: (state, action: PayloadAction<LicensePrice>) => {
      state.licensePriceToRemove = action.payload;
    },
    setLicensePriceToEdit: (state, action: PayloadAction<LicensePrice>) => {
      state.licensePriceToEdit = action.payload;
    },
    emptyLicensePriceState: (state) => {
      state.name = "";
      state.description = "";
      state.color = "green";
      state.prices = {
        usd3: undefined,
        usd6: undefined,
        usd12: undefined,
        eur3: undefined,
        eur6: undefined,
        eur12: undefined,
        sek3: undefined,
        sek6: undefined,
        sek12: undefined
      };
      state.userRights = undefined;
      state.licensePriceToRemove = undefined;
      state.licensePriceToEdit = undefined;
      state.licenseType = 1;
    },
    setLicenseType: (state, action: PayloadAction<number>) => {
      state.licenseType = action.payload;
    }
  }
});

export const {
  setLicensePriceName,
  setLicensePriceDescription,
  setLicensePriceUsd3,
  setLicensePriceUsd6,
  setLicensePriceUsd12,
  setLicensePriceEur3,
  setLicensePriceEur6,
  setLicensePriceEur12,
  setLicensePriceSek3,
  setLicensePriceSek6,
  setLicensePriceSek12,
  setLicensePriceColor,
  setLicensePriceUserRights,
  setLicensePriceToRemove,
  setLicensePriceToEdit,
  emptyLicensePriceState,
  setLicenseType
} = slice.actions;

export default slice.reducer;

export const selectLicensePriceState = (state: StoreApi) => state.licensePrice;
