import React from "react";
import { useSelector } from "react-redux";
import { selectPressure } from "../../state/openParxSlice";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryTheme
} from "victory";
import mobitronColors from "../../styles/mobitronColors";

interface chartDataType {
  x: number;
  y: number;
  y0: number;
}

const TempCompensationGraph = (): React.JSX.Element => {
  const pressureParams = useSelector(selectPressure);
  const { lowAlarm, highAlarm, slopeValue, tempValue } = pressureParams.params;

  const graphWidth = 300;
  const graphHeight = 150;
  const domainWidth = 100;
  const degPerPx = graphWidth / domainWidth;

  const chartData: chartDataType[] = [
    {
      x: -100,
      y: (-100 - tempValue) * slopeValue + highAlarm,
      y0: (-100 - tempValue) * slopeValue + lowAlarm
    },
    {
      x: 100,
      y: (100 - tempValue) * slopeValue + highAlarm,
      y0: (100 - tempValue) * slopeValue + lowAlarm
    }
  ];

  return (
    <VictoryChart
      containerComponent={<VictoryContainer responsive={false} />}
      width={graphWidth}
      height={graphHeight}
      padding={{ top: 10, bottom: 30, left: 0, right: 0 }}
      theme={VictoryTheme.material}
    >
      <VictoryAxis
        tickFormat={(t) => `${t}`}
        axisValue={0}
        tickValues={[-40, -30, -20, -10, 0, 10, 20, 30, 40]}
        theme={VictoryTheme.material}
      />

      <VictoryAxis
        dependentAxis
        fixLabelOverlap
        theme={VictoryTheme.material}
        offsetX={graphWidth / 2 + tempValue * degPerPx}
        axisLabelComponent={<VictoryLabel y={15} dx={39} textAnchor="middle" />}
        tickValues={[0, 500, 1000, 1500, 2000]}
        tickFormat={(t) => `${t}`}
      />

      <VictoryArea
        scale={{ x: "linear", y: "linear" }}
        data={chartData}
        domain={{ x: [-50, 50], y: [0, 2000] }}
        domainPadding={{ x: 5, y: 5 }}
        width={300}
        height={200}
        style={{
          data: { fill: mobitronColors.altLightGreen, fillOpacity: 0.4 }
        }}
      />
    </VictoryChart>
  );
};

export default TempCompensationGraph;
