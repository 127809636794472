import React, { useEffect, useState } from "react";
import {
  IParsedGPSData,
  selectDatxOptionalFiltersById,
  setMarkedMapKeys
} from "../../state/openDatxSlice";
import {
  CircleMarker,
  MapContainer,
  Marker,
  Polyline,
  TileLayer,
  useMapEvents
} from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import L, { LatLngTuple, Map } from "leaflet";
import { CameraFilled, HomeTwoTone } from "@ant-design/icons";
import ReactDOMServer from "react-dom/server";
import { deviceIcon } from "../DevicesPage/DeviceMap";
import { connectionColors } from "../../constants/colors";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import {
  selectExport,
  setIsFirstScreenshot,
  setMapExportImage
} from "../../state/exportSlice";
import { isNil, isUndefined } from "lodash-es";
import { useResizeDetector } from "react-resize-detector";
import {
  mapExportHeight,
  mapExportWidth
} from "../../helpers/pdf/pdfConstants";
import { notification } from "antd";
import { PrimaryButton } from "../Common/CommonButtons";
import { useTranslation } from "react-i18next";

interface IClusterData {
  position: LatLngTuple; // [latitude, longitude]
  numberOfPoints: number; // number of points in cluster
  key: number;
}

interface IProps {
  fileId: string;
  positionsData: IParsedGPSData[];
  outerBounds: LatLngTuple[];
  center: LatLngTuple;
  firstPositionKey?: number;
  lastPositionKey?: number;
  markedMapKeys?: number[];
  width: number;
  height: number;
}
const MapDashboardExport: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isFirstScreenshot } = useSelector(selectExport);

  const {
    fileId,
    positionsData,
    outerBounds,
    firstPositionKey,
    lastPositionKey,
    center
  } = props;

  const filters = useSelector(selectDatxOptionalFiltersById(fileId));
  const [mapState, setMapState] = useState<Map | null>(null);
  const [clusterData, setClusterData] = useState<IClusterData[]>([]);
  const [duplicatePositionKeys, setDuplicatePositionKeys] = useState<number[]>(
    []
  );

  const { width, height, ref } = useResizeDetector();

  // Get the absolute distance between two positions in display pixels
  const getDistance = (
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number
  ) => {
    const p1 = mapState?.latLngToContainerPoint([lat1, lng1]) || { x: 0, y: 0 };
    const p2 = mapState?.latLngToContainerPoint([lat2, lng2]) || { x: 0, y: 0 };
    return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
  };

  // Get clustered data
  const getClusteredData = () => {
    const clusterDistance = 20 * 20; // Squared in order to avoid using Math.sqrt
    let clusters: IClusterData[] = [];
    let duplicatePositions: number[] = [];
    // If two or more locations are within clusterDistance px of each other, add them to the same cluster.
    positionsData.forEach((data) => {
      if (data.position?.latitude && data.position?.longitude) {
        let index = 0;
        let foundCluster = false;
        const lat1 = data.position.latitude;
        const lng1 = data.position.longitude;
        while (!foundCluster && index < clusters.length) {
          const lat2 = clusters[index].position[0];
          const lng2 = clusters[index].position[1];
          if (getDistance(lat1, lng1, lat2, lng2) < clusterDistance) {
            clusters[index].numberOfPoints++;
            duplicatePositions.push(data.key);
            foundCluster = true;
          } else {
            index++;
          }
        }
        if (!foundCluster) {
          clusters.push({
            position: [data.position.latitude, data.position.longitude],
            numberOfPoints: 1,
            key: data.key
          });
        }
      }
    });
    // Remove clusters with only one point in it
    clusters = clusters.filter((cluster) => cluster.numberOfPoints > 1);
    return { clusters, duplicatePositions };
  };

  const isFiltered = filters.gps.hideSensorData || filters.gps.hideScheduleData;

  const linePositions: LatLngTuple[] = positionsData
    .filter((pos) => pos.position)
    .map((pos) => {
      return [pos.position!.latitude, pos.position!.longitude];
    });

  const pluginOptions = {
    cropImageByInnerWH: true, // crop blank opacity from image borders
    hidden: true, // hide screen icon
    preventDownload: true, // prevent download on button click
    domtoimageOptions: {
      // see options for dom-to-image
      quality: 1,
      bgcolor: "white",
      width: mapExportWidth,
      height: mapExportHeight
    }, // see options for dom-to-image
    // position: "topleft", // position of take screen icon
    screenName: "MapExport", // string or function
    // iconUrl: ICON_SVG_BASE64, // screen btn icon base64 or url
    hideElementsWithSelectors: [".leaflet-control-container"], // by default hide map controls All els must be child of _map._container
    mimeType: "image/png", // used if format == image,
    caption: null, // string or function, added caption to bottom of screen
    captionFontSize: 15,
    captionFont: "Arial",
    captionColor: "black",
    captionBgColor: "white",
    captionOffset: 5
  };

  let map: Map | undefined = undefined;
  const [mapLoaded, setMapLoaded] = useState(false);
  const screenshotter = new SimpleMapScreenshoter(pluginOptions);

  const takeScreenshot = () => {
    if (isUndefined(map)) {
      return;
    }
    screenshotter.addTo(map);
    screenshotter
      .takeScreen("image")
      .then((image: any) => {
        // Create <img> element to render img data
        const img = new Image();

        img.onload = (e) => {
          // Create canvas to process image data
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = mapExportWidth;
          canvas.height = mapExportHeight;

          // Draw just the portion of the whole map image that contains
          // your feature to the canvas
          // from https://stackoverflow.com/questions/26015497/how-to-resize-then-crop-an-image-with-canvas
          if (ctx) {
            ctx.drawImage(
              img,
              0, // top left x
              0, // top left y
              mapExportWidth, // imageSize.x,
              mapExportHeight, // imageSize.y,
              0,
              0,
              mapExportWidth, // width
              mapExportHeight // height
            );
          }
          // Create URL for resultant png
          var imageUrl = canvas.toDataURL("image/png");

          let resultantImage = new Image();
          resultantImage.src = imageUrl;

          // Used for notifications
          canvas.toBlob((blob) => {
            // If the is smaller than 11 000 bytes, the image is not saved
            if (!isNil(blob) && blob.size < 11000) {
              notification.error({
                message: t("ErrorSavingImage"),
                description: t("SomethingWentWrongSavingTheImage")
              });
            } else if (!isFirstScreenshot) {
              notification.success({
                message: t("ImageSaved"),
                description: t("ImageSavedSuccessfully")
              });
            }
          }, "image/png");

          dispatch(setMapExportImage(imageUrl));
        };
        img.src = image;

        dispatch(setIsFirstScreenshot(false));
        img.onerror = (e) => {
          console.log("onerror", e);
        };
      })
      .catch((error) => {
        console.log("catch error", error);
      });
  };

  // Runs the first time the modal is opened
  useEffect(() => {
    if (!isUndefined(map)) {
      updateClusters();
      /** The function seems to be running before the map is completed. Adding a delay fixes that error. */
      setTimeout(() => {
        takeScreenshot();
      }, 200);
    }
  }, [mapLoaded, map, mapState]);

  const updateClusters = () => {
    const { clusters, duplicatePositions } = getClusteredData();
    setClusterData(clusters);
    setDuplicatePositionKeys(duplicatePositions);
  };
  const MapHook = () => {
    map = useMapEvents({
      zoomend: () => {
        updateClusters();
      },
      resize: () => {
        updateClusters();
      },
      zoomlevelschange: () => {
        updateClusters();
      },
      mousedown: (e) => {
        // Prevents CC from crashing when "click-dragging" with scroll wheel button in the map
        if (e.originalEvent.button === 1) {
          e.originalEvent.preventDefault();
          return false;
        }
      }
    });
    return null;
  };
  useEffect(() => {
    updateClusters();
  }, [positionsData]);

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        height: mapExportHeight,
        width: "100%",
        overflow: "auto"
      }}
      ref={ref}
    >
      <PrimaryButton
        style={{
          position: "absolute",
          zIndex: 1048,
          bottom: 20,
          left: 20
        }}
        onClick={() => takeScreenshot()}
        icon={<CameraFilled />}
      >
        {t("TakeNewScreenshot")}
      </PrimaryButton>

      {height && width && (
        <MapContainer
          whenReady={() => setMapLoaded(true)}
          style={{
            width: mapExportWidth,
            height: mapExportHeight
          }}
          dragging={true}
          zoomControl={true}
          keyboard={false}
          center={outerBounds.length <= 1 ? center : undefined}
          bounds={outerBounds.length > 1 ? outerBounds : undefined}
          zoom={12}
          minZoom={2}
          maxZoom={12} // 12 is the maximum zoom level for the image to be saved
          scrollWheelZoom={true}
          ref={setMapState}
        >
          <MapHook />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxNativeZoom={19}
            maxZoom={20}
          />

          {/* Polyline */}
          <Polyline
            pathOptions={{ opacity: 0.3 }}
            positions={linePositions}
            color={"#006600"}
            pane="overlayPane"
          />

          {/* Dots, start and stop */}
          {positionsData.length > 0 &&
            positionsData.map((position, index) => {
              // Start marker
              if (position.key === firstPositionKey && !isFiltered) {
                const startMarker = new L.DivIcon({
                  iconAnchor: [10, 10],
                  popupAnchor: [0.5, -10],
                  html: ReactDOMServer.renderToString(
                    <HomeTwoTone
                      twoToneColor="#006600"
                      style={{ fontSize: 20 }}
                    />
                  ),
                  className: "custom-icon"
                });
                return (
                  <Marker
                    key={index}
                    position={[
                      position.position!.latitude,
                      position.position!.longitude
                    ]}
                    icon={startMarker}
                    pane={"markerPane"}
                  ></Marker>
                );
              }
              // End marker
              else if (position.key === lastPositionKey && !isFiltered) {
                return (
                  <Marker
                    key={index}
                    position={[
                      position.position!.latitude,
                      position.position!.longitude
                    ]}
                    icon={deviceIcon}
                    pane={"markerPane"}
                  ></Marker>
                );
              }
              // Normal markers
              else if (!duplicatePositionKeys.includes(position.key)) {
                return (
                  <div style={{ zIndex: 40 }} key={index}>
                    <CircleMarker
                      key={position.key}
                      center={[
                        position.position!.latitude,
                        position.position!.longitude
                      ]}
                      radius={3}
                      color={
                        position.alarm ? connectionColors.sensor : "#006600"
                      }
                      eventHandlers={{
                        click: () => {
                          dispatch(
                            setMarkedMapKeys({
                              fileId,
                              markedKeys: [position.key]
                            })
                          );
                        }
                      }}
                    ></CircleMarker>
                  </div>
                );
              } else {
                return <React.Fragment key={index}></React.Fragment>;
              }
            })}

          {/* Cluster position */}
          {clusterData &&
            clusterData.length > 0 &&
            clusterData.map((cluster) => {
              let pillW = 16;
              if (cluster.numberOfPoints < 10) {
              } else if (cluster.numberOfPoints < 100) {
                pillW = 20;
              } else if (cluster.numberOfPoints < 1000) {
                pillW = 24;
              } else if (cluster.numberOfPoints < 10000) {
                pillW = 28;
              } else {
                pillW = 32;
              }

              const customMarker = new L.DivIcon({
                className: "counter-icon",
                iconSize: [pillW * 2, 32],
                iconAnchor: [pillW, 16],
                html: cluster.numberOfPoints.toString()
              });

              return (
                <Marker
                  key={cluster.key}
                  opacity={0.8}
                  position={cluster.position}
                  icon={customMarker}
                  zIndexOffset={cluster.numberOfPoints}
                  eventHandlers={{
                    click: () => {
                      dispatch(
                        setMarkedMapKeys({
                          fileId,
                          markedKeys: [cluster.key]
                        })
                      );
                    }
                  }}
                ></Marker>
              );
            })}
        </MapContainer>
      )}
    </div>
  );
};

export default MapDashboardExport;
