import React from "react";
import { notification, Row, Typography } from "antd";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import {
  useProjectByIdQuery,
  useUpdateProjectStatusMutation
} from "../../state/cargologRestApi";
import {
  closeArchiveProjectModal,
  projectsState
} from "../../state/projectSlice";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import { WarningOutlined } from "@ant-design/icons";
import { WarningAlert } from "../Common/CommonAlerts";
import { skipToken } from "@reduxjs/toolkit/query";
import StandardModal from "../Common/StandardModal";

const { Text } = Typography;

type ArchiveProjectType = { projectId: string; status: number };

const ArchiveProjectModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedProjectId, isArchiveProjectModalOpen } =
    useSelector(projectsState);

  const { data: project } = useProjectByIdQuery(
    selectedProjectId ? { id: selectedProjectId } : skipToken
  );

  const [updateProjectStatus, requestStatus] = useUpdateProjectStatusMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleArchiveProject = async (request: ArchiveProjectType) => {
    const result: any = await updateProjectStatus(request);
    if (result.error) {
      let errorDescription: string = "";
      const e = result.error.data;
      if (e && e.errors) {
        for (let i in e.errors) {
          errorDescription += " " + e.errors[i][0];
        }
      } else if (e) {
        for (let i in e) {
          errorDescription += " " + e[i][0];
        }
      }
      notification.error({
        message: t("FailedToArchiveProject"),
        description: errorDescription
      });
    } else {
      notification.success({
        message: t("SuccessfullyArchivedProject") + " " + project?.title
      });
      dispatch(closeArchiveProjectModal());
    }
  };

  const onFinish = () => {
    if (!isUndefined(selectedProjectId)) {
      // status = 3 represents Archive project
      const request = { projectId: selectedProjectId, status: 3 };
      handleArchiveProject(request);
    }
  };

  return (
    <StandardModal
      title={t("ArchiveProject")}
      open={isArchiveProjectModalOpen}
      zIndex={1045}
      onCancel={() => dispatch(closeArchiveProjectModal())}
      closable={true}
      footer={[
        <NormalButton
          style={{ marginRight: size.m1 }}
          htmlType="submit"
          onClick={() => dispatch(closeArchiveProjectModal())}
        >
          {t("Cancel")}
        </NormalButton>,
        <PrimaryButton
          danger
          htmlType="submit"
          onClick={onFinish}
          loading={requestIsLoading}
        >
          <WarningOutlined /> {t("ArchiveProject")}
        </PrimaryButton>
      ]}
    >
      <Row>
        <WarningAlert message={t("ArchiveProjectWarningExplanation")} />
      </Row>
      <Row style={{ paddingTop: size.m1 }}>
        <Text>
          {t("AreYouSureYouWantToArchiveTheProject")}{" "}
          <Text strong>{project?.title}</Text>?
        </Text>
      </Row>
    </StandardModal>
  );
};

export default ArchiveProjectModal;
