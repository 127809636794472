import React from "react";
import { useDispatch } from "react-redux";
import { Form, Row, Col, InputNumber, Typography } from "antd";
import {
  setLicensePriceUsd3,
  setLicensePriceUsd6,
  setLicensePriceUsd12,
  setLicensePriceEur3,
  setLicensePriceEur6,
  setLicensePriceEur12,
  setLicensePriceSek3,
  setLicensePriceSek6,
  setLicensePriceSek12
} from "../../state/licensePriceSlice";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";

interface IProps {}
const CreateLicensePriceModalStage2: React.FC<IProps> = (props) => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <Row gutter={[size.m2, size.m2]}>
        <Col span={6}>
          <Form.Item label={" "}>
            <Text strong>3 </Text>
            {t("licensePriceMonths")}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"SEK"}>
            <InputNumber
              onChange={(value) => dispatch(setLicensePriceSek3(Number(value)))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"EUR"}>
            <InputNumber
              onChange={(value) => dispatch(setLicensePriceEur3(Number(value)))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={"USD"}>
            <InputNumber
              onChange={(value) => dispatch(setLicensePriceUsd3(Number(value)))}
            />
          </Form.Item>
        </Col>
      </Row>
      {/*---------------------*/}
      <Row gutter={[size.m2, size.m2]}>
        <Col span={6}>
          <Form.Item>
            <Text strong>6 </Text>
            {t("licensePriceMonths")}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              onChange={(value) => dispatch(setLicensePriceSek6(Number(value)))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              onChange={(value) => dispatch(setLicensePriceEur6(Number(value)))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              onChange={(value) => dispatch(setLicensePriceUsd6(Number(value)))}
            />
          </Form.Item>
        </Col>
      </Row>
      {/*---------------------*/}
      <Row gutter={[size.m2, size.m2]}>
        <Col span={6}>
          <Form.Item>
            <Text strong>12 </Text>
            {t("licensePriceMonths")}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              onChange={(value) => dispatch(setLicensePriceSek12(value))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              onChange={(value) => dispatch(setLicensePriceEur12(value))}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item>
            <InputNumber
              onChange={(value) => dispatch(setLicensePriceUsd12(value))}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CreateLicensePriceModalStage2;
