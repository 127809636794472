import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCreateOrderForCompanyModal,
  modalsState
} from "../../state/modalsSlice";
import { useTranslation } from "react-i18next";
import StandardModal from "../Common/StandardModal";
import {
  Card,
  Col,
  Input,
  notification,
  Row,
  Select,
  SelectProps,
  TreeSelect,
  Typography
} from "antd";
import {
  ChildCompany,
  ILicensePriceIdAndCount,
  UploadOrderToCompany,
  useCreateOrderForCompanyMutation,
  useGetAllLicensePricesQuery,
  useGetCompanyTreeQuery
} from "../../state/cargologRestApi";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DangerButtonSmall,
  NormalButton,
  PrimaryButton
} from "../Common/CommonButtons";
import { size } from "../../helpers/pageHelper";
const { Option } = Select;
const { Text, Title } = Typography;

enum ValueType {
  licensePriceId = 0,
  months = 1,
  count = 2
}
interface DataNode {
  title?: string;
  key: string;
  value: string;
  children?: DataNode[];
  disabled: boolean;
}

const emptyLicensePriceIdAndCount: ILicensePriceIdAndCount = {
  licensePriceId: undefined,
  count: 1,
  months: 3
};

const CreateOrderForCompanyModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [order, setOrder] = useState<UploadOrderToCompany>({
    companyId: "",
    paymentTypeId: 1,
    currency: "",
    licensePriceIdAndCount: [{ ...emptyLicensePriceIdAndCount }],
    purchaseOrderNumber: ""
  } as UploadOrderToCompany);

  const modals = useSelector(modalsState);
  const { data: companyTreeData } = useGetCompanyTreeQuery();
  const { data: licensePrices } = useGetAllLicensePricesQuery();
  const [createOrderForCompany, requestStatus] =
    useCreateOrderForCompanyMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  //#region Select options
  const CompanyTree: React.FC<{}> = () => {
    let walkTree = (company: ChildCompany): DataNode => {
      let treeChildren: DataNode[] = [];
      if (modals.companyIdObject !== company.id) {
        treeChildren = company.childCompanies
          ? company.childCompanies.map((child) => walkTree(child))
          : [];
      }
      let tree: DataNode = {
        title: company.name ?? "",
        key: company.id,
        value: company.id,
        children: treeChildren,
        disabled: modals.companyIdObject === company.id
      };
      return tree;
    };
    let treeData: DataNode[] | undefined = companyTreeData
      ? [walkTree(companyTreeData)]
      : undefined;
    return (
      <TreeSelect
        style={{ width: "100%" }}
        placeholder={t("SelectCompany")}
        treeData={treeData}
        treeDefaultExpandAll
        value={order.companyId}
        onChange={(value) => setOrder({ ...order, companyId: value })}
      />
    );
  };

  const LicenseSelect: React.FC<{ index: number }> = ({ index }) => {
    const licenseSelectData = licensePrices?.map((license) => {
      return (
        <Option key={license.id} value={license.id}>
          {license.name}
          <Text type="secondary">
            {" "}
            ({license.deviceType === 1 ? t("cargolog3rdGen") : null}
            {license.deviceType === 2 ? t("cargologEasy") : null}
            {license.deviceType === 3 ? t("vehicle") : null})
          </Text>
        </Option>
      );
    });
    return (
      <Select
        style={{ width: "100%" }}
        placeholder={t("SelectLicense")}
        value={order.licensePriceIdAndCount![index].licensePriceId}
        onChange={(value) =>
          handleLicenseUpdate(index, ValueType.licensePriceId, value)
        }
      >
        {licenseSelectData}
      </Select>
    );
  };

  const amountOptions: SelectProps["options"] = [];
  for (let i = 1; i < 11; i++) {
    amountOptions.push({ value: i, label: `${i.toString()}` });
  }

  const currencyOptions: SelectProps["options"] = [
    { value: "SEK", label: "SEK" },
    {
      value: "EUR",
      label: "EUR"
    },
    {
      value: "USD",
      label: "USD"
    }
  ];

  const monthsOptions: SelectProps["options"] = [
    {
      value: 3,
      label: "3 months"
    },
    {
      value: 6,
      label: "6 months"
    },
    { value: 12, label: "12 months" }
  ];
  //#endregion

  const handleLicenseUpdate = (
    index: number,
    valueType: number,
    value: number | string
  ) => {
    if (order.licensePriceIdAndCount) {
      switch (valueType) {
        case ValueType.licensePriceId:
          order.licensePriceIdAndCount![index].licensePriceId = value as string;
          break;
        case ValueType.months:
          order.licensePriceIdAndCount![index].months = value as number;
          break;
        case ValueType.count:
          order.licensePriceIdAndCount![index].count = value as number;
          break;
      }
      setOrder({ ...order });
    }
  };

  const handleCreateOrderForCompanyMutation = async (
    order: UploadOrderToCompany
  ) => {
    const result: any = await createOrderForCompany(order);
    if (result.data) {
      dispatch(closeCreateOrderForCompanyModal());
      notification.success({ message: t("OrderCreatedSuccessfully") });
    }
    if (result.error) {
      notification.error({ message: t("ErrorCreatingOrder") });
    }
  };

  const renderLicensePicker = (
    license: ILicensePriceIdAndCount,
    index: number
  ) => {
    return (
      <Card style={{ marginTop: index !== 0 ? size.m2 : 0 }} key={index}>
        <Row align={"middle"} gutter={size.m1}>
          <Col span={2}></Col>
          <Col span={12}>
            <Text strong style={{ color: "red" }}>
              *{" "}
            </Text>
            <Text>{t("LicenseType")}:</Text>
          </Col>
          <Col span={5}>
            <Text>{t("licensePriceMonths")}:</Text>
          </Col>
          <Col span={3}>
            <Text>{t("tableAmount")}:</Text>
          </Col>
          <Col span={2}></Col>
        </Row>
        <Row align={"middle"} gutter={size.m1}>
          <Col span={2}>
            <Title level={5} style={{ marginTop: size.m1 }}>
              {index + 1}.
            </Title>
          </Col>
          <Col span={12}>
            <LicenseSelect index={index} />
          </Col>
          <Col span={5}>
            <Select
              style={{ width: "100%" }}
              value={license.months}
              options={monthsOptions}
              onChange={(value) =>
                handleLicenseUpdate(index, ValueType.months, value)
              }
            ></Select>
          </Col>
          <Col span={3}>
            <Select
              style={{ width: "100%" }}
              value={license.count}
              options={amountOptions}
              onChange={(value) =>
                handleLicenseUpdate(index, ValueType.count, value)
              }
            ></Select>{" "}
          </Col>
          <Col span={2}>
            <DangerButtonSmall
              disabled={order.licensePriceIdAndCount!.length === 1}
              onClick={() => {
                const licenses = order.licensePriceIdAndCount;
                licenses?.splice(index, 1);
                setOrder({ ...order, licensePriceIdAndCount: licenses });
              }}
            >
              <DeleteOutlined />
            </DangerButtonSmall>
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <StandardModal
      title={t("CreateOrderForCompany")} //TODO: Add translation
      open={modals.isCreateOrderForCompanyModalOpen}
      onCancel={() => dispatch(closeCreateOrderForCompanyModal())}
      closable={true}
      width={800}
      zIndex={1045}
      destroyOnClose={true}
      centered={true}
      footer={[
        <NormalButton
          key="cancel"
          onClick={() => dispatch(closeCreateOrderForCompanyModal())}
        >
          {t("Cancel")}
        </NormalButton>,
        <PrimaryButton
          key="create"
          loading={requestIsLoading}
          disabled={
            order.licensePriceIdAndCount?.some(
              (product) => product.licensePriceId === undefined
            ) ||
            order.currency === "" ||
            requestIsLoading
          }
          onClick={() => {
            handleCreateOrderForCompanyMutation(order);
          }}
        >
          {t("Create")}
        </PrimaryButton>
      ]}
    >
      <Row
        justify="space-between"
        align="bottom"
        gutter={size.m1}
        style={{ marginBottom: 10 }}
      >
        <Col span={12}>
          <Col>
            <Text strong style={{ color: "red" }}>
              *{" "}
            </Text>
            <Text>{t("Company")}:</Text>
          </Col>
          <Col>
            <CompanyTree />
          </Col>
        </Col>
        <Col>
          <Col>
            <Text strong style={{ color: "red" }}>
              *{" "}
            </Text>
            <Text>{t("Currency")}:</Text>
          </Col>
          <Col>
            <Select
              style={{ width: "100%" }}
              options={currencyOptions}
              value={order.currency}
              onChange={(value) => {
                setOrder({ ...order, currency: value as string });
              }}
            ></Select>
          </Col>
        </Col>
        <Col>
          {order.companyId && order.licensePriceIdAndCount!.length < 10 && (
            <PrimaryButton
              icon={<PlusOutlined />}
              onClick={() =>
                setOrder({
                  ...order,
                  licensePriceIdAndCount: [
                    ...order.licensePriceIdAndCount!,
                    { ...emptyLicensePriceIdAndCount }
                  ]
                })
              }
            >
              {t("AddLicense")}
            </PrimaryButton>
          )}
        </Col>
      </Row>
      {order.companyId && (
        <div style={{ marginTop: size.m2 }}>
          <Title level={4} style={{ marginTop: 0 }}>
            {t("Licenses")}
          </Title>
          <Card style={{ maxHeight: "450px", overflow: "auto" }}>
            {order.licensePriceIdAndCount!.map((license, index) =>
              renderLicensePicker(license, index)
            )}
          </Card>
        </div>
      )}
      {order.companyId && (
        <Row style={{ marginTop: size.m2 }}>
          <Col span={12}>
            <Col>
              <Text>{t("PurchaseOrderNumber")}:</Text>
            </Col>
            <Col>
              <Input
                style={{ width: "80%" }}
                onChange={(value) =>
                  setOrder({
                    ...order,
                    purchaseOrderNumber: value.target.value
                  })
                }
              ></Input>
            </Col>
          </Col>
        </Row>
      )}
    </StandardModal>
  );
};

export default CreateOrderForCompanyModal;
