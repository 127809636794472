import * as React from "react";
import type { SVGProps } from "react";
const SvgPressureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 141.73 141.73"
    {...props}
  >
    <text
      x={2.31}
      y={100.29}
      fill={props.fill === undefined ? "#7c287d" : props.fill}
      fontFamily="CenturyGothic-Bold, 'Century Gothic'"
      fontSize={23.57}
      fontWeight={700}
    >
      <tspan
        letterSpacing=".11em"
        fill={props.fill === undefined ? "#7c287d" : props.fill}
      >
        {"P"}
      </tspan>
      <tspan
        x={18.01}
        y={100.29}
        letterSpacing=".01em"
        fill={props.fill === undefined ? "#7c287d" : props.fill}
      >
        {"r"}
      </tspan>
      <tspan
        x={25.86}
        y={100.29}
        letterSpacing=".03em"
        fill={props.fill === undefined ? "#7c287d" : props.fill}
      >
        {"e"}
      </tspan>
      <tspan
        x={41.56}
        y={100.29}
        letterSpacing=".06em"
        fill={props.fill === undefined ? "#7c287d" : props.fill}
      >
        {"s"}
      </tspan>
      <tspan
        x={53.35}
        y={100.29}
        fill={props.fill === undefined ? "#7c287d" : props.fill}
      >
        {"s"}
      </tspan>
    </text>
    <path
      d="M69.139 27.272a69 69 0 0 0-14.257 1.368l.157 12.16a57.2 57.2 0 0 1 16.673 2.237l4.683-15.309a70 70 0 0 0-7.255-.457zm11.102.998-4.88 15.953a58.8 58.8 0 0 1 15.205 8.19l11.657-16.464a69.8 69.8 0 0 0-21.983-7.68zM51.142 29.5a67.8 67.8 0 0 0-18.637 8.002l2.477 7.354a55.5 55.5 0 0 1 16.309-3.867zm54.329 8.35L93.502 54.757c4.442 3.773 8.425 8.277 11.773 13.472l18.76-13.451c-5.298-6.933-11.612-12.586-18.564-16.926m-76.207 1.778c-5.267 3.698-10.147 8.247-14.48 13.648l2.366 3.066c4.453-4.18 9.302-7.494 14.39-9.963zM12.438 56.372C7.267 63.548 3.006 72.067.016 81.992c3.781-9.296 8.73-16.93 14.449-22.991zm113.8 1.438-19.022 13.64c2.253 3.996 4.125 8.374 5.59 13.081 8.208-2.408 15.284-4.48 23.169-6.78-2.427-7.446-5.736-14.102-9.737-19.941zm11.409 25.771c-7.545 3.753-14.661 7.283-22.435 11.131a82 82 0 0 1 .921 8.471h24.3c-.306-7.001-1.268-13.536-2.786-19.602zM141.65 78.6c-2.007.027-48.369 14.014-65.661 19.251a10.19 10.19 0 0 1 4.7 8.585 10.2 10.2 0 0 1-.383 2.606c16.363-7.92 61.594-29.868 61.407-30.427q-.005-.015-.063-.014z"
      fill={props.fill === undefined ? "#7c287d" : props.fill}
    />
    <circle
      cx={70.499}
      cy={106.44}
      r={7.64}
      fillRule="evenodd"
      fill={props.fill === undefined ? "#7c287d" : props.fill}
    />
  </svg>
);
export default SvgPressureIcon;
