import React from "react";
import { Row, Col, Tabs } from "antd";
import {
  AuditOutlined,
  CreditCardOutlined,
  TeamOutlined,
  InboxOutlined,
  ShoppingOutlined,
  BankOutlined,
  PercentageOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import MobitronAdminHelp from "../components/MobitronAdminPage/MobitronAdminHelp";
import MobitronAdminOrders from "../components/MobitronAdminPage/MobitronAdminOrders";
import MobitronAdminLicenses from "../components/MobitronAdminPage/MobitronAdminLicenses";
import MobitronAdminUsers from "../components/MobitronAdminPage/MobitronAdminUsers";
import CompanyTree from "../components/MobitronAdminPage/MobitronAdminCompanies";
import MobitronAdminStore from "../components/MobitronAdminPage/MobitronAdminStore";
import MobitronAdminDiscounts from "../components/MobitronAdminPage/MobitronAdminDiscounts";
import Online from "../components/MicroComponents/Online";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentMobitronAdminTab,
  selectNavigation,
  setCurrentMobitronAdminTab
} from "../state/navigationSlice";
import { size } from "../helpers/pageHelper";
const { TabPane } = Tabs;

const AdminPage = () => {
  const { t } = useTranslation();
  const iconStyle = { marginRight: size.s1 };
  const navigation = useSelector(selectNavigation);
  const currentTab = navigation.currentMobitronAdminTab;
  const dispatch = useDispatch();

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify="center" style={{ padding: size.m1, paddingTop: 0 }}>
            <Tabs
              centered
              size="large"
              style={{ width: "100%" }}
              tabBarStyle={{ marginBottom: 0 }}
              activeKey={currentTab}
              onChange={(key: string) =>
                dispatch(
                  setCurrentMobitronAdminTab(key as CurrentMobitronAdminTab)
                )
              }
              destroyInactiveTabPane={true}
            >
              <TabPane
                tab={
                  <span>
                    <TeamOutlined style={iconStyle} />
                    {t("Users")}
                  </span>
                }
                key="users"
              >
                <Online>
                  <MobitronAdminUsers />
                </Online>
              </TabPane>
              <TabPane
                tab={
                  <>
                    <AuditOutlined style={iconStyle} />
                    {t("Licenses")}
                  </>
                }
                key="licenses"
              >
                <Online>
                  <MobitronAdminLicenses />
                </Online>
              </TabPane>
              <TabPane
                tab={
                  <>
                    <CreditCardOutlined style={iconStyle} />
                    {t("Invoices")}
                  </>
                }
                key="invoices"
              >
                <Online>
                  <MobitronAdminOrders />
                </Online>
              </TabPane>
              <TabPane
                tab={
                  <>
                    <ShoppingOutlined style={iconStyle} />
                    {t("StoreAdmin")}
                  </>
                }
                key="store"
              >
                <Online>
                  <MobitronAdminStore />
                </Online>
              </TabPane>
              <TabPane
                tab={
                  <>
                    <PercentageOutlined style={iconStyle} />
                    {t("Discounts")}
                  </>
                }
                key="discounts"
              >
                <Online>
                  <MobitronAdminDiscounts />
                </Online>
              </TabPane>
              <TabPane
                tab={
                  <>
                    <BankOutlined style={iconStyle} />
                    {t("Companies")}
                  </>
                }
                key="companies"
              >
                <Online>
                  <CompanyTree />
                </Online>
              </TabPane>
              <TabPane
                tab={
                  <>
                    <InboxOutlined style={iconStyle} />
                    {t("HelpInbox")}
                  </>
                }
                key="help"
              >
                <Online>
                  <MobitronAdminHelp />
                </Online>
              </TabPane>
            </Tabs>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AdminPage;
