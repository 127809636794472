import React from "react";
import { Row, Col, CardProps } from "antd";
import { LiftedCard } from "../Common/CommonCards";
import { size } from "../../helpers/pageHelper";
import CompanyLandingCard from "./CompanyLandingCard";
//import SetupDeviceLandingCard from "./SetupDeviceLandingCard";
//import ReadDataLandingCard from "./ReadDataLandingCard";
import ReportLandingCard from "./ReportLandingCard";
import DevicesLandingCard from "./DevicesLandingCard";
import UsersLandingCard from "./UsersLandingCard";
import LicensesLandingCard from "./LicensesLandingCard";
import InvoicesLandingCard from "./InvoicesLandingCard";
import AccountLandingCard from "./AccountLandingCard";
import MapLandingCard from "./MapLandingCard";
import CalibrationLandingCard from "./CalibrationLandingCard";

const landingCardHeight = 300;
/* Dynamic Landing Card */
export const LandingCard = (props: CardProps) => (
  <Col xs={24} lg={12} xl={8} xxl={6}>
    <LiftedCard
      {...props}
      bordered={false}
      style={{
        ...props.style,
        height: landingCardHeight,
        borderRadius: size.m1
      }}
    >
      {props.children}
    </LiftedCard>
  </Col>
);

const Landing = () => {
  return (
    <>
      <Row gutter={[size.l2, size.l2]}>
        {/* <SetupDeviceLandingCard/> */}

        {/* <ReadDataLandingCard/> */}

        <MapLandingCard />

        <ReportLandingCard />

        <DevicesLandingCard />

        <CalibrationLandingCard />

        <UsersLandingCard />

        <LicensesLandingCard />

        <InvoicesLandingCard />

        <CompanyLandingCard />

        <AccountLandingCard />
      </Row>
    </>
  );
};

export default Landing;
