import * as React from "react";
import type { SVGProps } from "react";
const SvgXAccIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path
      fill={props.fill === undefined ? "#70cddd" : props.fill}
      d="M222 354.4a37.24 37.24 0 0 1 26.33-10.92l659.92-.68a37 37 0 0 1 37 37.13A37.39 37.39 0 0 1 908 417.14l-660 .69a37.08 37.08 0 0 1-26-63.43"
    />
    <path
      fill="none"
      stroke={props.fill === undefined ? "#70cddd" : props.fill}
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={72}
      d="m632.81 101.56 277.84 278.62-279.2 278.42"
    />
    <path
      fill={props.fill === undefined ? "#231f20" : props.fill}
      d="M64.62 962.08 197 760 77 574.83h91.4l77.66 124.41 76.08-124.41h90.6L292.32 762.9l132.34 199.18h-94.3l-85.85-133.93-86.12 133.93Z"
    />
  </svg>
);
export default SvgXAccIcon;
