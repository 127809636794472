import React, { useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Row } from "antd";
import mobitronColors from "../../styles/mobitronColors";

interface ChildProp {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  key?: string | number;
}

export const Atable = (props: ChildProp): React.JSX.Element => (
  <div
    className="ant-table-wrapper mobitron-antd-table"
    style={{ width: "100%" }}
  >
    <div className="ant-table ant-table-bordered">
      <div className="ant-table-container">
        <div className="ant-table-content">
          <table style={{ textAlign: "start", width: "100%", ...props.style }}>
            {props.children}
          </table>
        </div>
      </div>
    </div>
  </div>
);

export const Athead = (props: ChildProp): React.JSX.Element => (
  <thead className="ant-table-thead" style={props.style} key={props.key}>
    {props.children}
  </thead>
);

export const Atbody = (props: ChildProp): React.JSX.Element => (
  <tbody className="ant-table-tbody" style={props.style} key={props.key}>
    {props.children}
  </tbody>
);

export const Ath = (props: ChildProp): React.JSX.Element => (
  <th className="ant-table-cell" style={props.style} key={props.key}>
    {props.children}
  </th>
);

export const Atd = (props: ChildProp): React.JSX.Element => (
  <td className="ant-table-row" style={props.style}>
    {props.children}
  </td>
);

export const AtColumnSorted = (
  props: ChildProp & {
    title: string;
    sortState: "asc" | "desc" | "default";
    onClick: () => void;
  }
): React.JSX.Element => {
  const [hover, setHover] = useState(false);

  return (
    <Row
      align="middle"
      justify="space-between"
      onClick={() => props.onClick()}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <th
        className="ant-table-cell ant-table-column-has-sorters"
        tabIndex={0}
        scope="col"
        style={{
          width: "100%",
          background:
            props.sortState !== "default" || hover === true
              ? "rgba(0, 0, 0, 0.06)"
              : "#fafafa"
        }}
      >
        <div className="ant-table-column-sorters">
          <span
            className="ant-table-column-title"
            style={{ fontWeight: 600, flex: "none" }}
          >
            {props.title}
          </span>
          <span className="ant-table-column-sorter ant-table-column-sorter-full">
            <span className="ant-table-column-sorter-inner">
              <CaretUpOutlined
                style={{
                  color:
                    props.sortState === "asc"
                      ? mobitronColors.altDarkGreen
                      : "rgba(0, 0, 0, 0.29)"
                }}
              />
              <CaretDownOutlined
                style={{
                  color:
                    props.sortState === "desc"
                      ? mobitronColors.altDarkGreen
                      : "rgba(0, 0, 0, 0.29)"
                }}
              />
            </span>
          </span>
        </div>
      </th>
    </Row>
  );
};
