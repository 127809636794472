import React, { useEffect, useRef, useState } from "react";
import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import {
  WarningOutlined,
  ExclamationCircleOutlined,
  CarryOutOutlined
} from "@ant-design/icons";
import { setNewCursorPosition } from "../../state/openDatxSlice";
import { useDispatch } from "react-redux";
import { timestampFormat } from "../../constants/defaultComponentsProps";
import { createTzDate } from "../../helpers/dateHelper";
import { Atable, AtColumnSorted, Ath, Athead } from "../Common/CommonTables";
import classNames from "classnames";
import { VariableSizeGrid as Grid } from "react-window";
import { size } from "../../helpers/pageHelper";
const { Link } = Typography;

export type LteRowType =
  | "power"
  | "error"
  | "blocked"
  | "schedule"
  | "sensor"
  | "sessionEnded"
  | "unknown";

export interface LteTableData {
  key: number | string;
  rowType: LteRowType[];
  message: string[];
  color: string[];
  timestamp: number;
}

export const emptyLteStatusData: LteTableData = {
  key: 0,
  rowType: ["unknown"],
  message: [""],
  color: ["black"],
  timestamp: 0
};

interface VirtualTableProps {
  dataSource?: LteTableData[];
  columns: ColumnsType<LteTableData>;
  height: number;
  width: number;
  rowHeights?: number[];
}

const VirtualTable = (props: VirtualTableProps) => {
  const { dataSource, columns, height, width } = props;
  const gridRef = useRef<any>();

  const resetVirtualGrid = () => {
    gridRef.current?.resetAfterIndices({
      columnIndex: 0,
      rowIndex: 0,
      shouldForceUpdate: true
    });
  };

  useEffect(() => resetVirtualGrid, [width, dataSource]);

  if (!dataSource || !columns) return null;

  const mergedColumns = columns.map((column) => {
    return {
      ...column,
      width: Math.floor((width - 20) / columns.length)
    };
  });

  const rowHeights = props.rowHeights ? [...props.rowHeights] : undefined;
  const getRowHeight = (index: number) => {
    return rowHeights ? rowHeights[index] : 32;
  };

  return (
    <div className="virtual-table" style={{ width: "100%" }}>
      <Grid
        ref={gridRef}
        className="virtual-grid"
        height={height}
        width={width}
        columnCount={mergedColumns.length}
        rowCount={dataSource.length}
        columnWidth={(index: number) => mergedColumns[index].width}
        rowHeight={getRowHeight}
        style={{ textAlign: "left", outline: "none" }}
      >
        {({
          columnIndex,
          rowIndex,
          style
        }: {
          columnIndex: number;
          rowIndex: number;
          style: React.CSSProperties;
        }) => {
          const renderer = mergedColumns[columnIndex].render!;
          const cellData = dataSource[rowIndex];
          return (
            <div
              className={classNames("virtual-table-cell", {
                "virtual-table-cell-last":
                  columnIndex === mergedColumns.length - 1
              })}
              style={{
                ...style,
                borderBottom: "1px solid #f0f0f0",
                padding: size.m2,
                paddingRight: "0px"
              }}
            >
              <>{renderer(cellData, cellData, rowIndex)}</>
            </div>
          );
        }}
      </Grid>
    </div>
  );
};

interface IProps {
  dataObject: LteTableData[];
  tableHeight: number;
  tableWidth: number;
  timezone: string;
}
const LteTable = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { dataObject, tableHeight, tableWidth } = props;

  const selectCursorPosition = (timestamp: number) => {
    dispatch(setNewCursorPosition(timestamp));
  };

  const [sortState, setSortState] = useState<"asc" | "desc" | "default">(
    "default"
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Set sort direction based on sort state
  useEffect(() => {
    if (sortState === "default") {
      setSortDirection("asc");
    } else if (sortState === "desc") {
      setSortDirection("desc");
    } else if (sortState === "asc") {
      setSortDirection("asc");
    }
  }, [sortState]);

  /** Switching between sorting states like it's done in AntD tables. */
  const onSort = () => {
    if (sortState === "default") {
      setSortState("desc");
    } else if (sortState === "desc") {
      setSortState("asc");
    } else if (sortState === "asc") {
      setSortState("default");
    }
  };

  const columns: ColumnsType<LteTableData> = [
    {
      title: t("Timestamp"),
      key: "timestamp",
      render: (data: LteTableData) => {
        return (
          <Link onClick={() => selectCursorPosition(data.timestamp)}>
            {createTzDate(data.timestamp, props.timezone).format(
              timestampFormat
            )}
          </Link>
        );
      }
    },
    {
      title: t("Event"),
      key: "event",
      render: (data: LteTableData) => {
        let elementArr: JSX.Element[] = [];

        for (let i = 0; i < data.rowType.length; i++) {
          switch (data.rowType[i]) {
            case "power":
              elementArr.push(
                <div key={i} style={{ color: data.color[i] }}>
                  {data.message[i]}
                </div>
              );
              break;
            case "error":
              elementArr.push(
                <div key={i} style={{ color: data.color[i] }}>
                  <WarningOutlined /> {data.message[i]}
                </div>
              );
              break;
            case "sensor":
              elementArr.push(
                <div key={i} style={{ color: data.color[i] }}>
                  <ExclamationCircleOutlined /> {data.message[i]}
                </div>
              );
              break;
            case "schedule":
              elementArr.push(
                <div key={i} style={{ color: data.color[i] }}>
                  <CarryOutOutlined /> {data.message[i]}
                </div>
              );
              break;
            default:
              elementArr.push(<div key={i}>{data.message[i]}</div>);
          }
        }
        return elementArr;
      }
    }
  ];

  /** Table content has a minHeight of 120px or else the same px-height as its row - 87 px*/
  const scrollHeight = () => {
    if (tableHeight < 200) {
      return 120;
    } else {
      return tableHeight - 55;
    }
  };

  // This data seems to be sorted by timestamp in ascending order. Reverse() should work best.
  const sortedTableData =
    sortDirection === "asc" ? dataObject : dataObject?.reverse();

  const rowHeights =
    sortedTableData?.map((row) => {
      let height = 32; // padding top and bottom are 16px
      const { rowType } = row;

      switch (rowType[0]) {
        case "power":
          height += 44;
          break;
        case "error":
          height += 44;
          break;
        case "sensor":
          height += 44;
          break;
        case "schedule":
          height += 44;
          break;
        case "sessionEnded":
          height += 44;
          break;
        case "blocked":
          height += 44;
          break;
        case "unknown":
          height += 44;
          break;
        default:
          break;
      }
      return height;
    }) || undefined;

  return (
    <>
      <Atable>
        <Athead>
          <tr>
            <Ath style={{ width: (tableWidth - 20) / 2, padding: 0 }}>
              <AtColumnSorted
                title={t("Timestamp")}
                sortState={sortState}
                onClick={onSort}
              />
            </Ath>
            <Ath>{t("Event")}</Ath>
          </tr>
        </Athead>
      </Atable>
      <VirtualTable
        dataSource={sortedTableData}
        columns={columns}
        height={scrollHeight()}
        width={tableWidth}
        rowHeights={rowHeights}
      />
    </>
  );
};

export default LteTable;
