import { Col, Space, Switch, Table, TableProps, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import IAlarmOutputBits, {
  OutputBit
} from "../../models/RecordingParameters/IAlarmOutputBits";
import { VMExternalOutputParams } from "../../models/ViewModelRecordingParameters/VMExternalOutputParams";
import { VMExternalInputParams } from "../../models/ViewModelRecordingParameters/VMExtternalInputParams";
import {
  selectActiveChannels,
  selectAlarmOutputBits,
  selectAlsoRecord,
  selectExtInputs,
  selectExtOutputs,
  selectExternalSensors,
  toggleAlsoRecord,
  toggleAlsoRecordOutputBit,
  toggleAlsoRecordOutputBitState,
  toggleExtInputAlarmOutputBit,
  toggleExtInputAlarmOutputBitState,
  toggleExtInputAlsoRecord,
  toggleExtSensorAlsoRecord
} from "../../state/openParxSlice";
import { CommonParamsMainContentRow } from "../Common/CommonParametersComponents";
import { insertIf } from "../../utils/generalUtils";
import IChannelBits from "../../models/RecordingParameters/IChannelBits";
import { RhTemp } from "../../models/FAT100DataTypes";
import { getSensorNrFromId } from "../../helpers/paramsHelper";
import { disabledAlarmOutputBits } from "../../constants/params-defaults";
import {
  getExternalSensor,
  getNumberOfIO
} from "../../helpers/parsers/parseSystemInfoHelper";

const { Text } = Typography;

interface Channel {
  key: string;
  name: string;
  extIn?: boolean; // External input requires different handling
  extSensor?: boolean; // External sensor requires different handling
  useChannel: boolean;
  trigger: number | string;
  channelBits: IChannelBits;
  alarmOutputBits: IAlarmOutputBits;
}

interface SwitchState {
  disabled: boolean;
  state: boolean;
  toggle: () => void;
}

const emptySwitchState: SwitchState = {
  state: false,
  disabled: true,
  toggle: () => {}
};

interface OutputSwitchState extends SwitchState {
  newState: boolean;
  toggleNewState: () => void;
}

interface TriggerChannel {
  key: string;
  name: string;
  disabled: boolean;
  temp?: SwitchState;
  pressure?: SwitchState;
  rh?: SwitchState;
  angle?: SwitchState;
  gps?: SwitchState;
  lte?: SwitchState;
  extOut1?: OutputSwitchState;
  extOut2?: OutputSwitchState;
  extOut3?: OutputSwitchState;
  extOut4?: OutputSwitchState;
  extOut5?: OutputSwitchState;
  extOut6?: OutputSwitchState;
  extOut7?: OutputSwitchState;
  extOut8?: OutputSwitchState;
}

interface AvailableFeatures {
  hasExtIO: boolean;
  extIOCfg: number;
  hasGps: boolean;
  hasLte: boolean;
  hasAcc: boolean;
  hasTemp: boolean;
  hasPressure: boolean;
  hasRh: boolean;
  hasAngle: boolean;
}

interface IProps {
  features: AvailableFeatures;
}

const getOutputFromNumber = (
  outputBits: IAlarmOutputBits,
  outputNumber: number
): OutputBit => {
  switch (outputNumber) {
    case 0:
      return outputBits.output0;
    case 1:
      return outputBits.output1;
    case 2:
      return outputBits.output2;
    case 3:
      return outputBits.output3;
    case 4:
      return outputBits.output4;
    case 5:
      return outputBits.output5;
    case 6:
      return outputBits.output6;
    case 7:
      return outputBits.output7;
    default:
      return {
        used: false,
        newStateOn: false
      };
  }
};

const ParamsBuilderTriggers: React.FC<IProps> = (props) => {
  const {
    hasGps,
    hasLte,
    hasExtIO,
    hasAcc,
    hasTemp,
    hasPressure,
    hasRh,
    hasAngle,
    extIOCfg
  } = props.features;
  const { t } = useTranslation();

  const numberOfIO = getNumberOfIO(extIOCfg);

  const inputUsed = (input: VMExternalInputParams) => {
    if (!input.used) {
      return false;
    }
    if (extIOCfg === 0) {
      return false;
    }
    if (numberOfIO > input.inputNumber) {
      return true;
    }
    return false;
  };

  const outputUsed = (output: VMExternalOutputParams) => {
    if (!output.used) {
      return false;
    }
    if (extIOCfg === 0) {
      return false;
    }
    if (numberOfIO > output.outputNumber) {
      return true;
    }
    return false;
  };

  // Normal channels
  const { useAcc, useTemp, usePressure, useRh, useAngle } =
    useSelector(selectActiveChannels);

  const channelBits = useSelector(selectAlsoRecord);
  const alarmOutputBits = useSelector(selectAlarmOutputBits);

  // Dynamic inputs and outputs
  const inputParams = useSelector(selectExtInputs);
  const outputParams = useSelector(selectExtOutputs);
  const externalSensorParams = useSelector(selectExternalSensors);

  const hasExtOut = [
    hasExtIO && outputUsed(outputParams[0]),
    hasExtIO && outputUsed(outputParams[1]),
    hasExtIO && outputUsed(outputParams[2]),
    hasExtIO && outputUsed(outputParams[3]),
    hasExtIO && outputUsed(outputParams[4]),
    hasExtIO && outputUsed(outputParams[5]),
    hasExtIO && outputUsed(outputParams[6]),
    hasExtIO && outputUsed(outputParams[7])
  ];

  const dispatch = useDispatch();

  // Main rendering function for the table
  const columns: TableProps<TriggerChannel>["columns"] = [
    {
      title: t("TriggerChannel"),
      dataIndex: "name",
      rowScope: "row",
      render: (name, data) => {
        return (
          <Text strong disabled={data.disabled}>
            {name}
          </Text>
        );
      }
    },
    ...insertIf(hasTemp, {
      title: t("Temperature"),
      dataIndex: "temp",
      render: (temp: SwitchState) => {
        return (
          <Switch
            disabled={temp.disabled}
            checked={temp.state}
            onChange={() => temp.toggle()}
          />
        );
      }
    }),
    ...insertIf(hasPressure, {
      title: t("Pressure"),
      dataIndex: "pressure",
      render: (pressure: SwitchState) => {
        return (
          <Switch
            disabled={pressure.disabled}
            checked={pressure.state}
            onChange={() => pressure.toggle()}
          />
        );
      }
    }),
    ...insertIf(hasRh, {
      title: t("Humidity"),
      dataIndex: "rh",
      render: (rh: SwitchState) => {
        return (
          <Switch
            disabled={rh.disabled}
            checked={rh.state}
            onChange={() => rh.toggle()}
          />
        );
      }
    }),
    ...insertIf(hasAngle, {
      title: t("Angle"),
      dataIndex: "angle",
      render: (angle: SwitchState) => {
        return (
          <Switch
            disabled={angle.disabled}
            checked={angle.state}
            onChange={() => angle.toggle()}
          />
        );
      }
    }),
    ...insertIf(hasGps, {
      title: "GPS",
      dataIndex: "gps",
      render: (gps: SwitchState) => {
        return (
          <Switch
            disabled={gps.disabled}
            checked={gps.state}
            onChange={() => gps.toggle()}
          />
        );
      }
    }),
    ...insertIf(hasLte, {
      title: "LTE",
      dataIndex: "lte",
      render: (lte: SwitchState) => {
        return (
          <Switch
            disabled={lte.disabled}
            checked={lte.state}
            onChange={() => lte.toggle()}
          />
        );
      }
    }),
    ...insertIf(hasExtOut[0], {
      title: outputParams[0].description,
      dataIndex: "extOut1",
      render: (extOut1: OutputSwitchState) => {
        return (
          <Space>
            <Switch
              disabled={extOut1.disabled}
              checked={extOut1.state}
              onChange={() => extOut1.toggle()}
            />
            <Switch
              disabled={extOut1.disabled || !extOut1.state}
              checked={extOut1.newState}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={() => extOut1.toggleNewState()}
              style={{
                backgroundColor: extOut1.newState ? "#1890ff" : "#f5222d"
              }}
            />
          </Space>
        );
      }
    }),
    ...insertIf(hasExtOut[1], {
      title: outputParams[1].description,
      dataIndex: "extOut2",
      render: (extOut2: OutputSwitchState) => {
        return (
          <Space>
            <Switch
              disabled={extOut2.disabled}
              checked={extOut2.state}
              onChange={() => extOut2.toggle()}
            />
            <Switch
              disabled={extOut2.disabled || !extOut2.state}
              checked={extOut2.newState}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={() => extOut2.toggleNewState()}
              style={{
                backgroundColor: extOut2.newState ? "#1890ff" : "#f5222d"
              }}
            />
          </Space>
        );
      }
    }),
    ...insertIf(hasExtOut[2], {
      title: outputParams[2].description,
      dataIndex: "extOut3",
      render: (extOut3: OutputSwitchState) => {
        return (
          <Space>
            <Switch
              disabled={extOut3.disabled}
              checked={extOut3.state}
              onChange={() => extOut3.toggle()}
            />
            <Switch
              disabled={extOut3.disabled || !extOut3.state}
              checked={extOut3.newState}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={() => extOut3.toggleNewState()}
              style={{
                backgroundColor: extOut3.newState ? "#1890ff" : "#f5222d"
              }}
            />
          </Space>
        );
      }
    }),
    ...insertIf(hasExtOut[3], {
      title: outputParams[3].description,
      dataIndex: "extOut4",
      render: (extOut4: OutputSwitchState) => {
        return (
          <Space>
            <Switch
              disabled={extOut4.disabled}
              checked={extOut4.state}
              onChange={() => extOut4.toggle()}
            />
            <Switch
              disabled={extOut4.disabled || !extOut4.state}
              checked={extOut4.newState}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={() => extOut4.toggleNewState()}
              style={{
                backgroundColor: extOut4.newState ? "#1890ff" : "#f5222d"
              }}
            />
          </Space>
        );
      }
    }),
    ...insertIf(hasExtOut[4], {
      title: outputParams[4].description,
      dataIndex: "extOut5",
      render: (extOut5: OutputSwitchState) => {
        return (
          <Space>
            <Switch
              disabled={extOut5.disabled}
              checked={extOut5.state}
              onChange={() => extOut5.toggle()}
            />
            <Switch
              disabled={extOut5.disabled || !extOut5.state}
              checked={extOut5.newState}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={() => extOut5.toggleNewState()}
              style={{
                backgroundColor: extOut5.newState ? "#1890ff" : "#f5222d"
              }}
            />
          </Space>
        );
      }
    }),
    ...insertIf(hasExtOut[5], {
      title: outputParams[5].description,
      dataIndex: "extOut6",
      render: (extOut6: OutputSwitchState) => {
        return (
          <Space>
            <Switch
              disabled={extOut6.disabled}
              checked={extOut6.state}
              onChange={() => extOut6.toggle()}
            />
            <Switch
              disabled={extOut6.disabled || !extOut6.state}
              checked={extOut6.newState}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={() => extOut6.toggleNewState()}
              style={{
                backgroundColor: extOut6.newState ? "#1890ff" : "#f5222d"
              }}
            />
          </Space>
        );
      }
    }),
    ...insertIf(hasExtOut[6], {
      title: outputParams[6].description,
      dataIndex: "extOut7",
      render: (extOut7: OutputSwitchState) => {
        return (
          <Space>
            <Switch
              disabled={extOut7.disabled}
              checked={extOut7.state}
              onChange={() => extOut7.toggle()}
            />
            <Switch
              disabled={extOut7.disabled || !extOut7.state}
              checked={extOut7.newState}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={() => extOut7.toggleNewState()}
              style={{
                backgroundColor: extOut7.newState ? "#1890ff" : "#f5222d"
              }}
            />
          </Space>
        );
      }
    }),
    ...insertIf(hasExtOut[7], {
      title: outputParams[7].description,
      dataIndex: "extOut8",
      render: (extOut8: OutputSwitchState) => {
        return (
          <Space>
            <Switch
              disabled={extOut8.disabled}
              checked={extOut8.state}
              onChange={() => extOut8.toggle()}
            />
            <Switch
              disabled={extOut8.disabled || !extOut8.state}
              checked={extOut8.newState}
              checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={() => extOut8.toggleNewState()}
              style={{
                backgroundColor: extOut8.newState ? "#1890ff" : "#f5222d"
              }}
            />
          </Space>
        );
      }
    })
  ];

  const extSensorTech = getExternalSensor(extIOCfg);
  const extSensors = extSensorTech === "I2C" ? externalSensorParams : [];
  const inputs =
    extSensorTech === "IO"
      ? inputParams.filter((input) => inputUsed(input))
      : [];

  // Configuration object for the data-maker loop
  const channels: Channel[] = [
    ...insertIf(hasAcc, {
      key: "Acc",
      name: t("Acceleration"),
      useChannel: useAcc,
      trigger: "Acc",
      channelBits: channelBits.acc,
      alarmOutputBits: alarmOutputBits.acc
    }),
    ...insertIf(hasTemp, {
      key: "Temp",
      name: t("Temperature"),
      useChannel: useTemp,
      trigger: "Temp",
      channelBits: channelBits.temp,
      alarmOutputBits: alarmOutputBits.temp
    }),
    ...insertIf(hasPressure, {
      key: "Pressure",
      name: t("Pressure"),
      useChannel: usePressure,
      trigger: "Pressure",
      channelBits: channelBits.pressure,
      alarmOutputBits: alarmOutputBits.pressure
    }),
    ...insertIf(hasRh, {
      key: "Rh",
      name: t("Humidity"),
      useChannel: useRh,
      trigger: "Rh",
      channelBits: channelBits.rh,
      alarmOutputBits: alarmOutputBits.rh
    }),
    ...insertIf(hasAngle, {
      key: "Angle",
      name: t("Angle"),
      useChannel: useAngle,
      trigger: "Angle",
      channelBits: channelBits.angle,
      alarmOutputBits: alarmOutputBits.angle
    }),
    ...inputs.map((input) => {
      return {
        key: "extIn" + input.inputNumber,
        name: input.description,
        extIn: true,
        useChannel: true,
        trigger: input.inputNumber,
        channelBits: input.channelBits,
        alarmOutputBits: input.alarmOutputBits
      };
    }),
    ...extSensors.map((sensor) => {
      const ESTI = sensor.params.sensorTypeId;
      let sensorName = "";
      if (RhTemp.includes(ESTI)) {
        sensorName = t("RhTempSensor") + " nr " + getSensorNrFromId(ESTI);
      } else {
        sensorName = "Unknown sensor nr " + getSensorNrFromId(ESTI);
      }
      if (sensor.params.sensorName.length > 0) {
        sensorName += " (" + sensor.params.sensorName + ")";
      }
      return {
        key: "extSensor" + ESTI,
        name: sensorName,
        extSensor: true,
        useChannel: true,
        trigger: ESTI,
        channelBits: sensor.channelBits,
        alarmOutputBits: disabledAlarmOutputBits
      };
    })
  ];

  // The data-maker loop where the data is prepared for the table
  const data: TriggerChannel[] = channels.map((channel) => {
    return {
      key: channel.key,
      name: channel.name,
      disabled: !channel.useChannel,
      temp:
        channel.trigger === "Temp"
          ? emptySwitchState
          : {
              state: channel.channelBits.Temp,
              disabled: !channel.useChannel,
              toggle: () => {
                const target = {
                  trigger: channel.trigger,
                  channel: "Temp"
                };
                if (channel.extIn) {
                  dispatch(toggleExtInputAlsoRecord(target));
                } else if (channel.extSensor) {
                  dispatch(toggleExtSensorAlsoRecord(target));
                } else {
                  dispatch(toggleAlsoRecord(target));
                }
              }
            },
      pressure:
        channel.trigger === "Pressure"
          ? emptySwitchState
          : {
              state: channel.channelBits.Pressure,
              disabled: !channel.useChannel,
              toggle: () => {
                const target = {
                  trigger: channel.trigger,
                  channel: "Pressure"
                };
                if (channel.extIn) {
                  dispatch(toggleExtInputAlsoRecord(target));
                } else if (channel.extSensor) {
                  dispatch(toggleExtSensorAlsoRecord(target));
                } else {
                  dispatch(toggleAlsoRecord(target));
                }
              }
            },
      rh:
        channel.trigger === "Rh"
          ? emptySwitchState
          : {
              state: channel.channelBits.Rh,
              disabled: !channel.useChannel,
              toggle: () => {
                const target = {
                  trigger: channel.trigger,
                  channel: "Rh"
                };
                if (channel.extIn) {
                  dispatch(toggleExtInputAlsoRecord(target));
                } else if (channel.extSensor) {
                  dispatch(toggleExtSensorAlsoRecord(target));
                } else {
                  dispatch(toggleAlsoRecord(target));
                }
              }
            },
      angle:
        channel.trigger === "Angle"
          ? emptySwitchState
          : {
              state: channel.channelBits.Angle,
              disabled: !channel.useChannel,
              toggle: () => {
                const target = {
                  trigger: channel.trigger,
                  channel: "Angle"
                };
                if (channel.extIn) {
                  dispatch(toggleExtInputAlsoRecord(target));
                } else if (channel.extSensor) {
                  dispatch(toggleExtSensorAlsoRecord(target));
                } else {
                  dispatch(toggleAlsoRecord(target));
                }
              }
            },
      gps:
        channel.trigger === "GPS"
          ? emptySwitchState
          : {
              state: channel.channelBits.GPS,
              disabled: !channel.useChannel,
              toggle: () => {
                const target = {
                  trigger: channel.trigger,
                  channel: "GPS"
                };
                if (channel.extIn) {
                  dispatch(toggleExtInputAlsoRecord(target));
                } else if (channel.extSensor) {
                  dispatch(toggleExtSensorAlsoRecord(target));
                } else {
                  dispatch(toggleAlsoRecord(target));
                }
              }
            },
      lte:
        channel.trigger === "LTE"
          ? emptySwitchState
          : {
              state: channel.channelBits.LTE,
              disabled: !channel.useChannel,
              toggle: () => {
                const target = {
                  trigger: channel.trigger,
                  channel: "LTE"
                };
                if (channel.extIn) {
                  dispatch(toggleExtInputAlsoRecord(target));
                } else if (channel.extSensor) {
                  dispatch(toggleExtSensorAlsoRecord(target));
                } else {
                  dispatch(toggleAlsoRecord(target));
                }
              }
            },
      extOut1: {
        state: getOutputFromNumber(channel.alarmOutputBits, 0).used,
        disabled: !channel.useChannel || !hasExtOut[0],
        toggle: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 0
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBit(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBit(target));
          }
        },
        newState: getOutputFromNumber(channel.alarmOutputBits, 0).newStateOn,
        toggleNewState: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 0
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBitState(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBitState(target));
          }
        }
      },
      extOut2: {
        state: getOutputFromNumber(channel.alarmOutputBits, 1).used,
        disabled: !channel.useChannel || !hasExtOut[1],
        toggle: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 1
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBit(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBit(target));
          }
        },
        newState: getOutputFromNumber(channel.alarmOutputBits, 1).newStateOn,
        toggleNewState: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 1
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBitState(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBitState(target));
          }
        }
      },
      extOut3: {
        state: getOutputFromNumber(channel.alarmOutputBits, 2).used,
        disabled: !channel.useChannel || !hasExtOut[2],
        toggle: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 2
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBit(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBit(target));
          }
        },
        newState: getOutputFromNumber(channel.alarmOutputBits, 2).newStateOn,
        toggleNewState: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 2
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBitState(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBitState(target));
          }
        }
      },
      extOut4: {
        state: getOutputFromNumber(channel.alarmOutputBits, 3).used,
        disabled: !channel.useChannel || !hasExtOut[3],
        toggle: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 3
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBit(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBit(target));
          }
        },
        newState: getOutputFromNumber(channel.alarmOutputBits, 3).newStateOn,
        toggleNewState: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 3
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBitState(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBitState(target));
          }
        }
      },
      extOut5: {
        state: getOutputFromNumber(channel.alarmOutputBits, 4).used,
        disabled: !channel.useChannel || !hasExtOut[4],
        toggle: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 4
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBit(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBit(target));
          }
        },
        newState: getOutputFromNumber(channel.alarmOutputBits, 4).newStateOn,
        toggleNewState: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 4
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBitState(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBitState(target));
          }
        }
      },
      extOut6: {
        state: getOutputFromNumber(channel.alarmOutputBits, 5).used,
        disabled: !channel.useChannel || !hasExtOut[5],
        toggle: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 5
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBit(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBit(target));
          }
        },
        newState: getOutputFromNumber(channel.alarmOutputBits, 5).newStateOn,
        toggleNewState: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 5
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBitState(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBitState(target));
          }
        }
      },
      extOut7: {
        state: getOutputFromNumber(channel.alarmOutputBits, 6).used,
        disabled: !channel.useChannel || !hasExtOut[6],
        toggle: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 6
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBit(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBit(target));
          }
        },
        newState: getOutputFromNumber(channel.alarmOutputBits, 6).newStateOn,
        toggleNewState: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 6
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBitState(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBitState(target));
          }
        }
      },
      extOut8: {
        state: getOutputFromNumber(channel.alarmOutputBits, 7).used,
        disabled: !channel.useChannel || !hasExtOut[7],
        toggle: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 7
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBit(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBit(target));
          }
        },
        newState: getOutputFromNumber(channel.alarmOutputBits, 7).newStateOn,
        toggleNewState: () => {
          const target = {
            trigger: channel.trigger,
            outputNumber: 7
          };
          if (channel.extIn) {
            dispatch(toggleExtInputAlarmOutputBitState(target));
          } else if (channel.extSensor) {
            // external output and external sensors are mutually exclusive
          } else {
            dispatch(toggleAlsoRecordOutputBitState(target));
          }
        }
      }
    };
  });

  const renderTriggers = () => {
    return (
      <Table columns={columns} dataSource={data} pagination={false} bordered />
    );
  };

  return (
    <CommonParamsMainContentRow>
      <Col order={1}>{renderTriggers()}</Col>
    </CommonParamsMainContentRow>
  );
};

export default ParamsBuilderTriggers;
