import { InputNumber, Row, Space, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isAccFilterActive } from "../../helpers/graphHelper";
import { AccGeneral, PressureIcon, RhIcon, TempIcon } from "../../icons";
import {
  selectActiveYAxisDomain,
  selectActiveYAxisTickCount,
  selectDatxOptionalFiltersById,
  setGraphAxisScaleAccMax,
  setGraphAxisScaleAccMin,
  setGraphAxisScalePressureMax,
  setGraphAxisScalePressureMin,
  setGraphAxisScaleRhMax,
  setGraphAxisScaleRhMin,
  setGraphAxisScaleTempMax,
  setGraphAxisScaleTempMin,
  setGraphAxisTickCountAcc,
  setGraphAxisTickCountPressure,
  setGraphAxisTickCountRh,
  setGraphAxisTickCountTemp
} from "../../state/openDatxSlice";
import { selectGlobalGraphScale } from "../../state/persistantStateSlice";
import { selectTemperatureScale } from "../../state/sessionSlice";
import {
  ParamsCardSubHeader,
  SmallText,
  SmallTitle
} from "../Common/CommonFonts";
import {
  CelsiusToFahrenheit,
  FahrenheitToCelsius
} from "../MicroComponents/TemperatureConverter";
import { size } from "../../helpers/pageHelper";

interface StandardRowObj {
  title: string;
  icon: JSX.Element;
  unit: string;
  tickCount: number;
  values: RowValues;
  actions: RowActions;
  globalRowToggle: boolean;
}

interface RowValues {
  min: number;
  max: number;
}

interface RowActions {
  setMin: (value: number) => void;
  setMax: (value: number) => void;
  setCount: (value: number) => void;
}

interface IProps {
  fileId: string;
}
const GraphAxisScaleOptions = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const yAxisDomain = useSelector(selectActiveYAxisDomain)!;
  const yAxisTickCount = useSelector(selectActiveYAxisTickCount)!;
  const dataFilter = useSelector(selectDatxOptionalFiltersById(props.fileId));

  // Global states from Graph Settings
  const globalScale = useSelector(selectGlobalGraphScale);

  const shouldRenderAcc = isAccFilterActive(dataFilter);
  const shouldRenderTemp = dataFilter.temp.dataToggle.isActive;
  const shouldRenderRh = dataFilter.rh.dataToggle.isActive;
  const shouldRenderPressure =
    dataFilter.pressureRaw.dataToggle.isActive ||
    dataFilter.pressureComp.dataToggle.isActive;

  const tempScale = useSelector(selectTemperatureScale);

  const disableToggler = (globalRowToggle: boolean) => {
    if (globalScale.toggle && globalRowToggle) {
      return true;
    } else {
      return false;
    }
  };

  const popoverContent = (globalRowToggle: boolean, title: string) => {
    if (globalScale.toggle && globalRowToggle) {
      return (
        <SmallText style={{ color: "white" }}>
          {t("DeactivateTheGlobalScaleSettingIn")} <br /> {t("Settings")} {">"}{" "}
          {t("GraphSettings")} {">"} {t("GlobalGraphScaleToEdit")} {title}{" "}
          {t("scaleSettings")}.
        </SmallText>
      );
    }
  };

  const renderStandardRow = (obj: StandardRowObj) => {
    const globalRowToggle = obj.globalRowToggle;

    return (
      <Tooltip
        title={popoverContent(globalRowToggle, obj.title)}
        placement="left"
        overlayStyle={{
          visibility:
            globalScale.toggle && globalRowToggle ? "visible" : "hidden",
          maxWidth: 330
        }}
      >
        <Space direction="vertical">
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingRight: size.s1 }}
          >
            <Space align="center">
              {obj.icon}
              <SmallTitle disabled={disableToggler(globalRowToggle)}>
                {obj.title}
              </SmallTitle>
            </Space>
          </Row>
          <Space size="large">
            {/* Min value */}
            <Space direction="vertical">
              <ParamsCardSubHeader disabled={disableToggler(globalRowToggle)}>
                {`${t("generalMinTitle")} (${obj.unit})`}
              </ParamsCardSubHeader>
              <InputNumber
                disabled={disableToggler(globalRowToggle)}
                value={obj.values.min}
                onChange={(e) => obj.actions.setMin(Number(e))}
              />
            </Space>

            {/* Max value */}
            <Space direction="vertical">
              <ParamsCardSubHeader disabled={disableToggler(globalRowToggle)}>
                {`${t("generalMaxTitle")} (${obj.unit})`}
              </ParamsCardSubHeader>
              <InputNumber
                disabled={disableToggler(globalRowToggle)}
                value={obj.values.max}
                onChange={(e) => obj.actions.setMax(Number(e))}
              />
            </Space>

            {/* Number of steps */}
            <Space direction="vertical">
              <ParamsCardSubHeader disabled={disableToggler(globalRowToggle)}>
                {`${t("ScaleSteps")}`}
              </ParamsCardSubHeader>
              <InputNumber
                disabled={disableToggler(globalRowToggle)}
                value={obj.tickCount}
                onChange={(e) => obj.actions.setCount(Number(e))}
                min={1}
              />
            </Space>
          </Space>
        </Space>
      </Tooltip>
    );
  };

  const iconStyle: React.CSSProperties = { fontSize: "2em" };

  const accRowObj: StandardRowObj = {
    title: t("generalAccTitle"),
    unit: "g",
    icon: disableToggler(globalScale.acc.toggle) ? (
      <AccGeneral fill="gray" style={iconStyle} />
    ) : (
      <AccGeneral style={iconStyle} />
    ),
    tickCount: yAxisTickCount.accTick!,
    values: {
      min: yAxisDomain?.acc?.[0]!,
      max: yAxisDomain?.acc?.[1]!
    },
    globalRowToggle: globalScale.acc.toggle,
    actions: {
      setMin: (v) => dispatch(setGraphAxisScaleAccMin(v)),
      setMax: (v) => dispatch(setGraphAxisScaleAccMax(v)),
      setCount: (v) => dispatch(setGraphAxisTickCountAcc(v))
    }
  };
  /** Round input number to 2 decimals */
  const round2Decimals = (v: number) => Math.round(v * 100) / 100;

  const celciusTempRowObj: StandardRowObj = {
    title: t("generalTempTitle"),
    unit: "°C",
    icon: disableToggler(globalScale.temp.toggle) ? (
      <TempIcon fill="gray" style={iconStyle} />
    ) : (
      <TempIcon style={iconStyle} />
    ),
    tickCount: yAxisTickCount.tempTick!,
    globalRowToggle: globalScale.temp.toggle,
    values: {
      min: round2Decimals(yAxisDomain?.temp?.[0]!),
      max: round2Decimals(yAxisDomain?.temp?.[1]!)
    },
    actions: {
      setMin: (v) => dispatch(setGraphAxisScaleTempMin(v)),
      setMax: (v) => dispatch(setGraphAxisScaleTempMax(v)),
      setCount: (v) => dispatch(setGraphAxisTickCountTemp(v))
    }
  };

  const fahrenheitTempRowObj: StandardRowObj = {
    title: t("generalTempTitle"),
    unit: "°F",
    icon: disableToggler(globalScale.temp.toggle) ? (
      <TempIcon fill="gray" style={iconStyle} />
    ) : (
      <TempIcon style={iconStyle} />
    ),
    tickCount: yAxisTickCount.tempTick!,
    globalRowToggle: globalScale.temp.toggle,
    values: {
      min: round2Decimals(CelsiusToFahrenheit(yAxisDomain?.temp?.[0]!)),
      max: round2Decimals(CelsiusToFahrenheit(yAxisDomain?.temp?.[1]!))
    },
    actions: {
      setMin: (v) => dispatch(setGraphAxisScaleTempMin(FahrenheitToCelsius(v))),
      setMax: (v) => dispatch(setGraphAxisScaleTempMax(FahrenheitToCelsius(v))),
      setCount: (v) => dispatch(setGraphAxisTickCountTemp(v))
    }
  };

  const rhRowObj: StandardRowObj = {
    title: t("generalRhTitle"),
    unit: "%Rh",
    icon: disableToggler(globalScale.rh.toggle) ? (
      <RhIcon fill="gray" style={iconStyle} />
    ) : (
      <RhIcon style={iconStyle} />
    ),
    tickCount: yAxisTickCount.rhTick!,
    values: {
      min: yAxisDomain?.rh?.[0]!,
      max: yAxisDomain?.rh?.[1]!
    },
    globalRowToggle: globalScale.rh.toggle,
    actions: {
      setMin: (v) => dispatch(setGraphAxisScaleRhMin(v)),
      setMax: (v) => dispatch(setGraphAxisScaleRhMax(v)),
      setCount: (v) => dispatch(setGraphAxisTickCountRh(v))
    }
  };

  const pressureRowObj: StandardRowObj = {
    title: t("Pressure"),
    unit: "mbar",
    icon: disableToggler(globalScale.pressure.toggle) ? (
      <PressureIcon fill="gray" style={iconStyle} />
    ) : (
      <PressureIcon style={iconStyle} />
    ),
    tickCount: yAxisTickCount.pressureTick!,
    values: {
      min: yAxisDomain?.pressure?.[0]!,
      max: yAxisDomain?.pressure?.[1]!
    },
    globalRowToggle: globalScale.pressure.toggle,
    actions: {
      setMin: (v) => dispatch(setGraphAxisScalePressureMin(v)),
      setMax: (v) => dispatch(setGraphAxisScalePressureMax(v)),
      setCount: (v) => dispatch(setGraphAxisTickCountPressure(v))
    }
  };

  return (
    <Row justify="start" style={{ width: "100%" }}>
      <Space
        direction="vertical"
        size="large"
        align="start"
        style={{ marginBottom: size.s1 }}
      >
        {shouldRenderAcc && renderStandardRow(accRowObj)}
        {shouldRenderTemp &&
          tempScale === "F" &&
          renderStandardRow(fahrenheitTempRowObj)}
        {shouldRenderTemp &&
          tempScale === "C" &&
          renderStandardRow(celciusTempRowObj)}
        {shouldRenderRh && renderStandardRow(rhRowObj)}
        {shouldRenderPressure && renderStandardRow(pressureRowObj)}
      </Space>
    </Row>
  );
};

export default GraphAxisScaleOptions;
