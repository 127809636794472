import React from "react";
import { Layout, message, Row } from "antd";
import { useTranslation } from "react-i18next";
import { NormalCard } from "../components/Common/CommonCards";
import Online from "../components/MicroComponents/Online";
import { size, contentFullHeightStyle } from "../helpers/pageHelper";
import CreateAccountProgress from "../components/CreateAccount/CreateAccountProgress";
import CreateAccountContent from "../components/CreateAccount/CreateAccountContent";
import { LargeTitle } from "../components/Common/CommonFonts";

message.config({
  top: 48,
  duration: 2,
  maxCount: 1
});

const CreateAccountPage = () => {
  const { t } = useTranslation();

  /** Primary styling used for header and footer in this component */
  const stickyElementStyle: React.CSSProperties = {
    position: "sticky",
    zIndex: 1,
    width: "100%",
    backgroundColor: "white",
    margin: 0,
    padding: 0
  };

  return (
    <>
      <Layout
        style={{
          height: "100%",
          minHeight: contentFullHeightStyle,
          width: "100%",
          maxWidth: "100%"
        }}
        className="move-window"
      >
        <Row
          align="middle"
          justify="center"
          style={{ height: "100%", width: "100%" }}
        >
          <NormalCard
            style={{
              width: "450px",
              height: "auto",
              border: 0,
              borderRadius: size.m1,
              overflow: "hidden"
            }}
            className="no-move-window"
          >
            <Layout.Header
              style={{
                ...stickyElementStyle,
                top: 0,
                height: "auto"
              }}
            >
              <LargeTitle
                type="secondary"
                style={{ padding: size.l2, marginBottom: size.s1 }}
              >
                {t("CreateAccount")}
              </LargeTitle>
              <CreateAccountProgress contentWidth={365} />
            </Layout.Header>
            <Online>
              <Layout.Content style={{ padding: 0 }}>
                <Row justify="center">
                  <Row
                    style={{
                      width: "100%",
                      height: "100%",
                      padding: size.l2
                    }}
                  >
                    <CreateAccountContent contentWidth={450} />
                  </Row>
                </Row>
              </Layout.Content>
            </Online>
          </NormalCard>
        </Row>
      </Layout>
    </>
  );
};

export default CreateAccountPage;
