import React, { useEffect, useState } from "react";
import { Collapse, List, Typography, Row, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { ellipsisIfLongTextStyle } from "../Common/CommonFonts";
import {
  closeFile,
  selectAllOpenCompareGraphFiles
} from "../../state/compareGraphsSlice";
import { NormalButtonSmall } from "../Common/CommonButtons";

interface IProps {}
const OpenCompareFiles: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const openCompareFiles = useSelector(selectAllOpenCompareGraphFiles);
  const dispatch = useDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    //automaticly opens the menu when the first file is opened
    if (openCompareFiles?.length === 1) {
      setIsMenuOpen(true);
    }
  }, [openCompareFiles]);

  return (
    <Collapse
      activeKey={isMenuOpen ? ["openFiles"] : undefined}
      expandIconPosition="end"
      ghost
      onChange={(_) => {
        setIsMenuOpen((z) => !z);
      }}
    >
      <Collapse.Panel
        header={t("openFilesGraph")}
        key="openFiles"
        className="custom"
      >
        {openCompareFiles?.length > 0 && (
          <List
            size="small"
            split={false}
            dataSource={openCompareFiles}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                style={{ paddingTop: 0, paddingRight: 0, paddingLeft: 0 }}
              >
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ width: "100%" }}
                  wrap={false}
                >
                  <Tooltip placement="right" title={item.filePath}>
                    <Typography.Text
                      style={{ ...ellipsisIfLongTextStyle, maxWidth: "85%" }}
                    >
                      {item.filePath}
                    </Typography.Text>
                  </Tooltip>
                  <NormalButtonSmall
                    icon={<CloseOutlined title={t("closeFileTip")} />}
                    onClick={() => dispatch(closeFile(item.id))}
                  />
                </Row>
              </List.Item>
            )}
          />
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default OpenCompareFiles;
