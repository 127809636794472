import React from "react";
import { Collapse, List, Typography, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { ellipsisIfLongTextStyle } from "../Common/CommonFonts";
import { RecentFile } from "../../state/persistantStateSlice";

interface IProps {
  data: RecentFile[];
  openFile: (recentFile: RecentFile) => Promise<void>;
}
const RecentFiles: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Collapse expandIconPosition="end" ghost defaultActiveKey="recentFiles">
      <Collapse.Panel
        header={t("RecentFiles")}
        key="recentFiles"
        className="custom"
      >
        <List
          size="small"
          split={false}
          locale={{ emptyText: t("NoData") }}
          dataSource={props.data}
          renderItem={(item) => (
            <List.Item
              key={item.fileName}
              style={{ paddingTop: 0, paddingRight: 0, paddingLeft: 0 }}
            >
              <Tooltip
                placement="right"
                title={() => (
                  <p style={{ marginBlock: 0 }}>
                    {item.fileName}
                    <br />
                    {t("LastInteraction")}:
                    {dayjs.unix(item.lastInteraction).format(" YYYY-MM-DD")}
                  </p>
                )}
              >
                <Typography.Link
                  onClick={() => props.openFile(item)}
                  className="selectableText"
                  style={{
                    ...ellipsisIfLongTextStyle,
                    color: "black"
                  }}
                >
                  {item.fileName}
                </Typography.Link>
              </Tooltip>
            </List.Item>
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default RecentFiles;
