import { Col, Collapse, Row, Space, Switch } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import {
  setLteEnable2G,
  setLteEnable3G,
  setLteEnable4G,
  setLteEnable5G,
  setLteEnable6G,
  setGpsStoreSpeed,
  selectRecordingParameters
} from "../../state/openParxSlice";
import {
  ParamsCardParagraph,
  ParamsCardSubHeader
} from "../Common/CommonFonts";
import { ParamsInputCard } from "../Common/CommonParametersComponents";
import ResetPanelBodyPadding from "../MicroComponents/ResetPanelBodyPadding";
const { Panel } = Collapse;

interface AvailableFeatures {
  hasGps: boolean;
  hasLte: boolean;
}

interface IProps {
  features: AvailableFeatures;
}

const ParamsBuilderAdvanced: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { LteParams, GpsParams } = useSelector(selectRecordingParameters);
  const { Enable2G, Enable3G, Enable4G, Enable5G, Enable6G } =
    LteParams.LteBands;
  const { hasLte, hasGps } = props.features;

  const renderStoreSpeed = () => (
    <ParamsInputCard
      title={t("cpGpsStoreSpeedTitle")}
      disabled={!hasGps}
      useToggle
      toggleChecked={GpsParams.storeSpeed && hasGps}
      toggleDisabled={!hasGps}
      toggleChangeAction={(value: boolean) => dispatch(setGpsStoreSpeed(value))}
    >
      <ParamsCardParagraph>{t("cpGpsStoreSpeedContent")}</ParamsCardParagraph>
    </ParamsInputCard>
  );

  // Render functions
  const renderLteBandSelector = () => (
    <ParamsInputCard title={t("cpLteBandSelectorTitle")} disabled={!hasLte}>
      <div>
        <ParamsCardParagraph>
          {t("cpLteBandSelectorContent")}
        </ParamsCardParagraph>
      </div>
      <div>
        {/* titles */}
        <Row gutter={size.m1}>
          <Col>
            <Space size={4}>
              <ParamsCardSubHeader>2G</ParamsCardSubHeader>
              <Row align={"middle"}>
                <Switch
                  disabled={!hasLte}
                  checked={Enable2G && hasLte}
                  onChange={(value) => dispatch(setLteEnable2G(value))}
                  size="small"
                />
              </Row>
            </Space>
          </Col>
          <Col>
            <Space size={4}>
              <ParamsCardSubHeader>3G</ParamsCardSubHeader>
              <Row align={"middle"}>
                <Switch
                  disabled={!hasLte}
                  checked={Enable3G && hasLte}
                  onChange={(value) => dispatch(setLteEnable3G(value))}
                  size="small"
                />
              </Row>
            </Space>
          </Col>
          <Col>
            <Space size={4}>
              <ParamsCardSubHeader>4G</ParamsCardSubHeader>
              <Row align={"middle"}>
                <Switch
                  disabled={!hasLte}
                  checked={Enable4G && hasLte}
                  onChange={(value) => dispatch(setLteEnable4G(value))}
                  size="small"
                />
              </Row>
            </Space>
          </Col>
          <Col>
            <Space size={4}>
              <ParamsCardSubHeader>5G</ParamsCardSubHeader>
              <Row align={"middle"}>
                <Switch
                  disabled={!hasLte}
                  checked={Enable5G && hasLte}
                  onChange={(value) => dispatch(setLteEnable5G(value))}
                  size="small"
                />
              </Row>
            </Space>
          </Col>
          <Col>
            <Space size={4}>
              <ParamsCardSubHeader>6G</ParamsCardSubHeader>
              <Row align={"middle"}>
                <Switch
                  disabled={!hasLte}
                  checked={Enable6G && hasLte}
                  onChange={(value) => dispatch(setLteEnable6G(value))}
                  size="small"
                />
              </Row>
            </Space>
          </Col>
        </Row>
      </div>
    </ParamsInputCard>
  );

  return (
    <div style={{ width: "100%", textAlign: "start" }}>
      <Collapse collapsible="header" ghost>
        <Panel header={t("advanced")} key="advanced">
          <ResetPanelBodyPadding>
            <Row gutter={size.m1}>
              <Col span={12}>
                {/** LTE band selector under an "Advanced" fold-down */}
                {renderLteBandSelector()}
              </Col>
              <Col span={12}>{renderStoreSpeed()}</Col>
            </Row>
          </ResetPanelBodyPadding>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ParamsBuilderAdvanced;
