import { Alert, AlertProps } from "antd";
import React from "react";
import { size } from "../../helpers/pageHelper";

const baseAlertStyle: React.CSSProperties = {
  borderRadius: size.m1
};

/** Styled Success Alert */
export const SuccessAlert: React.FC<AlertProps> = (props) => {
  return (
    <Alert
      {...props}
      style={{
        ...baseAlertStyle,
        ...props.style,
        backgroundColor: "#d4edda",
        borderColor: "#a8dbb4"
      }}
      type="success"
    />
  );
};

/** Styled Info Alert */
export const InfoAlert: React.FC<AlertProps> = (props) => {
  return (
    <Alert
      {...props}
      style={{
        ...baseAlertStyle,
        ...props.style,
        backgroundColor: "#e6f7ff",
        borderColor: "#abe4ff"
      }}
      type="info"
    />
  );
};

/** Styled Warning Alert */
export const WarningAlert: React.FC<AlertProps> = (props) => {
  return (
    <Alert
      {...props}
      style={{
        ...baseAlertStyle,
        ...props.style
      }}
      type="warning"
    />
  );
};

/** Styled Danger Alert */
export const DangerAlert: React.FC<AlertProps> = (props) => {
  return (
    <Alert
      {...props}
      style={{
        ...baseAlertStyle,
        ...props.style
      }}
      type="error"
    />
  );
};
