import React, { useEffect, useState } from "react";
import { Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  accString,
  AlarmData,
  AlarmRow,
  renderAlarmSensor,
  round2Decimals
} from "./AlarmsTab";
import { useTranslation } from "react-i18next";
import { toMsStr } from "../../helpers/dataModelHelper";
import {
  TemperatureToScale,
  TempScaleSymbol
} from "../MicroComponents/TemperatureConverter";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { DiffOutlined, LineChartOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useDownloadSerializedDataQuery } from "../../state/cargologRestApi";
import { skipToken } from "@reduxjs/toolkit/query";
import { isUndefined } from "lodash-es";
import {
  selectGraphPageVm,
  setNewCursorPosition,
  unpackDatxAsync
} from "../../state/openDatxSlice";
import { unpackFile } from "../../state/compareGraphsSlice";
import { closeProjectModal } from "../../state/projectSlice";
import { insertIf } from "../../utils/generalUtils";
import { useNavigate } from "react-router";
import { selectTemperatureScale } from "../../state/sessionSlice";
import dayjs from "dayjs";
const { Text } = Typography;

interface IDatxToDownload {
  parameterId: string;
  fileName: string;
  page: "/graph" | "/compare";
  timestamp: string;
}

interface IProps {
  alarmsData: AlarmRow[];
  loadingAlarms: boolean;
  isExport: boolean;
}
const AlarmsTabTable = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { alarmsData, loadingAlarms, isExport } = props;

  const tempScale = useSelector(selectTemperatureScale);
  const timezoneOffset = new Date().getTimezoneOffset() * 60;

  // Monitor graph page to trigger timestamp navigation once file is opened
  const { activeFileId, hasOpenFiles, openNewFileStatus } =
    useSelector(selectGraphPageVm);
  const fileIsOpen =
    activeFileId &&
    hasOpenFiles &&
    openNewFileStatus === "openFileStatusInactive";

  // FILE DOWNLOAD START

  // Current file being downloaded
  const [datxToDownload, setDatxToDownload] = useState<IDatxToDownload>();
  const [loadingIndex, setLoadingIndex] = useState<number>();
  const openOnlineFile = (
    parameterId: string,
    fileName: string,
    page: "/graph" | "/compare",
    timestamp: string,
    index: number
  ) => {
    setLoadingIndex(index);
    setDatxToDownload({ parameterId, fileName, page, timestamp });
  };

  // Download the selected file from the server
  const { data: selectedDatxFile, isLoading: loadingDatx } =
    useDownloadSerializedDataQuery(
      datxToDownload?.parameterId
        ? { parameterId: datxToDownload.parameterId }
        : skipToken
    );

  // Unpack the downloaded file (and navigate to compare if selected)
  useEffect(() => {
    if (isUndefined(selectedDatxFile) || isUndefined(datxToDownload)) return;

    if (datxToDownload.page === "/graph") {
      const rawData: number[] = [...selectedDatxFile];
      dispatch<any>(
        unpackDatxAsync({
          filePath: datxToDownload.fileName ?? datxToDownload.parameterId,
          rawData
        })
      );
    }
    if (datxToDownload.page === "/compare") {
      const rawData: number[] = [...selectedDatxFile];
      setDatxToDownload(undefined); // Prevents second useEffect
      setLoadingIndex(undefined);
      dispatch<any>(
        unpackFile({
          filePath: datxToDownload.fileName ?? datxToDownload.parameterId,
          rawData
        })
      );
      dispatch(closeProjectModal());
      navigate("/compare");
    }
  }, [selectedDatxFile]);

  // Sets the cursor to the alarm time (if file is opened in graph page)
  useEffect(() => {
    if (fileIsOpen && !isUndefined(datxToDownload)) {
      const alarmTime = new Date(datxToDownload.timestamp).getTime() / 1000;
      const cursorTime = alarmTime - timezoneOffset;
      setDatxToDownload(undefined);
      setLoadingIndex(undefined);
      dispatch(setNewCursorPosition(cursorTime));
      dispatch(closeProjectModal());
      navigate("/graph");
    }
  }, [fileIsOpen]);

  // END FILE DOWNLOAD

  const alarmColumns: ColumnsType<AlarmRow> = [
    {
      title: t("SerialNumber"),
      dataIndex: "serial",
      key: "serial",
      render: (serial: string) => <Text>{serial}</Text>,
      sorter: (a, b) => (a.serial ?? "").localeCompare(b.serial ?? "")
    },
    {
      title: t("Sensor"),
      dataIndex: "sensor",
      key: "sensor",
      render: (sensor: number) => renderAlarmSensor(sensor, t)
    },
    {
      title: t("Data"),
      dataIndex: "data",
      key: "data",
      render: (data: AlarmData) => {
        if (!data) return <></>;
        switch (data.sensorname) {
          case "acc":
            return (
              <Text>
                X: <Text strong>{accString(data.xAcc)}</Text> Y:{" "}
                <Text strong>{accString(data.yAcc)}</Text> Z:{" "}
                <Text strong>{accString(data.zAcc)}</Text> d:{" "}
                <Text strong>{toMsStr(data.duration)}</Text>
              </Text>
            );
          case "temp":
            return (
              <Text
                strong
              >{`${round2Decimals(TemperatureToScale(data.temp, tempScale))}${TempScaleSymbol(tempScale)}`}</Text>
            );
          case "rh":
            return <Text strong>{data.rh}%</Text>;
          case "angle":
            return (
              <Text>
                X: <Text strong>{data.xAngle ?? "N/A"}°</Text> Y:{" "}
                <Text strong>{data.yAngle ?? "N/A"}°</Text> Z:{" "}
                <Text strong>{data.zAngle ?? "N/A"}°</Text>
              </Text>
            );
        }
        return <></>;
      }
    },
    {
      title: t("Timestamp"),
      dataIndex: "timestamp",
      key: "timestamp",
      render: (date: string) => {
        const timezoneOffset = new Date().getTimezoneOffset();
        const time = dayjs
          .utc(date)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");

        return <Text>{time}</Text>;
      },
      sorter: (a, b) => {
        if (a.timestamp < b.timestamp) return -1;
        if (a.timestamp > b.timestamp) return 1;
        return 0;
      }
    },
    ...insertIf(!isExport, {
      title: t("tableActions"),
      dataIndex: "parameterId",
      key: "parameterId",
      render: (parameterId: string, data: AlarmRow, index: number) => {
        return (
          <Space>
            <NormalButtonSmall
              icon={<LineChartOutlined />}
              loading={
                loadingDatx &&
                loadingIndex === index &&
                datxToDownload?.page === "/graph"
              }
              onClick={() =>
                openOnlineFile(
                  parameterId,
                  data.fileName,
                  "/graph",
                  data.timestamp,
                  index
                )
              }
              title={t("OpenInReports")}
            />
            <NormalButtonSmall
              icon={<DiffOutlined />}
              loading={
                loadingDatx &&
                loadingIndex === index &&
                datxToDownload?.page === "/compare"
              }
              onClick={() =>
                openOnlineFile(
                  parameterId,
                  data.fileName,
                  "/compare",
                  data.timestamp,
                  index
                )
              }
              title={t("OpenInCompare")}
            />
          </Space>
        );
      }
    })
  ];

  return (
    <Table
      columns={alarmColumns}
      dataSource={alarmsData}
      loading={loadingAlarms}
      pagination={
        isExport
          ? false
          : {
              hideOnSinglePage: true,
              defaultPageSize: 8,
              showSizeChanger: true
            }
      }
    />
  );
};

export default AlarmsTabTable;
