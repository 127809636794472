import { Buffer } from "buffer";
import {
  DF_ACC,
  DF_ACCEXTRA,
  DF_ANGLE,
  DF_ANGLE_SENSOR,
  DF_BLUETOOTH,
  DF_DVA,
  DF_EXT_INPUTS,
  DF_EXT_OUTPUTS,
  DF_GPS,
  DF_INT_LIGHT,
  DF_LTE,
  DF_PRESSURE,
  DF_RESERVED1,
  DF_RH,
  DF_TEMP,
  DF_EXT_IO_MASK,
  DF_EXT_IO_CFG4,
  DF_EXT_IO_CFG1,
  DF_EXT_IO_CFG2
} from "../../constants/FAT100-prot-constants";
import { KIND_SYSTEMINFO } from "../../models/FAT100Kinds";
import {
  GeneralSystemInfo,
  ISystemInfoCV,
  ISystemInfoV2,
  ISystemInfoV3,
  ISystemInfoV4,
  expectedSystemInfoCVLength,
  expectedSystemInfoV2Length,
  expectedSystemInfoV3Length,
  expectedSystemInfoV4Length,
  isSystemInfoV4
} from "../../models/ISystemInfo";
import { VMDeviceFeatures } from "../../models/VMDeviceFeatures";
import { array2IDate } from "../dataModelHelper";
import { RhTemp, typeIdToFeature } from "../../models/FAT100DataTypes";

export const parseSystemInfo = (buf: Buffer): GeneralSystemInfo => {
  const { sysInfoVersion, contentBuf } = interpretSystemInfoBeforeParse(buf);

  let parsedSystemInfo: GeneralSystemInfo;
  switch (sysInfoVersion) {
    case 1:
      parsedSystemInfo = parseSystemInfoCVContent(contentBuf);
      break;
    case 2:
      parsedSystemInfo = parseSystemInfoV2Content(contentBuf);
      break;
    case 3:
      parsedSystemInfo = parseSystemInfoV3Content(contentBuf);
      break;
    case 4:
      parsedSystemInfo = parseSystemInfoV4Content(contentBuf);
      break;
    default:
      parsedSystemInfo = parseSystemInfoV3Content(contentBuf);
      break;
  }
  return parsedSystemInfo;
};

/**
 * Interprets SystemInfo version, look for errors and returns a buffer that can
 * be parsed using a systemInfo parser function
 * @param buf
 * @throws If not kind_systemInfo or wrong length of buffer
 */

const interpretSystemInfoBeforeParse = (buf: Buffer) => {
  let bufferPos = 0;

  //check kind and length
  const shouldBeKindSystemInfo = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  if (shouldBeKindSystemInfo !== KIND_SYSTEMINFO) {
    throw new Error(
      "Wrong kind. Should be KIND_SYSTEMINFO, received: " +
        shouldBeKindSystemInfo
    );
  }

  const length = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  if (length !== buf.length - 4) {
    throw new Error(
      `Wrong length for SystemInfo. Should be: ${buf.length} but reveived: ${length}`
    );
  }

  let sysInfoVersion: number = 0;
  //Check for older SystemInfo versions that don't state their own version otherwise use the version stated in the buffer
  if (length === expectedSystemInfoCVLength) {
    sysInfoVersion = 1;
  } else {
    sysInfoVersion = buf.readUInt16LE(bufferPos);
    bufferPos += 2;
  }

  //const configVersion = buf.readUInt16LE(bufferPos);
  //the future data model
  //Note: I don't step the bufferPos since i want config version to be part of the content buffer
  const contentBuf = buf.subarray(bufferPos, bufferPos + length);

  return { sysInfoVersion, contentBuf };
};

/**
 * Parses SystemInfo of ConfigVersion 4 or 5
 * @param buf
 */
const parseSystemInfoCVContent = (buf: Buffer): ISystemInfoCV => {
  if (buf.length !== expectedSystemInfoCVLength) {
    throw new Error(
      `Wrong length for systemInfo for config version 4. Expected ${expectedSystemInfoCVLength} but got ${buf.length}`
    );
  }

  let bufferPos = 0;

  const configVersion = buf.readUInt16LE(bufferPos) as 4 | 5;
  bufferPos += 2;

  const parxVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const datxVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const serial = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const hasFeatures = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const accStdFilter = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const accExtraFilter = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const accStdMaxG = buf.readUInt16LE(bufferPos) / 100;
  bufferPos += 2;

  const accExtraMaxG = buf.readUInt16LE(bufferPos) / 100;
  bufferPos += 2;

  const memoryAvailable = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const memoryUsed = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  // 8bit 80 times
  const artNum: string[] = [];
  for (let i = 0; i < 80; i++) {
    artNum.push(String.fromCharCode(buf.readUInt8(bufferPos)));
    bufferPos += 1;
  }

  const powerSource = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const mainBattery = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const backupBattery = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const runningStatus = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const timestampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    timestampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }
  const lastCalibration = array2IDate(timestampArr);

  const fwMajorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwMinorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwMainBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwSubBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  return {
    configVersion,
    parxVersion,
    datxVersion,
    serial,
    hasFeatures,
    accStdFilter,
    accExtraFilter,
    accStdMaxG,
    accExtraMaxG,
    memoryAvailable,
    memoryUsed,
    artNum,
    powerSource,
    mainBattery,
    backupBattery,
    runningStatus,
    lastCalibration,
    fwMajorVersion,
    fwMinorVersion,
    fwMainBuild,
    fwSubBuild
  };
};

/**
 * Parses SystemInfo of SystemInfo version 2
 *  @param buf
 */
const parseSystemInfoV2Content = (buf: Buffer): ISystemInfoV2 => {
  if (buf.length !== expectedSystemInfoV2Length) {
    throw new Error(
      `Wrong length for systemInfo v4. Expected ${expectedSystemInfoV2Length} but got ${buf.length}`
    );
  }

  let bufferPos = 0;

  const configVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const parxVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const datxVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  let serialString = "";
  for (let i = 0; i < 20; i++) {
    const nextChar = buf.readUInt8(bufferPos);
    if (nextChar !== 0) {
      serialString += String.fromCharCode(nextChar);
    }
    bufferPos += 1;
  }
  const serial = serialString;

  const hasFeatures = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const accStdFilter = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const accExtraFilter = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const accStdMaxG = buf.readUInt16LE(bufferPos) / 100;
  bufferPos += 2;

  const accExtraMaxG = buf.readUInt16LE(bufferPos) / 100;
  bufferPos += 2;

  const memoryAvailable = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const memoryUsed = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  // 8bit 80 times
  const artNum: string[] = [];
  for (let i = 0; i < 80; i++) {
    artNum.push(String.fromCharCode(buf.readUInt8(bufferPos)));
    bufferPos += 1;
  }

  const powerSource = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const mainBatteryVoltage = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const rebootTimeStampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    rebootTimeStampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }

  const rebootTimeStamp = array2IDate(rebootTimeStampArr);

  const mainBatteryTimeStampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    mainBatteryTimeStampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }
  const mainBatteryTimeStamp = array2IDate(mainBatteryTimeStampArr);

  const mainBattery = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const backupBattery = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const runningStatus = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const timestampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    timestampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }
  const lastCalibration = array2IDate(timestampArr);

  const fwMajorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwMinorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwMainBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwSubBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  return {
    configVersion,
    parxVersion,
    datxVersion,
    serial,
    hasFeatures,
    accStdFilter,
    accExtraFilter,
    accStdMaxG,
    accExtraMaxG,
    memoryAvailable,
    memoryUsed,
    artNum,
    powerSource,
    mainBatteryVoltage,
    rebootTimeStamp,
    mainBatteryTimeStamp,
    mainBattery,
    backupBattery,
    runningStatus,
    lastCalibration,
    fwMajorVersion,
    fwMinorVersion,
    fwMainBuild,
    fwSubBuild
  };
};

/**
 * Parses SystemInfo of SystemInfo version 3
 *  @param buf
 */
const parseSystemInfoV3Content = (buf: Buffer): ISystemInfoV3 => {
  if (buf.length !== expectedSystemInfoV3Length) {
    throw new Error(
      `Wrong length for systemInfo v3. Expected ${expectedSystemInfoV3Length} but got ${buf.length}`
    );
  }

  let bufferPos = 0;

  const configVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const parxVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const datxVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  let serialString = "";
  for (let i = 0; i < 20; i++) {
    const nextChar = buf.readUInt8(bufferPos);
    if (nextChar !== 0) {
      serialString += String.fromCharCode(nextChar);
    }
    bufferPos += 1;
  }
  const serial = serialString;

  const hasFeatures = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const accStdFilter = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const accExtraFilter = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const accStdMaxG = buf.readUInt16LE(bufferPos) / 100;
  bufferPos += 2;

  const accExtraMaxG = buf.readUInt16LE(bufferPos) / 100;
  bufferPos += 2;

  const memoryAvailable = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const memoryUsed = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  // 8bit 80 times
  const artNum: string[] = [];
  for (let i = 0; i < 80; i++) {
    artNum.push(String.fromCharCode(buf.readUInt8(bufferPos)));
    bufferPos += 1;
  }

  const powerSource = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const mainBatteryVoltage = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const rebootTimeStampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    rebootTimeStampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }

  const rebootTimeStamp = array2IDate(rebootTimeStampArr);

  const mainBatteryTimeStampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    mainBatteryTimeStampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }
  const mainBatteryTimeStamp = array2IDate(mainBatteryTimeStampArr);

  const mainBattery = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const backupBattery = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const runningStatus = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const timestampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    timestampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }
  const lastCalibration = array2IDate(timestampArr);

  const fwMajorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwMinorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwMainBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwSubBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const serialProtVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const coFWMajorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const coFWMinorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const coFWMainBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const coFWSubBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  return {
    configVersion,
    parxVersion,
    datxVersion,
    serial,
    hasFeatures,
    accStdFilter,
    accExtraFilter,
    accStdMaxG,
    accExtraMaxG,
    memoryAvailable,
    memoryUsed,
    artNum,
    powerSource,
    mainBatteryVoltage,
    rebootTimeStamp,
    mainBatteryTimeStamp,
    mainBattery,
    backupBattery,
    runningStatus,
    lastCalibration,
    fwMajorVersion,
    fwMinorVersion,
    fwMainBuild,
    fwSubBuild,
    serialProtVersion,
    coFWMajorVersion,
    coFWMinorVersion,
    coFWMainBuild,
    coFWSubBuild
  };
};

/**
 * Parses SystemInfo of SystemInfo version 4
 *  @param buf
 */
const parseSystemInfoV4Content = (buf: Buffer): ISystemInfoV4 => {
  if (buf.length !== expectedSystemInfoV4Length) {
    throw new Error(
      `Wrong length for systemInfo v4. Expected ${expectedSystemInfoV4Length} but got ${buf.length}`
    );
  }

  let bufferPos = 0;

  const configVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const parxVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const datxVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  let serialString = "";
  for (let i = 0; i < 20; i++) {
    const nextChar = buf.readUInt8(bufferPos);
    if (nextChar !== 0) {
      serialString += String.fromCharCode(nextChar);
    }
    bufferPos += 1;
  }
  const serial = serialString;

  const hasFeatures = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const accStdFilter = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const accExtraFilter = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const accStdMaxG = buf.readUInt16LE(bufferPos) / 100;
  bufferPos += 2;

  const accExtraMaxG = buf.readUInt16LE(bufferPos) / 100;
  bufferPos += 2;

  const memoryAvailable = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const memoryUsed = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  // 8bit 80 times
  const artNum: string[] = [];
  for (let i = 0; i < 80; i++) {
    artNum.push(String.fromCharCode(buf.readUInt8(bufferPos)));
    bufferPos += 1;
  }

  const powerSource = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const mainBatteryVoltage = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const rebootTimeStampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    rebootTimeStampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }

  const rebootTimeStamp = array2IDate(rebootTimeStampArr);

  const mainBatteryTimeStampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    mainBatteryTimeStampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }
  const mainBatteryTimeStamp = array2IDate(mainBatteryTimeStampArr);

  const mainBattery = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const backupBattery = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const runningStatus = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const timestampArr: number[] = [];
  for (let i = 0; i < 6; i++) {
    timestampArr.push(buf.readUInt8(bufferPos));
    bufferPos += 1;
  }
  const lastCalibration = array2IDate(timestampArr);

  const fwMajorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwMinorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwMainBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const fwSubBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const serialProtVersion = buf.readUInt16LE(bufferPos);
  bufferPos += 2;

  const coFWMajorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const coFWMinorVersion = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const coFWMainBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const coFWSubBuild = buf.readUInt8(bufferPos);
  bufferPos += 1;

  const ExtSensors1 = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const ExtSensors2 = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const ExtSensors3 = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  const ExtSensors4 = buf.readUInt32LE(bufferPos);
  bufferPos += 4;

  return {
    configVersion,
    parxVersion,
    datxVersion,
    serial,
    hasFeatures,
    accStdFilter,
    accExtraFilter,
    accStdMaxG,
    accExtraMaxG,
    memoryAvailable,
    memoryUsed,
    artNum,
    powerSource,
    mainBatteryVoltage,
    rebootTimeStamp,
    mainBatteryTimeStamp,
    mainBattery,
    backupBattery,
    runningStatus,
    lastCalibration,
    fwMajorVersion,
    fwMinorVersion,
    fwMainBuild,
    fwSubBuild,
    serialProtVersion,
    coFWMajorVersion,
    coFWMinorVersion,
    coFWMainBuild,
    coFWSubBuild,
    ExtSensors1,
    ExtSensors2,
    ExtSensors3,
    ExtSensors4
  };
};

/**
 * Interprets the hasFeatures-field in systemInfo
 * @param val 4bit uint number
 */
export const interpretSystemInfoHasFeatures = (
  val: number
): VMDeviceFeatures => ({
  DF_ACC: (val & DF_ACC) !== 0,
  DF_TEMP: (val & DF_TEMP) !== 0,
  DF_RH: (val & DF_RH) !== 0,
  DF_ANGLE: (val & DF_ANGLE) !== 0,
  DF_PRESSURE: (val & DF_PRESSURE) !== 0,
  DF_INT_LIGHT: (val & DF_INT_LIGHT) !== 0,
  DF_GPS: (val & DF_GPS) !== 0,
  DF_LTE: (val & DF_LTE) !== 0,
  DF_BLUETOOTH: (val & DF_BLUETOOTH) !== 0,
  DF_EXT_INPUTS: (val & DF_EXT_INPUTS) !== 0,
  DF_EXT_OUTPUTS: (val & DF_EXT_OUTPUTS) !== 0,
  DF_RESERVED1: (val & DF_RESERVED1) !== 0,
  DF_DVA: (val & DF_DVA) !== 0,
  DF_ACCEXTRA: (val & DF_ACCEXTRA) !== 0, // if using AccExtra as main acc
  DF_ANGLE_SENSOR: (val & DF_ANGLE_SENSOR) !== 0,
  DF_EXT_IO: (val & DF_EXT_IO_MASK) !== 0,
  DF_EXT_IO_CFG: val & DF_EXT_IO_MASK
});

/**
 * Interprets the External Sensors features into external IDs
 */
export const interpretExternalSensorFeatures = (
  systemInfo: GeneralSystemInfo
): number[] => {
  let availableSensors: number[] = [];
  // Check if systeminfo is v4
  if (isSystemInfoV4(systemInfo)) {
    RhTemp.forEach((sensor) => {
      const sensorBit = typeIdToFeature[sensor];
      if ((systemInfo.ExtSensors1 & sensorBit) > 0) {
        availableSensors.push(sensor);
      }
    });
  }
  return availableSensors;
};

/**
 * Returns the external sensor standard (IO or I2C) based on DF_EXT_IO_CFG
 */
type extStandard = "IO" | "I2C" | "NONE";
export const getExternalSensor = (DF_EXT_IO_CFG: number): extStandard => {
  if (DF_EXT_IO_CFG === DF_EXT_IO_CFG4) {
    return "I2C";
  } else if (
    DF_EXT_IO_CFG === DF_EXT_IO_CFG1 ||
    DF_EXT_IO_CFG === DF_EXT_IO_CFG2
  ) {
    return "IO";
  } else {
    return "NONE";
  }
};

export const getNumberOfIO = (DF_EXT_IO_CFG: number): number => {
  if (DF_EXT_IO_CFG === DF_EXT_IO_CFG1) {
    return 1;
  } else if (DF_EXT_IO_CFG === DF_EXT_IO_CFG2) {
    return 2;
  } else {
    return 0;
  }
};
