import React from "react";
import { Descriptions, Form, Input, Typography, Row } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";
import { Company } from "../../state/cargologRestApi";
import {
  NormalButton,
  NormalButtonSmall,
  PrimaryButton
} from "../Common/CommonButtons";
import { openEditInvoiceInfoModal, setCompany } from "../../state/modalsSlice";
import { DangerAlert, InfoAlert } from "../Common/CommonAlerts";
import { Help } from "../MicroComponents/Help";

const { Text } = Typography;

interface IProps {
  companyData?: Company;
  sendOrder: () => void;
  requestIsLoading: boolean;
  purchaseOrderNumber: string;
  setPurchaseOrderNumber: (value: string) => void;
}

const InvoiceCheckout: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    companyData,
    sendOrder,
    requestIsLoading,
    purchaseOrderNumber,
    setPurchaseOrderNumber
  } = props;

  const companyDetailsComplete =
    companyData?.invoiceEmail &&
    companyData?.invoiceAddress &&
    companyData?.invoicePostalCode &&
    companyData?.invoiceCity &&
    (companyData?.countryCode !== "SE" || companyData?.orgNumber);

  const requiredText = <Text type="danger">* {t("Required")}</Text>;
  return (
    <>
      <Descriptions
        title={t("InvoiceDetails")}
        extra={Help(
          <NormalButton
            disabled={!companyData}
            size="small"
            onClick={() => {
              dispatch(setCompany(companyData));
              dispatch(openEditInvoiceInfoModal());
            }}
          >
            {t("EditDetails")}
          </NormalButton>,
          t("ClickToEditInvoiceDetails"),
          "top"
        )}
      >
        <Descriptions.Item
          label={t("Email")}
          labelStyle={{ fontWeight: "bold" }}
          span={2}
        >
          {companyData?.invoiceEmail ?? requiredText}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("City")}
          labelStyle={{ fontWeight: "bold" }}
        >
          {companyData?.invoiceCity ?? requiredText}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("Address")}
          labelStyle={{ fontWeight: "bold" }}
          span={2}
        >
          {companyData?.invoiceAddress ?? requiredText}
        </Descriptions.Item>
        <Descriptions.Item
          label={t("PostalCode")}
          labelStyle={{ fontWeight: "bold" }}
        >
          {companyData?.invoicePostalCode ?? requiredText}
        </Descriptions.Item>
        {companyData && companyData.countryCode !== "SE" && (
          <Descriptions.Item
            label={t("VATNumber")}
            labelStyle={{ fontWeight: "bold" }}
          >
            {companyData?.vatNumber}
          </Descriptions.Item>
        )}
        {companyData && companyData.countryCode === "SE" && (
          <Descriptions.Item
            label={t("OrgNumber")}
            labelStyle={{ fontWeight: "bold" }}
          >
            {companyData?.orgNumber}
          </Descriptions.Item>
        )}
      </Descriptions>
      {!companyDetailsComplete && (
        <DangerAlert
          message={
            <Row justify="space-between">
              <Text>{t("PurchaseNotAvailable")}</Text>
              <NormalButtonSmall
                onClick={() => {
                  dispatch(setCompany(companyData));
                  dispatch(openEditInvoiceInfoModal());
                }}
              >
                {t("EditDetails")}
              </NormalButtonSmall>
            </Row>
          }
          type="error"
          style={{ marginBottom: size.m1 }}
        />
      )}
      <InfoAlert
        message={t("CheckoutMessage")}
        style={{ marginBottom: size.m1 }}
      />
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={sendOrder}
        onFinishFailed={() => console.log("Invoice form not complete")}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Form.Item style={{ marginBottom: 0 }} label={t("PurchaseOrderNumber")}>
          <Input
            disabled={!companyDetailsComplete}
            style={{ width: "120%" }}
            value={purchaseOrderNumber}
            onChange={(e) => setPurchaseOrderNumber(e.target.value)}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          {Help(
            <PrimaryButton
              style={{ float: "right" }}
              htmlType="submit"
              loading={requestIsLoading}
              disabled={!companyDetailsComplete}
            >
              {t("SendOrder")}
            </PrimaryButton>,
            t("ClickHereToSendYourOrder"),
            "top"
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default InvoiceCheckout;
