import React from "react";
import { Alert, Row, Table, Typography } from "antd";
import { AuditOutlined, ShoppingOutlined } from "@ant-design/icons";
import { LandingCard } from "./Landing";
import { useGetLicensesQuery } from "../../state/cargologRestApi";
import { isNull } from "lodash-es";
import dayjs from "dayjs";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import { useNavigate } from "react-router";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { useDispatch } from "react-redux";
import { setCurrentAdminTab } from "../../state/navigationSlice";
import Online from "../MicroComponents/Online";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const LicensesLandingCard = () => {
  const { t } = useTranslation();

  const { data: licenseData, isLoading } = useGetLicensesQuery({});

  const timezoneOffset = new Date().getTimezoneOffset();

  const columns = [
    {
      title: t("UnusedLicenses"),
      dataIndex: "license",
      name: "license",
      width: "50%"
    },
    {
      title: t("Created"),
      dataIndex: "created",
      name: "created",
      align: "right" as const
    }
  ];

  const tableData = licenseData
    ?.filter((license) => {
      return isNull(license.userId);
    })
    .reverse()
    .map((license, index) => {
      const text = license.text;
      const createdDate = dayjs
        .utc(license.created)
        .subtract(timezoneOffset, "minutes")
        .format("YYYY-MM-DD, HH:mm");
      let row = {
        key: index,
        license: text,
        created: createdDate
      };
      return row;
    });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      {LicenseAccess(
        "AFB",
        <LandingCard
          key="licenses"
          title={
            <LargeHeaderTitle>
              <AuditOutlined /> {t("Licenses")}
            </LargeHeaderTitle>
          }
          extra={
            <NormalButtonSmall
              onClick={() => {
                dispatch(setCurrentAdminTab("licenses"));
                navigate("/admin");
              }}
            >
              {t("Manage")}
            </NormalButtonSmall>
          }
        >
          <Online>
            {/* List licenses that are not used by anyone. "You have the following unused licenses." 
            Link to Licenses, Link to AdminUsers Link to License Store (just nu inget state för sidval under Admin, men vi ska bygga det)*/}

            {tableData?.length === 0 ? (
              <Alert
                style={{ textAlign: "center" }}
                message={
                  <>
                    <Row justify="space-between">
                      <Text>{t("YouDontHaveAnyNewUnusedLicenses")}</Text>
                      <NormalButtonSmall
                        icon={<ShoppingOutlined />}
                        onClick={() => {
                          dispatch(setCurrentAdminTab("store"));
                          navigate("/admin");
                        }}
                      >
                        {t("BuyLicenses")}
                      </NormalButtonSmall>
                    </Row>
                  </>
                }
                type="warning"
              />
            ) : (
              <Table
                size="small"
                columns={columns}
                dataSource={tableData}
                pagination={false}
                loading={isLoading}
                style={{ height: "200px", overflow: "auto" }}
              />
            )}
          </Online>
        </LandingCard>
      )}
    </>
  );
};

export default LicensesLandingCard;
