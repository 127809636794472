import * as React from "react";
import type { SVGProps } from "react";
const SvgPressureTempIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 141.73 141.73"
    {...props}
  >
    <path
      d="M92.166 36.465a92 92 0 0 0-19.01 1.824l.21 16.213a76.3 76.3 0 0 1 22.23 2.984l6.244-20.412a93 93 0 0 0-9.674-.61m14.803 1.33-6.508 21.271a78.4 78.4 0 0 1 20.275 10.92l15.543-21.95a93 93 0 0 0-29.31-10.241m-38.8 1.64a90.4 90.4 0 0 0-24.849 10.67l3.303 9.805a74 74 0 0 1 21.746-5.156zm72.44 11.135L124.65 73.111c5.923 5.031 11.233 11.036 15.698 17.963L165.36 73.14c-7.063-9.244-15.483-16.782-24.752-22.569M38.998 52.94c-7.023 4.93-13.529 10.996-19.307 18.197l3.155 4.088c5.938-5.574 12.404-9.992 19.187-13.284zM16.563 75.265C9.667 84.834 3.986 96.193 0 109.428 5.041 97.032 11.639 86.854 19.266 78.77zm151.74 1.918-25.364 18.185c3.004 5.33 5.5 11.167 7.454 17.444 10.944-3.212 20.378-5.975 30.892-9.04-3.235-9.93-7.648-18.804-12.982-26.59m15.213 34.36a16855 16855 0 0 1-29.914 14.843c.59 3.641 1.008 7.405 1.228 11.295h32.4c-.408-9.336-1.691-18.048-3.714-26.137"
      transform="scale(.74999)"
      fill={props.fill === undefined ? "#7c287d" : props.fill}
      fillOpacity={1}
    />
    <path
      d="M141.635 78.675c-2.007.027-48.369 14.015-65.66 19.251a10.19 10.19 0 0 1 4.7 8.585 10.2 10.2 0 0 1-.384 2.606c16.363-7.92 61.594-29.868 61.407-30.427q-.005-.015-.063-.015"
      fill={props.fill === undefined ? "#7c287d" : props.fill}
      fillOpacity={1}
    />
    <circle
      cx={70.483}
      cy={106.512}
      r={7.64}
      fill={props.fill === undefined ? "#7c287d" : props.fill}
      fillOpacity={1}
      fillRule="evenodd"
    />
    <path
      d="M34.976 60.233a4.825 4.825 0 0 0-4.927 4.52v37.527h9.276V64.616a4.72 4.72 0 0 0-4.35-4.383"
      fill={props.fill === undefined ? "#ee2624" : props.fill}
      fillOpacity={1}
    />
    <circle
      cx={34.687}
      cy={106.512}
      r={13.087}
      fill={props.fill === undefined ? "#ee2624" : props.fill}
    />
    <path
      d="M34.856 62.155a2.825 2.825 0 0 0-2.885 2.647v14.475h5.432V64.722a2.766 2.766 0 0 0-2.547-2.567"
      fill="#fff"
      fillOpacity={1}
    />
  </svg>
);
export default SvgPressureTempIcon;
