import { Buffer } from "buffer";
import { IAccFilterValues } from "../../models/IAccFilterValues";
import { IProgInfo } from "../../models/IProgInfo";
import { ISRD, IScheduler } from "../../models/IScheduler";
import IAccParams from "../../models/RecordingParameters/IAccParams";
import IAngleParams from "../../models/RecordingParameters/IAngleParams";
import IExternalInputsParams from "../../models/RecordingParameters/IExternalInputsParams";
import IExternalOutputsParams from "../../models/RecordingParameters/IExternalOutputsParams";
import IExternalSensorParams from "../../models/RecordingParameters/IExternalSensorParams";
import IGPSParams from "../../models/RecordingParameters/IGPSParams";
import ILTEParams from "../../models/RecordingParameters/ILTEParams";
import ILightSensorParams from "../../models/RecordingParameters/ILightSensorParams";
import IPressureParams from "../../models/RecordingParameters/IPressureParams";
import IRecParams from "../../models/RecordingParameters/IRecParams";
import IRhParams from "../../models/RecordingParameters/IRhParams";
import ITempParams from "../../models/RecordingParameters/ITempParams";
import { RhTemp } from "../../models/FAT100DataTypes";
import IExternalRhTempParams from "../../models/RecordingParameters/IExternalRhTempParams";

export const parseProgInfo = (data: number[]): IProgInfo => ({
  appVersionMajor: data[0],
  appVersionMinor: data[1],
  appBuildMain: data[2],
  appBuildSub: data[3],
  timezoneId: data[4],
  appId: data[5]
});

//Can i convert to buffer and react int16s??
export const parseAccParams = (data: number[]) => {
  return {
    Xreg: (data[1] << 8) | data[0],
    Yreg: (data[3] << 8) | data[2],
    Zreg: (data[5] << 8) | data[4],
    Xalarm: (data[7] << 8) | data[6],
    Yalarm: (data[9] << 8) | data[8],
    Zalarm: (data[11] << 8) | data[10],
    Xms: (data[13] << 8) | data[12],
    Yms: (data[15] << 8) | data[14],
    Zms: (data[17] << 8) | data[16]
  } as IAccParams;
};

export const parseAccDVATrigLevel = (data: number[]): number => {
  const buf = Buffer.from(data);
  return buf.readUInt16LE(0);
};

export const parseTempParams = (data: number[]) => {
  const buf = Buffer.from(data);
  const lowAlarm = buf.readInt16LE(0) / 100;
  const highAlarm = buf.readInt16LE(2) / 100;
  return {
    lowAlarm,
    highAlarm
  } as ITempParams;
};

export const parseRhParams = (data: number[]) => {
  const buf = Buffer.from(data);
  const lowAlarm = buf.readInt16LE(0) / 100;
  const highAlarm = buf.readInt16LE(2) / 100;
  return {
    lowAlarm,
    highAlarm
  } as IRhParams;
};

export const parseAngleParams = (
  data: number[],
  version?: number
): IAngleParams => {
  //this function uses a hacky way to determine cfg-version
  const buf = Buffer.from(data);

  //latest version
  if (data.length === 12) {
    const xAlarmLevel = buf.readUInt16LE(0);
    const yAlarmLevel = buf.readUInt16LE(2);
    const zAlarmLevel = buf.readUInt16LE(4);
    //note: not unsigned since these can be negative
    const xOffset = buf.readInt16LE(6);
    const yOffset = buf.readInt16LE(8);
    const zOffset = buf.readInt16LE(10);

    return { xAlarmLevel, yAlarmLevel, zAlarmLevel, xOffset, yOffset, zOffset };
  }

  //older version
  else if (data.length === 6) {
    const xAlarmLevel = buf.readUInt16LE(0);
    const yAlarmLevel = buf.readUInt16LE(2);
    const zAlarmLevel = buf.readUInt16LE(4);

    return { xAlarmLevel, yAlarmLevel, zAlarmLevel };
  }

  //wrong
  else {
    throw new Error(
      `Wrong length for unpacked angle params. Should be 12, but recieved: ${data.length}`
    );
  }
};

//change all of them to hande 16bit
export const parsePressureParams = (data: number[]) => {
  const buf = Buffer.from(data);
  let bufPos = 0;

  const lowAlarm = buf.readUInt16LE(bufPos);
  bufPos += 2;

  const highAlarm = buf.readUInt16LE(bufPos);
  bufPos += 2;

  // The stored value is 1000 times the actual value
  const slopeValue = buf.readInt32LE(bufPos) / 1000;
  bufPos += 4;

  // The stored value is 100 times the actual value
  const tempValue = buf.readInt16LE(bufPos) / 100;
  bufPos += 2;

  return {
    lowAlarm,
    highAlarm,
    slopeValue,
    tempValue
  } as IPressureParams;
};

// New in config version 7
export const parseExternalInputsParam = (
  data: number[]
): IExternalInputsParams => {
  const buf = Buffer.from(data);
  let bufPos = 0;

  const inputBit = buf.readUInt8(bufPos);
  bufPos += 1;
  const onChange = buf.readUInt8(bufPos);
  bufPos += 1;
  const alsoRecord = buf.readUInt16LE(bufPos);
  bufPos += 2;
  const alarmOutputBits = buf.readUInt16LE(bufPos);
  bufPos += 2;
  const alarmInputBits = buf.readUInt16LE(bufPos);
  bufPos += 2;

  let description = "";
  for (let i = 0; i < 24; i++) {
    const nextChar = buf.readUInt8(bufPos);
    if (nextChar !== 0) {
      description += String.fromCharCode(nextChar);
    }
    bufPos += 1;
  }

  return {
    inputBit,
    onChange,
    alsoRecord,
    alarmOutputBits,
    alarmInputBits,
    description
  };
};

// New in config version 7
export const parseExternalOutputsParam = (
  data: number[]
): IExternalOutputsParams => {
  const buf = Buffer.from(data);
  let bufPos = 0;

  const outputBit = buf.readUInt8(bufPos);
  bufPos += 1;
  const onChange = buf.readUInt8(bufPos);
  bufPos += 1;
  // Extra unused byte
  bufPos += 1;
  const initialState = buf.readUInt8(bufPos);
  bufPos += 1;
  const alsoRecord = buf.readUInt16LE(bufPos);
  bufPos += 2;
  const alarmOutputBits = buf.readUInt16LE(bufPos);
  bufPos += 2;
  const alarmInputBits = buf.readUInt16LE(bufPos);
  bufPos += 2;

  let description = "";
  for (let i = 0; i < 24; i++) {
    const nextChar = buf.readUInt8(bufPos);
    if (nextChar !== 0) {
      description += String.fromCharCode(nextChar);
    }
    bufPos += 1;
  }

  return {
    outputBit,
    onChange,
    initialState,
    alsoRecord,
    alarmOutputBits,
    alarmInputBits,
    description
  };
};

// New in config version 7
export const parseGPSParams = (data: number[]): IGPSParams => {
  return { storeSpeed: data[0] };
};

// New in config version 7
export const parseLTEParams = (data: number[]): ILTEParams => {
  const buf = (data[1] << 8) | data[0];

  const bitMasks = {
    GPSRequired: 1,
    Enable2G: 256,
    Enable3G: 512,
    Enable4G: 1024,
    Enable5G: 2048,
    Enable6G: 4096
  };
  return {
    GPSRequired: buf & bitMasks.GPSRequired ? 1 : 0,
    Enable2G: buf & bitMasks.Enable2G ? 1 : 0,
    Enable3G: buf & bitMasks.Enable3G ? 1 : 0,
    Enable4G: buf & bitMasks.Enable4G ? 1 : 0,
    Enable5G: buf & bitMasks.Enable5G ? 1 : 0,
    Enable6G: buf & bitMasks.Enable6G ? 1 : 0
  };
};

const parseExternalRhTempParams = (
  buf: Buffer,
  bufPos: number
): IExternalRhTempParams => {
  const rhMin = buf.readInt16LE(bufPos) / 100;
  bufPos += 2;

  const rhMax = buf.readInt16LE(bufPos) / 100;
  bufPos += 2;

  const tempMin = buf.readInt16LE(bufPos) / 100;
  bufPos += 2;

  const tempMax = buf.readInt16LE(bufPos) / 100;
  bufPos += 2;

  return {
    rhMin,
    rhMax,
    tempMin,
    tempMax
  };
};

// New in config version 9
export const parseExternalSensorParams = (
  data: number[]
): IExternalSensorParams => {
  const buf = Buffer.from(data);
  let bufPos = 0;
  const sensorParams = {} as IExternalSensorParams;

  // Sensor type
  sensorParams.sensorTypeId = buf.readUInt16LE(bufPos);
  bufPos += 2;

  // Sensor name (24 bytes) - 0x00 terminated
  let sensorName = "";
  for (let i = 0; i < 24; i++) {
    const nextChar = buf.readUInt8(bufPos);
    if (nextChar !== 0) {
      sensorName += String.fromCharCode(nextChar);
    }
    bufPos += 1;
  }

  sensorParams.sensorName = sensorName;

  if (RhTemp.includes(sensorParams.sensorTypeId)) {
    sensorParams.sensorConfig = parseExternalRhTempParams(buf, bufPos);
  }

  return sensorParams;
};

// New in config version 6
export const parseCompanyIdParams = (data: number[]): string => {
  return data.reduce((str, curr) => {
    return str + String.fromCharCode(curr);
  }, "");
};

// New in config version 8
export const parseParameterIdParams = (data: number[]): string => {
  return data.reduce((str, curr) => {
    return str + String.fromCharCode(curr);
  }, "");
};

export const parseLightSensorParams = (data: number[]) => {
  return { alarmLevel: (data[1] << 8) | data[0] } as ILightSensorParams;
};

export const parseUserInfoParams = (data: number[]): string => {
  return data.reduce((str, curr) => {
    return str + String.fromCharCode(curr);
  }, "");
};

export const parseProjectNameParams = (data: number[]): string => {
  return data.reduce((str, curr) => {
    return str + String.fromCharCode(curr);
  }, "");
};

export const parseAccFilterValues = (data: number[]): IAccFilterValues => {
  if (data.length !== 4) {
    throw new Error(
      `Wrong accFilter length. It should be 4 but I recieved: ${data.length}`
    );
  }

  const buf = Buffer.from(data);
  const value1 = buf.readUInt16LE(0);
  const value2 = buf.readUInt16LE(2);

  return { value1, value2 };
};

export const parseSchedulers = (data: number[]): IScheduler[] => {
  const res: IScheduler[] = [];

  const buf = Buffer.from(data);
  let bufPos = 0;
  // Read through the buffer and create scheduler for every channel
  while (bufPos < data.length) {
    const schedule = {} as IScheduler;
    schedule.sensorId = buf.readUInt8(bufPos);
    bufPos += 1;

    const numOfSchedulers = buf.readUInt8(bufPos);
    schedule.numOfSchedulers = numOfSchedulers;
    bufPos += 1;

    const SRDlist: ISRD[] = [];
    let SRDcnt = 0;

    while (SRDcnt < numOfSchedulers) {
      let SRD = {} as ISRD;
      SRD = { start: {}, end: {} } as ISRD;
      SRD.start.year = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.start.month = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.start.day = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.start.hour = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.start.minute = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.start.second = buf.readUInt8(bufPos);
      bufPos += 1;

      SRD.end.year = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.end.month = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.end.day = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.end.hour = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.end.minute = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.end.second = buf.readUInt8(bufPos);
      bufPos += 1;

      SRD.onPeriod = buf.readUInt32LE(bufPos);
      bufPos += 4;
      SRD.extAlsoRecord = buf.readUInt32LE(bufPos);
      bufPos += 4;
      SRD.measureInterval = buf.readUInt32LE(bufPos);
      bufPos += 4;
      SRD.value32 = buf.readUInt32LE(bufPos);
      bufPos += 4;
      SRD.ExtSensTypeID = buf.readUInt16LE(bufPos);
      bufPos += 2;
      SRD.FuncCtrl = buf.readUInt16LE(bufPos);
      bufPos += 2;
      SRD.IgnoreCountLTE = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.IgnoreCountGPS = buf.readUInt8(bufPos);
      bufPos += 1;
      SRD.AlsoRecord = buf.readUInt16LE(bufPos);
      bufPos += 2;
      SRD.AlarmOutputBits = buf.readUInt16LE(bufPos);
      bufPos += 2;
      SRD.AlarmInputBits = buf.readUInt16LE(bufPos);
      bufPos += 2;
      // Last 2 bytes are reserved for future use
      bufPos += 2;
      SRDlist.push(SRD);
      SRDcnt++;
    }
    schedule.SRD = SRDlist;
    res.push(schedule);
  }

  return res;
};

// Deprecated in config version 6
export const parseLicenseKey = (data: number[]) => {
  return data;
};

export const parseRecParams = (data: number[]): IRecParams => {
  const expectedLength = 14;

  if (data.length !== expectedLength) {
    throw new Error(
      `Wrong length. Expected ${expectedLength} but got: ${data.length}`
    );
  }

  //not sure if recmode is in right order
  return {
    // TODO: not sure if this is correct
    recMode: (data[1] << 8) | data[0],
    start: {
      year: data[2],
      month: data[3],
      day: data[4],
      hour: data[5],
      minute: data[6],
      second: data[7]
    },
    end: {
      year: data[8],
      month: data[9],
      day: data[10],
      hour: data[11],
      minute: data[12],
      second: data[13]
    }
  };
};
