import React from "react";
import { Card, Row, Col, Select } from "antd";
import { size } from "../../helpers/pageHelper";
import { SmallText } from "../Common/CommonFonts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectMapProvider, setMapProvider } from "../../state/sessionSlice";
const { Option } = Select;

const MapProviderCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectAndButtonWidth = 190;
  const mapProvider = useSelector(selectMapProvider);

  return (
    <Card style={{ marginBottom: size.m1 }}>
      <Row style={{ marginBottom: size.m1 }}>
        <SmallText strong>{t("ExternalMapLinks")}</SmallText>
      </Row>
      <Row align="middle" style={{ paddingLeft: size.m1 }}>
        <Col span={12}>
          <SmallText>{t("OpenExternalLinksIn")}: </SmallText>
        </Col>
        <Col>
          <Select
            style={{ minWidth: selectAndButtonWidth, textAlign: "center" }}
            defaultValue={mapProvider}
            onChange={(v) => {
              dispatch(setMapProvider(v));
            }}
            // Keeps Dropdown in place when scrolling
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            <Option value="google">{t("GoogleMaps")}</Option>
            <Option value="openStreetMap">{t("OpenStreetMap")}</Option>
            {/* <Option value="hereWeGo">{("HERE WeGo")}</Option> */}
          </Select>
        </Col>
      </Row>
    </Card>
  );
};

export default MapProviderCard;
