import * as React from "react";
import type { SVGProps } from "react";
const SvgExtSensMsgIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 1024 1024"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M511.88 927.15h-1.853a418.4 418.4 0 0 1-185.28-44.48h-140.91a42.37 42.37 0 0 1-30.135-12.51 42.83 42.83 0 0 1-12.492-30.117v-140.85c-65.021-128.41-58.846-281.35 16.316-404.1s208.57-197.78 352.5-198.24h1.853c229.28-.287 415.38 185.33 415.66 414.64a415.14 415.14 0 0 1-123.25 295.68 410.2 410.2 0 0 1-132.28 88.192 415.8 415.8 0 0 1-160.13 31.785zm-1.483-759.87c-189.97.389-343.67 154.75-343.28 344.72v1.668a346.3 346.3 0 0 0 40.33 160.13l4.151 7.766v130.48h130.48l7.766 4.15a345.6 345.6 0 0 0 160.18 40.265h1.853c190.32 5.56 349.13-144.17 354.71-334.53s-144.17-349.09-334.49-354.65a335 335 0 0 0-20.22 0zm186.82 389.2c-31.689-.04-53.173-32.258-41.029-61.527 12.145-29.269 50.128-36.812 72.536-14.405a44.48 44.48 0 0 1-31.45 75.932zm-185.33 0c-31.689-.04-53.173-32.258-41.029-61.527 12.145-29.269 50.128-36.812 72.536-14.405a44.48 44.48 0 0 1-31.506 75.932zm-185.33 0c-21.213 0-39.475-14.978-43.622-35.779-4.147-20.8 6.975-41.631 26.565-49.767s42.197-1.313 54.005 16.306c11.81 17.619 9.531 41.128-5.441 56.155a44.8 44.8 0 0 1-31.507 13.085z"
      fill="#9c4043"
    />
    <path
      d="M-.116.48h153.12v34.413H38.544v82.36h110.59v34.607H38.544v83.905h118.12v34.414H-.126z"
      fill="#535353"
    />
  </svg>
);
export default SvgExtSensMsgIcon;
