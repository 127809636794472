import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import GraphPage from "./pages/GraphPage";
import ParamsPage from "./pages/ParamsPage";
import DevicesPage from "./pages/DevicesPage";
import MobitronAdminPage from "./pages/MobitronAdminPage";
import AdminPage from "./pages/AdminPage";
import LandingPage from "./pages/LandingPage";
import ProjectsPage from "./pages/ProjectsPage";
import ComparePage from "./pages/ComparePage";

const Routes = (): React.JSX.Element => {
  return (
    <Switch>
      <Route path="/" element={<LandingPage />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/graph" element={<GraphPage />} />
      <Route path="/compare" element={<ComparePage />} />
      <Route path="/params" element={<ParamsPage />} />
      <Route path="/devices" element={<DevicesPage />} />
      <Route path="/projects" element={<ProjectsPage />} />
      <Route path="/mobitronAdmin" element={<MobitronAdminPage />} />
      <Route path="/admin" element={<AdminPage />} />
    </Switch>
  );
};

export default Routes;
