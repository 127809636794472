import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  offlineNetworkStatus,
  onlineNetworkStatus,
  sessionState
} from "../state/sessionSlice";
import { BASE_URL } from "../utils/baseQuery";

const useNetworkStatus = () => {
  const dispatch = useDispatch();
  const { networkStatus } = useSelector(
    sessionState,
    (o, n) => o.networkStatus === n.networkStatus
  );
  const checkUrl = BASE_URL + "User/check";

  // Check if the user is online
  const checkInternetStatus = async () => {
    try {
      // Fetch default is to use GET method
      const response = await fetch(checkUrl);
      if (response.ok) {
        if (networkStatus !== "online") {
          dispatch(onlineNetworkStatus());
        }
      } else {
        if (networkStatus !== "offline") {
          dispatch(offlineNetworkStatus());
        }
      }
    } catch (error) {
      dispatch(offlineNetworkStatus());
    }
  };

  useEffect(() => {
    // Check every 2 minutes
    const checkInterval = setInterval(checkInternetStatus, 120000);

    window.addEventListener("online", () => {
      checkInternetStatus();
    });
    window.addEventListener("offline", () => {
      dispatch(offlineNetworkStatus());
    });

    // Cleanup on unmount
    return () => {
      clearInterval(checkInterval);
      window.removeEventListener("online", () => {
        checkInternetStatus();
      });
      window.removeEventListener("offline", () => {
        dispatch(offlineNetworkStatus());
      });
    };
  }, [checkUrl, dispatch]);
};

export default useNetworkStatus;
