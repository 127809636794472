import * as React from "react";
import type { SVGProps } from "react";
const SvgYAccIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path
      fill={props.fill === undefined ? "#b9529f" : props.fill}
      d="M618.85 804.54A37.22 37.22 0 0 1 608 778.18l1.14-659.92a37 37 0 0 1 37.24-36.93 37.4 37.4 0 0 1 37.11 37.42l-1.15 659.92a37.08 37.08 0 0 1-63.5 25.87Z"
    />
    <path
      fill="none"
      stroke={props.fill === undefined ? "#b9529f" : props.fill}
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={72}
      d="m367.15 393.02 279.39-277.07 277.65 279.98"
    />
    <path
      fill={props.fill === undefined ? "#231f20" : props.fill}
      d="M186.34 960.19v-159L63.66 572h100.22l39.74 93.31c12.1 28.23 20.16 49 29.95 74.31h1.16c8.64-24.2 17.85-46.66 29.37-74.31L303.27 572h98.49L273.89 798.34v161.85Z"
    />
  </svg>
);
export default SvgYAccIcon;
