import {
  Card,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Space,
  Tooltip,
  Typography
} from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseOutlined,
  ExportOutlined,
  FilePdfOutlined
} from "@ant-design/icons";
import { isEmpty, isNil, isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { dvaDataToCsv } from "../../helpers/dataExportHelper";
import { VMDvaData } from "../../helpers/dataModelHelper";
import {
  createDateTupleWithOffset,
  createTzDate,
  createUtcOffsetStr
} from "../../helpers/dateHelper";
import { saveAsCsv } from "../../helpers/fileHelperUniversal";
import {
  ZoomDimension,
  dvaSampleToMsTickFormater
} from "../../helpers/graphHelper";
import {
  StateZoomDomain,
  deactivateDvaBlock,
  redoDvaZoomDomain,
  removeDvaCursorPosition,
  resetDvaZoomDomain,
  selectActiveDatxFile,
  selectDashboardHeader,
  selectDvaDataForDashboard,
  selectDvaGraphTools,
  selectForDashboardRangePicker,
  selectProjectInfo,
  setDvaZoomDomain,
  switchDvaZoomMode
} from "../../state/openDatxSlice";
import {
  getUser,
  selectCsvFormat,
  selectGlobalTimezone,
  selectGlobalTimezoneToggle
} from "../../state/sessionSlice";
import { Optional } from "../../utils/utilTypes";
import { MenuButton, NormalButton } from "../Common/CommonButtons";
import MoveableCard from "../Common/MoveableCard";
import { ExtraButtonItem, emptyFunction } from "../Common/MoveableCardProps";
import { displayErrorMessage } from "../MicroComponents/GeneralUserFeedback";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import { PdfExportComponent, useExportPdfHook } from "../PrintExport/pdfExport";
import {
  TransportHeaderData,
  ItemHeaderData,
  DocumentProps
} from "../../helpers/pdf/pdfInterfaces";
import DvaGraph from "./DvaGraph";
import DvaOverviewGraph from "./DvaOverviewGraph";
import DvaScoreValues from "./DvaScoreValues";
import DvaTable from "./DvaTable";
import GraphZoomButtons from "./GraphZoomButtons";
import { size } from "../../helpers/pageHelper";
import { iconColors } from "../../constants/colors";
import { channelName } from "../../pages/ComparePage";
import DvaStatusGraph, {
  // ChannelData,
  // VectorData,
  VectorList
} from "./DvaStatusGraph";
// import { selectVectorData } from "../../state/persistantStateSlice";
import { appType } from "../../state/cargologRestApi";
const { Sider } = Layout;
const { Text } = Typography;

export interface DvaCardViewModel {
  dvaData: VMDvaData[];
  activeKey?: number;
  markedKeys: number[];
  timezone: string;
  zoomDomain?: StateZoomDomain;
  dvaZoomDimension: ZoomDimension;
  hasZoomed: boolean;
  dvaFrequency?: number;
}

const DvaGraphContent = (props: {
  fileId: string;
  width: number;
  height: number;
  zoomDimension: ZoomDimension;
  hasZoomed: boolean;
  zoomDomain: Optional<StateZoomDomain>;
  dvaDataBlock: VMDvaData;
  timezone: string;
  activeKey: number;
  vectorData?: VectorList;
}) => {
  const {
    fileId,
    width,
    height,
    zoomDimension,
    hasZoomed,
    zoomDomain,
    dvaDataBlock,
    timezone,
    activeKey,
    vectorData
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeFile = useSelector(selectActiveDatxFile);
  const isCursorPosSelected = activeFile?.scoreValuesCursorPosDvaGraph ?? false;

  const graphTools = useSelector(selectDvaGraphTools);
  const scoreValues =
    !isNil(graphTools?.dvaScoreValuesCursorPosIndex) &&
    dvaDataBlock.data[graphTools!.dvaScoreValuesCursorPosIndex];

  const user = useSelector(getUser);
  const { serialNumber } = useSelector(selectDashboardHeader(props.fileId))!;

  const { dataDomain } = useSelector(
    selectForDashboardRangePicker(props.fileId)
  );

  const entireDomain = createDateTupleWithOffset(dataDomain!, timezone);
  const utcOffsetStr = createUtcOffsetStr(timezone);

  // Height of the status graph
  const statusGraphHeight = appType === "win-dev" ? 45 : 0;
  // title: 64 + padding: 12 + border: 1
  const graphTitleHeight = 77;
  const graphContentHeight = height - graphTitleHeight - statusGraphHeight;
  const overviewGraphHeight =
    graphContentHeight > 300 ? 115 : graphContentHeight * 0.3;
  const mainGraphHeight = graphContentHeight - overviewGraphHeight;
  const scoreValueHeight = height - graphTitleHeight;
  const scoreValueWidth = 180;
  const mainGraphWidth =
    scoreValues === false ? width : width - scoreValueWidth;

  const handleZoomDomain = useCallback(
    (newDomain: StateZoomDomain) => {
      dispatch(setDvaZoomDomain({ fileId, newDomain }));
    },
    [dispatch, fileId]
  );

  /** Hook: Export the currently showing dva graph as PDF */
  const singleExport = useExportPdfHook();

  const recStartTitle = createTzDate(dvaDataBlock.start, timezone).format(
    "YYYY-MM-DD, HH:mm:ss"
  );
  const recEndTitle = createTzDate(dvaDataBlock.end, timezone).format(
    "YYYY-MM-DD, HH:mm:ss"
  );

  const projectInfo = useSelector(selectProjectInfo(props.fileId));

  // Get report start
  const reportStart = () => {
    if (isUndefined(zoomDomain)) {
      return (
        createTzDate(dvaDataBlock.start, timezone).format(
          "YYYY-MM-DD, HH:mm:ss "
        ) + utcOffsetStr
      );
    } else {
      // Adds zoom domain to report start
      return (
        createTzDate(
          dvaDataBlock.start +
            dvaSampleToMsTickFormater(zoomDomain.x[0] / 1000),
          timezone
        ).format("YYYY-MM-DD, HH:mm:ss.SSS ") + utcOffsetStr
      );
    }
  };
  // Get report end
  const reportEnd = () => {
    if (isUndefined(zoomDomain)) {
      return (
        createTzDate(dvaDataBlock.end, timezone).format(
          "YYYY-MM-DD, HH:mm:ss "
        ) + utcOffsetStr
      );
    } else {
      // Adds zoom domain to report end
      return (
        createTzDate(
          dvaDataBlock.end + dvaSampleToMsTickFormater(zoomDomain.x[1] / 1000),
          timezone
        ).format("YYYY-MM-DD, HH:mm:ss.SSS ") + utcOffsetStr
      );
    }
  };

  const documentProps: DocumentProps = {
    documentTitle: t("DetailedVibrationAnalysis"),
    fileName: "dva-graph.pdf"
  };

  const itemHeader: ItemHeaderData = {
    itemType: "DetailedVibrationAnalysis",
    itemTitle: t("DetailedVibrationAnalysis")
  };

  const transportHeader: TransportHeaderData = {
    reportType: "DetailedVibrationAnalysis",
    projectName: projectInfo.projectName ?? "",
    hasFilters: projectInfo.isFiltered,
    recStart: entireDomain[0].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    recEnd: entireDomain[1].format("YYYY-MM-DD, HH:mm:ss ") + utcOffsetStr,
    reportStart: reportStart(),
    reportEnd: reportEnd(),
    exportedBy: user,
    deviceId: serialNumber
  };

  return (
    <>
      {!isNil(activeKey) && singleExport.isExporting && (
        <PdfExportComponent
          ComponentBody={DvaGraph}
          componentProps={{
            dvaDataBlock: dvaDataBlock,
            zoomDomain: zoomDomain,
            zoomDimension: zoomDimension
          }}
          documentProps={documentProps}
          itemHeader={itemHeader}
          transportHeader={transportHeader}
          reportExportDone={singleExport.finishExport}
        />
      )}

      <Card
        styles={{
          header: {
            paddingBlock: 0,
            paddingRight: size.m1,
            paddingLeft: size.l2
          },
          body: { paddingBlock: 0, paddingInline: 0 }
        }}
        title={
          <>
            <Row
              justify="space-between"
              align="middle"
              style={{ backgroundColor: "#fff" }}
            >
              <Space>
                <Col>
                  {recStartTitle} - {recEndTitle}
                </Col>
                <Col>
                  <Text style={{ color: iconColors.xAcc }}>
                    {channelName.x}
                  </Text>
                  {", "}
                  <Text style={{ color: iconColors.yAcc }}>
                    {channelName.y}
                  </Text>
                  {", "}
                  <Text style={{ color: iconColors.zAcc }}>
                    {channelName.z}
                  </Text>
                </Col>
              </Space>

              <Space>
                <Dropdown
                  trigger={["click"]}
                  destroyPopupOnHide
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="exportPdf"
                        onClick={() => singleExport.startExport()}
                      >
                        <FilePdfOutlined /> PDF
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <MenuButton icon={<ExportOutlined />}>
                    {t("ExportGraph")}
                  </MenuButton>
                </Dropdown>

                <Tooltip
                  placement="left"
                  title={t("CloseTheViewOfTheSelectedGraph")}
                  getPopupContainer={(triggerNode: HTMLElement) =>
                    triggerNode.parentNode as HTMLElement
                  }
                >
                  <NormalButton
                    style={{
                      paddingRight: size.s2,
                      paddingLeft: size.s2
                    }}
                    onClick={() => {
                      dispatch(removeDvaCursorPosition());
                      dispatch(deactivateDvaBlock({ fileId: fileId }));
                    }}
                  >
                    <CloseOutlined style={{ fontSize: "12px" }} />
                  </NormalButton>
                </Tooltip>
              </Space>
            </Row>
          </>
        }
      >
        <Layout>
          <Layout.Content style={{ backgroundColor: "#fff" }}>
            {appType === "win-dev" && !isUndefined(vectorData) && (
              <DvaStatusGraph
                data={vectorData}
                zoomDomain={zoomDomain}
                width={mainGraphWidth}
                height={statusGraphHeight}
              />
            )}

            <GraphZoomButtons
              switchZoomMode={() =>
                dispatch(switchDvaZoomMode({ fileId: props.fileId }))
              }
              zoomOut={() =>
                dispatch(redoDvaZoomDomain({ fileId: props.fileId }))
              }
              resetZoom={() =>
                dispatch(resetDvaZoomDomain({ fileId: props.fileId }))
              }
              removeCursorPos={() => dispatch(removeDvaCursorPosition())}
              zoomDimension={zoomDimension}
              canUseZoomOut={hasZoomed}
              style={{
                position: "absolute",
                zIndex: 1000,
                right: scoreValues ? scoreValueWidth + 20 : 20,
                paddingTop: 0
              }}
              isCursorPosSelected={isCursorPosSelected}
            />

            <div style={{ height: mainGraphHeight }}>
              <DvaGraph
                width={mainGraphWidth}
                height={mainGraphHeight}
                // This works since the key is based on the index on creation
                dvaDataBlock={dvaDataBlock}
                zoomDomain={zoomDomain}
                handleZoom={handleZoomDomain}
                zoomDimension={zoomDimension}
              />
            </div>
            <div style={{ height: overviewGraphHeight }}>
              <DvaOverviewGraph
                width={mainGraphWidth}
                height={overviewGraphHeight}
                // This works since the key is based on the index on creation
                dvaDataBlock={dvaDataBlock}
                zoomDomain={zoomDomain}
                handleZoom={handleZoomDomain}
                zoomDimension={zoomDimension}
                resetZoom={() =>
                  dispatch(resetDvaZoomDomain({ fileId: props.fileId }))
                }
              />
            </div>
          </Layout.Content>
          {scoreValues && (
            <Sider
              width={scoreValueWidth}
              style={{ backgroundColor: "#fff", height: scoreValueHeight }}
            >
              <div style={{ height: scoreValueHeight }}>
                <DvaScoreValues
                  dvaDataBlock={dvaDataBlock}
                  scoreValueHeight={scoreValueHeight}
                />
              </div>
            </Sider>
          )}
        </Layout>
      </Card>
    </>
  );
};

interface IProps {
  fileId: string;
  width: number;
  height: number;
  dragHandle: string;
  closeCard: ExtraButtonItem;
}
const DvaDashboardCard: React.FC<IProps> = (props) => {
  const {
    dvaData,
    activeKey,
    timezone,
    zoomDomain,
    dvaZoomDimension,
    hasZoomed
  } = useSelector(selectDvaDataForDashboard(props.fileId));
  const { t } = useTranslation();

  // Subtract 25 to account for various differences in height
  const contentHeight = props.height - 25;

  const tableHeight = contentHeight * 0.4;
  const graphContentHeight = contentHeight - tableHeight;

  const csvFormat = useSelector(selectCsvFormat);
  const timezoneState = useSelector(selectGlobalTimezone);
  const timezoneToggle = useSelector(selectGlobalTimezoneToggle);
  // const { vectorTimespan, vectorSize } = useSelector(selectVectorData);

  const exportDvaAsCsv = () => {
    const content = dvaDataToCsv(
      dvaData,
      csvFormat,
      timezone,
      timezoneState,
      timezoneToggle,
      t
    );
    if (isEmpty(content)) {
      displayErrorMessage(t("FailedToExportDvaData"));
      return;
    }
    saveAsCsv(content);
  };

  const extraButtons: ExtraButtonItem[] = [
    {
      title: "",
      button: "exportMultiple",
      action: emptyFunction,
      csvAction: exportDvaAsCsv
    },
    {
      title: "",
      button: "close",
      action: emptyFunction,
      disabled: CheckUserRightsAccess("ADA") ? false : true
    }
  ];

  /** Returning the right number of datapoints for the set timespan */
  // const selectVectorLength = (vectorSize: number) => {
  //   if (vectorSize % 5 === 0 && vectorSize >= 5 && vectorSize <= 50) {
  //     return (vectorSize / 5) * 8;
  //   }
  //   return 8;
  // };

  // interface IDiff {
  //   start: number;
  //   end: number;
  //   diff: number;
  // }

  // const isNewValueBigger = (prev: number, newNumber: number) => {
  //   if (newNumber > prev) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // const isNewValueSmaller = (prev: number, newNumber: number) => {
  //   if (newNumber < prev) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // const vectorList: VectorList[] | undefined = dvaData.map((dvaDataBlock) => {
  //   let returnData: VectorData[] = [];
  //   let startIndex = 0;
  //   let endIndex = 0;

  //   for (let i = 0; i < dvaDataBlock.data.length; i++) {
  //     let compareGroup: {
  //       xAlarm: { index: number; value: number };
  //       yAlarm: { index: number; value: number };
  //       zAlarm: { index: number; value: number };
  //     }[] = [];

  //     // Save the next N samples in compareGroup
  //     for (let j = 0; j < selectVectorLength(vectorTimespan); j++) {
  //       if (i + j >= dvaDataBlock.data.length) {
  //         break;
  //       }
  //       compareGroup.push({
  //         xAlarm: { index: i + j, value: dvaDataBlock.data[i + j].xAlarm },
  //         yAlarm: { index: i + j, value: dvaDataBlock.data[i + j].yAlarm },
  //         zAlarm: { index: i + j, value: dvaDataBlock.data[i + j].zAlarm }
  //       });
  //     }

  //     interface channelData {
  //       index: number;
  //       value?: number;
  //     }
  //     interface channelMinMax {
  //       xMin: channelData;
  //       xMax: channelData;
  //       yMin: channelData;
  //       yMax: channelData;
  //       zMin: channelData;
  //       zMax: channelData;
  //     }
  //     let channelMinMax: channelMinMax = {
  //       xMin: { index: i, value: undefined },
  //       xMax: { index: i, value: undefined },
  //       yMin: { index: i, value: undefined },
  //       yMax: { index: i, value: undefined },
  //       zMin: { index: i, value: undefined },
  //       zMax: { index: i, value: undefined }
  //     };
  //     // Save the biggest and smallest number in each channel
  //     compareGroup.forEach((element) => {
  //       const updateMin = (
  //         channel: keyof typeof channelMinMax,
  //         value: number,
  //         index: number
  //       ) => {
  //         channelMinMax[channel].value = isUndefined(
  //           channelMinMax[channel].value
  //         )
  //           ? value
  //           : Math.min(channelMinMax[channel].value!, value);
  //         channelMinMax[channel].index = isNewValueSmaller(
  //           channelMinMax[channel].value!,
  //           value
  //         )
  //           ? index
  //           : channelMinMax[channel].index;
  //       };

  //       const updateMax = (
  //         channel: keyof typeof channelMinMax,
  //         value: number,
  //         index: number
  //       ) => {
  //         channelMinMax[channel].value = isUndefined(
  //           channelMinMax[channel].value
  //         )
  //           ? value
  //           : Math.max(channelMinMax[channel].value!, value);
  //         channelMinMax[channel].index = isNewValueBigger(
  //           channelMinMax[channel].value!,
  //           value
  //         )
  //           ? index
  //           : channelMinMax[channel].index;
  //       };

  //       updateMin("xMin", element.xAlarm.value, element.xAlarm.index);
  //       updateMax("xMax", element.xAlarm.value, element.xAlarm.index);

  //       updateMin("yMin", element.yAlarm.value, element.yAlarm.index);
  //       updateMax("yMax", element.yAlarm.value, element.yAlarm.index);

  //       updateMin("zMin", element.zAlarm.value, element.zAlarm.index);
  //       updateMax("zMax", element.zAlarm.value, element.zAlarm.index);
  //     });

  //     /** Calculate the difference-value and set start and end time for vector leap for X.
  //      * Adding + 1 to the end time to get the correct time span in table and popover.
  //      */
  //     const xDiff: IDiff =
  //       !isUndefined(channelMinMax.xMin.value) &&
  //       !isUndefined(channelMinMax.xMax.value)
  //         ? {
  //             start: compareGroup[0].xAlarm.index,
  //             end: compareGroup[compareGroup.length - 1].xAlarm.index + 1,
  //             diff: channelMinMax.xMax.value - channelMinMax.xMin.value
  //           }
  //         : {
  //             start: compareGroup[0].xAlarm.index,
  //             end: compareGroup[compareGroup.length - 1].xAlarm.index + 1,
  //             diff: 0
  //           };
  //     /** Calculate the difference-value and set start and end time for vector leap for Y
  //      * Adding + 1 to the end time to get the correct time span in table and popover.
  //      */
  //     const yDiff: IDiff =
  //       !isUndefined(channelMinMax.yMin.value) &&
  //       !isUndefined(channelMinMax.yMax.value)
  //         ? {
  //             start: compareGroup[0].yAlarm.index,
  //             end: compareGroup[compareGroup.length - 1].yAlarm.index + 1,
  //             diff: channelMinMax.yMax.value - channelMinMax.yMin.value
  //           }
  //         : {
  //             start: compareGroup[0].yAlarm.index,
  //             end: compareGroup[compareGroup.length - 1].yAlarm.index + 1,
  //             diff: 0
  //           };
  //     /** Calculate the difference-value and set start and end time for vector leap for Z
  //      * Adding + 1 to the end time to get the correct time span in table and popover.
  //      */
  //     const zDiff: IDiff =
  //       !isUndefined(channelMinMax.zMin.value) &&
  //       !isUndefined(channelMinMax.zMax.value)
  //         ? {
  //             start: compareGroup[0].zAlarm.index,
  //             end: compareGroup[compareGroup.length - 1].zAlarm.index + 1,
  //             diff: channelMinMax.zMax.value - channelMinMax.zMin.value
  //           }
  //         : {
  //             start: compareGroup[0].zAlarm.index,
  //             end: compareGroup[compareGroup.length - 1].zAlarm.index + 1,
  //             diff: 0
  //           };

  //     let vectorData: VectorData | undefined = undefined;
  //     let xData: ChannelData | undefined = undefined;
  //     let yData: ChannelData | undefined = undefined;
  //     let zData: ChannelData | undefined = undefined;
  //     let vectorChannels: string[] = [];

  //     if (xDiff.diff > vectorSize) {
  //       xData = {
  //         alarm: round(dvaDataBlock.data[i].xAlarm, 2),
  //         startEnd: [xDiff.start, xDiff.end],
  //         alarmDiff: round(xDiff.diff, 2)
  //       };
  //       vectorChannels.push("x");
  //     }

  //     if (yDiff.diff > vectorSize) {
  //       yData = {
  //         alarm: round(dvaDataBlock.data[i].yAlarm, 2),
  //         startEnd: [yDiff.start, yDiff.end],
  //         alarmDiff: round(yDiff.diff, 2)
  //       };
  //       vectorChannels.push("y");
  //     }

  //     if (zDiff.diff > vectorSize) {
  //       zData = {
  //         alarm: round(dvaDataBlock.data[i].zAlarm, 2),
  //         startEnd: [zDiff.start, zDiff.end],
  //         alarmDiff: round(zDiff.diff, 2)
  //       };
  //       vectorChannels.push("z");
  //     }

  //     // Only push to list if there is any data
  //     if (
  //       xDiff.diff > vectorSize ||
  //       yDiff.diff > vectorSize ||
  //       zDiff.diff > vectorSize
  //     ) {
  //       vectorData = {
  //         index: i,
  //         vectorChannels,
  //         xData,
  //         yData,
  //         zData
  //       };
  //     }

  //     if (i === dvaDataBlock.data.length - 1) {
  //       endIndex = i;
  //     }

  //     if (!isUndefined(vectorData)) {
  //       returnData.push(vectorData);
  //     }
  //   }

  //   return {
  //     startIndex: startIndex,
  //     endIndex: endIndex,
  //     data: returnData
  //   };
  // });

  return (
    <>
      {/* Card that will be shown in the dashboard */}
      <MoveableCard
        title={t("DetailedVibrationAnalysis")}
        dragHandle={props.dragHandle}
        closeMe={props.closeCard}
        extraButtons={extraButtons}
        fileId={props.fileId}
      >
        <DvaTable
          fileId={props.fileId}
          tableHeight={!isNil(activeKey) ? tableHeight : contentHeight}
          // vectorData={vectorList}
        />
        {!isNil(activeKey) ? (
          <>
            <DvaGraphContent
              fileId={props.fileId}
              dvaDataBlock={dvaData[activeKey]}
              activeKey={activeKey}
              width={props.width}
              height={graphContentHeight}
              zoomDimension={dvaZoomDimension}
              hasZoomed={hasZoomed}
              zoomDomain={zoomDomain}
              timezone={timezone}
              // vectorData={vectorList && vectorList[activeKey]}
            />
          </>
        ) : (
          <></>
        )}
      </MoveableCard>
    </>
  );
};

export default DvaDashboardCard;
