import { LockOutlined } from "@ant-design/icons";
import { Input, Row, Typography, notification } from "antd";
import { isUndefined } from "lodash-es";
import React, { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { clearSessionAndData } from "../../App";
import { size } from "../../helpers/pageHelper";
import {
  LoginDetails,
  appType,
  cargologRestApi,
  useRequestAuthMutation,
  useRevokeAccessTokenMutation
} from "../../state/cargologRestApi";
import { setSessionTokens } from "../../state/persistantStateSlice";
import {
  getUser,
  sessionState,
  setReauthRequired,
  setSkipCheckToken
} from "../../state/sessionSlice";
import { NormalButton, PrimaryButton } from "../Common/CommonButtons";
import StandardModal from "../Common/StandardModal";
import { openConfirmLoginModal } from "../../state/modalsSlice";
import ConfirmLoginModal, { confirmLoginOrigin } from "./ConfirmLoginModal";
const { Text } = Typography;

const iconStyle: React.CSSProperties = { color: "#BFBFBF" };

const SessionEndedModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  /** API for requesting authentication */
  const [requestAuth, requestAuthStatus] = useRequestAuthMutation();
  const { isLoading: authRequestIsLoading } = requestAuthStatus;

  /** API for signing out */
  const [revokeAccessToken] = useRevokeAccessTokenMutation();

  const { reauthRequired, networkStatus } = useSelector(sessionState);
  const { firstName, lastName, email } = useSelector(getUser);
  const [hashedRefreshToken, setHashedRefreshToken] = useState<string>();

  const [password, setPassword] = useState<string>();
  const formIsValid =
    !isUndefined(email) && !isUndefined(password) && password.length > 0;

  const handleAuthRequest = async (request: LoginDetails) => {
    const result: any = await requestAuth(request);
    if (result.data) {
      if (result.data.accessToken === null) {
        setHashedRefreshToken(result.data.refreshToken);
        dispatch(openConfirmLoginModal());
      } else {
        dispatch(setSessionTokens(result.data));
        dispatch(setReauthRequired(false));
        dispatch(setSkipCheckToken(false));
        dispatch(cargologRestApi.util.resetApiState());
      }
    } else if (result.error) {
      const errorDescription = result.error.data.title;
      notification.error({
        message: t("FailedToSignIn"),
        description: errorDescription
      });
    }
  };

  // When user clicks Sign In
  const signIn = () => {
    if (formIsValid) {
      // Typescript 4 doesn't understand that email and password are not undefined here.
      if (email === undefined || password === undefined) return;
      handleAuthRequest({ email, password, appType, newClient: true });
    }
  };

  const signOut = () => {
    revokeAccessToken(appType);
    /* Clear current user data and access tokens */
    clearSessionAndData(dispatch);
    navigate("/");
  };

  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") signIn();
  };

  const inputRef = createRef();

  return (
    <StandardModal
      open={reauthRequired && networkStatus === "online"}
      title={`${t("RenewSession")}`}
      zIndex={1045}
      closable={false}
      footer={null}
    >
      <Row>
        <Text>{t("SessionExpiredExplanation")}</Text>
      </Row>

      <Row style={{ marginTop: size.l2 }}>
        <Input.Password
          placeholder={t("Password")}
          prefix={<LockOutlined style={iconStyle} />}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          ref={inputRef as any}
          onKeyDown={onEnter}
          autoFocus
        />
      </Row>
      <Row style={{ marginBottom: size.l2 }}>
        <Text type="secondary">
          {firstName} {lastName} ({email})
        </Text>
      </Row>

      <Row justify="space-between">
        <NormalButton onClick={signOut} style={{ marginRight: size.s2 }}>
          {t("SignOut")}
        </NormalButton>
        <PrimaryButton
          loading={authRequestIsLoading}
          onClick={signIn}
          disabled={!formIsValid}
        >
          {t("SignIn")}
        </PrimaryButton>
      </Row>
      <ConfirmLoginModal
        origin={confirmLoginOrigin.reauth}
        email={email!}
        password={password!}
        hashedRefreshToken={hashedRefreshToken!}
      />
    </StandardModal>
  );
};

export default SessionEndedModal;
