import React from "react";
import { SpeedUnit } from "../../state/cargologRestApi";

/** Converting cm/s to either km/h or miles/h */
export const SpeedConverter = (cmps: number, speedUnit: SpeedUnit): string => {
  // Converting cm/s to km/h
  const kmph = cmps * 0.036;

  // Converting cm/s to miles/h
  const mph = cmps * 0.02236936;

  return speedUnit === "kmph" ? kmph.toFixed(2) : mph.toFixed(2);
};
