export const iconColors = {
  xAcc: "#70cddd",
  yAcc: "#b9529f",
  zAcc: "#8fc640",
  xAccA: "rgba(112, 205, 221, 0.7)",
  yAccA: "rgba(185, 82, 159, 0.7)",
  zAccA: "rgba(143, 198, 64, 0.7)",
  temp: "#eb2c28",
  rh: "#3953a4",
  pressure: "#7c287d",
  pressureRaw: "#574e57",
  pressureComp: "#7c287d",
  angle: "#7c287d",
  input: "#8fc640",
  inputDark: "#6f9c2f",
  output: "#70cddd",
  outputDark: "#299aae",
  timer: "#409c46"
};

export const externalTempColors = ["#b61411", "#cd1713", "#ed433f", "#f2716e"];
export const externalRhColors = ["#25366b", "#2c407e", "#405db7", "#4e6ac2"];

export const connectionColors = {
  error: "#eb2c28",
  blocked: "#eb2c28",
  sensor: "#d67548",
  schedule: "#568a43",
  powerOn: "#437e8a",
  powerOff: "#757575",
  sessionEnded: "#000000"
};

export const gpsAccuracyColors = {
  low: "#eb2c28", // red
  medium: "#FAAD14", // yellow
  high: "#568A43", // green
  default: "#757575" // grey
};

export const deviceColorsArray = [
  "#4d2600", // brown
  "#003399", // navy blue
  "#ff9966", // orange/beige
  "#666600", // dark yellow/green
  "#0099cc", // light blue
  "#2eb82e", // light green
  "#99003d", // pink
  "#ff9900", // orange
  "#6600ff", // blue/purple
  "#006666", // turquoise
  "#669900", // lighter green
  "#b33c00", // brick
  "#5c5c8a", // gray/blue
  "#996633", // light brown
  "#000000", // black
  "#660066", // purple
  "#3333ff", // blue
  "#006600", // green
  "#cc0000", // red

  "#4d2600", // brown
  "#003399", // navy blue
  "#ff9966", // orange/beige
  "#666600", // dark yellow/green
  "#0099cc", // light blue
  "#2eb82e", // light green
  "#99003d", // pink
  "#ff9900", // orange
  "#6600ff", // blue/purple
  "#006666", // turquoise
  "#669900", // lighter green
  "#b33c00", // brick
  "#5c5c8a", // gray/blue
  "#996633", // light brown
  "#000000", // black
  "#660066", // purple
  "#3333ff", // blue
  "#006600", // green
  "#cc0000", // red

  "#4d2600", // brown
  "#003399", // navy blue
  "#ff9966", // orange/beige
  "#666600", // dark yellow/green
  "#0099cc", // light blue
  "#2eb82e", // light green
  "#99003d", // pink
  "#ff9900", // orange
  "#6600ff", // blue/purple
  "#006666", // turquoise
  "#669900", // lighter green
  "#b33c00", // brick
  "#5c5c8a", // gray/blue
  "#996633", // light brown
  "#000000", // black
  "#660066", // purple
  "#3333ff", // blue
  "#006600", // green
  "#cc0000", // red

  "#4d2600", // brown
  "#003399", // navy blue
  "#ff9966", // orange/beige
  "#666600", // dark yellow/green
  "#0099cc", // light blue
  "#2eb82e", // light green
  "#99003d", // pink
  "#ff9900", // orange
  "#6600ff", // blue/purple
  "#006666", // turquoise
  "#669900", // lighter green
  "#b33c00", // brick
  "#5c5c8a", // gray/blue
  "#996633", // light brown
  "#000000", // black
  "#660066", // purple
  "#3333ff", // blue
  "#006600", // green
  "#cc0000", // red

  "#4d2600", // brown
  "#003399", // navy blue
  "#ff9966", // orange/beige
  "#666600", // dark yellow/green
  "#0099cc", // light blue
  "#2eb82e", // light green
  "#99003d", // pink
  "#ff9900", // orange
  "#6600ff", // blue/purple
  "#006666", // turquoise
  "#669900", // lighter green
  "#b33c00", // brick
  "#5c5c8a", // gray/blue
  "#996633", // light brown
  "#000000", // black
  "#660066", // purple
  "#3333ff", // blue
  "#006600", // green
  "#cc0000", // red

  "#4d2600", // brown
  "#003399", // navy blue
  "#ff9966", // orange/beige
  "#666600", // dark yellow/green
  "#0099cc", // light blue
  "#2eb82e", // light green
  "#99003d", // pink
  "#ff9900", // orange
  "#6600ff", // blue/purple
  "#006666", // turquoise
  "#669900", // lighter green
  "#b33c00", // brick
  "#5c5c8a", // gray/blue
  "#996633", // light brown
  "#000000", // black
  "#660066", // purple
  "#3333ff", // blue
  "#006600", // green
  "#cc0000" // red
];
