import React from "react";
import SystemInfoPreview from "./SystemInfoPreview";
import { useTranslation } from "react-i18next";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import { useDispatch, useSelector } from "react-redux";
import { closeSystemInfoModal, modalsState } from "../../state/modalsSlice";
import StandardModal from "../Common/StandardModal";

interface IProps {
  systemInfo: GeneralSystemInfo;
}

const SystemInfoModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();

  return (
    <>
      {/* view system info modal */}
      <StandardModal
        title={t("sysInfoPreviewTitle")}
        open={modals.isSystemInfoModalOpen}
        onCancel={() => dispatch(closeSystemInfoModal())}
        footer={null}
        width={700}
        zIndex={1048}
      >
        <SystemInfoPreview systemInfo={props.systemInfo} />
      </StandardModal>
    </>
  );
};

export default SystemInfoModal;
