import React, { CSSProperties } from "react";
import { Row, Select, Spin, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import {
  ProjectNotification,
  ProjectNotificationForCreation,
  ProjectWithUserRights,
  useCreateProjectNotificationMutation,
  useUpdateProjectNotificationMutation
} from "../../state/cargologRestApi";
import { size } from "../../helpers/pageHelper";
import { isUndefined } from "lodash-es";
import { EmailNotifications, IUserData, peopleTableData } from "./PeopleTab";
import { DangerButtonSmall } from "../Common/CommonButtons";
import { CheckProjectRightsAccess } from "../MicroComponents/LicenseAccess";
import { CloseOutlined } from "@ant-design/icons";
import { TokenData } from "../../helpers/sessionHelper";
import { insertIf } from "../../utils/generalUtils";

const { Text, Title } = Typography;
const { Option } = Select;

interface IProps {
  activePeople: peopleTableData[];
  project?: ProjectWithUserRights;
  loadingProject: boolean;
  projectEmailNotifications?: ProjectNotification[];
  user: TokenData;
  userRights: string[];
  isExport: boolean;
  tableStyle?: CSSProperties;
}
const ActivePeopleTabTable = (props: IProps) => {
  const { t } = useTranslation();
  const {
    activePeople,
    project,
    loadingProject,
    projectEmailNotifications,
    user,
    userRights,
    tableStyle,
    isExport
  } = props;

  const [createNotification] = useCreateProjectNotificationMutation();
  const [updateNotification] = useUpdateProjectNotificationMutation();

  const changeNotification = (emailNotifications: EmailNotifications) => {
    if (!isUndefined(project)) {
      const request: ProjectNotificationForCreation = {
        projectId: project.id,
        userId: emailNotifications.userId,
        type: emailNotifications.type
      };

      // Update if notification already exists
      if (
        projectEmailNotifications?.find(
          (alert) => alert.userId === emailNotifications.userId
        ) &&
        emailNotifications.notificationId
      ) {
        updateNotification({
          id: emailNotifications.notificationId,
          type: emailNotifications.type
        });
      } else {
        createNotification(request);
      }
    }
  };

  const activePeopleColumns: ColumnsType<peopleTableData> = [
    {
      title: t("Name"),
      dataIndex: "userData",
      key: "userData",
      width: "25%",
      render: (data: IUserData) => (
        <>
          {/* Adding Rows here for the PDF-export to show correctly */}
          <Title level={5} style={{ marginBlock: 0 }}>
            {data.name}
          </Title>
          {!isExport && <Text type="secondary">{data.email}</Text>}
        </>
      ),
      sorter: (a, b) =>
        a.userData.name
          .toLowerCase()
          .localeCompare(b.userData.name.toLowerCase(), "sv")
    },
    ...insertIf(isExport, {
      title: t("Email"),
      dataIndex: "userData",
      key: "userEmail",
      // width: "25%",
      render: (data: IUserData) => (
        <>
          <Text type="secondary">{data.email}</Text>
        </>
      )
    }),
    {
      title: t("Company"),
      dataIndex: "companyName",
      key: "companyName",
      width: "25%",
      render: (companyName: string) => <Text>{companyName}</Text>,
      sorter: (a, b) =>
        a.companyName
          .toLowerCase()
          .localeCompare(b.companyName.toLowerCase(), "sv")
    },
    {
      title: t("Licenses"),
      dataIndex: "licenseActive",
      key: "licenseActive",
      width: "20%",
      render: (licenseNames: string[]) =>
        licenseNames &&
        licenseNames.map((license, index) => (
          <Row key={index}>
            <Text>{license}</Text>
          </Row>
        ))
    },
    ...insertIf(!isExport, {
      title: t("Notifications"),
      dataIndex: "notifications",
      key: "notifications",
      width: "25%",
      render: (emailNotification: EmailNotifications) => {
        const hasAccess =
          CheckProjectRightsAccess(userRights, "ADK") ||
          emailNotification.userId === user.userId;

        return (
          <Row
            key={emailNotification.type}
            justify="start"
            style={{ width: "100%" }}
          >
            <Select
              size="small"
              variant="borderless"
              defaultValue={emailNotification.type ? emailNotification.type : 0}
              style={{}}
              disabled={!hasAccess}
              dropdownStyle={{ minWidth: 160 }}
              onChange={(v) => {
                changeNotification({
                  notificationId: emailNotification.notificationId,
                  userId: emailNotification.userId,
                  type: v
                });
              }}
            >
              <Option value={0}>{t("None")}</Option>
              <Option value={1}>{t("OnData")}</Option>
              <Option value={2}>{t("OnAlarm")}</Option>
            </Select>
          </Row>
        );
      }
    }),
    ...insertIf(!isExport, {
      title: t("Remove"),
      dataIndex: "action",
      key: "action",
      width: "5%",
      render: (action: { disabled: boolean; removeUser: () => void }) => (
        <>
          <Row justify="center" style={{ width: "100%" }}>
            <DangerButtonSmall
              icon={<CloseOutlined />}
              onClick={action.removeUser}
              disabled={action.disabled}
            ></DangerButtonSmall>
          </Row>
        </>
      )
    })
  ];

  return !loadingProject && activePeople && activePeople.length > 0 ? (
    <Table
      columns={activePeopleColumns}
      dataSource={activePeople}
      pagination={
        isExport ? false : { hideOnSinglePage: true, defaultPageSize: 10 }
      }
      loading={loadingProject}
      style={tableStyle ? tableStyle : {}}
    />
  ) : loadingProject === true ? (
    <Row justify={"center"} align={"middle"} style={{ height: 120 }}>
      <Spin />
    </Row>
  ) : (
    <Row justify="center" style={{ marginBottom: size.l2 }}>
      <Title type="secondary" level={5}>
        {t("NoMembersInProject")}
      </Title>
    </Row>
  );
};

export default ActivePeopleTabTable;
