import React from "react";
import { ButtonProps, Space } from "antd";
import { ZoomDimension } from "../../helpers/graphHelper";
import {
  StockOutlined,
  LineChartOutlined,
  ZoomOutOutlined,
  UndoOutlined,
  DeleteColumnOutlined
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { NormalButton } from "../Common/CommonButtons";
import { size } from "../../helpers/pageHelper";

interface ZoomButtonsProps {
  switchZoomMode?: () => void;
  zoomOut: () => void;
  resetZoom: () => void;
  removeCursorPos?: () => void;
  zoomDimension: ZoomDimension;
  canUseZoomOut: boolean;
  style?: React.CSSProperties;
  isCursorPosSelected?: boolean;
}
const GraphZoomButtons = (props: ZoomButtonsProps) => {
  const { t } = useTranslation();
  const buttonProps: ButtonProps = {
    //To avoid grey background when disabled
    style: {
      backgroundColor: "transparent",
      height: 32,
      width: 32
    }
  };

  return (
    <Space style={props.style}>
      <NormalButton
        title={t("SwitchZoomMode")}
        icon={
          props.zoomDimension === "x" ? (
            <StockOutlined />
          ) : (
            <LineChartOutlined />
          )
        }
        onClick={props.switchZoomMode}
        {...buttonProps}
        style={{
          width: 64,
          height: 32,
          whiteSpace: "break-spaces",
          paddingInline: size.s2
        }}
      >
        {props.zoomDimension === "x" ? "x".padEnd(4) : "x/y"}
      </NormalButton>
      <NormalButton
        title={t("GoToPriorZoomLevel")}
        icon={<ZoomOutOutlined />}
        onClick={props.zoomOut}
        disabled={!props.canUseZoomOut}
        {...buttonProps}
      />
      <NormalButton
        title={t("ResetZoomLevel")}
        icon={<UndoOutlined />}
        onClick={props.resetZoom}
        disabled={!props.canUseZoomOut}
        {...buttonProps}
      />
      <NormalButton
        title={t("RemoveCursorPosition")}
        icon={<DeleteColumnOutlined />}
        onClick={props.removeCursorPos}
        disabled={!props.isCursorPosSelected}
        {...buttonProps}
      />
    </Space>
  );
};

export default GraphZoomButtons;
