import React from "react";
import {
  selectExternalSensorByIndex,
  setExternalSensorIntervalDays,
  setExternalSensorIntervalHours,
  setExternalSensorIntervalMinutes,
  setExternalSensorRhHighAlarm,
  setExternalSensorRhLowAlarm,
  setExternalSensorTempHighAlarm,
  setExternalSensorTempLowAlarm
} from "../../state/openParxSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonParamsInputCard,
  CommonParamsMainContentRow,
  willFitWidth2
} from "../Common/CommonParametersComponents";
import {
  ParamsCardParagraph,
  ParamsCardSubHeader
} from "../Common/CommonFonts";
import { useTranslation } from "react-i18next";
import { Col, InputNumber, Row } from "antd";
import { isNull } from "lodash-es";
import { size } from "../../helpers/pageHelper";
import {
  TempSymbol,
  TemperatureToRoundCelsius,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";
import { selectTemperatureScale } from "../../state/sessionSlice";
import {
  maxRhValue,
  maxTempValue,
  minRhValue,
  minTempValue
} from "../../constants/params-defaults";
import { isIExternalRhTempParams } from "../../models/RecordingParameters/IExternalRhTempParams";
import { useResizeDetector } from "react-resize-detector";

interface IProps {
  index: number;
}

/** Style used for input fields */
const inputFieldStyle: React.CSSProperties = {
  margin: size.s2
};

const toInt = (n: number) => Math.round(n);

const ParamsBuilderRhTempSensor: React.FC<IProps> = (props: IProps) => {
  const { index } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width, ref } = useResizeDetector();
  const componentWidth = width ?? window.innerWidth;

  const sensor = useSelector(selectExternalSensorByIndex(index));
  const sensorConfig = isIExternalRhTempParams(sensor.params.sensorConfig)
    ? sensor.params.sensorConfig
    : { tempMin: 0, tempMax: 0, rhMin: 0, rhMax: 0 };
  const tempScale = useSelector(selectTemperatureScale);

  const lowTemp = toInt(TemperatureToScale(sensorConfig.tempMin, tempScale));

  const highTemp = toInt(TemperatureToScale(sensorConfig.tempMax, tempScale));

  const setLowAlarmTemp = (val: number | null) => {
    if (isNull(val)) return;
    let temp = TemperatureToRoundCelsius(val, tempScale);
    if (temp < minTempValue) temp = minTempValue;
    if (temp > maxTempValue) temp = maxTempValue;
    dispatch(setExternalSensorTempLowAlarm({ index, value: temp }));
  };

  const setHighAlarmTemp = (val: number | null) => {
    if (isNull(val)) return;
    let temp = TemperatureToRoundCelsius(val, tempScale);
    if (temp < minTempValue) temp = maxTempValue;
    if (temp > maxTempValue) temp = minTempValue;
    dispatch(setExternalSensorTempHighAlarm({ index, value: temp }));
  };

  const syncHighToLowTemp = () => {
    if (sensorConfig.tempMin > sensorConfig.tempMax)
      dispatch(
        setExternalSensorTempHighAlarm({
          index,
          value: sensorConfig.tempMin
        })
      );
  };

  const syncLowToHighTemp = () => {
    if (sensorConfig.tempMax < sensorConfig.tempMin)
      dispatch(
        setExternalSensorTempLowAlarm({
          index,
          value: sensorConfig.tempMax
        })
      );
  };

  const setLowAlarmRh = (val: number | null) => {
    if (isNull(val)) return;
    if (val < minRhValue) val = minRhValue;
    if (val > maxRhValue) val = maxRhValue;
    dispatch(
      setExternalSensorRhLowAlarm({
        index,
        value: val
      })
    );
  };

  const setHighAlarmRh = (val: number | null) => {
    if (isNull(val)) return;
    if (val < minRhValue) val = minRhValue;
    if (val > maxRhValue) val = maxRhValue;
    dispatch(
      setExternalSensorRhHighAlarm({
        index,
        value: val
      })
    );
  };

  const syncHighToLowRh = () => {
    if (sensorConfig.rhMax < sensorConfig.rhMin)
      dispatch(
        setExternalSensorRhHighAlarm({
          index,
          value: sensorConfig.rhMax
        })
      );
  };

  const syncLowToHighRh = () => {
    if (sensorConfig.rhMin > sensorConfig.rhMax)
      dispatch(
        setExternalSensorRhLowAlarm({
          index,
          value: sensorConfig.rhMin
        })
      );
  };

  //the max interval for a period is exacly 28 days in ms. Therefore if 28 days
  //is set, hours and minututes can't be more than 0
  const maxHours = sensor.intervalDays === 28 ? 0 : 23;
  const maxMinutes = sensor.intervalDays === 28 ? 0 : 59;

  const renderLogInterval = (wideCard: boolean) => (
    <CommonParamsInputCard
      title={t("cpTempLogIntervalTitle")}
      width={wideCard ? 2 : 1}
      height={wideCard ? 1 : 2}
    >
      <Row justify="space-between">
        <Col>
          <ParamsCardParagraph>
            {t("cpTempLogIntervalContent")}
          </ParamsCardParagraph>
        </Col>
        <div style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genDays")}</ParamsCardSubHeader>
            </Col>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genHours")}</ParamsCardSubHeader>
            </Col>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genMinutes")}</ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input-fields */}
          <Row>
            <Col span={8}>
              <InputNumber
                value={sensor.intervalDays}
                onChange={(val) =>
                  isNull(val)
                    ? {}
                    : dispatch(
                        setExternalSensorIntervalDays({
                          index,
                          value: Number(val)
                        })
                      )
                }
                min={0}
                max={28}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                value={sensor.intervalHours}
                onChange={(val) =>
                  isNull(val)
                    ? {}
                    : dispatch(
                        setExternalSensorIntervalHours({
                          index,
                          value: Number(val)
                        })
                      )
                }
                min={0}
                max={maxHours}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                value={sensor.intervalMinutes}
                onChange={(val) =>
                  isNull(val)
                    ? {}
                    : dispatch(
                        setExternalSensorIntervalMinutes({
                          index,
                          value: Number(val)
                        })
                      )
                }
                min={0}
                max={maxMinutes}
                style={{ ...inputFieldStyle, marginRight: 0 }}
              />
            </Col>
          </Row>
        </div>
      </Row>
    </CommonParamsInputCard>
  );

  const renderTempAlarmLevel = (wideCard: boolean) => (
    <CommonParamsInputCard
      title={t("cpExternalSensorTempAlarmLevelTitle")}
      width={wideCard ? 2 : 1}
      height={wideCard ? 1 : 2}
    >
      <Row justify="space-between" wrap>
        <Col flex="auto">
          <ParamsCardParagraph>
            {t("cpTempAlarmLevelContent")}
          </ParamsCardParagraph>
        </Col>
        <Col style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={12}>
              <ParamsCardSubHeader>
                {t("generalMinTitle")} (<TempSymbol />)
              </ParamsCardSubHeader>
            </Col>
            <Col span={12}>
              <ParamsCardSubHeader>
                {t("generalMaxTitle")} (<TempSymbol />)
              </ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input fields */}
          <Row>
            <Col span={12}>
              <InputNumber
                value={lowTemp}
                onChange={(val) => setLowAlarmTemp(val)}
                onBlur={() => syncHighToLowTemp()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                value={highTemp}
                onChange={(val) => setHighAlarmTemp(val)}
                onBlur={() => syncLowToHighTemp()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );

  const renderRhAlarmLevel = (wideCard: boolean) => (
    <CommonParamsInputCard
      title={t("cpExternalSensorRhAlarmLevelTitle")}
      width={wideCard ? 2 : 1}
      height={wideCard ? 1 : 2}
    >
      <Row justify="space-between" wrap>
        <Col flex="auto">
          <ParamsCardParagraph>
            {t("cpRhAlarmLevelContent")}
          </ParamsCardParagraph>
        </Col>
        <Col style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={12}>
              <ParamsCardSubHeader>{`${t("generalMinTitle")} (%)`}</ParamsCardSubHeader>
            </Col>
            <Col span={12}>
              <ParamsCardSubHeader>{`${t("generalMaxTitle")} (%)`}</ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input fields */}
          <Row>
            <Col span={12}>
              <InputNumber
                value={
                  isIExternalRhTempParams(sensorConfig) ? sensorConfig.rhMin : 0
                }
                onChange={(val) => setLowAlarmRh(val)}
                onBlur={() => syncHighToLowRh()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                value={
                  isIExternalRhTempParams(sensorConfig) ? sensorConfig.rhMax : 0
                }
                onChange={(val) => setHighAlarmRh(val)}
                onBlur={() => syncLowToHighRh()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );

  return (
    <div ref={ref}>
      <CommonParamsMainContentRow>
        <Col>{renderLogInterval(!willFitWidth2(componentWidth))}</Col>
        <Col>{renderTempAlarmLevel(!willFitWidth2(componentWidth))}</Col>
        <Col>{renderRhAlarmLevel(!willFitWidth2(componentWidth))}</Col>
      </CommonParamsMainContentRow>
    </div>
  );
};

export default ParamsBuilderRhTempSensor;
