import React from "react";
import { useSelector } from "react-redux";
import { selectSlopeCalc } from "../../state/openParxSlice";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
  VictoryLine,
  VictoryTheme
} from "victory";
import mobitronColors from "../../styles/mobitronColors";

interface chartDataType {
  x: number;
  y: number;
}

const SlopeCalculatorGraph = (): React.JSX.Element => {
  const slopeCalc = useSelector(selectSlopeCalc);
  const { temp1, temp2, pressure1, pressure2 } = slopeCalc;

  const graphWidth = 300;
  const graphHeight = 150;

  const chartData: chartDataType[] = [
    {
      x: temp1,
      y: pressure1
    },
    {
      x: temp2,
      y: pressure2
    }
  ];

  return (
    <VictoryChart
      containerComponent={<VictoryContainer responsive={false} />}
      width={graphWidth}
      height={graphHeight}
      padding={{ top: 10, bottom: 30, left: 0, right: 0 }}
      theme={VictoryTheme.material}
    >
      <VictoryAxis
        tickFormat={(t) => `${t}`}
        axisValue={0}
        tickValues={[-40, -30, -20, -10, 0, 10, 20, 30, 40]}
        theme={VictoryTheme.material}
      />

      <VictoryAxis
        dependentAxis
        fixLabelOverlap
        theme={VictoryTheme.material}
        axisLabelComponent={<VictoryLabel y={15} dx={39} textAnchor="middle" />}
        tickValues={[0, 500, 1000, 1500, 2000]}
        tickFormat={(t) => `${t}`}
      />

      <VictoryLine
        scale={{ x: "linear", y: "linear" }}
        data={chartData}
        domain={{ x: [-50, 50], y: [0, 2000] }}
        domainPadding={{ x: 5, y: 5 }}
        width={300}
        height={200}
        style={{
          data: { stroke: mobitronColors.altDarkGreen, fillOpacity: 0.4 }
        }}
      />
    </VictoryChart>
  );
};

export default SlopeCalculatorGraph;
