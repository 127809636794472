import React from "react";
import { Row, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectCreateUserPageProgress,
  selectNavigation,
  setCreateUserAccountProgress
} from "../../state/navigationSlice";

interface IProps {
  contentWidth: number;
}
const CreateAccountProgress: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isCreateUserSuccess } = useSelector(selectNavigation);

  const { createUserProgress } = useSelector(selectCreateUserPageProgress);

  const onChange = (current: number) => {
    dispatch(setCreateUserAccountProgress(current));
  };

  return (
    <Row justify="center" style={{ width: "100%" }}>
      <Steps
        direction="horizontal"
        type="default"
        size="default"
        current={createUserProgress}
        onChange={onChange}
        style={{ backgroundColor: "white", maxWidth: props.contentWidth }}
      >
        <Steps.Step disabled={isCreateUserSuccess} title={t("UserDetails")} />
        <Steps.Step
          disabled={!isCreateUserSuccess}
          title={t("CompanyDetails")}
        />
      </Steps>
    </Row>
  );
};

export default CreateAccountProgress;
