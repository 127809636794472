import React from "react";
import { UndoOutlined } from "@ant-design/icons";
import { Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import {
  CompareStateZoomDomain,
  ICompareGraph
} from "../../state/compareGraphsSlice";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { LiftedCard } from "../Common/CommonCards";
import { SmallText } from "../Common/CommonFonts";
import { GraphEmpty } from "../MicroComponents/GraphEmpty";
import DvaGraphsOnTop from "./DvaGraphsOnTop";
import PrimaryGraphsOnTop from "./PrimaryGraphsOnTop";

interface IProps {
  allOpenFiles: ICompareGraph[];
  width: number;
  height: number;
  handleSelectedDomain: (domain: CompareStateZoomDomain | undefined) => void;
}
const CompareGraphsOnTop: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  // get all open files from props
  const { allOpenFiles, width, height } = props;
  const primaryGraphs = allOpenFiles.filter(
    (file) => file.activeGraphType === "mainGraph"
  );
  const dvaGraphs = allOpenFiles.filter(
    (file) => file.activeGraphType === "dvaGraph" && file.activeDvaGraph
  );

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      {primaryGraphs.length > 0 && (
        <LiftedCard
          title={
            <Row align="middle" justify="space-between">
              <SmallText strong style={{ fontSize: 16 }}>
                {t("PrimaryGraphs")}
              </SmallText>
              <NormalButtonSmall
                icon={<UndoOutlined />}
                onClick={() => props.handleSelectedDomain(undefined)}
                title={t("ResetZoomLevel")}
              />
            </Row>
          }
          size="small"
        >
          <PrimaryGraphsOnTop
            data={primaryGraphs}
            width={width}
            height={height}
            handleSelectedDomain={props.handleSelectedDomain}
          />
        </LiftedCard>
      )}
      {dvaGraphs.length > 0 && (
        <LiftedCard
          title={
            <Row align="middle" justify="space-between">
              <SmallText strong style={{ fontSize: 16 }}>
                {t("DvaGraphs")}
              </SmallText>
              <NormalButtonSmall
                icon={<UndoOutlined />}
                onClick={() => props.handleSelectedDomain(undefined)}
                title={t("ResetZoomLevel")}
              />
            </Row>
          }
          size="small"
        >
          <DvaGraphsOnTop
            data={dvaGraphs}
            width={width}
            height={height}
            handleSelectedDomain={props.handleSelectedDomain}
          />
        </LiftedCard>
      )}
      {primaryGraphs.length === 0 && dvaGraphs.length === 0 && (
        <LiftedCard
          size="small"
          title={
            <SmallText strong style={{ fontSize: 16 }}>
              {t("CompareGraphs")}
            </SmallText>
          }
        >
          <GraphEmpty />
        </LiftedCard>
      )}
    </Space>
  );
};

export default React.memo(CompareGraphsOnTop);
