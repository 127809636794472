import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { isUndefined } from "lodash-es";
import { RootState } from "../state/store";
import {
  setForcedEndSession,
  setIsImpersonating,
  setSessionTokens
} from "../state/persistantStateSlice";
import { setReauthRequired, setSkipCheckToken } from "../state/sessionSlice";
import { clearSessionAndData } from "../App";
import { Tokens } from "../state/cargologRestApi";

const mutex = new Mutex();
export const BASE_URL = "https://mobitron1.azurewebsites.net/api/1.0/";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    if (headers.get("Content-Type") === null) {
      headers.set("Content-Type", "application/json");
    }
    // If accessToken is aquired, send it in the header
    const tokens = (getState() as RootState).persistantState?.sessionTokens;
    if (!isUndefined(tokens) && Object.keys(tokens).length > 0) {
      headers.set("Authorization", `Bearer ${tokens.accessToken}`);
    }
    return headers;
  }
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const state = api.getState() as RootState;
  let currentAction = "checkToken";
  let hasTriedRefresh = false;
  let skipQueue = false;
  let result: any = { error: { data: { title: "No request performed" } } };
  if (!state.persistantState) return result;
  if (typeof args !== "string") {
    console.log("API:", args.url);
    if (args.url === "auth/" || args.url === "Token/revoke/") {
      currentAction = "tryRequest";
      skipQueue = true;
      hasTriedRefresh = true;
    }
  }

  // wait for the mutex to unlock
  // This is not quick enough for parallel requests
  if (mutex.isLocked() && !skipQueue) {
    await mutex.waitForUnlock();
    currentAction = "tryRequest";
  }

  while (currentAction !== "return") {
    const accessTokenExpiry = state.persistantState.tokenData.exp;

    switch (currentAction) {
      case "checkToken":
        // Check if token is expired
        if (
          !isUndefined(accessTokenExpiry) &&
          accessTokenExpiry < Date.now() / 1000 &&
          state.session.skipCheckToken === false
        ) {
          currentAction = "tryRefresh";
        } else {
          currentAction = "tryRequest";
        }
        break;
      case "tryRequest":
        // Run the request
        result = await baseQuery(args, api, extraOptions);
        if (!navigator.onLine) {
          currentAction = "returnFailure";
        } else if (
          result.error &&
          (result.error.status === 401 || result.error.status === "FETCH_ERROR")
        ) {
          if (!hasTriedRefresh && !isUndefined(accessTokenExpiry)) {
            currentAction = "tryRefresh";
          } else {
            currentAction = "returnFailure";
          }
        } else if (result.error) {
          console.log("Error:", result.error);
          currentAction = "returnFailure";
        } else {
          currentAction = "returnData";
        }
        break;
      case "tryRefresh":
        if (!mutex.isLocked() && !hasTriedRefresh) {
          hasTriedRefresh = true;
          // Lock mutex
          // Refresh token
          const release = await mutex.acquire();
          try {
            const sessionTokens = state.persistantState.sessionTokens;
            const requestBody = {
              refreshToken: sessionTokens.refreshToken,
              accessToken: sessionTokens.accessToken
            };
            const requestOptions = {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(requestBody)
            };
            const refreshResult = await fetch(
              `${BASE_URL}Token/refresh/`,
              requestOptions
            );

            // if refresh was successful, retry the original request
            if (refreshResult.status === 200) {
              console.log("Token refresh was successful");
              const res: Tokens = await refreshResult.json();
              api.dispatch(setSessionTokens(res));
              api.dispatch(setReauthRequired(false));
              currentAction = "tryRequest";
            } else {
              // if isImpersonating then sign out user instead of showing SessionEndedModal
              if (state.persistantState.impersonate.isImpersonating) {
                clearSessionAndData(api.dispatch);
                api.dispatch(setIsImpersonating(false));
                api.dispatch(setForcedEndSession(true));
              } else {
                // If we are asked to reauth, we need to skip the checkToken to avoid infinite loop
                api.dispatch(setSkipCheckToken(true));
                api.dispatch(setReauthRequired(true));
              }
              currentAction = "returnFailure";
            }
          } finally {
            // release mutex
            release();
          }
        } else {
          // wait for other reauth to finish
          await mutex.waitForUnlock();
          hasTriedRefresh = true;
          currentAction = "tryRequest";
        }
        break;
      case "returnData":
        // Return data
        currentAction = "return";
        break;
      default:
        // Return failure
        currentAction = "return";
        break;
    }
  }
  return result;
};

export default baseQueryWithReauth;
