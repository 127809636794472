import React from "react";
import { Typography, Table } from "antd";
import {
  modalsState,
  closeUserRequestAdminModal
} from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash-es";
import {
  useGetUserTransferRequestsQuery,
  useReplyUserTransferRequestMutation
} from "../../state/cargologRestApi";
import { ColumnsType } from "antd/lib/table";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import StandardModal from "../Common/StandardModal";
const { Text, Title } = Typography;

/** All the fields for table used in this component */
interface ITableData {
  key: string;
  userData: IUserData;
  actions?: IRequestActions;
  created: string;
}

/** user data fields in the table */
interface IUserData {
  firstName: string;
  lastName: string;
  userId: string;
}

/** all aviable actions for a field in the table */
interface IRequestActions {
  accept: () => void;
  reject: () => void;
}

// Main component
const UserRequestAdminModal = () => {
  const modals = useSelector(modalsState);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const timezoneOffset = new Date().getTimezoneOffset();
  let tableData: ITableData[] | undefined = undefined;

  // Load users from API
  const { data: requestsData, isLoading } = useGetUserTransferRequestsQuery();

  // Reply to user transfer request. Used in accept and reject buttons.
  const [replyUserTransferRequest] = useReplyUserTransferRequestMutation();

  if (!isUndefined(requestsData)) {
    tableData = requestsData.map((request) => {
      const created = dayjs
        .utc(request.created)
        .subtract(timezoneOffset, "minutes")
        .format("YYYY-MM-DD, HH:mm");
      const row = {
        key: request.id,
        userData: {
          firstName: request.firstName,
          lastName: request.lastName,
          userId: request.userId
        },
        actions: {
          accept: () =>
            replyUserTransferRequest({
              userTransferRequestId: request.id,
              approved: true
            }),
          reject: () =>
            replyUserTransferRequest({
              userTransferRequestId: request.id,
              approved: false
            })
        },
        created: created
      };
      return row;
    });
  }

  const columns: ColumnsType<ITableData> = [
    {
      title: t("User"),
      dataIndex: "userData",
      key: "userData",
      render: (data) => (
        <>
          <Title level={5} style={{ marginBlock: 0 }}>
            {data.firstName} {data.lastName}
          </Title>
          <Text type="secondary">{data.userId}</Text>
        </>
      )
    },
    {
      title: t("Requested"),
      dataIndex: "created",
      key: "created",
      render: (created) => (
        <>
          <Text>{created}</Text>
        </>
      )
    },
    {
      title: t("tableActions"),
      dataIndex: "actions",
      key: "actions",
      render: (actions: IRequestActions) => (
        <>
          <NormalButtonSmall icon={<CheckOutlined />} onClick={actions.accept}>
            {t("AcceptUser")}
          </NormalButtonSmall>
          <NormalButtonSmall icon={<CloseOutlined />} onClick={actions.reject}>
            {t("RejectUser")}
          </NormalButtonSmall>
        </>
      )
    }
  ];

  return (
    <>
      <StandardModal
        title={t("UserRequests")}
        zIndex={1045}
        open={modals.isUserRequestAdminModalOpen}
        onCancel={() => dispatch(closeUserRequestAdminModal())}
        closable={true}
        footer={null}
        destroyOnClose={true}
        width={900}
      >
        <Table
          columns={columns}
          dataSource={tableData}
          loading={isLoading}
          style={{ width: "100%" }}
          size="small"
          pagination={{ hideOnSinglePage: true }}
        />
      </StandardModal>
    </>
  );
};

export default UserRequestAdminModal;
