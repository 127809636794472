import dayjs from "dayjs";
import { DictionaryTransKeys } from "../lib/i18n";
import { VMRecordingParameters } from "../models/ViewModelRecordingParameters/VMRecordingParameters";
import { isUndefined } from "lodash-es";

interface ITimeZone {
  timezoneStr: string;
  utcOffsetStr: string;
}

/** Helper function that returns recording start as string */
export const getStartDate = (
  recordingParameters: VMRecordingParameters,
  timezone: ITimeZone,
  t: (s: DictionaryTransKeys) => string
) => {
  const { startRecordingType, startTimestamp } = recordingParameters.RecParams;

  const { timezoneStr, utcOffsetStr } = timezone;

  switch (startRecordingType) {
    case "date":
      return startTimestamp
        ? dayjs
            .unix(startTimestamp!)

            .tz(timezoneStr)
            .format("YYYY-MM-DD, HH:mm ") + utcOffsetStr
        : t("NotSet");

    case "directly":
      return t("RecStartDownload");

    case "button":
      return t("RecStartButton");

    default:
      return t("somethingWentWrong");
  }
};

/** Helper function that returns recording end as string */
export const getEndDate = (
  recordingParameters: VMRecordingParameters,
  timezone: ITimeZone,
  t: (s: DictionaryTransKeys) => string
) => {
  const {
    startRecordingType,
    startTimestamp,
    stopTimestamp,
    stopAfterDuration
  } = recordingParameters.RecParams;

  const { timezoneStr, utcOffsetStr } = timezone;

  if (startRecordingType === "date" && startTimestamp) {
    return stopTimestamp
      ? dayjs
          .unix(stopTimestamp!)
          .tz(timezoneStr)
          .format("YYYY-MM-DD, HH:mm ") + utcOffsetStr
      : t("NotSet");
  } else {
    return `${t("After")} ${stopAfterDuration.days}d ${stopAfterDuration.hours}h`;
  }
};

/** Helper function that converts timestamp for DT_running_status 8th bit to a date string */
export const getRecordingStart = (
  timestampForRecStart: number | undefined,
  timezone: ITimeZone,
  t: (s: DictionaryTransKeys) => string
): string => {
  const { timezoneStr, utcOffsetStr } = timezone;

  if (timestampForRecStart) {
    return (
      dayjs
        .unix(timestampForRecStart)
        .tz(timezoneStr)
        .format("YYYY-MM-DD, HH:mm ") + utcOffsetStr
    );
  } else {
    return t("NoRecordStartFound");
  }
};

/** Helper function that converts timestamp for DT_running_status 9th bit to a date string */
export const getRecordingEnd = (
  timestampForRecEnd: number | undefined,
  timezone: ITimeZone,
  t: (s: DictionaryTransKeys) => string
): string => {
  const { timezoneStr, utcOffsetStr } = timezone;

  if (timestampForRecEnd) {
    return (
      dayjs
        .unix(timestampForRecEnd)
        .tz(timezoneStr)
        .format("YYYY-MM-DD, HH:mm ") + utcOffsetStr
    );
  } else {
    return t("NoRecordEndFound");
  }
};

/** Helper function that returns a date-string if there is a `startTimestamp`, otherwise returns the start method */
export const getScheduledToStart = (
  recordingParameters: VMRecordingParameters,
  timezone: ITimeZone,
  t: (s: DictionaryTransKeys) => string
): string => {
  const { timezoneStr, utcOffsetStr } = timezone;
  const { startTimestamp, startRecordingType } = recordingParameters.RecParams;

  if (startTimestamp) {
    return (
      dayjs.unix(startTimestamp).tz(timezoneStr).format("YYYY-MM-DD, HH:mm ") +
      utcOffsetStr
    );
  } else {
    switch (startRecordingType) {
      case "date":
        return t("NotSet");
      case "directly":
        return t("RecStartDownload");
      case "button":
        return t("RecStartButton");
      default:
        return t("somethingWentWrong");
    }
  }
};

/** Helper function that returns an object with `time` and `duration` properties.
 *
 * If `startRecordingType` is `date`, returns the `stopTimestamp` as `time` and `undefined` as `duration`.
 *
 * If `startRecordingType` is not `date`, calculates the `time` by adding the `stopAfterDuration` to the `startTimestamp` and the `stopAfterDuration` as `duration`.
 * If `paramsBuilder` is `true`, returns `undefined` as `time` and the `stopAfterDuration` as `duration`. This is only used in the ParamsBuilderContent.tsx.
 *
 * If `startTimestamp` is not defined, returns `undefined` as `time` and the `stopAfterDuration` as `duration`.
 */
export const getScheduledToEnd = (
  recordingParameters: VMRecordingParameters,
  timezone: ITimeZone,
  t: (s: DictionaryTransKeys) => string,
  startTimestamp?: number | undefined,
  paramsBuilder?: boolean
): { time: string | undefined; duration: string | undefined } => {
  const { timezoneStr, utcOffsetStr } = timezone;
  const { startRecordingType, stopTimestamp, stopAfterDuration } =
    recordingParameters.RecParams;

  if (startRecordingType === "date" && startTimestamp) {
    return stopTimestamp
      ? {
          time: `${dayjs.unix(stopTimestamp).tz(timezoneStr).format("YYYY-MM-DD, HH:mm ")} ${utcOffsetStr}`,
          duration: undefined
        }
      : { time: t("NotSet"), duration: undefined };
  }

  if (
    (startRecordingType === "directly" || startRecordingType === "button") &&
    !isUndefined(startTimestamp)
  ) {
    if (paramsBuilder) {
      return {
        time: undefined,
        duration: `${t("After")} ${stopAfterDuration.days}d ${stopAfterDuration.hours}`
      };
    }

    const recEnd =
      startTimestamp.toString().length === 13 // If the timestamp is in epoch milliseconds, convert it to epoch seconds.
        ? startTimestamp / 1000
        : startTimestamp +
          stopAfterDuration.days * 24 * 60 * 60 +
          stopAfterDuration.hours * 60 * 60;

    return {
      time: `${dayjs.unix(recEnd).tz(timezoneStr).format("YYYY-MM-DD, HH:mm ")} ${utcOffsetStr}`,
      duration: `${t("After")} ${stopAfterDuration.days}d ${stopAfterDuration.hours}h`
    };
  }

  return {
    time: undefined,
    duration: `${t("After")} ${stopAfterDuration.days}d ${stopAfterDuration.hours}h`
  };
};

/** Helper functions that returns the record start method. */
export const getStartMethod = (
  recordingParameters: VMRecordingParameters,
  t: (s: DictionaryTransKeys) => string
): string => {
  const method = recordingParameters.RecParams.startRecordingType;

  switch (method) {
    case "date":
      return t("RecStartDate");
    case "directly":
      return t("RecStartDownload");
    case "button":
      return t("RecStartButton");
    default:
      return t("somethingWentWrong");
  }
};
