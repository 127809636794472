import React from "react";
import { Form, Input } from "antd";
import { closeFilePasswordModal, modalsState } from "../../state/modalsSlice";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../Common/CommonButtons";
import { useState } from "react";
import { unlockDatxFile } from "../../state/openDatxSlice";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { unlockCompareGraphFile } from "../../state/compareGraphsSlice";
import { selectImpersonate } from "../../state/persistantStateSlice";
import StandardModal from "../Common/StandardModal";

type Props = {
  fileId: string | undefined;
  page: "reports" | "compare";
};

const FilePasswordModal = (props: Props) => {
  const modals = useSelector(modalsState);
  const { isImpersonating } = useSelector(selectImpersonate);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Form state
  const [password, setPassword] = useState<string>();

  // When user klicks Unlock
  const formComplete = () => {
    if (!isUndefined(password)) {
      if (props.page === "reports" && !isUndefined(props.fileId)) {
        dispatch(
          unlockDatxFile({ fileId: props.fileId, password, isImpersonating })
        );
      }
      // Using slice state instead of props to get the right fileId
      if (props.page === "compare" && !isUndefined(modals.passwordFileId)) {
        dispatch(
          unlockCompareGraphFile({
            fileId: modals.passwordFileId,
            password,
            isImpersonating
          })
        );
      }
      dispatch(closeFilePasswordModal());
    }
  };

  // When user klicks Unlock but form is incomplete
  const formIncomplete = () => {};

  return (
    <>
      <StandardModal
        title={t("UnlockFile")}
        open={modals.isFilePasswordModalOpen}
        onCancel={() => dispatch(closeFilePasswordModal())}
        closable={true}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={formComplete}
          onFinishFailed={formIncomplete}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <Form.Item
            label={t("Password")}
            name="password"
            rules={[
              {
                required: true,
                message: t("PasswordIsRequiredToUnlockThisFile")
              }
            ]}
          >
            <Input.Password
              placeholder=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoFocus
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <PrimaryButton block style={{ height: "40px" }} htmlType="submit">
              {t("UnlockFile")}
            </PrimaryButton>
          </Form.Item>
        </Form>
      </StandardModal>
    </>
  );
};

export default FilePasswordModal;
