import React, { useState } from "react";
import {
  DeleteOutlined,
  CheckCircleOutlined,
  SearchOutlined,
  CreditCardOutlined,
  FileDoneOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Row, Col, Space, Typography, Input, Tooltip, Table } from "antd";
import { LiftedCard } from "../Common/CommonCards";
import { DangerButtonSmall, NormalButtonSmall } from "../Common/CommonButtons";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { isNull, isUndefined } from "lodash-es";
import {
  useMobitronGetCompaniesQuery,
  useMobitronGetOrdersQuery,
  User,
  useSetOrderAsPaidMutation
} from "../../state/cargologRestApi";
import dayjs from "dayjs";
import OrderDetailsModal from "../Modals/OrderDetailsModal";
import RemoveOrderModal from "../Modals/RemoveOrderModal";
import { useDispatch, useSelector } from "react-redux";
import {
  openOrderDetailsModal,
  setInvoiceId,
  openRemoveOrderModal,
  setOrderToRemove
} from "../../state/modalsSlice";
import { fullNameString } from "../DevicesPage/MyDevicesList";
import { selectImpersonate } from "../../state/persistantStateSlice";
import {
  impersonatingHeight143,
  impersonatingHeight320,
  size
} from "../../helpers/pageHelper";

const { Text, Link } = Typography;

/** All the fields for table used in this component */
interface ITableData {
  key: string;
  user: User;
  nr: string;
  paymentType: number;
  orderPrice: {
    price: number;
    currency: string;
  };
  status: string;
  created: string;
  expires: string;
  paidInFull: string;
  company: string;
  actions?: IOrderActions;
}

/** all aviable actions for a field in the table */
interface IOrderActions {
  markpaid: () => void;
  remove: () => void;
  status: string;
  paymentType: number;
}

const MobitronAdminOrders = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isImpersonating } = useSelector(selectImpersonate);
  const [stringValue, setStringValue] = useState("");
  const [setOrderAsPaid] = useSetOrderAsPaidMutation();
  const { data: orderData, isLoading: orderIsLoading } =
    useMobitronGetOrdersQuery();
  const { data: allCompanies, isLoading: companiesIsLoading } =
    useMobitronGetCompaniesQuery();

  const tableData: ITableData[] = orderData
    ? orderData
        .filter((e) => {
          return (
            stringValue === "" ||
            e.id.toString().includes(stringValue.toLowerCase()) ||
            e.priceTotal.toString().includes(stringValue.toLowerCase()) ||
            e.expires?.toLowerCase().includes(stringValue.toLowerCase()) ||
            e.created.toLowerCase().includes(stringValue.toLowerCase())
          );
        })
        .sort((a, b) => {
          return ("" + b.invoiceNumber).localeCompare(a.invoiceNumber);
        })
        .map((order) => {
          const company = allCompanies?.filter(
            (company) => company.id === order.companyId
          )[0];
          const companyName = company?.name ?? "";
          const timezoneOffset = new Date().getTimezoneOffset();
          const created = dayjs
            .utc(order.created)
            .subtract(timezoneOffset, "minutes")
            .format("YYYY-MM-DD, HH:mm");
          const expires = dayjs
            .utc(order.expires)
            .subtract(timezoneOffset, "minutes")
            .format("YYYY-MM-DD, HH:mm");
          const paidInFull = order.paid
            ? dayjs
                .utc(order.paid)
                .subtract(timezoneOffset, "minutes")
                .format("YYYY-MM-DD, HH:mm")
            : "";
          const status = !isNull(order.paid) ? t("paid") : t("pending");
          const row = {
            key: order.id,
            user: order.user,
            company: companyName,
            nr: order.invoiceNumber,
            paymentType: order.paymentTypeId,
            orderPrice: {
              price: order.priceTotal,
              currency: order.currency
            },
            status: status,
            created: created,
            expires: expires,
            paidInFull: paidInFull,
            actions: {
              markpaid: () => setOrderAsPaid({ orderId: order.id, paid: true }),
              remove: () => {
                dispatch(setOrderToRemove(order));
                dispatch(openRemoveOrderModal());
              },
              status: status,
              paymentType: order.paymentTypeId
            }
          };
          return row;
        })
    : [];

  const columns: ColumnsType<ITableData> = [
    {
      title: "",
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      render: (user: User) => {
        return (
          <Tooltip
            placement="right"
            title={
              <>
                <Row>{fullNameString(user.firstName, user.lastName)}</Row>
                <Row>{user.email}</Row>
              </>
            }
          >
            <UserOutlined />
          </Tooltip>
        );
      }
    },
    {
      title: t("Company"),
      dataIndex: "company",
      key: "company",
      ellipsis: true,
      sorter: (a, b) => {
        return ("" + a.company).localeCompare(b.company);
      },
      render: (company) => <Text strong>{company}</Text>
    },
    {
      title: t("tableNr"),
      dataIndex: "nr",
      key: "nr",
      width: 80,
      ellipsis: true,
      sorter: (a, b) => {
        return ("" + a.nr).localeCompare(b.nr);
      },
      render: (nr) => (
        <Tooltip placement="right" title={t("ClickForDetails")}>
          <Link
            onClick={() => {
              dispatch(setInvoiceId(nr));
              dispatch(openOrderDetailsModal());
            }}
          >
            {nr}
          </Link>
        </Tooltip>
      )
    },
    {
      title: t("PaymentType"),
      dataIndex: "paymentType",
      key: "paymentType",
      align: "left",
      ellipsis: true,
      sorter: (a, b) => a.paymentType - b.paymentType,
      render: (data) => {
        if (data === 1) {
          return (
            <Text>
              <FileDoneOutlined /> {t("Invoice")}
            </Text>
          );
        }
        if (data === 2) {
          return (
            <Text>
              <CreditCardOutlined /> {t("CreditCard")}
            </Text>
          );
        }
      }
    },
    {
      title: t("tableAmount"),
      dataIndex: "orderPrice",
      key: "orderPrice",
      align: "right",
      ellipsis: true,
      sorter: (a, b) => a.orderPrice.price - b.orderPrice.price,
      render: (data) => (
        <>
          <Text strong>{data.price}</Text> <Text>{data.currency}</Text>
        </>
      )
    },
    {
      title: t("tableStatus"),
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      sorter: (a, b) => {
        return ("" + a.status).localeCompare(b.status);
      },
      render: (status) => {
        let statusColor = "#000";
        if (status === "paid") {
          statusColor = "green";
        }
        return (
          <Text style={{ color: statusColor, textTransform: "capitalize" }}>
            {status}
          </Text>
        );
      }
    },
    {
      title: t("tableInvoiceDate"),
      dataIndex: "created",
      key: "created",
      ellipsis: true,
      sorter: (a, b) => {
        return ("" + a.created).localeCompare(b.created);
      }
    },
    {
      title: t("tablePaidOn"),
      dataIndex: "paidInFull",
      key: "paidInFull",
      ellipsis: true,
      sorter: (a, b) => {
        return ("" + a.paidInFull).localeCompare(b.paidInFull);
      }
    },
    {
      title: t("tableActions"),
      dataIndex: "actions",
      key: "actions",
      ellipsis: true,
      render: (actions: IOrderActions) => {
        if (!isUndefined(actions)) {
          const isDisabled =
            actions.paymentType === 2 || actions.status === "paid";
          return (
            <>
              <Row>
                <NormalButtonSmall
                  disabled={isDisabled}
                  icon={<CheckCircleOutlined />}
                  onClick={actions.markpaid}
                >
                  {t("tableMarkAsPaid")}
                </NormalButtonSmall>
              </Row>
              <Row style={{ paddingTop: size.s1 }}>
                <DangerButtonSmall
                  disabled={isDisabled}
                  icon={<DeleteOutlined />}
                  onClick={actions.remove}
                >
                  {t("genDelete")}
                </DangerButtonSmall>
              </Row>
            </>
          );
        }
        return null;
      }
    }
  ];

  return (
    <>
      <LiftedCard
        style={{
          margin: size.m1,
          height: impersonatingHeight143(isImpersonating)
        }}
      >
        <Row justify="space-between" style={{ marginBottom: size.l2 }}>
          <Col>
            <Space>
              <LargeHeaderTitle>{t("AllInvoices")}</LargeHeaderTitle>
            </Space>
          </Col>
          <Col>
            <Input
              placeholder={t("FilterInvoices")}
              prefix={<SearchOutlined />}
              value={stringValue}
              onChange={(e) => setStringValue(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Table
            columns={columns}
            dataSource={tableData}
            scroll={{
              x: "calc(100vw - 300px)",
              y: impersonatingHeight320(isImpersonating)
            }}
            style={{ width: "100%" }}
            tableLayout="auto"
            size="small"
            loading={orderIsLoading || companiesIsLoading}
            pagination={{
              defaultPageSize: 20,
              hideOnSinglePage: true,
              showSizeChanger: true
            }}
          />
        </Row>
      </LiftedCard>
      <OrderDetailsModal />
      <RemoveOrderModal />
    </>
  );
};

export default MobitronAdminOrders;
