import {
  BlockOutlined,
  CloseOutlined,
  LockOutlined,
  RollbackOutlined,
  UndoOutlined,
  VerticalAlignBottomOutlined
} from "@ant-design/icons";
import {
  Col,
  Collapse,
  DatePicker,
  InputNumber,
  Layout,
  Row,
  Select,
  Slider,
  Space,
  Spin
} from "antd";
import { isNull } from "lodash-es";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import {
  NormalButton,
  NormalButtonSmall
} from "../components/Common/CommonButtons";
import { LiftedCard, NormalHeaderCard } from "../components/Common/CommonCards";
import { SmallText } from "../components/Common/CommonFonts";
import CompareGraphSideMenu from "../components/CompareGraphs/CompareGraphSideMenu";
import CompareGraphsCombined from "../components/CompareGraphs/CompareGraphsCombined";
import CompareGraphsInline from "../components/CompareGraphs/CompareGraphsInline";
import CompareGraphsOnTop from "../components/CompareGraphs/CompareGraphsOnTop";
import FilePasswordModal from "../components/GraphPage/FilePasswordModal";
import DataFilterToggle from "../components/MicroComponents/DataFilterToggle";
import { CheckUserRightsAccess } from "../components/MicroComponents/LicenseAccess";
import { iconColors } from "../constants/colors";
import { VMDvaData } from "../helpers/dataModelHelper";
import { isSmallScreen, sideMenuWidth, size } from "../helpers/pageHelper";
import {
  PressureIcon,
  PressureRawIcon,
  RhIcon,
  TempIcon,
  XAccIcon,
  YAccIcon,
  ZAccIcon
} from "../icons";
import { DictionaryTransKeys } from "../lib/i18n";
import {
  ChannelTypes,
  CompareStateZoomDomain,
  Comparison,
  GraphTypes,
  ICompareGraph,
  closeFile,
  duplicateFile,
  resetStart,
  selectAllOpenCompareGraphFiles,
  selectComparisonType,
  selectOpenFileStatus,
  setActiveDvaGraph,
  setChannelTypes,
  setComparisonType,
  setDvaFrequency,
  setGlobalZoomDomain,
  setGraphType,
  setOffset,
  setStart
} from "../state/compareGraphsSlice";
import { openFilePasswordModal, setPasswordFileId } from "../state/modalsSlice";
import commonColors from "../styles/commonColors";
const { Option } = Select;
const { Panel } = Collapse;

export const channelName = {
  x: "Acc X",
  y: "Acc Y",
  z: "Acc Z",
  temp: "Temp",
  rh: "Humid",
  pressureRaw: "Pressure Raw",
  pressureComp: "Pressure Comp"
};

const InputFreq: React.FC<{ row: ICompareGraph }> = (props) => {
  const dispatch = useDispatch();
  const setFrequency = (id: string, value: number) => {
    dispatch(setDvaFrequency({ fileId: id, frequency: value }));
  };
  return (
    <InputNumber
      defaultValue={props.row.dvaFrequency ?? 1000}
      onChange={(v) => (isNull(v) ? {} : setFrequency(props.row.id, v))}
    />
  );
};

const InputMs: React.FC<{ row: ICompareGraph }> = (props) => {
  const dispatch = useDispatch();
  const setMs = (id: string, value: number) => {
    dispatch(setOffset({ id: id, offset: value }));
  };
  const marks = {
    "-1000": "-1000ms",
    "0": "0ms",
    "1000": "1000ms"
  };

  const offset = props.row.offsetMs ?? 0;
  return (
    <Space>
      <div
        style={{
          width: 100,
          marginLeft: 20,
          marginRight: 20
        }}
      >
        <Slider
          marks={marks}
          min={-1000}
          max={1000}
          included={false}
          onChange={(v: number) => setMs(props.row.id, v)}
          value={offset}
        />
      </div>
      <InputNumber
        value={offset}
        min={-1000}
        max={1000}
        onChange={(v) => (isNull(v) ? {} : setMs(props.row.id, v))}
      />
    </Space>
  );
};

const SelectChannels: React.FC<{ row: ICompareGraph }> = (props) => {
  const recParams = props.row.compareGraphContent.recordingParameters;
  const isAcc = recParams.AccParams.useAcc;
  const isRh = recParams.RhParams.useRh;
  const isTemp = recParams.TempParams.useTemp;
  const isPressure = recParams.PressureParams.usePressure;
  const isDvaGraph = props.row.activeGraphType === "dvaGraph";
  const activeChannels = props.row.activeChannels;

  const dispatch = useDispatch();
  const setChannels = (id: string, channels: ChannelTypes[]) => {
    dispatch(setChannelTypes({ id, channelType: channels }));
  };

  const toggleFilter = (channel: ChannelTypes) => {
    if (activeChannels.includes(channel)) {
      setChannels(
        props.row.id,
        activeChannels.filter((c) => c !== channel)
      );
    } else {
      setChannels(props.row.id, [...activeChannels, channel]);
    }
  };

  // todo: hide buttons if no data in channel?
  return (
    <Space>
      <DataFilterToggle
        icon={XAccIcon}
        state={{
          isActive: activeChannels.includes("x"),
          isUsed: isAcc
        }}
        onClick={() => toggleFilter("x")}
      />
      <DataFilterToggle
        icon={YAccIcon}
        state={{
          isActive: activeChannels.includes("y"),
          isUsed: isAcc
        }}
        onClick={() => toggleFilter("y")}
      />
      <DataFilterToggle
        icon={ZAccIcon}
        state={{
          isActive: activeChannels.includes("z"),
          isUsed: isAcc
        }}
        onClick={() => toggleFilter("z")}
      />
      <DataFilterToggle
        icon={TempIcon}
        state={{
          isActive: activeChannels.includes("temp"),
          isUsed: isTemp && !isDvaGraph
        }}
        onClick={() => toggleFilter("temp")}
      />
      <DataFilterToggle
        icon={RhIcon}
        state={{
          isActive: activeChannels.includes("rh"),
          isUsed: isRh && !isDvaGraph
        }}
        onClick={() => toggleFilter("rh")}
      />
      <DataFilterToggle
        icon={PressureRawIcon}
        state={{
          isActive: activeChannels.includes("pressureRaw"),
          isUsed: isPressure && !isDvaGraph
        }}
        onClick={() => toggleFilter("pressureRaw")}
      />
      <DataFilterToggle
        icon={PressureIcon}
        state={{
          isActive: activeChannels.includes("pressureComp"),
          isUsed: isPressure && !isDvaGraph
        }}
        onClick={() => toggleFilter("pressureComp")}
      />
    </Space>
  );
};

const ComparePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width, ref } = useResizeDetector();
  const windowWidth = window.innerWidth ?? width;

  const comparisonType = useSelector(selectComparisonType());
  const openFiles = useSelector(selectAllOpenCompareGraphFiles);
  const openFileStatus = useSelector(selectOpenFileStatus);

  const changeComparison = (comparison: Comparison) => {
    dispatch(setComparisonType({ comparison }));
  };

  const changeGraphType = (id: string, graphType: GraphTypes) => {
    dispatch(setGraphType({ id, graphType }));
  };

  const changeDvaGraph = (id: string, key?: any) => {
    handleSelectedGlobalDomain(undefined);
    dispatch(setActiveDvaGraph({ fileId: id, blockKey: key }));
  };

  const setTime = (id: string, time: number | undefined) => {
    if (time) dispatch(setStart({ id, time: time }));
    else dispatch(resetStart({ id }));
  };

  const removeGraphRow = (key: any) => {
    dispatch(closeFile(key));
  };

  const duplicateGraphRow = (key: any) => {
    dispatch(duplicateFile(key));
  };

  const handleSelectedGlobalDomain = (
    domain: CompareStateZoomDomain | undefined
  ) => {
    dispatch(setGlobalZoomDomain({ selectedDomain: domain }));
  };

  const graphWidth = window.innerWidth - 205;

  const graphRow = (row: ICompareGraph, isMobitron: boolean, index: number) => {
    const dvaGraphs = row.compareGraphContent.dvaData;
    const isUnlocked = row.isUnlocked;

    // remove temp, rh and pressure from active channels if dvaGraph is selected
    if (row.activeGraphType === "dvaGraph") {
      const channelTypes = row.activeChannels;
      if (
        channelTypes.includes("temp") ||
        channelTypes.includes("rh") ||
        channelTypes.includes("pressureRaw") ||
        channelTypes.includes("pressureComp")
      ) {
        const newChannels = channelTypes.filter(
          (c) =>
            c !== "temp" &&
            c !== "rh" &&
            c !== "pressureRaw" &&
            c !== "pressureComp"
        );
        dispatch(setChannelTypes({ id: row.id, channelType: newChannels }));
      }
    }

    const colors = {
      x: iconColors.xAcc,
      y: iconColors.yAcc,
      z: iconColors.zAcc,
      temp: iconColors.temp,
      rh: iconColors.rh,
      pressureRaw: iconColors.pressureRaw,
      pressureComp: iconColors.pressureComp
    };

    return (
      <Collapse defaultActiveKey={row.id} key={index}>
        <Panel
          header={
            <Row justify="space-between" style={{ width: "100%" }}>
              <div>
                <SmallText type={!isUnlocked ? "secondary" : undefined} strong>
                  {row.filePath + " "}
                </SmallText>
                {isUnlocked ? (
                  <>
                    <SmallText strong> | </SmallText>
                    <SmallText> {t("Channels")}: </SmallText>
                    {row.activeChannels?.map((type, index) => {
                      return (
                        <React.Fragment key={index}>
                          <SmallText
                            style={{
                              color: isUnlocked
                                ? colors[type]
                                : commonColors.disabledText
                            }}
                          >
                            {channelName[type]}
                          </SmallText>
                          {index + 1 < row.activeChannels.length ? ", " : ""}
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <SmallText></SmallText>
                )}
              </div>
              <div>
                <Space>
                  <NormalButtonSmall
                    icon={<BlockOutlined />}
                    onClick={() => {
                      duplicateGraphRow(row.id);
                    }}
                    title={t("DuplicateFile")}
                    disabled={!isUnlocked}
                  >
                    {t("Duplicate")}
                  </NormalButtonSmall>
                  <NormalButtonSmall
                    icon={<CloseOutlined />}
                    onClick={() => {
                      removeGraphRow(row.id);
                    }}
                    title={t("RemoveFromComparison")}
                  >
                    {t("Close")}
                  </NormalButtonSmall>
                </Space>
              </div>
            </Row>
          }
          key={row.id}
        >
          {isUnlocked ? (
            <Row key={row.id} justify="start" gutter={size.m1} align="middle">
              {/* Kanske även en drag-handle för sortering av graferna*/}
              <Col>
                <Select
                  placeholder={t("GraphType")}
                  value={
                    row.activeGraphType ? row.activeGraphType : "mainGraph"
                  }
                  onChange={(value: GraphTypes) =>
                    changeGraphType(row.id, value)
                  }
                  style={{ minWidth: "150px" }}
                >
                  <Option value="mainGraph">{t("MainGraph")}</Option>
                  <Option value="dvaGraph" disabled={dvaGraphs.length < 1}>
                    {t("DVAGraph")}
                  </Option>
                </Select>
              </Col>
              {row.activeGraphType === "dvaGraph" && dvaGraphs.length > 0 && (
                <Col>
                  <Select
                    placeholder={t("SelectDVAGraph")}
                    value={row.activeDvaGraph}
                    onSelect={(v, option) =>
                      changeDvaGraph(row.id, option.value)
                    }
                    style={{ minWidth: "150px" }}
                  >
                    {dvaGraphs.map((dvaGraph: VMDvaData, index) => (
                      <Option
                        value={`${dvaGraph.start}${dvaGraph.end}`}
                        key={index}
                      >
                        {dayjs.unix(dvaGraph.start).format("HH:mm:ss")} -{" "}
                        {dayjs.unix(dvaGraph.end).format("HH:mm:ss")}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
              <Col>
                <SelectChannels row={row} />
              </Col>
              {(comparisonType === "combined" || comparisonType === "ontop") &&
                isMobitron &&
                row.activeGraphType === "dvaGraph" && (
                  <Col>
                    <Space>
                      <SmallText>{t("DVAFrequency")}:</SmallText>
                      <InputFreq row={row} />
                    </Space>
                  </Col>
                )}

              {(comparisonType === "combined" ||
                comparisonType === "ontop") && (
                <>
                  <Col>
                    <Space>
                      <SmallText>{t("StartGraphAt")}:</SmallText>
                      <DatePicker
                        showTime
                        allowClear={false}
                        onChange={(e) => setTime(row.id, e?.unix())}
                        defaultValue={
                          row.startTime
                            ? dayjs.unix(row.startTime)
                            : row.dataDomain
                              ? dayjs.unix(row.dataDomain[0])
                              : undefined
                        }
                      />
                      {row.startTime && (
                        <NormalButtonSmall
                          icon={<RollbackOutlined />}
                          onClick={() => dispatch(resetStart({ id: row.id }))}
                        >
                          {t("Reset")}
                        </NormalButtonSmall>
                      )}
                    </Space>
                  </Col>
                  <Col>
                    <Space>
                      <SmallText>{t("OffsetInMs")}:</SmallText>
                      <InputMs row={row} />
                    </Space>
                  </Col>
                </>
              )}
            </Row>
          ) : (
            // If file is password protected
            <Row justify="center">
              <Col>
                <Row justify="center">
                  <LockOutlined
                    style={{
                      fontSize: "48px",
                      color: commonColors.disabledText
                    }}
                  />
                </Row>
                <Row justify="center">
                  <p>{t("TheSelectedFileIsPasswordProtected")}</p>
                </Row>
                <Row justify="center">
                  <NormalButton
                    id={row.id}
                    key={row.id}
                    onClick={(e) => {
                      dispatch(setPasswordFileId(e.currentTarget.id));
                      dispatch(openFilePasswordModal());
                    }}
                  >
                    {t("UnlockFile")}
                  </NormalButton>
                </Row>
              </Col>
              <FilePasswordModal fileId={undefined} page="compare" />
            </Row>
          )}
        </Panel>
      </Collapse>
    );
  };

  /** Primary styling used for header */
  const stickyElementStyle: React.CSSProperties = {
    position: "sticky",
    zIndex: 1,
    width: "100%",
    backgroundColor: "white",
    margin: 0,
    padding: 0
  };

  const isMobitron = CheckUserRightsAccess("MOB");

  const passwordProtectedGraph = (title: DictionaryTransKeys) => (
    <LiftedCard
      title={
        <Row align="middle">
          <SmallText strong style={{ fontSize: 16 }}>
            {t(title)}
          </SmallText>
        </Row>
      }
      styles={{ body: { padding: size.l2 } }}
      size="small"
    >
      <Row justify="center">
        <LockOutlined
          style={{ fontSize: "48px", color: commonColors.disabledText }}
        />
      </Row>
      <Row justify="center">
        <SmallText>
          {t("OneOrMoreSelectedGraphsArePasswordProtected")}
        </SmallText>
      </Row>
      <Row justify="center">
        <SmallText strong>{t("PleaseUnlockOrCloseTheFileToSee")}</SmallText>
      </Row>
    </LiftedCard>
  );

  const contentWidth = isSmallScreen(windowWidth)
    ? "100%"
    : windowWidth - sideMenuWidth;
  return (
    <div
      style={{ width: "100%", height: "100%", overflowY: "scroll" }}
      ref={ref}
    >
      <Row wrap={false} style={{ height: "inherit" }}>
        <CompareGraphSideMenu
          isExpanded={!isSmallScreen(windowWidth)}
          isFloating={isSmallScreen(windowWidth)}
        />

        <Layout
          style={{
            width: contentWidth,
            maxWidth: "100%",
            paddingLeft: isSmallScreen(windowWidth) ? size.l2 : 0
          }}
        >
          <Layout.Header
            style={{
              ...stickyElementStyle,
              top: 0,
              height: "auto"
            }}
          >
            <NormalHeaderCard
              size="default"
              styles={{
                header: { height: "100%" },
                body: { height: "auto", padding: size.m1 }
              }}
              style={{ borderTop: 0 }}
            >
              <Collapse defaultActiveKey={["1"]}>
                <Panel
                  header={
                    <div style={{ textAlign: "start" }}>
                      {`${t("Comparing")} ${openFiles.length} ${openFiles.length === 1 ? ` ${t("graph")}` : ` ${t("graphs")}`}.`}
                      {(openFileStatus === "parsing" ||
                        openFileStatus === "rendering") && <Spin />}
                    </div>
                  }
                  extra={
                    <div
                      onClick={(event) => {
                        // Prevents clicks to trigger collapse
                        event.stopPropagation();
                      }}
                    >
                      <SmallText>{t("TypeOfComparison")}: </SmallText>
                      <Space>
                        <Select
                          size="small"
                          style={{ minWidth: "150px" }}
                          defaultValue="inline"
                          onChange={changeComparison}
                        >
                          <Option value="inline">{t("AboveAndBelow")}</Option>
                          <Option value="ontop">{t("OnTop")}</Option>
                          {isMobitron && (
                            <Option value="combined">{t("Combined")}</Option>
                          )}
                        </Select>

                        {(comparisonType === "ontop" ||
                          comparisonType === "combined") &&
                          openFiles.length > 1 && (
                            <NormalButtonSmall
                              icon={<VerticalAlignBottomOutlined rotate={90} />}
                              // style={{ marginLeft: size.m1 }}
                              onClick={() => {
                                // Sync all graphs by setting their time to current time
                                const now = dayjs().unix();
                                openFiles.forEach((row) => {
                                  setTime(row.id, now);
                                });
                              }}
                              title={t("SyncAllGraphs")}
                            >
                              {t("SyncGraphs")}
                            </NormalButtonSmall>
                          )}
                      </Space>
                    </div>
                  }
                  key="1"
                >
                  {/** If graphs list contains at least one graph */}
                  {openFiles.length > 0 ? (
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{ width: "100%" }}
                    >
                      {openFiles.map((rowData: ICompareGraph, index) =>
                        graphRow(rowData, isMobitron, index)
                      )}
                    </Space>
                  ) : (
                    <></>
                  )}
                </Panel>
              </Collapse>
            </NormalHeaderCard>
          </Layout.Header>
          <Layout.Content
            style={{
              padding: size.m1,
              paddingTop: 0,
              textAlign: "left"
            }}
          >
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
              {comparisonType === "inline" &&
                openFiles.map((graph) => {
                  // Password protected/locked file
                  if (!graph.isUnlocked) {
                    return (
                      <LiftedCard
                        title={
                          <SmallText
                            strong
                            type={!graph.isUnlocked ? "secondary" : undefined}
                            style={{ fontSize: 16 }}
                          >
                            {graph.filePath}
                          </SmallText>
                        }
                        styles={{ body: { padding: size.m1 } }}
                        size="small"
                        key={graph.id}
                      >
                        <Row justify="center" style={{ padding: size.m1 }}>
                          <LockOutlined
                            style={{
                              fontSize: "48px",
                              color: commonColors.disabledText
                            }}
                          />
                        </Row>
                      </LiftedCard>
                    );
                  } else {
                    // Unlocked file
                    return (
                      <CompareGraphsInline
                        fileId={graph.id}
                        filePath={graph.filePath}
                        graphTypes={
                          graph.activeGraphType
                            ? graph.activeGraphType
                            : "mainGraph"
                        }
                        channelTypes={graph.activeChannels}
                        width={graphWidth}
                        height={150}
                        key={graph.id}
                        startTimestamp={
                          graph.startTime
                            ? graph.startTime
                            : graph.dataDomain?.[0] ?? undefined
                        }
                      />
                    );
                  }
                })}

              {openFiles.length > 0 &&
                !openFiles.find((file) => file.isUnlocked === false) &&
                comparisonType === "ontop" && (
                  <CompareGraphsOnTop
                    allOpenFiles={openFiles}
                    width={graphWidth}
                    height={300}
                    handleSelectedDomain={handleSelectedGlobalDomain}
                  />
                )}

              {openFiles.length > 0 &&
                !openFiles.find((file) => file.isUnlocked === false) &&
                comparisonType === "combined" && (
                  <LiftedCard
                    title={
                      <Row align="middle" justify="space-between">
                        <SmallText strong style={{ fontSize: 16 }}>
                          {t("DVAAndPrimaryGraphs")}
                        </SmallText>
                        <NormalButtonSmall
                          icon={<UndoOutlined />}
                          onClick={() => handleSelectedGlobalDomain(undefined)}
                          title={t("ResetZoomLevel")}
                        />
                      </Row>
                    }
                    size="small"
                  >
                    <CompareGraphsCombined
                      allOpenFiles={openFiles}
                      width={graphWidth}
                      height={300}
                      handleSelectedDomain={handleSelectedGlobalDomain}
                    />
                  </LiftedCard>
                )}

              {/* Password protected COMBINED */}
              {openFiles.find((file) => file.isUnlocked === false) &&
                openFiles.length > 0 &&
                comparisonType === "combined" &&
                passwordProtectedGraph("DVAAndPrimaryGraphs")}

              {/* Password protected ONTOP */}
              {openFiles.find((file) => file.isUnlocked === false) &&
                openFiles.length > 0 &&
                comparisonType === "ontop" &&
                passwordProtectedGraph("PrimaryGraphs")}

              {openFiles.length === 0 && (
                <LiftedCard
                  title={
                    <SmallText strong style={{ fontSize: 16 }}>
                      {t("NoGraphsToCompare")}
                    </SmallText>
                  }
                  size="small"
                  styles={{ body: { padding: size.l2 } }}
                >
                  {/** If no graphs are open */}
                  <SmallText>
                    {t("SelectTheGraphsYouWantToCompareFromTheSideMenu")}
                  </SmallText>
                </LiftedCard>
              )}
            </Space>
          </Layout.Content>
        </Layout>
      </Row>
    </div>
  );
};

export default ComparePage;
