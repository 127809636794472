import React from "react";
import { VictoryGroup, VictoryLine } from "victory";
import { iconColors } from "../../constants/colors";
import { IDvaDataPoint, IMetaData } from "../../helpers/compareGraphsHelper";

export const getDvaContent = (
  dvaData: IDvaDataPoint[],
  metaData: IMetaData,
  index: number
) => (
  <VictoryGroup key={index}>
    {/* x-axis data */}
    {metaData.activeChannels.includes("x") && (
      <VictoryLine
        name="dvaXAlarm"
        data={dvaData}
        y="xAlarm"
        x="time"
        style={{
          data: {
            stroke: iconColors.xAcc,
            strokeWidth: 2
          }
        }}
      />
    )}
    {/* y-axis data */}
    {metaData.activeChannels.includes("y") && (
      <VictoryLine
        name="dvaYAlarm"
        data={dvaData}
        y="yAlarm"
        x="time"
        style={{
          data: {
            stroke: iconColors.yAcc,
            strokeWidth: 2
          }
        }}
      />
    )}
    {/* z-axis data */}
    {metaData.activeChannels.includes("z") && (
      <VictoryLine
        name="dvaZAlarm"
        data={dvaData}
        y="zAlarm"
        x="time"
        style={{
          data: {
            stroke: iconColors.zAcc,
            strokeWidth: 2
          }
        }}
      />
    )}
  </VictoryGroup>
);
