import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeConfirmLoginModal, modalsState } from "../../state/modalsSlice";
import StandardModal from "../Common/StandardModal";
import { notification, Typography } from "antd";
import {
  appType,
  cargologRestApi,
  ConfirmLoginDetails,
  useConfirmLoginMutation,
  useGetCompanyDetailsQuery
} from "../../state/cargologRestApi";
import { useTranslation } from "react-i18next";
import {
  setSessionTokens,
  setValidSetup
} from "../../state/persistantStateSlice";
import { parseAccessToken } from "../../helpers/sessionHelper";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  setInitalizeSwitcher,
  setReauthRequired,
  setSkipCheckToken
} from "../../state/sessionSlice";

const { Text } = Typography;

export enum confirmLoginOrigin {
  login = 0,
  reauth = 1
}
interface IProps {
  origin: number;
  email: string;
  password: string;
  hashedRefreshToken: string;
  resetLoginForm?: () => void;
}

const ConfirmLoginModal = (props: IProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isConfirmLoginModalOpen } = useSelector(modalsState);
  const [confirmLogin, confirmStatus] = useConfirmLoginMutation();
  const { isLoading: confirmIsLoading } = confirmStatus;

  const [companyId, setCompanyId] = useState<string>();
  const { data: companyData } = useGetCompanyDetailsQuery(
    companyId ? { companyId: companyId } : skipToken
  );

  //If we have companyData we redirect to the appLayout, only used when we are coming from the LoginPage.tsx
  if (companyData) {
    dispatch(setInitalizeSwitcher("appLayout"));
    dispatch(setValidSetup(true));
  }

  const handleConfirmLogin = async (request: ConfirmLoginDetails) => {
    const result: any = await confirmLogin(request);

    if (result.data) {
      //If we are coming from LoginPage.tsx
      if (confirmLoginOrigin.login === props.origin) {
        dispatch(setSessionTokens(result.data));
        const tokenData = parseAccessToken(result.data.accessToken);
        setCompanyId(tokenData.companyId);
      }
      //If we are coming from SessionEndedModal.tsx
      if (confirmLoginOrigin.reauth === props.origin) {
        dispatch(setSessionTokens(result.data));
        dispatch(setReauthRequired(false));
        dispatch(setSkipCheckToken(false));
        dispatch(cargologRestApi.util.resetApiState());
      }
      dispatch(closeConfirmLoginModal());
    } else if (result.error) {
      if (result.error.status === 400) {
        // The refresh token is invalid - either because a third client tried to login or the first client has logged out
        notification.error({
          message: t("FailedToSignIn"),
          description: t("errorConfirmLoginText")
        });
        if (props.resetLoginForm) {
          props.resetLoginForm(); // Call back to reset the login form
        }
      } else {
        notification.error({
          message: t("FailedToSignIn"),
          description: result.error.data.title
        });
      }
      dispatch(closeConfirmLoginModal());
    }
  };

  return (
    <StandardModal
      title={"Confirm Login"}
      open={isConfirmLoginModalOpen}
      onCancel={() => {
        if (props.resetLoginForm) {
          props.resetLoginForm();
        }
        dispatch(closeConfirmLoginModal());
      }}
      onOk={() => {
        handleConfirmLogin({
          email: props.email,
          password: props.password,
          appType: appType,
          hashedRefreshToken: props.hashedRefreshToken,
          newClient: true
        });
      }}
      zIndex={1046}
      closable={true}
      className={"no-move-window"}
      confirmLoading={confirmIsLoading}
      centered={props.origin === confirmLoginOrigin.login}
    >
      <Text>{t("confirmLoginText")}</Text>
    </StandardModal>
  );
};

export default ConfirmLoginModal;
