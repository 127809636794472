import * as React from "react";
import type { SVGProps } from "react";
const SvgFatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="64 64 896 896"
    fill="currentColor"
    {...props}
  >
    <path d="m666.19 139.75-308.38.004-218.06 218.06.004 308.38 218.06 218.06 308.38-.004 218.06-218.05-.004-308.38-10.545-10.545zm-29.824 72.002 175.88 175.88.002 248.73-175.88 175.88-248.73.002-175.88-175.88-.002-248.73 175.88-175.88z" />
    <path d="M421 339.53c-44.524 0-81.492 36.968-81.492 81.492v181.98c0 44.524 36.968 81.492 81.492 81.492h181.98c44.524 0 81.492-36.968 81.492-81.492v-181.98c0-44.524-36.968-81.492-81.492-81.492zm0 72h181.98c5.881 0 9.492 3.61 9.492 9.492v181.98c0 5.882-3.61 9.492-9.492 9.492H421c-5.881 0-9.492-3.61-9.492-9.492v-181.98c0-5.881 3.61-9.492 9.492-9.492" />
  </svg>
);
export default SvgFatIcon;
