import * as React from "react";
import type { SVGProps } from "react";
const SvgBluetoothIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 -6 23 28"
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="m14.341 12.03 4.343 4.343-5.656 5.656h-2v-6.686l-4.364 4.364-1.415-1.414 5.779-5.778v-.97l-5.779-5.78 1.415-1.414 4.364 4.364V2.029h2l5.656 5.657zm-1.313 1.514v5.657l2.828-2.828zm0-3.03 2.828-2.828-2.828-2.828v5.657z" />
  </svg>
);
export default SvgBluetoothIcon;
