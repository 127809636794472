import React from "react";
import { Card, Row, Col, Select } from "antd";
import { size } from "../../helpers/pageHelper";
import { SmallText } from "../Common/CommonFonts";
import { useDispatch, useSelector } from "react-redux";
import { selectMyCurrency, setCurrency } from "../../state/sessionSlice";
import { isUndefined } from "lodash-es";
import { setCartState } from "../../state/licenseStoreSlice";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const CurrencyCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  /** Get default currency and set to USD if undefined (first run) */
  const persistantCurrency = useSelector(selectMyCurrency);
  if (isUndefined(persistantCurrency)) {
    dispatch(setCurrency("USD"));
  }
  const selectAndButtonWidth = 190;
  return (
    <Card style={{ marginBottom: size.m1 }}>
      <Row style={{ marginBottom: size.m1 }}>
        <SmallText strong>{t("Currency")}</SmallText>
      </Row>
      <Row align="middle" style={{ paddingLeft: size.m1 }}>
        <Col span={12}>
          <SmallText>{t("SelectYourCurrency")}:</SmallText>
        </Col>
        <Col>
          <Select
            style={{ minWidth: selectAndButtonWidth, textAlign: "center" }}
            defaultValue={persistantCurrency}
            onChange={(currencyValue) => {
              dispatch(setCartState([]));
              dispatch(setCurrency(currencyValue));
            }}
            // Keeps Dropdown in place when scrolling
            getPopupContainer={(triggerNode: HTMLElement) =>
              triggerNode.parentNode as HTMLElement
            }
          >
            <Option value="SEK">SEK</Option>
            <Option value="EUR">EUR</Option>
            <Option value="USD">USD</Option>
          </Select>
        </Col>
      </Row>
    </Card>
  );
};

export default CurrencyCard;
