import { VMDvaData } from "./dataModelHelper";
import { createFormatedLocalDateFromUnix, createTzDate } from "./dateHelper";
import { isEmpty } from "lodash-es";
import { dvaSampleToMsTickFormater } from "./graphHelper";
import {
  MinMaxRecordingDataBlocks,
  RecordingDataBlockUnique,
  isMinMaxType
} from "./datasetHelper";
import { Optional, ValueOf } from "../utils/utilTypes";
import { AccData, AngleData } from "../state/openDatxSlice";
import { AccHistogramData } from "../components/GraphPage/AccHistogramGraph";
import { timezoneSelector } from "./timezoneSelector";
import { DictionaryTransKeys } from "../lib/i18n";
import { DeviceData } from "../components/DevicesPage/DeviceMap";
import dayjs, { Dayjs } from "dayjs";

//todo: not tested from here yet. dublett from openDatxSlice
/**
 * Transforms recording data to a string in csv format. If data is empty, an
 * empty string will be returned.
 * @param data
 */
export const datxToCsv = (
  data: RecordingDataBlockUnique[],
  csvFormat: string,
  timezone: string,
  timezoneState: string | undefined,
  timezoneToggle: boolean,
  t: (s: DictionaryTransKeys) => string
): string => {
  if (isEmpty(data)) {
    return "";
  }

  // If global timezone is selected use it.
  // Otherwise use active file timezone.
  const curTimezone = timezoneSelector(timezone, timezoneState, timezoneToggle);

  /** Cell separator in swedish or brittish form */
  const sep = csvFormat === "swe" ? ";" : ",";

  const utcOffset = dayjs().tz(curTimezone).utcOffset() / 60;

  const utcOffsetStr = utcOffset >= 0 ? `UTC+${utcOffset}` : `UTC${utcOffset}`;

  const csvHeader = `${t("Timestamp")} (${utcOffsetStr})${sep}Acc X${sep}${t("dur")} X${sep}Acc Y${sep}${t("dur")} Y${sep}Acc Z${sep}${t("dur")} Z${sep}Temp${sep}Rh${sep}${t("Angle")} X${sep}${t("Angle")} Y${sep}${t("Angle")} Z${sep}ESTI${sep}Ext Temp Value${sep}Ext RH Value${sep}${t("BtnPress")}\n`;

  /**
   * Returns true if all cols equals empty string
   * @param cols Array of future csv-cols except timestamp
   */
  const hasNoValuesToShow = (cols: string[]) =>
    cols.filter((x) => x !== "").length === 0;

  return data.reduce((arr: string, curr) => {
    const timestamp = dayjs
      .unix(curr.timestamp)
      .tz(curTimezone)
      .format("YYYY-MM-DD HH:mm:ss");
    const accX = floatToLocalizedString(curr?.xAcc?.[0], csvFormat);
    const accXDurr = floatToLocalizedString(curr?.xAcc?.[1], csvFormat);
    const accY = floatToLocalizedString(curr?.yAcc?.[0], csvFormat);
    const accYDurr = floatToLocalizedString(curr?.yAcc?.[1], csvFormat);
    const accZ = floatToLocalizedString(curr?.zAcc?.[0], csvFormat);
    const accZDurr = floatToLocalizedString(curr?.zAcc?.[1], csvFormat);
    const temp = floatToLocalizedString(curr?.temp, csvFormat);
    const rh = floatToLocalizedString(curr?.rh, csvFormat);
    const angleX = floatToLocalizedString(curr?.angle?.[0], csvFormat);
    const angleY = floatToLocalizedString(curr?.angle?.[1], csvFormat);
    const angleZ = floatToLocalizedString(curr?.angle?.[2], csvFormat);
    // External Sensors
    // If external temp sensor is found RH will exist and have the same value
    const esti = curr?.externalTemp
      ? curr?.externalTemp?.sensorId.toString(16)
      : "";
    // External Temp
    const extTempValue = floatToLocalizedString(
      curr?.externalTemp?.temp,
      csvFormat
    );
    // External RH
    const extRhValue = floatToLocalizedString(curr?.externalRh?.rh, csvFormat);
    const btnPress = curr?.runningStatus?.includes("btnPress")
      ? t("BtnPress")
      : "";

    if (
      hasNoValuesToShow([
        accX,
        accXDurr,
        accY,
        accYDurr,
        accZ,
        accZDurr,
        temp,
        rh,
        angleX,
        angleY,
        angleZ,
        esti,
        extTempValue,
        extRhValue,
        btnPress
      ])
    ) {
      return arr;
    }

    return arr.concat(
      `${timestamp}${sep}${accX}${sep}${accXDurr}${sep}${accY}${sep}${accYDurr}${sep}${accZ}${sep}${accZDurr}${sep}${temp}${sep}${rh}${sep}${angleX}${sep}${angleY}${sep}${angleZ}${sep}${esti}${sep}${extTempValue}${sep}${extRhValue}${sep}${btnPress}\n`
    );
  }, csvHeader);
};

/**
 * Transforms dva data to a string in csv-format that can be saved and opened in
 * excel. If dva data is empty, and empty string will be returned
 * @param dvaData
 */
export const dvaDataToCsv = (
  dvaData: VMDvaData[],
  csvFormat: string,
  timezone: string,
  timezoneState: string | undefined,
  timezoneToggle: boolean,
  t: (s: DictionaryTransKeys) => string
): string => {
  if (isEmpty(dvaData)) {
    return "";
  }

  /** Cell separator in swedish or brittish form */
  const separator = csvFormat === "swe" ? ";" : ",";

  /** Creates a csv row */
  const createRow = (
    title: string,
    sample: string,
    xAcc: string,
    yAcc: string,
    zAcc: string,
    sep: string
  ) => `${title}${sep}${sample}${sep}${xAcc}${sep}${yAcc}${sep}${zAcc}\n`;

  /** Creates a csv title row */
  const createTitleRow = (title: string) =>
    createRow(
      title,
      `${t("sample")} (ms)`,
      "x-acc (g)",
      "y-acc (g)",
      "z-acc (g)",
      separator
    );

  /** Creates a data csv row */
  const createDataRow = (
    sample: number,
    xAcc: number,
    yAcc: number,
    zAcc: number
  ) =>
    createRow(
      "",
      floatToLocalizedString(sample, csvFormat),
      floatToLocalizedString(xAcc, csvFormat),
      floatToLocalizedString(yAcc, csvFormat),
      floatToLocalizedString(zAcc, csvFormat),
      separator
    );

  const curTimezone = timezoneSelector(timezone, timezoneState, timezoneToggle);

  /** String that will be mutated in every iteration and returned at the
   * end of the function */
  let responseString = "";

  dvaData.forEach((item) => {
    const { start, data, end } = item;

    const eventDescription = createTitleRow(
      `${createFormatedLocalDateFromUnix(
        start,
        curTimezone
      )} - ${createFormatedLocalDateFromUnix(end, curTimezone)}`
    );

    // Event name rows

    // Content in the format: timestamp, xAcc, yAcc, zAcc

    const dataContent = data.reduce(
      (res, dvaData, index) =>
        res +
        createDataRow(
          dvaSampleToMsTickFormater(index),
          dvaData.xAlarm,
          dvaData.yAlarm,
          dvaData.zAlarm
        ),
      ""
    );

    // Append result to result string

    responseString += eventDescription;
    responseString += dataContent;
    responseString += "\n";
  });

  return responseString;
};

export const minMaxDataToCsv = (
  data: MinMaxRecordingDataBlocks,
  csvFormat: string,
  timezone: string,
  timezoneState: string | undefined,
  timezoneToggle: boolean,
  t: (s: DictionaryTransKeys) => string
): string => {
  const values = Object.values(data).reduce(
    (
      resp: RecordingDataBlockUnique[],
      item: ValueOf<MinMaxRecordingDataBlocks>
    ) => {
      // Check if item is MinMaxType
      if (isMinMaxType(item)) {
        resp.push(item.min);
        resp.push(item.max);
      }
      // Check if item is Array
      else if (Array.isArray(item)) {
        item.forEach((minMaxItem) => {
          if (isMinMaxType(minMaxItem)) {
            resp.push(minMaxItem.min);
            resp.push(minMaxItem.max);
          }
        });
      }
      return resp;
    },
    []
  );

  // Check for duplicates and sort data by timestamp
  const uniqueValues = values
    .filter(
      (
        value: RecordingDataBlockUnique,
        index: number,
        self: RecordingDataBlockUnique[]
      ) => {
        const firstIndex = self.findIndex(
          (item) => item.timestamp === value.timestamp
        );
        return firstIndex === index;
      }
    )
    .sort(
      (a: RecordingDataBlockUnique, b: RecordingDataBlockUnique) =>
        a.timestamp - b.timestamp
    );

  return datxToCsv(
    uniqueValues,
    csvFormat,
    timezone,
    timezoneState,
    timezoneToggle,
    t
  );
};

export const angleDataToCsv = (
  data: AngleData[],
  csvFormat: string,
  timezone: string,
  t: (s: DictionaryTransKeys) => string
): string => {
  /** Cell separator in swedish or brittish form */
  const separator = csvFormat === "swe" ? ";" : ",";

  /** Creates a csv row */
  const createRow = (
    timestamp: string,
    xAngle: string,
    yAngle: string,
    zAngle: string,
    sep: string
  ) => `${timestamp}${sep}${xAngle}${sep}${yAngle}${sep}${zAngle}\n`;

  const createTitleRow = () =>
    createRow(
      `${t("Timestamp")} ${utcOffsetAsStr()}`,
      `${t("RollAngle")} (degrees)`,
      `${t("NickAngle")} (degrees)`,
      `${t("YawAngle")} (degrees)`,
      separator
    );

  const createDataRow = (d: AngleData, timezone: string) => {
    const timestamp = createTzDate(new Date(d.timestamp).getTime(), timezone);
    return createRow(
      timestampAsString(timestamp),
      floatToLocalizedString(d.xAngle, csvFormat),
      floatToLocalizedString(d.yAngle, csvFormat),
      floatToLocalizedString(d.zAngle, csvFormat),
      separator
    );
  };

  const titleRow = createTitleRow();

  return data.reduce(
    (resp, item) => resp + createDataRow(item, timezone),
    titleRow
  );
};

export const accDataToCsv = (
  data: AccData[],
  csvFormat: string,
  t: (s: DictionaryTransKeys) => string
): string => {
  /** Cell separator in swedish or brittish form */
  const separator = csvFormat === "swe" ? ";" : ",";

  /** Creates a csv row */
  const createRow = (
    timestamp: string,
    xAcc: string,
    xDur: string,
    yAcc: string,
    yDur: string,
    zAcc: string,
    zDur: string,
    sep: string
  ) =>
    `${timestamp}${sep}${xAcc}${sep}${xDur}${sep}${yAcc}${sep}${yDur}${sep}${zAcc}${sep}${zDur}\n`;

  const createTitleRow = () =>
    createRow(
      `${t("Timestamp")} ${utcOffsetAsStr()}`,
      "x-acceleration (g)",
      `x-${t("Duration")} (ms)`,
      "y-acceleration (g)",
      `y-${t("Duration")} (ms)`,
      "z-acceleration (g)",
      `z-${t("Duration")} (ms)`,
      separator
    );

  const createDataRow = (d: AccData) => {
    const timestamp = createTzDate(d.timestamp, d.timezone);
    return createRow(
      timestampAsString(timestamp),
      floatToLocalizedString(d.xAcc[0], csvFormat),
      floatToLocalizedString(d.xAcc[1], csvFormat),
      floatToLocalizedString(d.yAcc[0], csvFormat),
      floatToLocalizedString(d.yAcc[1], csvFormat),
      floatToLocalizedString(d.zAcc[0], csvFormat),
      floatToLocalizedString(d.zAcc[1], csvFormat),
      separator
    );
  };

  const titleRow = createTitleRow();

  return data.reduce((resp, item) => resp + createDataRow(item), titleRow);
};

export const accHistogramDataToCsv = (
  data: AccHistogramData[],
  csvFormat: string,
  t: (s: DictionaryTransKeys) => string
): string => {
  /** Cell separator in swedish or brittish form */
  const separator = csvFormat === "swe" ? ";" : ",";

  /** Creates a csv row */
  const createRow = (
    gSpan: string,
    xAmount: string,
    yAmount: string,
    zAmount: string,
    sep: string
  ) => `${gSpan}${sep}${xAmount}${sep}${yAmount}${sep}${zAmount}\n`;

  const createTitleRow = () =>
    createRow(
      `${t("shockSpan")} (g)`,
      `x-accelerations (${t("amount")})`,
      `y-accelerations (${t("amount")})`,
      `z-accelerations (${t("amount")})`,
      separator
    );

  const createDataRow = (d: AccHistogramData) =>
    createRow(
      //If this value is sent as a raw string without "g" postfix, excel
      //transforms the cell to a date
      `${d.x.x}g`,
      floatToLocalizedString(d.x.y, csvFormat),
      floatToLocalizedString(d.y.y, csvFormat),
      floatToLocalizedString(d.z.y, csvFormat),
      separator
    );

  const titleRow = createTitleRow();

  return data.reduce((resp, item) => resp + createDataRow(item), titleRow);
};

/**
 * Returns a string based on num using "," or "." as a seperator
 * depending on csvFormat. E.g. 4.23 => "4,23"
 * If num is undefined, an empty string wil be returned
 * @param num
 */
export const floatToLocalizedString = (
  num: Optional<number>,
  csvFormat: string
): string => {
  if (csvFormat === "swe") {
    return num?.toFixed(2).replace(".", ",") ?? "";
  } else {
    return num?.toFixed(2) ?? "";
  }
};

const timestampAsString = (d: Dayjs) => d.format("YYYY-MM-DD HH:mm:ss");

const utcOffsetAsStr = () => {
  const utcOffset = dayjs().utcOffset() / 60;
  const utcOffsetStr = utcOffset >= 0 ? `UTC+${utcOffset}` : `UTC${utcOffset}`;

  return utcOffsetStr;
};

// special development parsers
export const datxRawToCsv = (
  data: RecordingDataBlockUnique[],
  csvFormat: string,
  t: (s: DictionaryTransKeys) => string
): string => {
  const utcOffset = dayjs().utcOffset() / 60;
  if (isEmpty(data)) {
    return "";
  }

  /** Cell separator in swedish or brittish form */
  const sep = csvFormat === "swe" ? ";" : ",";

  const utcOffsetStr = utcOffset >= 0 ? `UTC+${utcOffset}` : `UTC${utcOffset}`;

  const csvHeader = `${t("Timestamp")} (${utcOffsetStr})${sep}Acc X(raw)${sep}${t("dur")} X${sep}Acc Y(raw)${sep}${t("dur")} Y${sep}Acc Z(raw)${sep}${t("dur")} Z${sep}Temp${sep}Rh${sep}${t("Angle")} X${sep}${t("Angle")} Y${sep}${t("Angle")} Z${sep}${t("BtnPress")}\n`;

  /**
   * Returns true if all cols equals empty string
   * @param cols Array of future csv-cols except timestamp
   */
  const hasNoValuesToShow = (cols: string[]) =>
    cols.filter((x) => x !== "").length === 0;

  return data.reduce((arr: string, curr) => {
    const timestamp = dayjs.unix(curr.timestamp).format("YYYY-MM-DD HH:mm:ss");
    const accX = floatToLocalizedString(curr?.xAcc?.[0], csvFormat);
    const accXDurr = floatToLocalizedString(curr?.xAcc?.[1], csvFormat);
    const accY = floatToLocalizedString(curr?.yAcc?.[0], csvFormat);
    const accYDurr = floatToLocalizedString(curr?.yAcc?.[1], csvFormat);
    const accZ = floatToLocalizedString(curr?.zAcc?.[0], csvFormat);
    const accZDurr = floatToLocalizedString(curr?.zAcc?.[1], csvFormat);
    const temp = floatToLocalizedString(curr?.temp, csvFormat);
    const rh = floatToLocalizedString(curr?.rh, csvFormat);
    const angleX = floatToLocalizedString(curr?.angle?.[0], csvFormat);
    const angleY = floatToLocalizedString(curr?.angle?.[1], csvFormat);
    const angleZ = floatToLocalizedString(curr?.angle?.[2], csvFormat);
    const btnPress = curr?.runningStatus?.includes("btnPress")
      ? t("BtnPress")
      : "";

    if (
      hasNoValuesToShow([
        accX,
        accXDurr,
        accY,
        accYDurr,
        accZ,
        accZDurr,
        temp,
        rh,
        angleX,
        angleY,
        angleZ,
        btnPress
      ])
    ) {
      return arr;
    }

    return arr.concat(
      `${timestamp}${sep}${accX}${sep}${accXDurr}${sep}${accY}${sep}${accYDurr}${sep}${accZ}${sep}${accZDurr}${temp}${sep}${rh}${sep}${angleX}${sep}${angleY}${angleZ}${sep}${btnPress}\n`
    );
  }, csvHeader);
};

export const devicesToCsv = (
  data: DeviceData[],
  csvFormat: string,
  t: (s: DictionaryTransKeys) => string
): string => {
  /** Cell separator in swedish or brittish form */
  const sep = csvFormat === "swe" ? ";" : ",";

  const csvHeader =
    t("myDevicesTableSerialNumberTitle") +
    sep +
    t("Project") +
    sep +
    t("myDevicesTableNextCalibrationTitle") +
    sep +
    t("myDevicesTableLastUserTitle") +
    sep +
    t("myDevicesTableLastConnectedTitle") +
    sep +
    t("LastPosition") +
    "\n";

  /**
   * Returns true if all cols equals empty string
   * @param cols Array of future csv-cols except timestamp
   */
  const hasNoValuesToShow = (cols: string[]) =>
    cols.filter((x) => x !== "").length === 0;

  return data.reduce((arr: string, curr) => {
    const serialNumber = curr.serialNumber;
    const project = curr.project?.name ?? "";
    const nextCalibration = curr.nextCalibration ?? "";
    const lastUser = (): string => {
      if (curr.lastUser?.firstName && curr.lastUser?.lastName) {
        return curr.lastUser.firstName + " " + curr.lastUser.lastName;
      }
      return "";
    };
    const lastSeen = curr.lastSeen ?? "";
    const lastPos = (): string => {
      if (curr.lastPos?.lastLat && curr.lastPos?.lastLon) {
        return curr.lastPos.lastLat + " " + curr.lastPos.lastLon;
      }
      return "";
    };

    if (
      hasNoValuesToShow([
        serialNumber,
        project,
        nextCalibration,
        lastUser(),
        lastSeen,
        lastPos()
      ])
    ) {
      return arr;
    }

    return arr.concat(
      serialNumber +
        sep +
        project +
        sep +
        nextCalibration +
        sep +
        lastUser() +
        sep +
        lastSeen +
        sep +
        lastPos() +
        "\n"
    );
  }, csvHeader);
};
