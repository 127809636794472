import React from "react";
import { useSelector } from "react-redux";
import { selectTemperatureScale } from "../../state/sessionSlice";
import { round } from "lodash-es";

/** If tempScale === "F" it converts the number to Fahrenheit or else the input value (celsius) will be returned unchanged. */
export const TemperatureToScale = (
  celsius: number,
  tempScale: string
): number => {
  if (tempScale === "F") {
    const fahrenheit = celsius * 1.8 + 32;
    return fahrenheit;
  } else {
    return celsius;
  }
};

/** If tempScale === "F" it converts the number to Celsius with 2 decimals.
 * This makes it possible to reverse and round back to the original number.
 * If tempScale === "C" the input value will be rounded to full number. */
export const TemperatureToRoundCelsius = (
  temp: number,
  tempScale: string
): number => {
  if (tempScale === "F") {
    const celsius = round((temp - 32) / 1.8, 2);
    return celsius;
  } else {
    return round(temp, 0);
  }
};

export const CelsiusToFahrenheit = (celsius: number): number => {
  const fahrenheit = celsius * 1.8 + 32;
  return fahrenheit;
};

export const FahrenheitToCelsius = (fahrenheit: number): number => {
  const celsius = (fahrenheit - 32) / 1.8;
  return celsius;
};

export const TempScaleSymbol = (tempScale: string): string => {
  if (tempScale === "F") {
    return "°F";
  } else {
    return "°C";
  }
};

export const TempSymbol: React.FC = () => {
  const tempScale = useSelector(selectTemperatureScale);
  if (tempScale === "F") {
    return <>°F</>;
  } else {
    return <>°C</>;
  }
};
