import React, { useState } from "react";
import { Form, notification, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  ParameterForAlteration,
  useGetAllProjectsQuery,
  useProjectByIdQuery,
  useUpdateParameterMutation
} from "../../state/cargologRestApi";
import { closeMoveDeviceModal, projectsState } from "../../state/projectSlice";
import { PrimaryButton } from "../Common/CommonButtons";
import { isUndefined } from "lodash-es";
import { skipToken } from "@reduxjs/toolkit/query";
import StandardModal from "../Common/StandardModal";
const { Text } = Typography;

const MoveDeviceModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { selectedProjectId, isMoveDeviceModalOpen, parameterType } =
    useSelector(projectsState);

  const [selectProject, setSelectProject] = useState<string | undefined>(
    undefined
  );

  const { data: projects } = useGetAllProjectsQuery();
  const { data: project } = useProjectByIdQuery(
    selectProject ? { id: selectProject } : skipToken
  );
  const [updateParameter, requestStatus] = useUpdateParameterMutation();
  const { isLoading: requestIsLoading } = requestStatus;

  const handleUpdateParameter = async (request: ParameterForAlteration) => {
    const result: any = await updateParameter(request);
    if (result.data) {
      notification.success({
        message: (
          <>
            {t("TheDeviceHasBeenMovedToProject")}{" "}
            <Text strong>{project?.title}.</Text>
          </>
        )
      });
      setSelectProject(undefined);
      dispatch(closeMoveDeviceModal());
    } else {
      notification.error({ message: t("FailedToMoveTheDevice") });
    }
  };

  const onFinish = () => {
    if (!isUndefined(selectProject) && !isUndefined(parameterType)) {
      const request: ParameterForAlteration = {
        id: parameterType.parameterId,
        projectId: selectProject,
        deviceName: parameterType.deviceName,
        deviceDescription: parameterType.deviceDescription,
        isActive: parameterType.isActive
      };
      handleUpdateParameter(request);
    }
  };

  const projectsOptions = [...(projects ?? [])]
    .sort((a, b) =>
      a.title.toLowerCase().localeCompare(b.title.toLowerCase(), "sv")
    )
    .map((project) => ({
      value: project.id,
      label: project.title,
      disabled: project.id === selectedProjectId
    }));

  const onChange = (value: string) => {
    setSelectProject(value);
  };

  const onClear = () => {
    setSelectProject(undefined);
  };

  return (
    <>
      <StandardModal
        title={t("MoveDeviceToAnotherProject")}
        open={isMoveDeviceModalOpen}
        zIndex={1045}
        width={400}
        onCancel={() => dispatch(closeMoveDeviceModal())}
        footer={null}
        destroyOnClose={true}
      >
        <Form onFinish={onFinish} layout="vertical" style={{ width: "100%" }}>
          <Form.Item
            name="id"
            rules={[{ required: true, message: t("AProjectIsRequired") }]}
          >
            <Select
              placeholder={t("SelectProject")}
              optionFilterProp="children"
              onChange={onChange}
              onClear={onClear}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={projectsOptions}
              showSearch
              allowClear
              autoFocus
            />
          </Form.Item>
          <PrimaryButton
            style={{ width: "100%", height: "40px" }}
            htmlType="submit"
            loading={requestIsLoading}
          >
            {t("Move")}
          </PrimaryButton>
        </Form>
      </StandardModal>
    </>
  );
};

export default MoveDeviceModal;
