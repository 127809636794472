import { useSelector } from "react-redux";
import { selectMyCurrency } from "../../state/sessionSlice";

import React from "react";

interface IProps {
  price: number;
}

const CurrencyAndPrice: React.FC<IProps> = (props) => {
  const persistantCurrency = useSelector(selectMyCurrency);

  const checkIfDecimals = props.price % 1 !== 0;
  // Rounds to 2 decimals without errors
  const roundedPrice = Math.round(props.price * 100) / 100;
  // Adds trailing 0 to 2 decimals
  const decimals = roundedPrice.toFixed(2);
  const noDecimals = props.price;
  switch (persistantCurrency) {
    case "SEK":
      return checkIfDecimals ? <>{decimals}kr</> : <>{noDecimals}kr</>;
    case "EUR":
      return checkIfDecimals ? <>€{decimals}</> : <>€{noDecimals}</>;
    case "USD":
      return checkIfDecimals ? <>${decimals}</> : <>${noDecimals}</>;
    default:
      return checkIfDecimals ? <>{decimals}</> : <>{noDecimals}</>;
  }
};

export default CurrencyAndPrice;
