import React from "react";
import { Row, Col, Divider, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { size } from "../helpers/pageHelper";
import { Layout } from "antd";
import Landing from "../components/LandingPage/Landing";
import { getUser } from "../state/sessionSlice";
import { useSelector } from "react-redux";
import { MobitronHugeLogo } from "../logos";
const { Title } = Typography;

const LandingPage = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);

  return (
    <Row>
      <Col span={24}>
        <Row justify="center" style={{ padding: size.m1 }}>
          <Layout
            style={{
              width: "100%",
              maxWidth: "100%"
            }}
          >
            <Layout.Content style={{ padding: size.m1, textAlign: "left" }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Title level={2} style={{ marginBlock: 0 }}>
                    {t("Welcome")} {user.firstName}!
                  </Title>
                </Col>
                <Col style={{ width: 200 }}>
                  <MobitronHugeLogo />
                </Col>
              </Row>
              <Divider style={{ marginTop: 0 }} />
              <Landing />
            </Layout.Content>
          </Layout>
        </Row>
      </Col>
    </Row>
  );
};

export default LandingPage;
