import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Space } from "antd";
import { FileUnknownOutlined } from "@ant-design/icons";
import commonColors from "../../styles/commonColors";

const NoData = () => {
  const { t } = useTranslation();

  return (
    <Row
      align="middle"
      justify="center"
      style={{ height: "100%", textAlign: "center" }}
    >
      <Space direction="vertical">
        <FileUnknownOutlined
          style={{ fontSize: "48px", color: commonColors.disabledText }}
        />
        <p>{t("noOpenFileMsg")}</p>
      </Space>
    </Row>
  );
};

export default NoData;
