import React from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { useGetCompanyDetailsQuery } from "../../state/cargologRestApi";
import {
  closeEditUserModal,
  modalsState,
  openChangeEmailModal,
  openChangePasswordModal,
  openEditUserCompanyModal,
  openEditUserNameModal,
  openEditUserPhoneModal,
  setUser
} from "../../state/modalsSlice";
import ChangePasswordModal from "./ChangePasswordModal";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { SmallText } from "../Common/CommonFonts";
import EditUserNameModal from "./EditUserNameModal";
import EditUserPhoneModal from "./EditUserPhoneModal";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useTranslation } from "react-i18next";
import { emptyUser } from "../../helpers/sessionHelper";
import StandardModal from "../Common/StandardModal";

const EditUserModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector(modalsState);
  const selectedUser = modals.userObject;
  const user = selectedUser ?? emptyUser;

  // Load User Company from API
  const { data: companyData } = useGetCompanyDetailsQuery(
    user.companyId === "" ? skipToken : { companyId: user.companyId }
  );

  return (
    <>
      <StandardModal
        title={t("EditUserAccount")}
        open={modals.isEditUserModalOpen}
        onCancel={() => dispatch(closeEditUserModal())}
        closable={true}
        footer={null}
        zIndex={1045}
        destroyOnClose={true}
      >
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("Name")}</Col>
          <Col span={12}>
            <SmallText strong>{user.firstName + " " + user.lastName}</SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openEditUserNameModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("Email")}</Col>
          <Col span={12}>
            <SmallText strong>{user.email}</SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              disabled
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openChangeEmailModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("PhoneNumber")}</Col>
          <Col span={12}>
            <SmallText strong>{user.phoneNumber}</SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openEditUserPhoneModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("Company")}</Col>
          <Col span={12}>
            <SmallText strong key={companyData?.name}>
              {companyData?.name}
            </SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openEditUserCompanyModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
        <Row style={{ marginBottom: size.m1 }}>
          <Col span={8}>{t("Password")}</Col>
          <Col span={12}>
            <SmallText>· · · · · ·</SmallText>
          </Col>
          <Col span={4}>
            <NormalButtonSmall
              disabled
              onClick={() => {
                dispatch(setUser(user));
                dispatch(openChangePasswordModal());
              }}
            >
              {t("genEdit")}
            </NormalButtonSmall>
          </Col>
        </Row>
      </StandardModal>
      <ChangePasswordModal />
      <EditUserPhoneModal />
      <EditUserNameModal />
    </>
  );
};

export default EditUserModal;
