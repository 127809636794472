import * as cheerio from "cheerio";
import { ExportTypes, TableType } from "./pdfInterfaces";
import { parseBackground } from "./pdfHelpers";
import { TableCell } from "pdfmake/interfaces";

/** Parses out all HTML tables with headers and rows
 *  Colspan is supported but rowspan is not
 */
export const parseHtmlTables = (
  html: HTMLElement,
  itemHeader: ExportTypes
): TableType[] => {
  const tables: TableType[] = [];
  const parser = cheerio.load(html.outerHTML);
  // Remove all SVG elements
  parser("svg").remove();
  parser("table").each((index, element) => {
    const table: TableType = { headers: [], rows: [] };
    // Extract headers from the table
    parser(element)
      .find("thead tr")
      .each((rowIndex, element) => {
        const cells: TableCell[] = [];
        parser(element)
          .find("th")
          .each((columnIndex, element) => {
            const text = parser(element).text();
            const colspan = parser(element).attr("colspan");
            if (colspan) {
              const span = parseInt(colspan);
              cells.push({
                text: text,
                bold: true,
                colSpan: span
              });
              // Add empty cells for the colspan
              for (let i = 1; i < span; i++) {
                cells.push({});
              }
            } else {
              cells.push({
                text: text,
                bold: true
              });
            }
          });
        if (cells.length > 0) {
          table.headers.push(cells);
        }
      });

    // Extract rows from the table
    parser(element)
      .find("tbody tr")
      .each((rowIndex, element) => {
        const cells: TableCell[] = [];
        parser(element)
          .find("td")
          .each((columnIndex, element) => {
            // Find if any of the children have a background-color
            let backgroundColor: string | undefined = undefined;
            parser(element)
              .children()
              .each((bgIndex, element) => {
                backgroundColor = parseBackground(
                  parser(element).css("background-color")
                );
              });

            let text = "";
            if (itemHeader === "Notes" || itemHeader === "Recordings") {
              text = parser(element).text();
            } else {
              text = parser(element).text().replace(/\n/g, "").trim();
            }

            const colspan = parser(element).attr("colspan");
            if (colspan) {
              const span = parseInt(colspan);

              // columnIndex === 0 works because Recordings Description has a span so the other
              // row will be in the other if-case.
              const isRecordingsDescriptionColumn =
                itemHeader === "Recordings" && columnIndex === 0;

              /**
               * Special case for Notes for removing the line between first and second row.
               * Also aplies to Recordings for the first row. Second row is changed in next if-case.
               */
              const borderSelector = () => {
                switch (itemHeader) {
                  case "Notes":
                    return rowIndex === 0
                      ? [true, true, true, false]
                      : [true, false, true, true];
                  case "Recordings":
                    return isRecordingsDescriptionColumn
                      ? [true, false, true, true]
                      : undefined;
                  default:
                    return undefined;
                }
              };

              const marginSelector = () => {
                switch (itemHeader) {
                  case "Recordings":
                    return isRecordingsDescriptionColumn ? [10, 0, 0, 10] : 0;
                  case "Notes":
                    return itemHeader === "Notes" && rowIndex === 1
                      ? [0, 0, 0, 5]
                      : 0;
                  default:
                    return 0;
                }
              };

              const showGrayText =
                (itemHeader === "Notes" && rowIndex === 0) ||
                isRecordingsDescriptionColumn;

              cells.push({
                text: text,
                background: backgroundColor,
                colSpan: span,
                border: borderSelector(),
                color: showGrayText ? "#595959" : "black",
                margin: marginSelector()
              });
              // Add empty cells for the colspan
              for (let i = 1; i < span; i++) {
                cells.push({});
              }
            } else {
              /** Removing row line between data row and recording description row in Recordings */
              const isEvenRowInRecordings =
                itemHeader === "Recordings" && rowIndex % 2 === 0;

              const isRecordingsAlarmsColumn =
                itemHeader === "Recordings" && columnIndex === 3;
              const isRecordingsStatusColumn =
                itemHeader === "Recordings" && columnIndex === 4;
              const colorSelector = () => {
                if (isRecordingsAlarmsColumn) {
                  if (text === "0") {
                    return "#3f8600";
                  } else {
                    return "#cf1322";
                  }
                } else if (isRecordingsStatusColumn) {
                  if (text === "Finished") {
                    return "#595959";
                  } else if (text === "Active") {
                    return "#3f8600";
                  } else {
                    return "black";
                  }
                } else {
                  return "black";
                }
              };

              cells.push({
                text: text,
                background: backgroundColor,
                border: isEvenRowInRecordings
                  ? [true, true, true, false]
                  : undefined,
                color: colorSelector()
              });
            }
          });
        if (cells.length > 0) {
          table.rows.push(cells);
        }
      });
    if (table.headers.length > 0 || table.rows.length > 0) {
      tables.push(table);
    }
  });
  return tables;
};
