import { isNil } from "lodash-es";

/** Simulate an uint8 byte. Will return a value between 0 and 255 */
export const toUint8 = (n: number) => n % 256;

/**
 * Returns elem if condition is met. Usefull together with the spread operator
 * for conditinal insert into array/object declarations
 * @param condition Condition to check
 * @param elem The element that will be returned if condition is met
 */
export const insertIf = <T>(condition: boolean, ...elem: T[]): T[] => {
  return condition ? [...elem] : [];
};

/**
 * Higher order function that checks if any of the incoming arguments is
 * undefined and if that's the case returns undefined.
 * @param func Any function
 * @param args The args belonging to the function
 */
export const undefinedIfArgUndefined = <T extends (...a: any[]) => any>(
  func: T,
  ...args: Parameters<T>
): ReturnType<T> | undefined => {
  if (args.some(isNil)) {
    return undefined;
  }

  return func(...args);
};

/**
 * Higher order function that executes a function that returns a promise. If the promise is not fullfilled,
 * the same function will run again once more. If it fails a second time, this function
 * will return a rejected promise.
 * @param fn Function that returns a promise
 * @param wait Number of milliseconds to wait before try again
 */
export const retryOnceIfErrorAfterDelay = async <T>(
  fn: () => Promise<T>,
  wait: number
) => {
  fn()
    // Sucess the first time
    .then(() => Promise.resolve())
    .catch(() =>
      //try one more if failure
      setTimeout(() => {
        fn()
          .then(() => Promise.resolve())
          .catch((err) => Promise.reject(err));
      }, wait)
    );
};
