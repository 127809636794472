import { CloseOutlined } from "@ant-design/icons";
import { Card, Col, Input, Row, Select, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { size } from "../../helpers/pageHelper";
import { getSensorNrFromId } from "../../helpers/paramsHelper";
import {
  addExternalSensor,
  removeExternalSensor,
  selectExternalSensors,
  setExternalSensorName
} from "../../state/openParxSlice";
import mobitronColors from "../../styles/mobitronColors";
import { NormalButtonSmall } from "../Common/CommonButtons";
import { ParamsCardParagraph } from "../Common/CommonFonts";
import { CommonParamsInputCard } from "../Common/CommonParametersComponents";
import {
  RHTemp1M,
  RHTemp1S,
  RHTemp2M,
  RHTemp3M,
  RHTemp4M,
  RHTemp5M,
  RHTemp6M,
  RHTemp7M,
  RHTemp8M
} from "../../models/FAT100DataTypes";

interface IProps {
  availableSensors: number[];
}
interface ISelectOption {
  value: number;
  label: string;
}

const ParamsBuilderExtSensors: React.FC<IProps> = (props) => {
  const slots = useSelector(selectExternalSensors);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { availableSensors } = props;

  const selectOptions: ISelectOption[] = [
    {
      value: RHTemp1S,
      label: "RH/Temp Sensor nr 1"
    },
    {
      value: RHTemp1M,
      label: "RH/Temp Sensor nr 1"
    },
    {
      value: RHTemp2M,
      label: "RH/Temp Sensor nr 2"
    },
    {
      value: RHTemp3M,
      label: "RH/Temp Sensor nr 3"
    },
    {
      value: RHTemp4M,
      label: "RH/Temp Sensor nr 4"
    },
    {
      value: RHTemp5M,
      label: "RH/Temp Sensor nr 5"
    },
    {
      value: RHTemp6M,
      label: "RH/Temp Sensor nr 6"
    },
    {
      value: RHTemp7M,
      label: "RH/Temp Sensor nr 7"
    },
    {
      value: RHTemp8M,
      label: "RH/Temp Sensor nr 8"
    }
  ];

  return (
    <>
      <CommonParamsInputCard
        title={t("generalExtSensorsTitle")}
        width={2}
        height={1}
        style={{ marginBottom: size.m2 }}
      >
        <ParamsCardParagraph>
          {t("ExternalSensorsInfoText")}
        </ParamsCardParagraph>
        <Row>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Select
              style={{ minWidth: 300 }}
              value={"Choose a sensor to add"}
              options={selectOptions.filter((sensor) => {
                const isUsed = slots.some(
                  (slot) => slot.params.sensorTypeId === sensor.value
                );
                const isAvailable = availableSensors.includes(sensor.value);

                return !isUsed && isAvailable;
              })}
              onSelect={(value) =>
                dispatch(
                  addExternalSensor({
                    value
                  })
                )
              }
            />
          </Col>
        </Row>
      </CommonParamsInputCard>
      <Row gutter={size.m2}>
        {slots.map((slot, index) => {
          const sensorLabel =
            selectOptions.find(
              (sensor) => sensor.value === slot.params.sensorTypeId
            )?.label || "";
          return (
            <Col span={8} key={index}>
              <Card
                style={{
                  borderColor: mobitronColors.softDarkGreen,
                  marginBottom: size.m2
                }}
                styles={{ body: { padding: size.m1 } }}
                key={index}
              >
                <Row justify={"space-between"}>
                  <Typography.Title
                    level={5}
                    style={{
                      marginTop: 0,
                      marginBottom: size.s2
                    }}
                  >
                    {sensorLabel}
                  </Typography.Title>
                  <NormalButtonSmall
                    icon={<CloseOutlined />}
                    onClick={() => dispatch(removeExternalSensor(index))}
                  >
                    {t("Remove")}
                  </NormalButtonSmall>
                </Row>
                <ParamsCardParagraph>
                  {t("AssignANameToTheSensor")}
                </ParamsCardParagraph>
                <Input
                  placeholder={
                    t("AssignNameToSensorNr") +
                    getSensorNrFromId(slot.params.sensorTypeId)
                  }
                  value={slot.params.sensorName}
                  style={{ width: "100%" }}
                  maxLength={24}
                  allowClear={true}
                  onChange={(e) =>
                    dispatch(
                      setExternalSensorName({ value: e.target.value, index })
                    )
                  }
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default ParamsBuilderExtSensors;
