import { Col, InputNumber, Row, Select, Space, Switch } from "antd";
import { round, isNull, max } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import { minAccValue } from "../../constants/params-defaults";
import { accFilterCtValueToHzLookup } from "../../helpers/paramsHelper";
import {
  selectAccParams,
  setAccDvaTriggerLevel,
  setAccFilter,
  setAccIgnoreCount,
  setAccXAlarm,
  setAccXReg,
  setAccXms,
  setAccYAlarm,
  setAccYReg,
  setAccYms,
  setAccZAlarm,
  setAccZReg,
  setAccZms,
  toggleAccMainSwitch,
  toggleAccStoreAngle,
  toggleAccStoreGps,
  toggleAccUseDva,
  toggleAccUseLte,
  toggleAccX,
  toggleAccY,
  toggleAccZ
} from "../../state/openParxSlice";
import { Optional } from "../../utils/utilTypes";
import {
  ParamsCardParagraph,
  ParamsCardSubHeader
} from "../Common/CommonFonts";
import {
  CommonParamsInputCard,
  CommonParamsMainContentRow,
  ParamsInputCardContainer,
  SendGsmAlarmCard,
  willFitWidth2,
  willFitWidth3
} from "../Common/CommonParametersComponents";
import { AccelerationAxis } from "../../helpers/graphHelper";
import { size } from "../../helpers/pageHelper";

interface AvailableAccFeatures {
  hasAngle: boolean;
  hasDva: boolean;
  hasGps: boolean;
  hasLte: boolean;
}

/** Rounds number to use no decimal */
const toInt = (n: number) => round(n, 0);

/** Rounds number to use 2 decimals */
const toDoubbleDec = (n: number) => round(n, 2);

/**
 * Try to parse a string as a number. If it fails, {fallback} will be returned.
 * Postfixes will be removed, e.g. 4g => 4. The function is curried, so the
 * first fallback argument can be applied before the inner function needs to be used
 */
const parseDecNumber =
  (fallback: number) => (displayValue: Optional<string>) => {
    if (displayValue === undefined) {
      return fallback;
    }

    const inNormalForm = displayValue.replace(",", ".");
    const asNum = parseFloat(inNormalForm);

    return isNaN(asNum) ? fallback : asNum;
  };

interface IProps {
  features: AvailableAccFeatures;
  maxG: number;
}
const ParamsBuilderAcc: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const accParams = useSelector(selectAccParams);
  const dispatch = useDispatch();
  const { width, ref } = useResizeDetector();
  const componentWidth = width ?? window.innerWidth;

  //for a switch to be toggled on, the main switch also need to be toggled on
  const mainToggle =
    accParams.useAcc && (accParams.useX || accParams.useY || accParams.useZ);

  const useX = accParams.useX && accParams.useAcc;
  const useY = accParams.useY && accParams.useAcc;
  const useZ = accParams.useZ && accParams.useAcc;

  const storeAngle = accParams.channelBits.Angle ? true : false && mainToggle;
  const storeGps = accParams.channelBits.GPS ? true : false && mainToggle;
  const useLte = accParams.channelBits.LTE ? true : false && mainToggle;
  const ignoreCount = accParams.IgnoreCountLTE;

  const accFilterAlternetives = Object.entries(accFilterCtValueToHzLookup)
    .map(([asCtValue, asHz]) => ({ asCtValue, asHz }))
    .sort((a, b) => a.asHz - b.asHz);

  const setRegLevel = (axis: AccelerationAxis, value: number | null) => {
    if (isNull(value)) return;
    value = toDoubbleDec(value);
    if (value < minAccValue) value = minAccValue;
    if (value > props.maxG) value = props.maxG;
    switch (axis) {
      case "x":
        dispatch(setAccXReg(value));
        break;
      case "y":
        dispatch(setAccYReg(value));
        break;
      case "z":
        dispatch(setAccZReg(value));
        break;
    }
  };
  const setAlarmLevel = (axis: AccelerationAxis, value: number | null) => {
    if (isNull(value)) return;
    value = toDoubbleDec(value);
    if (value < minAccValue) value = minAccValue;
    if (value > props.maxG) value = props.maxG;
    switch (axis) {
      case "x":
        dispatch(setAccXAlarm(value));
        break;
      case "y":
        dispatch(setAccYAlarm(value));
        break;
      case "z":
        dispatch(setAccZAlarm(value));
        break;
    }
  };

  /** If alarm is lower than reg, set to reg */
  const syncAlarmToReg = (axis: AccelerationAxis) => {
    switch (axis) {
      case "x":
        if (accParams.params.Xalarm < accParams.params.Xreg) {
          dispatch(setAccXAlarm(accParams.params.Xreg));
        }
        break;
      case "y":
        if (accParams.params.Yalarm < accParams.params.Yreg) {
          dispatch(setAccYAlarm(accParams.params.Yreg));
        }
        break;
      case "z":
        if (accParams.params.Zalarm < accParams.params.Zreg) {
          dispatch(setAccZAlarm(accParams.params.Zreg));
        }
        break;
    }
  };

  /** If reg is higher than alarm, set to alarm */
  const syncRegToAlarm = (axis: AccelerationAxis) => {
    switch (axis) {
      case "x":
        if (accParams.params.Xreg > accParams.params.Xalarm) {
          dispatch(setAccXReg(accParams.params.Xalarm));
        }
        break;
      case "y":
        if (accParams.params.Yreg > accParams.params.Yalarm) {
          dispatch(setAccYReg(accParams.params.Yalarm));
        }
        break;
      case "z":
        if (accParams.params.Zreg > accParams.params.Zalarm) {
          dispatch(setAccZReg(accParams.params.Zalarm));
        }
        break;
    }
  };

  // Render functions
  const renderMainToggle = () => (
    <Row align="middle" style={{ marginBottom: size.s2 }}>
      <Space>
        {/* Main acceleration switch */}
        <Switch
          checked={accParams.useAcc}
          onChange={() => dispatch(toggleAccMainSwitch())}
        />
        <ParamsCardSubHeader>{t("cpAccMainToggleTitle")}</ParamsCardSubHeader>
      </Space>
    </Row>
  );

  const renderRegLevel = (withDescription: boolean) => {
    /** Contains spacing rations for inputs in registration level */
    const regLvlSpacing = {
      toggle: 6,
      regLevel: 6,
      ghost: withDescription ? 2 : 0,
      alarmLvl: withDescription ? 5 : 6,
      duration: withDescription ? 5 : 6
    };

    const commonAccInputProps = {
      step: 0.1
    };

    return (
      <CommonParamsInputCard
        title={t("cpAccRegLevelsTitle")}
        width={withDescription ? 2 : 1}
        height={2}
      >
        <Row justify="space-between" wrap={false}>
          {withDescription && (
            <Col flex="auto" style={{ paddingRight: size.xl2 }}>
              <ParamsCardParagraph>
                {t("cpAccRegLevelsContent")}
              </ParamsCardParagraph>
            </Col>
          )}
          <Col flex="550px" style={{ textAlign: "center" }}>
            {/* titles */}
            <Row justify="space-between" align="middle">
              {/* ghost element */}
              <Col span={regLvlSpacing.toggle} />
              <Col span={regLvlSpacing.regLevel}>
                <ParamsCardSubHeader>
                  {t("cpAccRegLevelsInpRegLevel")}
                </ParamsCardSubHeader>
              </Col>
              {/* ghost element */}
              <Col span={regLvlSpacing.ghost} />
              <Col span={regLvlSpacing.alarmLvl}>
                <ParamsCardSubHeader>
                  {t("cpAccRegLevelsInpAlarmLevel")}
                </ParamsCardSubHeader>
              </Col>
              <Col span={regLvlSpacing.duration}>
                <ParamsCardSubHeader>
                  {t("cpAccRegLevelsInpDurationLevel")}
                </ParamsCardSubHeader>
              </Col>
            </Row>
            {/* x-acc inputs/toggle */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBlock: size.s2 }}
            >
              {/* x-acc toggle */}
              <Col span={regLvlSpacing.toggle}>
                <Space>
                  <ParamsCardSubHeader>{t("genXAxis")}</ParamsCardSubHeader>
                  <Switch
                    disabled={!accParams.useAcc}
                    checked={useX}
                    onChange={() => dispatch(toggleAccX())}
                    size="small"
                  />
                </Space>
              </Col>
              {/* x-acc reg-level */}
              <Col span={regLvlSpacing.regLevel}>
                {/* todo: real numbers for min/max */}
                <InputNumber
                  disabled={!useX}
                  value={accParams.params.Xreg}
                  parser={parseDecNumber(accParams.params.Xreg)}
                  onChange={(val) => setRegLevel("x", val)}
                  onBlur={() => syncAlarmToReg("x")}
                  {...commonAccInputProps}
                />
              </Col>
              {/* ghost element */}
              <Col span={regLvlSpacing.ghost} />
              {/* x-acc alarm-level */}
              <Col span={regLvlSpacing.alarmLvl}>
                {/* todo: real numbers */}
                <InputNumber
                  disabled={!useX}
                  value={accParams.params.Xalarm}
                  parser={parseDecNumber(accParams.params.Xalarm)}
                  onChange={(value) => setAlarmLevel("x", value)}
                  onBlur={() => syncRegToAlarm("x")}
                  {...commonAccInputProps}
                />
              </Col>
              {/* x-acc duration */}
              <Col span={regLvlSpacing.duration}>
                {/* todo: real numbers */}
                <InputNumber
                  disabled={!useX}
                  value={accParams.params.Xms}
                  onChange={(val) =>
                    isNull(val) ? {} : dispatch(setAccXms(toInt(val)))
                  }
                  // min={1}
                  // max={10}
                />
              </Col>
            </Row>

            {/* y-acc inputs/toggle */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBlock: size.s2 }}
            >
              {/* y-acc toggle */}
              <Col span={regLvlSpacing.toggle}>
                <Space>
                  <ParamsCardSubHeader>{t("genYAxis")}</ParamsCardSubHeader>
                  <Switch
                    disabled={!accParams.useAcc}
                    checked={useY}
                    onChange={() => dispatch(toggleAccY())}
                    size="small"
                  />
                </Space>
              </Col>
              {/* y-acc reg-level */}
              <Col span={regLvlSpacing.regLevel}>
                {/* todo: real numbers */}
                <InputNumber
                  disabled={!useY}
                  value={accParams.params.Yreg}
                  parser={parseDecNumber(accParams.params.Yreg)}
                  onChange={(val) => setRegLevel("y", val)}
                  onBlur={() => syncAlarmToReg("y")}
                  {...commonAccInputProps}
                />
              </Col>
              <Col span={regLvlSpacing.ghost} />
              {/* y-acc alarm-level */}
              <Col span={regLvlSpacing.alarmLvl}>
                {/* todo: real numbers */}
                <InputNumber
                  disabled={!useY}
                  value={accParams.params.Yalarm}
                  parser={parseDecNumber(accParams.params.Yalarm)}
                  onChange={(value) => setAlarmLevel("y", value)}
                  onBlur={() => syncRegToAlarm("y")}
                  {...commonAccInputProps}
                />
              </Col>
              {/* y-acc duration */}
              <Col span={regLvlSpacing.duration}>
                {/* todo: real numbers */}
                <InputNumber
                  disabled={!useY}
                  value={accParams.params.Yms}
                  onChange={(val) =>
                    isNull(val) ? {} : dispatch(setAccYms(toInt(val)))
                  }
                  // min={1}
                  // max={10}
                />
              </Col>
            </Row>

            {/* z-acc inputs/toggle */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBlock: size.s2 }}
            >
              {/* z-acc toggle */}
              <Col span={regLvlSpacing.toggle}>
                <Space>
                  <ParamsCardSubHeader>{t("genZAxis")}</ParamsCardSubHeader>
                  <Switch
                    disabled={!accParams.useAcc}
                    checked={useZ}
                    onChange={() => dispatch(toggleAccZ())}
                    size="small"
                  />
                </Space>
              </Col>
              {/* z-acc reg-level */}
              <Col span={regLvlSpacing.regLevel}>
                {/* todo: real numbers */}
                <InputNumber
                  disabled={!useZ}
                  value={accParams.params.Zreg}
                  parser={parseDecNumber(accParams.params.Zreg)}
                  onChange={(val) => setRegLevel("z", val)}
                  onBlur={() => syncAlarmToReg("z")}
                  {...commonAccInputProps}
                />
              </Col>
              {/* ghost element */}
              <Col span={regLvlSpacing.ghost} />
              {/* z-acc alarm-level */}
              <Col span={regLvlSpacing.alarmLvl}>
                {/* todo: real numbers */}
                <InputNumber
                  disabled={!useZ}
                  value={accParams.params.Zalarm}
                  parser={parseDecNumber(accParams.params.Zalarm)}
                  onChange={(value) => setAlarmLevel("z", value)}
                  onBlur={() => syncRegToAlarm("z")}
                  {...commonAccInputProps}
                />
              </Col>
              {/* z-acc duration */}
              <Col span={regLvlSpacing.duration}>
                {/* todo: real numbers */}
                <InputNumber
                  disabled={!useZ}
                  value={accParams.params.Zms}
                  onChange={(val) =>
                    isNull(val) ? {} : dispatch(setAccZms(toInt(val)))
                  }
                  // min={1}
                  // max={10}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CommonParamsInputCard>
    );
  };

  const renderLowPassFilter = () => (
    <CommonParamsInputCard title={t("cpAccLowPassFilterTitle")} height={2}>
      <Row justify="space-between" wrap={false}>
        <Col flex="auto">
          <ParamsCardParagraph>
            {t("cpAccLowPassFilterContent")}
          </ParamsCardParagraph>
        </Col>
        <Col>
          <Select
            disabled={!mainToggle}
            value={{
              value: accParams.accFilter,
              label: `${accFilterCtValueToHzLookup[accParams.accFilter]}Hz`
            }}
            onChange={(val) => dispatch(setAccFilter(val.value))}
            labelInValue
            style={{ width: "90px" }}
          >
            {accFilterAlternetives.map((item, index) => (
              <Select.Option value={item.asCtValue} key={index}>
                {item.asCtValue === "10" || item.asCtValue === "11"
                  ? `${item.asHz}Hz ★`
                  : `${item.asHz}Hz`}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );

  const renderDvaContent = () => (
    <CommonParamsInputCard
      title={t("cpAccDvaTitle")}
      disabled={!props.features.hasDva}
      useToggle
      toggleChecked={accParams.useDva}
      toggleDisabled={!mainToggle}
      toggleChangeAction={() => dispatch(toggleAccUseDva())}
      width={1}
      height={2}
    >
      <Row justify="space-between" wrap={false}>
        <Col flex="auto" style={{ marginRight: size.s2 }}>
          <ParamsCardParagraph disabled={!props.features.hasDva}>
            {t("cpAccDvaContent")}
          </ParamsCardParagraph>
        </Col>
        <Col flex="150px">
          <Row justify="end" align="middle">
            <Space direction="vertical">
              <ParamsCardSubHeader disabled={!props.features.hasDva}>
                {t("cpAccRegLevelsInpRegLevel")}
              </ParamsCardSubHeader>
              <InputNumber
                value={accParams.dvaTriggerLevel}
                disabled={!accParams.useDva}
                onChange={(value) =>
                  dispatch(setAccDvaTriggerLevel(Number(value)))
                }
                parser={parseDecNumber(accParams.dvaTriggerLevel)}
                min={
                  accParams.useDva
                    ? max([
                        accParams.params.Xreg,
                        accParams.params.Yreg,
                        accParams.params.Zreg
                      ])
                    : 0
                }
              />
            </Space>
          </Row>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );

  const renderStoreAngleAndGps = () => (
    <ParamsInputCardContainer width={1} height={2}>
      {/* store angle content */}
      <CommonParamsInputCard
        title={t("cpAccStoreAngleTitle")}
        disabled={!props.features.hasAngle}
        useToggle
        toggleChecked={storeAngle && props.features.hasAngle}
        toggleDisabled={!mainToggle}
        toggleChangeAction={() => dispatch(toggleAccStoreAngle())}
      >
        <ParamsCardParagraph disabled={!props.features.hasAngle}>
          {t("cpAccStoreAngleContent")}
        </ParamsCardParagraph>
      </CommonParamsInputCard>
      {/* store gps content */}
      <CommonParamsInputCard
        title={t("cpAccStoreGpsPosTitle")}
        disabled={!props.features.hasGps}
        useToggle
        toggleChecked={storeGps && props.features.hasGps}
        toggleDisabled={!mainToggle}
        toggleChangeAction={() => dispatch(toggleAccStoreGps())}
      >
        <ParamsCardParagraph disabled={!props.features.hasGps}>
          {t("cpAccStoreGpsPosContent")}
        </ParamsCardParagraph>
      </CommonParamsInputCard>
    </ParamsInputCardContainer>
  );

  const renderGsmAlarmContent = () => (
    <SendGsmAlarmCard
      hasLte={props.features.hasLte}
      sendGsmTextContent={t("cpAccSendGsmAlarmContent")}
      isParentParamsActive={mainToggle}
      useLte={useLte}
      toggleUseLte={() => dispatch(toggleAccUseLte())}
      ignoreCount={ignoreCount}
      setIgnoreCount={(v: number) => dispatch(setAccIgnoreCount(v))}
    />
  );

  return (
    <div ref={ref}>
      {renderMainToggle()}

      <CommonParamsMainContentRow>
        {/* Reg level content */}
        <Col order={1}>{renderRegLevel(!willFitWidth2(componentWidth))}</Col>

        {/* Group of store angle and gps. Order explanation: if the screen is small, put this card further down */}
        <Col order={willFitWidth3(componentWidth) ? 5 : 2}>
          {renderStoreAngleAndGps()}
        </Col>

        {/* gps alarm content */}
        <Col order={5}>{renderGsmAlarmContent()}</Col>

        {/* low pass filter content. Order explanation: If the screen is small, put this above "store angle and gps" */}
        <Col order={willFitWidth3(componentWidth) ? 2 : 3}>
          {renderLowPassFilter()}
        </Col>

        {/* dva content. Order explanation: If the screen is small, put this above "store angle and gps" */}
        <Col order={willFitWidth3(componentWidth) ? 3 : 4}>
          {renderDvaContent()}
        </Col>
      </CommonParamsMainContentRow>
    </div>
  );
};

export default ParamsBuilderAcc;
