import BatteryType from "../models/BatteryType";

/*
 form: Type of battery slot
 name: Brand and model of bettery
 type: Battery chemistry
 volt: Volt per batteri
 mah: mAh per battery
  pulseCapability: Maximum pulse Capability in mA
 */
export const batteryConfigurations: BatteryType[] = [
  {
    id: 1,
    name: "Tadiran SL-2770",
    volt: 3.6,
    mah: 8500,
    pulseCapability: 400,
    form: "2xC",
    type: "Lithium",
    isDefault: true
  },
  {
    id: 2,
    name: "Saft LS26500",
    volt: 3.6,
    mah: 7700,
    pulseCapability: 300,
    form: "2xC",
    type: "Lithium",
    isDefault: true
  },
  {
    id: 3,
    name: "Duracell MN1400",
    volt: 1.5,
    mah: 7800,
    pulseCapability: 0,
    form: "2xC",
    type: "Alkaline",
    isDefault: true
  },
  {
    id: 4,
    name: "Saft LS14500",
    volt: 3.6,
    mah: 2600,
    pulseCapability: 250,
    form: "4xAA",
    type: "Lithium",
    isDefault: true
  },
  {
    id: 5,
    name: "Duracell MN1500",
    volt: 1.5,
    mah: 2850,
    pulseCapability: 0,
    form: "4xAA",
    type: "Alkaline",
    isDefault: true
  },
  {
    id: 6,
    name: "SAFT LSH 14",
    volt: 3.6,
    mah: 5800,
    pulseCapability: 2000,
    form: "2xC",
    type: "Lithium",
    isDefault: true
  }
];

// Constants for energy consumption of different firmware versions in mA
export const firmwareEnergyConsumptionConstants = [
  {
    fwver: "0.1.0.0",
    base: 0.298,
    accReg: 8.16,
    accRegDuration: 4 / 3600, // 4 seconds in hours
    tempReg: 8.16,
    tempRegDuration: 4 / 3600, // 4 seconds in hours
    rhReg: 8.16,
    rhRegDuration: 4 / 3600, // 4 seconds in hours
    angleReg: 8.16,
    angleRegDuration: 4 / 3600, // 4 seconds in hours
    healthReg: 8.16,
    healthRegDuration: 4 / 3600, // 4 seconds in hours
    gpsReg: 122,
    gpsRegDuration: 30 / 3600, // 30 seconds in hours
    lte2GReg: 1088,
    lte3GReg: 500,
    lte4GReg: 250,
    lte5GReg: 250,
    lte6GReg: 250,
    lteRegDuration: 60 / 3600 // 60 seconds in hours
  },
  {
    fwver: "0.10.0.0",
    base: 0.245,
    accReg: 17,
    accRegDuration: 5 / 3600, // 5 seconds in hours
    tempReg: 17,
    tempRegDuration: 5 / 3600, // 5 seconds in hours
    rhReg: 17,
    rhRegDuration: 5 / 3600, // 5 seconds in hours
    angleReg: 17,
    angleRegDuration: 5 / 3600, // 5 seconds in hours
    healthReg: 17,
    healthRegDuration: 5 / 3600, // 5 seconds in hours
    gpsReg: 85,
    gpsRegDuration: 60 / 3600, // 60 seconds in hours
    lte2GReg: 1088,
    lte3GReg: 500,
    lte4GReg: 250,
    lte5GReg: 250,
    lte6GReg: 250,
    lteRegDuration: 60 / 3600 // 60 seconds in hours
  }
];
