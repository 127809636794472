import React from "react";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { selectHelp } from "../../state/helpSlice";
import { TooltipPlacement } from "antd/lib/tooltip";

export const Help = (
  content: JSX.Element,
  text: string,
  placement: TooltipPlacement
) => {
  const { isHelpModeActive } = useSelector(selectHelp);
  return (
    <Tooltip
      title={text}
      open={isHelpModeActive}
      placement={placement}
      zIndex={900} // what shoud this be set to?
      getPopupContainer={(triggerNode: HTMLElement) =>
        triggerNode.parentNode as HTMLElement
      }
    >
      {content}
    </Tooltip>
  );
};
