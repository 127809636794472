import React from "react";
import { useSelector } from "react-redux";
import { Row } from "antd";
import { selectCreateUserPageProgress } from "../../state/navigationSlice";
import CreateUser from "./CreateUser";
import CreateCompany from "./CreateCompany";
import { CreateUserProgress } from "../../state/navigationSlice";

interface IProps {
  contentWidth: number;
}
const CreateAccountContent: React.FC<IProps> = (props) => {
  const { createUserProgress } = useSelector(selectCreateUserPageProgress);

  /** Parameters pages based on user progress */
  const pages: Record<CreateUserProgress, JSX.Element> = {
    0: <CreateUser />,
    1: <CreateCompany />
  };

  return (
    <Row
      style={{ width: "100%", maxWidth: props.contentWidth, height: "100%" }}
      justify="center"
    >
      {pages[createUserProgress]}
    </Row>
  );
};

export default CreateAccountContent;
