import { Content } from "pdfmake/interfaces";
import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import {
  packOrderLinesTable,
  packOrderTotalTable,
  packTable,
  packTitle,
  packTransportHeader
} from "../../helpers/pdf/contentPackers";
import { createDocumentDefinition } from "../../helpers/pdf/createDocumentDefinition";
import {
  parseOrderFooter,
  parseOrderHeader,
  parseOrderTables
} from "../../helpers/pdf/parseOrder";
import { parseQuickReportTable } from "../../helpers/pdf/parseQuickReport";
import { parseSvg } from "../../helpers/pdf/parseSvg";
import { parseHtmlTables } from "../../helpers/pdf/parseHtmlTables";
import { createPdf } from "../../helpers/pdf/pdfCreator";
import { getFullWidth } from "../../helpers/pdf/pdfHelpers";
import {
  DocumentProps,
  ItemHeaderData,
  TransportHeaderData,
  svgTypes
} from "../../helpers/pdf/pdfInterfaces";
import { MobitronLogoBackground } from "../../logos";
import {
  componentWidth,
  componentHeight
} from "../../helpers/pdf/pdfConstants";

/** Props for the PdfExportComponent */
interface PdfExportProps {
  ComponentBody: React.FC<any>;
  componentProps: any;
  itemHeader: ItemHeaderData;
  transportHeader?: TransportHeaderData;
  documentProps: DocumentProps;
  reportExportDone: () => void;
}

export const MobitronLogo = ReactDOMServer.renderToStaticMarkup(
  <MobitronLogoBackground />
);

/** Hook to manage the export process */
export const useExportPdfHook = () => {
  const [isExporting, setIsExporting] = useState(false);

  const startExport = () => {
    setIsExporting(true);
  };
  const finishExport = () => {
    setIsExporting(false);
  };

  return { isExporting, startExport, finishExport };
};

/** Component wrapping a JSX Component  */
export const PdfExportComponent = (props: PdfExportProps) => {
  const { t } = useTranslation();
  const {
    ComponentBody,
    componentProps,
    itemHeader,
    transportHeader,
    documentProps,
    reportExportDone
  } = props;
  const itemRef = useRef();
  useEffect(() => {
    if (itemRef.current) {
      const html = itemRef.current as HTMLElement;
      const content: Content = [];
      const footer: Content = [];

      if (transportHeader) {
        content.push(packTransportHeader(t, transportHeader));
      }

      if (itemHeader.itemType === "Invoice") {
        const invoiceHeader = parseOrderHeader(html);
        content.push(invoiceHeader);
        const tables = parseOrderTables(html);
        if (tables.length === 2) {
          content.push(packOrderLinesTable(tables[0]));
          content.push(packOrderTotalTable(tables[1]));
        }
        const invoiceFooter = parseOrderFooter(html);
        footer.push(invoiceFooter);
      } else if (itemHeader.itemType === "QuickReport") {
        content.push(packTitle(itemHeader.itemTitle));
        const quickReport = parseQuickReportTable(html);
        content.push(packTable(quickReport, itemHeader.itemType));
      } else if (svgTypes.includes(itemHeader.itemType)) {
        content.push(packTitle(itemHeader.itemTitle));
        const svg = parseSvg(html);
        content.push({
          svg: svg,
          width: getFullWidth(documentProps)
        });
      } else {
        content.push(packTitle(itemHeader.itemTitle));
        const tables = parseHtmlTables(html, itemHeader.itemType);
        tables.forEach((table) => {
          content.push(packTable(table, "default"));
        });
      }
      const documentDefinition = createDocumentDefinition({
        content,
        t,
        documentProps,
        transportHeader,
        footer: footer.length > 0 ? footer : undefined
      });
      createPdf(documentDefinition, documentProps.fileName);
      reportExportDone();
    }
  }, [transportHeader, itemHeader, reportExportDone]);

  return (
    <div style={{ display: "none" }}>
      <div ref={itemRef as any}>
        <ComponentBody
          {...componentProps}
          width={componentWidth}
          height={componentHeight}
        />
      </div>
    </div>
  );
};
