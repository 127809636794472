import * as React from "react";

// Used for export only because they want it as a string.
// width, height and fontSize are changed compared to QuickReportOkIcon().
export const quickReportCircleStringSvg = `<svg
    width="20px"
    height="20px"
    viewBox="0 0 24.00 24.00"
    xmlns="http://www.w3.org/2000/svg"
    transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
  >
    <g id="ok_circle">
      <circle
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#8bca83"
        stroke="none"
        cx="12"
        cy="12"
        r="12"
      ></circle>
      <text
        x="11.5"
        y="15.5"
        style="font-size: 10px;"
        text-anchor="middle"
        stroke="none"
        fill="white"
      >
        OK
      </text>
    </g>
  </svg>`;

// todo: remove props?
const QuickReportCircleIcon = () => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 24.00 24.00"
    xmlns="http://www.w3.org/2000/svg"
    transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)"
  >
    <g id="ok_circle">
      <circle
        fill-rule="evenodd"
        clip-rule="evenodd"
        fill="#8bca83"
        stroke="none"
        cx="12"
        cy="12"
        r="12"
      ></circle>
      <text
        x="11.5"
        y="15.5"
        fontSize="10"
        text-anchor="middle"
        stroke="none"
        fill="white"
      >
        OK
      </text>
    </g>
  </svg>
);

export default QuickReportCircleIcon;
