export default interface IExternalRhTempParams {
  rhMin: number;
  rhMax: number;
  tempMin: number;
  tempMax: number;
}

export const isIExternalRhTempParams = (
  obj: any
): obj is IExternalRhTempParams => {
  return (
    obj.rhMin !== undefined &&
    obj.rhMax !== undefined &&
    obj.tempMin !== undefined &&
    obj.tempMax !== undefined
  );
};
