import { Buffer } from "buffer";
import {
  CT_AccDVATrigLevel,
  CT_AccFilterValues,
  CT_AccParams,
  CT_AngleParams,
  CT_CfgVersion,
  CT_CompanyId,
  CT_ExternalInputs,
  CT_ExternalOutputs,
  CT_ExternalSensor,
  CT_GPS,
  CT_LicenseKey,
  CT_LightSensorParams,
  CT_LTE,
  CT_ParameterId,
  CT_PressureParams,
  CT_ProgInfo,
  CT_ProjectName,
  CT_RecParams,
  CT_RhParams,
  CT_TempParams,
  CT_UserInfo,
  CX_Schedule,
  KIND_RECPARAMS
} from "../../constants/FAT100-prot-constants";
import {
  IRecordingParameters,
  IRecordingParametersKeys
} from "../../models/RecordingParameters/IRecordingParameters";
import {
  parseAccDVATrigLevel,
  parseAccFilterValues,
  parseAccParams,
  parseAngleParams,
  parseCompanyIdParams,
  parseExternalInputsParam,
  parseExternalOutputsParam,
  parseExternalSensorParams,
  parseGPSParams,
  parseLicenseKey,
  parseLightSensorParams,
  parseLTEParams,
  parseParameterIdParams,
  parsePressureParams,
  parseProgInfo,
  parseProjectNameParams,
  parseRecParams,
  parseRhParams,
  parseSchedulers,
  parseTempParams,
  parseUserInfoParams
} from "./parseConfigTypesHelper";

/** A hashmap that is used to map a ct/cx type to its corresponding RecParams-key */
const getCtCxHashMap = () =>
  new Map<number, IRecordingParametersKeys>([
    [CT_CfgVersion, "CfgVersion"],
    [CT_ProgInfo, "ProgInfo"],
    [CT_LicenseKey, "LicenseKey"],
    [CT_RecParams, "RecParams"],
    [CT_AccParams, "AccParams"],
    [CT_AccDVATrigLevel, "AccDVATrigLevel"],
    [CT_TempParams, "TempParams"],
    [CT_RhParams, "RhParams"],
    [CT_AngleParams, "AngleParams"],
    [CT_PressureParams, "PressureParams"],
    [CT_ExternalInputs, "ExternalInputs"],
    [CT_ExternalOutputs, "ExternalOutputs"],
    [CT_LightSensorParams, "LightSensorParams"],
    [CT_GPS, "GPS"],
    [CT_LTE, "LTE"],
    [CT_UserInfo, "UserInfo"],
    [CT_ExternalSensor, "ExternalSensors"],
    [CT_ProjectName, "ProjectName"],
    [CT_CompanyId, "CompanyId"],
    [CT_ParameterId, "ParameterId"],
    [CT_AccFilterValues, "AccFilterValues"],
    [CX_Schedule, "SRD"]
  ]);

/**
 * Takes Recording Parameters in byte form directly from FAT100, or from the
 * "Recording Parameters" section in a parx and return a data model
 * repressenting it
 * @param data Recording Parameters in byte-form
 * @returns datamodel - A datamodel that represents Recording Parameters
 */
export const parseRecordingParameters = (
  data: ArrayBuffer
): IRecordingParameters => {
  type parserStates =
    | "SEARCHING"
    | "WANT-LEN"
    | "WANT-CT-OR-CX"
    | "CX_SCHEDULE";

  /** Response that will be sent when the parsing is done */
  let res: IRecordingParameters;
  /** Used to map ct or cx byte to its corresponding RecParams key */
  const ctCxMap = getCtCxHashMap();
  /** Current state machine state */
  let state: parserStates = "SEARCHING";

  /** A field of data in RecParams */
  let recParamsField: IRecordingParametersKeys | undefined;
  /** Buffer to put data when processing a CT or CX */
  let dataContent: number[] = [];
  /** Number of bytes left to process when reading a CT or CX */
  let dataLeft = 0;

  /** Buffer to put cx reserved byte and length (3 bytes total) */
  const cxReservedAndLength: number[] = [];

  /** Buffer containg Recording Parameters in byte form */
  const dataBuffer = Buffer.from(data);
  /** Current position in buffer */
  let bufferPos = 0;

  //check so that we are up for a good beggining
  //ERROR: This isn't KIND_RECPARAMS
  const shouldBeKindRecParams = dataBuffer.readUInt16LE(bufferPos);
  bufferPos += 2;

  if (shouldBeKindRecParams !== KIND_RECPARAMS) {
    throw new Error(
      `wrong kind. Expected ${KIND_RECPARAMS} but got: ${shouldBeKindRecParams}`
    );
  }

  /** the KIND_RECPARAMS content length according to the KIND_RECPARAMS length-field */
  const messageLength = dataBuffer.readUInt16LE(bufferPos);
  bufferPos += 2;

  /** Total length of the entire recParams-buffer (kind, length and content) */
  const totalLengthRecordingParameters = 4 + messageLength;

  //ERROR: The RecParams-length is wrong. Something is wrong
  if (totalLengthRecordingParameters !== dataBuffer.length) {
    throw new Error("Wrong length RecParams");
  }

  while (bufferPos < totalLengthRecordingParameters) {
    if (state === "SEARCHING") {
      recParamsField = ctCxMap.get(dataBuffer.readUInt8(bufferPos));
      if (!recParamsField) {
        throw new Error(`Unknown datatype: ${dataBuffer.readUInt8(bufferPos)}`);
      }

      bufferPos += 1;

      if (recParamsField === "SRD") state = "CX_SCHEDULE";
      else state = "WANT-LEN";
    } else if (state === "WANT-LEN") {
      dataLeft = dataBuffer.readUInt8(bufferPos);
      bufferPos += 1;
      state = "WANT-CT-OR-CX";
    } else if (state === "WANT-CT-OR-CX") {
      dataContent.push(dataBuffer.readUInt8(bufferPos));
      bufferPos += 1;
      dataLeft -= 1;
      if (dataLeft === 0) {
        res = reduceRecParams(recParamsField!, dataContent, res!);
        dataContent = [];
        state = "SEARCHING";
      }
    } else if (state === "CX_SCHEDULE") {
      cxReservedAndLength.push(dataBuffer.readUInt8(bufferPos));
      bufferPos += 1;
      //1 reserved and 2 for length
      if (cxReservedAndLength.length === 3) {
        dataLeft = (cxReservedAndLength[2] << 8) | cxReservedAndLength[1];
        state = "WANT-CT-OR-CX";
      }
    }
  }

  return res!;
};

/**
 * Takes RecParams, extends it and returns
 * @param type The cfgField that should be inserted
 * @param data The data for the cfgField
 * @param recParams The old RecParams that will be extended
 */
const reduceRecParams = (
  type: IRecordingParametersKeys,
  data: number[],
  recParams: IRecordingParameters
): IRecordingParameters => {
  switch (type) {
    case "CfgVersion":
      return { ...recParams, CfgVersion: data?.[0] };
    case "ProgInfo":
      return { ...recParams, ProgInfo: parseProgInfo(data) };
    case "LicenseKey":
      return { ...recParams, LicenseKey: parseLicenseKey(data) };
    case "RecParams":
      return { ...recParams, RecParams: parseRecParams(data) };
    case "AccParams":
      return { ...recParams, AccParams: parseAccParams(data) };
    case "AccDVATrigLevel":
      return { ...recParams, AccDVATrigLevel: parseAccDVATrigLevel(data) };
    case "TempParams":
      return { ...recParams, TempParams: parseTempParams(data) };
    case "RhParams":
      return { ...recParams, RhParams: parseRhParams(data) };
    case "AngleParams":
      return {
        ...recParams,
        AngleParams: parseAngleParams(data, recParams?.CfgVersion)
      };
    case "PressureParams":
      return { ...recParams, PressureParams: parsePressureParams(data) };
    case "ExternalInputs":
      return {
        ...recParams,
        ExternalInputs:
          recParams.ExternalInputs === undefined
            ? [parseExternalInputsParam(data)]
            : [...recParams.ExternalInputs, parseExternalInputsParam(data)]
      };
    case "ExternalOutputs":
      return {
        ...recParams,
        ExternalOutputs:
          recParams.ExternalOutputs === undefined
            ? [parseExternalOutputsParam(data)]
            : [...recParams.ExternalOutputs, parseExternalOutputsParam(data)]
      };
    case "LightSensorParams":
      return { ...recParams, LightSensorParams: parseLightSensorParams(data) };
    case "GPS":
      return { ...recParams, GPS: parseGPSParams(data) };
    case "LTE":
      return { ...recParams, LTE: parseLTEParams(data) };
    case "UserInfo":
      return { ...recParams, UserInfo: parseUserInfoParams(data) };
    case "ExternalSensors":
      return {
        ...recParams,
        ExternalSensors:
          recParams.ExternalSensors === undefined
            ? [parseExternalSensorParams(data)]
            : [...recParams.ExternalSensors, parseExternalSensorParams(data)]
      };
    case "ProjectName":
      return { ...recParams, ProjectName: parseProjectNameParams(data) };
    case "CompanyId":
      return { ...recParams, CompanyId: parseCompanyIdParams(data) };
    case "ParameterId":
      return { ...recParams, ParameterId: parseParameterIdParams(data) };
    case "AccFilterValues":
      return { ...recParams, AccFilterValues: parseAccFilterValues(data) };
    case "SRD":
      return { ...recParams, SRD: parseSchedulers(data) };
    default: {
      console.log("unknown CT or CX type");
      return recParams;
    }
  }
};
