import dayjs from "dayjs";
import { User } from "../state/cargologRestApi";
import { Buffer } from "buffer";

export interface Tokens {
  accessToken?: string;
  refreshToken?: string;
  refreshTokenExpiry?: string;
}

export interface TokenData {
  email?: string;
  firstName?: string;
  lastName?: string;
  companyId?: string;
  userId?: string;
  exp?: number;
}

export const emptyUser: User = {
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  companyId: "",
  temperatureScale: "",
  timeZone: ""
};

/**
 * Parses Access Token string into TokenData object
 * @param token string
 */
export function parseAccessToken(token: string) {
  try {
    const tokendata64 = token.split(".")[1];
    const tokendata = Buffer.from(tokendata64, "base64");
    return JSON.parse(tokendata.toString());
  } catch (e) {
    return null;
  }
}

/**
 * Checks if input date is still in the future
 * @param tokenExpiry string
 */
export function validateToken(tokenExpiry: string) {
  return dayjs().isBefore(dayjs(tokenExpiry));
}
