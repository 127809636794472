import React from "react";
import { Col, Row, Typography } from "antd";
import dayjs from "dayjs";
import { TransportHeaderData } from "../../helpers/pdf/pdfInterfaces";
import { MobitronLogoBackground } from "../../logos";
import { isUndefined } from "lodash-es";
import { useTranslation } from "react-i18next";
import { size } from "../../helpers/pageHelper";

const { Text } = Typography;

export interface Header {
  header: TransportHeaderData;
  isHorizontal: boolean;
}

const ReportHeader = (props: Header): React.JSX.Element => {
  const { header, isHorizontal } = props;
  const {
    reportType,
    projectName,
    hasFilters,
    startMethod,
    parametersLoaded,
    scheduledToStart,
    scheduledToEnd,
    recStart,
    recEnd,
    recEndReason,
    reportStart,
    reportEnd,
    exportedBy,
    deviceId
  } = header;

  const { t } = useTranslation();

  const localUtcOffset = dayjs().utcOffset() / 60;
  const localUtcOffsetStr =
    localUtcOffset >= 0 ? `(UTC+${localUtcOffset})` : `(UTC${localUtcOffset})`;

  /** Space between rows */
  const rowPadding = 2;
  const sectionPadding = 8;
  const colSpanLeft = 10;
  const recordAndReport = (): React.JSX.Element => {
    return (
      <>
        <Col span={isHorizontal ? 9 : 8}>
          <Row>
            {startMethod && (
              <>
                <Col span={colSpanLeft}>
                  <Text strong>{t("StartMethod")}: </Text>
                </Col>
                <Col>
                  <Text>{`${startMethod}`}</Text>
                </Col>
              </>
            )}
          </Row>
          <Row style={{ paddingTop: sectionPadding }}>
            {parametersLoaded && (
              <>
                <Col span={colSpanLeft}>
                  <Text strong>{t("ParametersLoaded")}: </Text>
                </Col>
                <Col>
                  <Text>{`${parametersLoaded}`}</Text>
                </Col>
              </>
            )}
            {scheduledToStart && (
              <>
                <Col span={colSpanLeft}>
                  <Text strong>{t("ScheduledToStart")}: </Text>
                </Col>
                <Col>
                  <Text>{`${scheduledToStart}`}</Text>
                </Col>
              </>
            )}
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            {scheduledToEnd && (
              <>
                <Col span={colSpanLeft}>
                  <Text strong>{t("ScheduledToEnd")}: </Text>
                </Col>
                <Col>
                  {scheduledToEnd.time && (
                    <Row>
                      <Text>{`${scheduledToEnd.time}`}</Text>
                    </Row>
                  )}
                  {scheduledToEnd.duration && (
                    <Row>
                      <Text>{`${scheduledToEnd.duration}`}</Text>
                    </Row>
                  )}
                </Col>
              </>
            )}
          </Row>
          <Row style={{ paddingTop: sectionPadding }}>
            {recStart && (
              <>
                <Col span={colSpanLeft}>
                  <Text strong>{t("RecordStart")}: </Text>
                </Col>
                <Col>
                  <Text>{`${recStart}`}</Text>
                </Col>
              </>
            )}
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            {recEnd && (
              <>
                <Col span={colSpanLeft}>
                  <Text strong>{t("RecordEnd")}: </Text>
                </Col>
                <Col>
                  <Text>{`${recEnd}`}</Text>
                </Col>
              </>
            )}
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            {recEndReason && (
              <>
                <Col span={colSpanLeft}>
                  <Text strong>{t("RecordEndReason")}: </Text>
                </Col>
                <Col>
                  <Text>{t(recEndReason as string)}</Text>
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            {reportStart && (
              <>
                <Col span={colSpanLeft}>
                  <Text strong>{t("ReportStart")}: </Text>
                </Col>
                <Col>
                  <Text>{`${reportStart}`}</Text>
                </Col>
              </>
            )}
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            {reportEnd && (
              <>
                <Col span={colSpanLeft}>
                  <Text strong>{t("ReportEnd")}: </Text>
                </Col>
                <Col>
                  <Text>{`${reportEnd}`}</Text>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </>
    );
  };

  const recordAndReportGhostElement = (): React.JSX.Element => {
    return (
      <>
        <Col span={isHorizontal ? 9 : 8}>
          <Row>
            <Col span={7}></Col>
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            <Col span={7}></Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row>
            <Col span={7}></Col>
          </Row>
          <Row style={{ paddingTop: rowPadding }}>
            <Col span={7}></Col>
          </Row>
        </Col>
      </>
    );
  };

  const hasFiltersText = (): string => {
    if (!isUndefined(hasFilters)) {
      if (hasFilters) {
        return `(${t("ReportDataIsFiltered")})`;
      } else {
        return `(${t("ReportDataIsNotFiltered")})`;
      }
    } else {
      return "";
    }
  };

  return (
    <>
      <div style={{ textAlign: "start" }}>
        <Row align="top">
          <Col span={isHorizontal ? 9 : 8}>
            <MobitronLogoBackground width={300} height={100} />
          </Col>

          {header.reportType !== "ProjectReport" && (
            <>
              {/* Ghost element */}
              <Col span={8}></Col>

              <Col span={isHorizontal ? 7 : 8} style={{ paddingTop: 7 }}>
                <Row>
                  <Col>{hasFiltersText()}</Col>
                </Row>
                <Row style={{ paddingTop: rowPadding }}>
                  <Col span={7}>
                    <Text strong>{t("Report")}: </Text>
                  </Col>
                  <Col>
                    <Text>{t(reportType as string)}</Text>
                  </Col>
                </Row>
                <Row style={{ paddingTop: rowPadding }}>
                  <Col span={7}>
                    <Text strong>{t("Exported")}: </Text>
                  </Col>
                  <Col>
                    <Text>
                      {dayjs().format("YYYY-MM-DD, HH:mm:ss ") +
                        localUtcOffsetStr}
                    </Text>
                  </Col>
                </Row>
                <Row style={{ paddingTop: rowPadding }}>
                  {exportedBy && (
                    <>
                      <Col span={7}>
                        <Text strong>{t("ExportedBy")}: </Text>
                      </Col>
                      <Col>
                        <Text>{`${exportedBy.firstName} ${exportedBy.lastName}`}</Text>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>

        {header.reportType !== "ProjectReport" && (
          <>
            <Row style={{ paddingTop: size.m2, paddingLeft: 3 }}>
              {(parametersLoaded || scheduledToStart) && scheduledToEnd
                ? recordAndReport()
                : // If paramDownloaded or scheduledToStart and scheduledToEnd is undefined it creates ghost element to keep Project and Device id columns in place
                  recordAndReportGhostElement()}

              <Col span={isHorizontal ? 7 : 8}>
                <Row>
                  <Col span={7}>
                    <Text strong>{t("Project")}: </Text>
                  </Col>
                  <Col>
                    <Text type={projectName ? undefined : "danger"}>
                      {projectName ?? t("NoProject")}
                    </Text>
                  </Col>
                </Row>
                <Row style={{ paddingTop: rowPadding }}>
                  <Col span={7}>
                    <Text strong>{t("SerialNumber")}: </Text>
                  </Col>
                  <Col>
                    <Text>{deviceId}</Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default ReportHeader;
