import { isUndefined } from "lodash-es";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserRightsQuery } from "../../state/cargologRestApi";
import {
  selectUserRights,
  setUserRights
} from "../../state/persistantStateSlice";

interface IProps {
  right: string;
  children: React.ReactNode;
}

export const License: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const { data: userRightsData } = useGetUserRightsQuery();

  useEffect(() => {
    if (!isUndefined(userRightsData)) {
      dispatch(setUserRights(userRightsData));
    }
  }, [dispatch, userRightsData]);

  return CheckUserRightsAccess(props.right) === true ? (
    <> {props.children} </>
  ) : (
    <> </>
  );
};

export const LicenseAccess = (licenseRight: string, content: JSX.Element) => {
  const dispatch = useDispatch();

  const { data: userRightsData } = useGetUserRightsQuery();

  useEffect(() => {
    if (!isUndefined(userRightsData)) {
      dispatch(setUserRights(userRightsData));
    }
  }, [dispatch, userRightsData]);

  if (CheckUserRightsAccess(licenseRight) === true) {
    return content;
  } else {
    return <> </>;
  }
};

export const CheckUserRightsAccess = (licenseRight: string): boolean => {
  const userRightsState: string[] = useSelector(selectUserRights);
  if (userRightsState?.find((right) => right === licenseRight)) {
    return true;
  } else {
    return false;
  }
};

export const CheckProjectRightsAccess = (
  rightsArray: string[],
  licenseRight: string
): boolean => {
  if (
    rightsArray.length > 0 &&
    rightsArray?.find((right) => right === licenseRight || right === "MOB")
  ) {
    return true;
  } else {
    return false;
  }
};
