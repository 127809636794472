import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreApi } from "./store";
import { SettingsModalPage } from "../components/SettingsModal/SettingsModal";
import { User } from "./cargologRestApi";

/** Main interface for this state-slice */
export interface StateSettings {
  isSettingsModalOpen: boolean;
  currentPage: SettingsModalPage;
  userObject?: User;
  /** A page that can be switched to once systemInfo is available */
  awaitingPage?: SettingsModalPage;
}

/** Initial state for this slice */
const initialState: StateSettings = {
  isSettingsModalOpen: false,
  currentPage: "general",
  userObject: undefined,
  awaitingPage: undefined
};

export const slice = createSlice({
  name: "Settings",
  initialState,
  reducers: {
    openSettingsModal: (state) => {
      state.isSettingsModalOpen = true;
    },
    closeSettingsModal: (state) => {
      state.isSettingsModalOpen = false;
      state.awaitingPage = undefined;
      state.userObject = undefined;
    },
    setCurrentPage: (state, action: PayloadAction<SettingsModalPage>) => {
      state.currentPage = action.payload;
    }
  }
});

export const { openSettingsModal, closeSettingsModal, setCurrentPage } =
  slice.actions;

export const selectSettings = (state: StoreApi) => state.settings;

export default slice.reducer;
