import * as cheerio from "cheerio";
import { TableType } from "./pdfInterfaces";
import { TableCell } from "pdfmake/interfaces";
import { quickReportCircleStringSvg } from "../../icons/QuickReportCircleIcon";
import { QuickReportWarningStringSvg } from "../../icons/QuickReportWarningIcon";

/** Parses a Quick Report table with headers and rows */
export const parseQuickReportTable = (html: HTMLElement): TableType => {
  const parser = cheerio.load(html.outerHTML);

  const htmlTable = parser("table").first();
  const table: TableType = { headers: [], rows: [] };
  // Extract headers and rows from the table
  htmlTable.find("tr").each((index, element) => {
    const headerCells: TableCell[] = [];
    parser(element)
      .find("th")
      .each((index, element) => {
        const text = parser(element).text();
        headerCells.push({
          text: text,
          bold: true,
          alignment: "center"
        });
      });
    if (headerCells.length > 0) {
      table.headers.push(headerCells);
    }
    const contentCells: TableCell[] = [];
    parser(element)
      .find("td")
      .each((index, element) => {
        let svgIcon: string = "";
        parser(element)
          .children()
          .each((index, element) => {
            // Find the already existing svg icons and replace them with the ones existing as strings
            if (element.name === "svg") {
              // Green circle
              if (parser(element).children()[0].attribs.id === "ok_circle") {
                svgIcon = quickReportCircleStringSvg;
              }
              // Warning triangle
              else if (
                parser(element).children()[0].attribs.id === "warning_triangle"
              ) {
                svgIcon = QuickReportWarningStringSvg;
              }
            }
          });

        const text = parser(element).text().replace(/\n/g, "").trim();

        // Push the svg icons and text to different content cells
        if (svgIcon !== "") {
          contentCells.push({
            svg: svgIcon,
            alignment: "center"
          });
        } else {
          contentCells.push({
            text: text,
            alignment: "center"
          });
        }
      });
    if (contentCells.length > 0) {
      table.rows.push(contentCells);
    }
  });

  return table;
};
