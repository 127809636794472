import { BankOutlined, SettingOutlined } from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import { Alert, Descriptions, Spin, Typography } from "antd";
import { isNull } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useGetCompanyDetailsQuery } from "../../state/cargologRestApi";
import { getUser } from "../../state/sessionSlice";
import { openSettingsModal, setCurrentPage } from "../../state/settingsSlice";
import { NormalButton, NormalButtonSmall } from "../Common/CommonButtons";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { CheckUserRightsAccess } from "../MicroComponents/LicenseAccess";
import Online from "../MicroComponents/Online";
import { LandingCard } from "./Landing";
import { size } from "../../helpers/pageHelper";

const { Text } = Typography;

const CompanyLandingCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector(getUser);
  // Load User Company from API
  const { data: companyData, isLoading } = useGetCompanyDetailsQuery(
    currentUser?.companyId ? { companyId: currentUser.companyId } : skipToken
  );
  const isAdmin = CheckUserRightsAccess("AFC");

  return (
    <>
      <LandingCard
        key="company"
        extra={
          isAdmin && (
            <NormalButtonSmall
              icon={<SettingOutlined />}
              onClick={() => {
                dispatch(setCurrentPage("company"));
                dispatch(openSettingsModal());
              }}
            >
              {t("Settings")}
            </NormalButtonSmall>
          )
        }
        title={
          <LargeHeaderTitle>
            <BankOutlined /> {t("Company")}
          </LargeHeaderTitle>
        }
      >
        <Online>
          {/* Alert if company is missing invoice details */}
          {isAdmin &&
          companyData &&
          (isNull(companyData.invoiceEmail) ||
            isNull(companyData.invoiceAddress) ||
            isNull(companyData.invoiceCity) ||
            isNull(companyData.invoicePostalCode)) ? (
            <Alert
              style={{ textAlign: "center" }}
              message={
                <>
                  <Text>
                    {t("YourCompanyIsStillMissingSomeInvoiceDetails")}
                  </Text>
                  <NormalButton
                    icon={<SettingOutlined />}
                    style={{ marginTop: size.s1 }}
                    block
                    onClick={() => {
                      dispatch(setCurrentPage("company"));
                      dispatch(openSettingsModal());
                    }}
                  >
                    {t("OpenCompanySettings")}
                  </NormalButton>
                </>
              }
              type="warning"
            />
          ) : (
            <Spin spinning={isLoading}>
              <Descriptions column={1} size="small">
                <Descriptions.Item
                  label={t("Name")}
                  labelStyle={{ fontWeight: "bold" }}
                  span={2}
                >
                  {companyData?.name}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("Address")}
                  labelStyle={{ fontWeight: "bold" }}
                >
                  {companyData?.address} <br /> {companyData?.address2}{" "}
                  {companyData?.address2 ? <br /> : <></>}{" "}
                  {companyData?.postalCode} <br /> {companyData?.city}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("OrgNumber")}
                  labelStyle={{ fontWeight: "bold" }}
                >
                  {companyData?.orgNumber}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t("AdminEmail")}
                  labelStyle={{ fontWeight: "bold" }}
                >
                  {companyData?.adminEmail}
                </Descriptions.Item>
              </Descriptions>
            </Spin>
          )}
        </Online>
      </LandingCard>
    </>
  );
};

export default CompanyLandingCard;
