import { TFunction } from "i18next";
import { Content, TDocumentDefinitions } from "pdfmake/interfaces";
import { getLocalTimeAndOffset } from "../dateHelper";
import { MobitronLogo } from "../../components/PrintExport/pdfExport";
import { DocumentProps, TransportHeaderData } from "./pdfInterfaces";
import { isUndefined } from "lodash-es";

interface DocumentDefinitionProps {
  content: Content[];
  t: TFunction;
  documentProps: DocumentProps;
  transportHeader?: TransportHeaderData;
  footer?: Content;
}

export const createDocumentDefinition = (props: DocumentDefinitionProps) => {
  const { content, t, documentProps, transportHeader, footer } = props;
  const bottomMargin = footer ? 120 : 40;
  const documentDefinition: TDocumentDefinitions = {
    defaultStyle: {
      font: "Roboto",
      fontSize: 10
    },
    pageOrientation: documentProps.orientation ?? "portrait", // "portrait" | "landscape"
    pageSize: documentProps.paperFormat ?? "A4", // "A4" | "A3" | "LETTER"
    pageMargins: [20, 60, 20, bottomMargin],
    pageBreakBefore: (
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) => {
      // Make Map start on its own page
      if (currentNode.text === "Map" && currentNode.headlineLevel === 1) {
        return true;
      }

      // Page break specified for Recordings table in Project export
      if (
        nodesOnNextPage.length > 0 &&
        !isUndefined(nodesOnNextPage[0].text?.toString())
      ) {
        // Check if the item is a recording description column
        const isRecordingDescription =
          /^((\bRecording Description\b)[^$]*)$/.test(
            nodesOnNextPage[0].text?.toString()
          );

        // If the next item is a recording description column and the current page
        // has 7-11 items left we break so that the description is not split from
        // its main data row.
        // (Nodes 1-6 that are left on the page isthe footer)
        if (
          followingNodesOnPage.length >= 7 &&
          followingNodesOnPage.length <= 11 &&
          isRecordingDescription
        ) {
          return true;
        }
      }

      // If the current item is a headline and the next item has a columns property we have probably found an orphan header
      if (currentNode.headlineLevel === 1 && followingNodesOnPage.length > 0) {
        const nextItem = followingNodesOnPage[0];
        if (nextItem && nextItem.columns) return true;
      }
      // If the current item is a headline and and starts just above the bottom of the page we probably have a table that is split very close to its start
      if (
        currentNode.headlineLevel === 1 &&
        currentNode.startPosition.verticalRatio > 0.8
      ) {
        return true;
      }
      // Otherwise we don't break manually
      return false;
    },
    watermark: transportHeader?.hasFilters
      ? { text: t("ReportDataIsFiltered"), opacity: 0.1 }
      : undefined,
    header: () => {
      return {
        columns: [
          {
            text: documentProps.documentTitle,
            alignment: "left",
            fontSize: 14,
            margin: [20, 14, 0, 0]
          },
          {
            svg: MobitronLogo,
            alignment: "right",
            width: 90,
            height: 30,
            margin: [0, 7, 10, 0]
          }
        ],
        margin: [0, 0, 0, 20]
      };
    },
    footer: (currentPage, pageCount, pageSize) => {
      if (footer) {
        return footer;
      }
      return {
        columns: [
          {
            text: getLocalTimeAndOffset(),
            alignment: "left",
            margin: [20, 0, 0, 0]
          },
          {
            text: transportHeader?.deviceId ?? "Mobitron AB",
            alignment: "center"
          },
          {
            text: currentPage.toString() + " / " + pageCount,
            alignment: "right",
            margin: [0, 0, 20, 0]
          }
        ],
        margin: [0, 20, 0, 0]
      };
    },
    content
  };
  return documentDefinition;
};
