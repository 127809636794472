//--- control bits
/** Save value with OnPeriod interval and trig alarm if needed*/
export const SCTRL_RECORD = 0x0001;
/** Measure value with MeasureInterval and trig alarm if needed */
export const SCTRL_MEASURE = 0x0002;
/** Trigger channel on event from other channel */
export const SCTRL_EVENTTRIGGED = 0x0004;
/** Turn on channel for OnPerod and off for OffPeroid */
export const SCTRL_PERODIC = 0x0010;
/** Keep on during schedule time range */
export const SCTRL_ALWAYSON = 0x0020;
/** Used for channels LTE,GPS and Bluetooth to keep power off during schedule time range */
export const SCTRL_FLIGHTMODE = 0x0100;
/** Keep the channel inactive during this time range. */
export const SCTRL_INACTIVE = 0x8000;
/** For LTE only. A GPS position is needed before LTE is started */
export const SCTRL_GPSREQUIRED = 0x0200;
