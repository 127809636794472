import * as React from "react";
import type { SVGProps } from "react";
const SvgFlightMode = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    aria-hidden="true"
    data-icon="edit"
    viewBox="64 64 896 896"
    {...props}
  >
    <path d="M348 160c-21 0-34 5-27 24 17 43 106 264 106 264H237L120 331v363l117-117h190s-89 221-106 264c-7 19 6 24 27 24 23 0 44-6 63-27l224-260h210c33 0 61-32 61-65s-28-65-61-65H635L411 188c-19-21-40-27-63-27z" />
  </svg>
);
export default SvgFlightMode;
