import * as React from "react";
import type { SVGProps } from "react";
const SvgExtTempIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 141.73 141.73"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      d="M71.718 1.488a11.3 11.3 0 0 0-11.539 10.586v69.238a30.66 30.66 0 0 0-19.795 28.586 30.66 30.66 0 0 0 30.658 30.658 30.66 30.66 0 0 0 30.658-30.658A30.66 30.66 0 0 0 81.905 81.24V11.754A11.064 11.064 0 0 0 71.717 1.488zm-.28 4.502a6.48 6.48 0 0 1 5.965 6.012V46.1H64.68V12.19a6.62 6.62 0 0 1 6.758-6.2z"
      fill={props.fill === undefined ? "#ee2424" : props.fill}
    />
    <path
      d="M100.16 26.22v4.49H87.888v-4.49zM100.16 38.22v4.49H87.888v-4.49zM100.16 50.22v4.49H87.888v-4.49zM100.16 62.22v4.49H87.888v-4.49z"
      fillRule="evenodd"
      fill={props.fill === undefined ? "#ee2424" : props.fill}
    />
    <g aria-label="E" fill="#fff">
      <path
        d="M0 0h22.709v5.104H5.734v12.215h16.401v5.132H5.734v12.444h17.519V40H-.001z"
        fill="#535353"
      />
    </g>
  </svg>
);
export default SvgExtTempIcon;
