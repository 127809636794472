import React from "react";
import { Spin, Statistic, Typography } from "antd";
import {
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined
} from "@ant-design/icons";
import { LandingCard } from "./Landing";
import {
  useGetUsersQuery,
  useGetUserTransferRequestsQuery
} from "../../state/cargologRestApi";
import { openUserRequestAdminModal } from "../../state/modalsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { size } from "../../helpers/pageHelper";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import { LargeHeaderTitle } from "../Common/CommonFonts";
import { NormalButton, NormalButtonSmall } from "../Common/CommonButtons";
import { setCurrentAdminTab } from "../../state/navigationSlice";
import { InfoAlert } from "../Common/CommonAlerts";
import Online from "../MicroComponents/Online";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const UsersLandingCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: usersData, isLoading } = useGetUsersQuery();
  const { data: requestsData } = useGetUserTransferRequestsQuery();
  const navigate = useNavigate();
  return (
    <>
      {LicenseAccess(
        "AFA",
        <LandingCard
          key="users"
          extra={
            <NormalButtonSmall
              onClick={() => {
                dispatch(setCurrentAdminTab("users"));
                navigate("/admin");
              }}
            >
              {t("Manage")}
            </NormalButtonSmall>
          }
          title={
            <LargeHeaderTitle>
              <TeamOutlined /> {t("Users")}
            </LargeHeaderTitle>
          }
        >
          <Online>
            {/* Number of users in statistical form. Add alert for Admin if there are user requests. Link to User Request modal, Link to Create New User modal */}
            <Spin spinning={isLoading}>
              <Statistic
                title={t("ActiveUsers")}
                value={usersData?.length}
                prefix={<UserOutlined />}
              />
            </Spin>

            {requestsData && requestsData.length > 0 ? (
              <InfoAlert
                style={{
                  marginTop: size.l2,
                  textAlign: "center"
                }}
                message={
                  <>
                    <Text>
                      {/* todo: this will not work with other languages than English */}
                      {t("YouHave")} {requestsData.length}{" "}
                      {requestsData.length > 1
                        ? t("userRequestsToReplyTo")
                        : t("userRequestToReplyTo")}
                    </Text>
                    <NormalButton
                      icon={<UserSwitchOutlined />}
                      style={{ marginTop: size.s1 }}
                      block
                      onClick={() => {
                        dispatch(setCurrentAdminTab("users"));
                        dispatch(openUserRequestAdminModal());
                        navigate("/admin");
                      }}
                    >
                      {t("OpenUserRequests")}
                    </NormalButton>
                  </>
                }
              />
            ) : (
              <></>
            )}
          </Online>
        </LandingCard>
      )}
    </>
  );
};

export default UsersLandingCard;
