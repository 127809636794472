export interface VMGpsScheduleParams {
  useGpsInterval: boolean;
  alwaysOn: boolean;
  intervalDays: number;
  intervalHours: number;
  intervalMinutes: number;
  flightModeGps: boolean;
}

export interface VMGpsParams {
  storeSpeed: boolean;
}

export const emptyGpsParams: VMGpsScheduleParams = {
  useGpsInterval: true,
  alwaysOn: false,
  intervalDays: 0,
  intervalHours: 6,
  intervalMinutes: 0,
  flightModeGps: false
};

export const disabledGpsParams: VMGpsScheduleParams = {
  ...emptyGpsParams,
  useGpsInterval: false
};
