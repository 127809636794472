import React from "react";
import { Avatar, Dropdown, Menu, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  appType,
  useRevokeAccessTokenMutation
} from "../../state/cargologRestApi";
import { isUndefined } from "lodash-es";
import { useNavigate } from "react-router";
import { openSettingsModal, setCurrentPage } from "../../state/settingsSlice";
import { clearSessionAndData } from "../../App";
import { closeModals } from "../../AppLayout";
import { selectImpersonate } from "../../state/persistantStateSlice";
const { Text } = Typography;

interface IProps {
  initials: string;
  firstName: string;
  lastName: string;
  email: string;
}

const UserAvatar: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isImpersonating } = useSelector(selectImpersonate);

  /* Get API for Revoke Access Token */
  const [revokeAccessToken, revokeAccessTokenStatus] =
    useRevokeAccessTokenMutation();

  // Get Revoke Access Token status
  const { isSuccess: revokeAccessTokenSuccess } = revokeAccessTokenStatus;

  // If Revoke Access Token succeeds
  if (!isUndefined(revokeAccessTokenSuccess)) {
  }

  const navigate = useNavigate();

  const onMenuClick = (event: any) => {
    switch (event.key) {
      case "username": {
        closeModals(dispatch);
        dispatch(setCurrentPage("account"));
        dispatch(openSettingsModal());
        break;
      }
      case "settings": {
        closeModals(dispatch);
        dispatch(setCurrentPage("general"));
        dispatch(openSettingsModal());
        break;
      }
      case "signOut": {
        revokeAccessToken(appType);
        navigate("/");
        /* Clear current user data and access tokens */
        clearSessionAndData(dispatch);
        break;
      }
      default: {
        break;
      }
    }
  };

  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="username">
        <>
          <Text strong>
            {props.firstName} {props.lastName}
          </Text>
          <br />
          <Text type="secondary">{props.email}</Text>
        </>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="settings">{t("Settings")}</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="signOut" disabled={isImpersonating}>
        <Text type={isImpersonating ? "secondary" : "danger"}>
          {t("SignOut")}
        </Text>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        trigger={["click"]}
        overlay={menu}
        placement="bottomRight"
        arrow
      >
        <Avatar
          style={{
            cursor: "pointer",
            verticalAlign: "middle",
            backgroundColor: "#286123"
          }}
        >
          {props.initials}
        </Avatar>
      </Dropdown>
    </>
  );
};

export default UserAvatar;
