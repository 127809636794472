import React, { useState } from "react";
import { Col, ColProps, Pagination, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTargetDevice,
  setParamsUserProgress,
  setRecordingParametersTargetDevice
} from "../../state/paramsPageSlice";
import dayjs from "dayjs";
import { interpretSystemInfoHasFeatures } from "../../helpers/parsers/parseSystemInfoHelper";
import { LargeTitle, SmallTitle } from "../Common/CommonFonts";
import { useTranslation } from "react-i18next";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import { createFeaturesAsCommaSeperatedString } from "../../helpers/dataModelHelper";
import {
  defaultTableColumnPropsNumber,
  defaultTableColumnPropsString
} from "../../constants/defaultComponentsProps";
import { useGetRecentDeviceQuery } from "../../state/cargologRestApi";
import { setCompanyId } from "../../state/openParxSlice";
import { getUser } from "../../state/sessionSlice";
import { size } from "../../helpers/pageHelper";

interface ITableData {
  key: number;
  serialNumber: string;
  lastSeen: number;
  features: string;
  systemInfo: GeneralSystemInfo;
}

interface IProps {}
const ParamsBuilderStart: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: myDevices } = useGetRecentDeviceQuery();
  const { companyId } = useSelector(getUser);

  const targetDevice = useSelector(selectTargetDevice);
  const hasSerial = targetDevice.serial && targetDevice.serial !== "default";

  const tableData = myDevices?.map((item, index) => {
    const key = index;
    const serialNumber = item.systemInfo.serial.toString();
    const lastSeen = item.lastInteraction;

    const { systemInfo } = item;

    const featuresObj = interpretSystemInfoHasFeatures(systemInfo.hasFeatures);
    const features = createFeaturesAsCommaSeperatedString(featuresObj, t);

    return { key, serialNumber, lastSeen, features, systemInfo };
  });

  const columns: ColumnsType<ITableData> = [
    {
      ...defaultTableColumnPropsString,
      title: t("Serialnumber"),
      dataIndex: "serialNumber",
      width: 200,
      sorter: (a, b) => a.serialNumber.localeCompare(b.serialNumber)
    },
    {
      ...defaultTableColumnPropsNumber,
      title: t("LastSeen"),
      dataIndex: "lastSeen",
      width: 250,
      render: (item: number) => dayjs.unix(item).format("YYYY-MM-DD"),
      sorter: (a, b) => a.lastSeen - b.lastSeen
    },
    {
      title: t("Features"),
      dataIndex: "features"
    }
  ];

  const FullSizeCol: React.FC<ColProps> = (props) => (
    <Col span={24}>
      <Row align="middle">{props.children}</Row>
    </Col>
  );

  const WhiteSpaceSeperator = (props: { style?: React.CSSProperties }) => (
    <div style={{ height: "24px", ...props.style }} />
  );

  const [activePage, setActivePage] = useState(1);
  const [tablePageSize, setTablePageSize] = useState(10);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignContent: "space-between"
      }}
    >
      {/* Upper content */}
      <FullSizeCol>
        <SmallTitle strong={false}>{t("SelectedDevice")}</SmallTitle>
      </FullSizeCol>

      <FullSizeCol>
        <LargeTitle style={{ margin: 0, marginTop: size.s2 }} type="secondary">
          {hasSerial ? targetDevice.serial : t("NoDeviceSelected")}
        </LargeTitle>
      </FullSizeCol>

      {/* Creates space between "upper" and "lower" content */}
      <WhiteSpaceSeperator style={{ flexGrow: 0 }} />

      {/* Lower content */}
      <div style={{ width: "100%", textAlign: "end" }}>
        <FullSizeCol>
          <SmallTitle>{t("MyDevices")}</SmallTitle>
        </FullSizeCol>

        <Table
          style={{ width: "100%" }}
          columns={columns}
          dataSource={tableData}
          //Note: Pagination will not be rendered here, a dedicated pagination
          //componenet an be found further down
          pagination={{
            pageSize: tablePageSize,
            current: activePage,
            style: {
              visibility: "hidden",
              height: "0px",
              margin: "0px"
            }
          }}
          onRow={(record, index) => {
            return {
              onClick: () => {
                dispatch(setRecordingParametersTargetDevice(record.systemInfo));
                dispatch(setCompanyId(companyId ?? ""));
                dispatch(setParamsUserProgress(1));
              },
              style: {
                cursor: "pointer"
              }
            };
          }}
        />
        <Pagination
          showSizeChanger
          current={activePage}
          pageSize={tablePageSize}
          total={tableData?.length}
          onChange={(newPage) => setActivePage(newPage)}
          onShowSizeChange={(newPage, newPageSize) => {
            setActivePage(newPage);
            setTablePageSize(newPageSize);
          }}
          style={{ marginTop: size.m2 }}
        />
      </div>
    </div>
  );
};

export default ParamsBuilderStart;
