import { GeneralSystemInfo, ISystemInfoV2 } from "../models/ISystemInfo";

const defaltTimestamp = {
  year: 0,
  month: 0,
  day: 0,
  hour: 0,
  minute: 0,
  second: 0
};

/** Function that converts the combined GeneralSystemInfo type to SystemInfo, which is the format of the API */
export const generalSysInfoToSyncable = (
  generalSystemInfo: GeneralSystemInfo
): ISystemInfoV2 => {
  // If no configVersion is found, config version 1 is assumed
  const configVersion =
    "configVersion" in generalSystemInfo ? generalSystemInfo.configVersion : 1;
  const mainBatteryVoltage =
    "mainBatteryVoltage" in generalSystemInfo
      ? generalSystemInfo.mainBatteryVoltage
      : 0;

  let rebootTimeStamp = defaltTimestamp;
  if ("rebootTimeStamp" in generalSystemInfo) {
    rebootTimeStamp = generalSystemInfo.rebootTimeStamp;
  }

  let mainBatteryTimeStamp = defaltTimestamp;
  if ("mainBatteryTimeStamp" in generalSystemInfo) {
    mainBatteryTimeStamp = generalSystemInfo.mainBatteryTimeStamp;
  }

  const syncableSysInfo: ISystemInfoV2 = {
    serial: generalSystemInfo.serial.toString(),
    configVersion: configVersion,
    parxVersion: generalSystemInfo.parxVersion,
    datxVersion: generalSystemInfo.datxVersion,
    hasFeatures: generalSystemInfo.hasFeatures,
    accStdFilter: generalSystemInfo.accStdFilter,
    accExtraFilter: generalSystemInfo.accExtraFilter,
    accStdMaxG: generalSystemInfo.accStdMaxG,
    accExtraMaxG: generalSystemInfo.accExtraMaxG,
    memoryAvailable: generalSystemInfo.memoryAvailable,
    memoryUsed: generalSystemInfo.memoryUsed,
    artNum: generalSystemInfo.artNum,
    powerSource: generalSystemInfo.powerSource,
    mainBatteryVoltage: mainBatteryVoltage,
    rebootTimeStamp: rebootTimeStamp,
    mainBatteryTimeStamp: mainBatteryTimeStamp,
    mainBattery: generalSystemInfo.mainBattery,
    backupBattery: generalSystemInfo.backupBattery,
    runningStatus: generalSystemInfo.runningStatus,
    lastCalibration: generalSystemInfo.lastCalibration,
    fwMajorVersion: generalSystemInfo.fwMajorVersion,
    fwMinorVersion: generalSystemInfo.fwMinorVersion,
    fwMainBuild: generalSystemInfo.fwMainBuild,
    fwSubBuild: generalSystemInfo.fwSubBuild
  };

  return syncableSysInfo;
};
