import { Buffer } from "buffer";
import { parxHeaderLength } from "../../constants/fat100SpecDefaults";
import { KIND_PARXHEADER } from "../../models/FAT100Kinds";
import { IParxFile } from "../../models/PARX/IParxFile";
import { parseRecordingParameters } from "./parseRecordingParamsHelper";

export const parseParx = (data: Buffer): IParxFile => {
  let bufPos = 0;
  let headerLength = parxHeaderLength - 4;
  if (data.readUInt16LE(bufPos) === KIND_PARXHEADER) {
    bufPos += 4;
    headerLength += 4;
  }

  const header = {
    fileVersion: data.readUInt16LE(bufPos + 0),
    appId: data.readUInt8(bufPos + 2),
    appVersionMajor: data.readUInt8(bufPos + 3),
    appVersionMinor: data.readUInt8(bufPos + 4),
    appBuildMain: data.readUInt8(bufPos + 5),
    appBuildSub: data.readUInt8(bufPos + 6),
    timezoneId: data.readUInt8(bufPos + 7),
    configVersion: data.readUInt16LE(bufPos + 8)
  };

  const recordingParametersSlice = data.subarray(headerLength);

  const recordingParameters = parseRecordingParameters(
    recordingParametersSlice
  );

  return { header, recordingParameters };
};
