import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closeVectorSettingsModal, modalsState } from "../../state/modalsSlice";
import StandardModal from "../Common/StandardModal";
import { InputNumber, Select, Space } from "antd";
import { ParamsCardSubHeader } from "../Common/CommonFonts";
import { NormalButton } from "../Common/CommonButtons";
import {
  selectVectorData,
  setVectorSize,
  setVectorTimespan
} from "../../state/persistantStateSlice";
import { isUndefined } from "lodash-es";

const VectorSettingsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector(modalsState);

  const { vectorTimespan, vectorSize } = useSelector(selectVectorData);

  // If no data has been set, set default values
  if (isUndefined(vectorTimespan)) {
    dispatch(setVectorTimespan(5));
  }
  if (isUndefined(vectorSize)) {
    dispatch(setVectorSize(1));
  }

  return (
    <>
      <StandardModal
        title={t("VectorLeapSettings")}
        closable={true}
        open={modals.isVectorSettingsModalOpen}
        width={400}
        footer={[
          <NormalButton onClick={() => dispatch(closeVectorSettingsModal())}>
            {t("Close")}
          </NormalButton>
        ]}
        onCancel={() => dispatch(closeVectorSettingsModal())}
        zIndex={1048}
      >
        <Space size="large">
          <Space direction="vertical">
            <ParamsCardSubHeader>{t("Timespan")}</ParamsCardSubHeader>
            <Select
              defaultValue={vectorTimespan}
              style={{ width: 100 }}
              onChange={(value) => dispatch(setVectorTimespan(Number(value)))}
              // todo: how many options should there be?
              options={[
                { value: 5, label: "5 ms" },
                { value: 10, label: "10 ms" },
                { value: 15, label: "15 ms" },
                { value: 20, label: "20 ms" },
                { value: 25, label: "25 ms" },
                { value: 30, label: "30 ms" },
                { value: 35, label: "35 ms" },
                { value: 40, label: "40 ms" },
                { value: 45, label: "45 ms" },
                { value: 50, label: "50 ms" }
              ]}
            />
          </Space>

          <Space direction="vertical">
            <ParamsCardSubHeader>{t("VectorSize") + "(g)"}</ParamsCardSubHeader>
            <InputNumber
              defaultValue={vectorSize}
              min={0.1}
              max={15}
              step={1}
              onChange={(value) => dispatch(setVectorSize(Number(value)))}
            />
          </Space>
        </Space>
      </StandardModal>
    </>
  );
};

export default VectorSettingsModal;
