import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import dayjs from "dayjs";
import { ExternalTimer } from "./ExternalTimersGraph";

export interface ExternalTimersTableData {
  key: number;
  sensorId: number;
  startTime: number;
  duration: number;
}

interface IProps {
  data: ExternalTimer[];
  timezone: string;
}

const createTableData = (data: ExternalTimer[]): ExternalTimersTableData[] => {
  const sortedData = data.sort((a, b) => {
    return a.x.getTime() - b.x.getTime();
  });

  const keyedData: ExternalTimersTableData[] =
    sortedData.length > 0
      ? sortedData.map((data, index) => {
          return {
            key: index,
            sensorId: data.data.sensorId,
            startTime: data.x.getTime(),
            duration: data.data.timerTime
          };
        })
      : [];

  return keyedData;
};

const ExternalTimersTable = (props: IProps) => {
  const { t } = useTranslation();
  const { data, timezone } = props;
  const tableData = data ? createTableData(data) : [];

  const tableColumns = [
    // {
    //   title: t("Sensor"),
    //   dataIndex: "sensorId",
    //   key: "sensorId",
    // },
    {
      title: t("StartTime"),
      dataIndex: "startTime",
      key: "startTime",
      render: (time: Date) => {
        return dayjs(time).tz(timezone).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    {
      title: t("Duration"),
      dataIndex: "duration",
      key: "duration",
      render: (duration: number) => {
        return duration + " ms";
      }
    }
  ];

  return (
    <Table
      columns={tableColumns}
      dataSource={tableData}
      size="small"
      tableLayout="auto"
      pagination={false}
    />
  );
};

export default ExternalTimersTable;
