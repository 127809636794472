import { CheckOutlined } from "@ant-design/icons";
import { Input, List } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { formatUtcOffset, getAllTzWithOffset } from "../../helpers/dateHelper";
import { closeTimezoneModal, modalsState } from "../../state/modalsSlice";
import {
  selectGlobalTimezone,
  setGlobalTimezoneState
} from "../../state/sessionSlice";
import mobitronColors from "../../styles/mobitronColors";
import { SmallText } from "../Common/CommonFonts";
import { size } from "../../helpers/pageHelper";
import StandardModal from "../Common/StandardModal";

/**  */
const TimezoneModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modals = useSelector(modalsState);
  const timezoneState = useSelector(selectGlobalTimezone);

  const [timeZoneFilter, setTimeZoneFilter] = useState("");

  const allTimezones = getAllTzWithOffset();

  const filteredTimeZones = allTimezones.filter((x) =>
    timeZoneFilter === "" ? true : x.name.toLowerCase().includes(timeZoneFilter)
  );

  const isActiveTimezone = (zone: string) => zone === timezoneState;

  const activeItemStyle: React.CSSProperties = {
    color: mobitronColors.altDarkGreen
  };

  /** When timezone is set user details is updated with the selected timezone */
  const selectTimezone = (zone: string) => {
    dispatch(setGlobalTimezoneState(zone));
  };

  return (
    <>
      <StandardModal
        title={t("SelectGlobalTimezone")}
        closable={true}
        open={modals.isTimezoneModalOpen}
        footer={null}
        onCancel={() => dispatch(closeTimezoneModal())}
        zIndex={1045}
      >
        <div>
          <Input.Search
            placeholder={t("SearchForTimezone")}
            enterButton
            allowClear
            size="middle"
            value={timeZoneFilter}
            onChange={(e) => setTimeZoneFilter(e.currentTarget.value)}
            autoFocus={true}
          />

          <div>
            <List
              style={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
              dataSource={filteredTimeZones}
              renderItem={(item) => (
                <List.Item
                  key={item.name}
                  onClick={() => selectTimezone(item.name)}
                  style={{ cursor: "pointer" }}
                >
                  <SmallText
                    style={isActiveTimezone(item.name) ? activeItemStyle : {}}
                  >
                    {`${item.name} (UTC ${formatUtcOffset(item.offset)})`}
                  </SmallText>
                  <div style={{ paddingRight: size.s2 }}>
                    {isActiveTimezone(item.name) ? (
                      <CheckOutlined style={activeItemStyle} />
                    ) : null}
                  </div>
                </List.Item>
              )}
            />
          </div>
        </div>
      </StandardModal>
    </>
  );
};

export default TimezoneModal;
