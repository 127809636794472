import React from "react";
import { EnvironmentOutlined } from "@ant-design/icons";
import { LandingCard } from "./Landing";
import { LicenseAccess } from "../MicroComponents/LicenseAccess";
import { LargeHeaderTitle, SmallTitle } from "../Common/CommonFonts";
import { LatLngExpression, LatLngTuple } from "leaflet";
import { size } from "../../helpers/pageHelper";
import { isUndefined, uniqBy } from "lodash-es";
import {
  useGetAllProjectGpsQuery,
  useGetAllProjectsQuery,
  useGetParametersQuery
} from "../../state/cargologRestApi";
import dayjs from "dayjs";
import { Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { deviceColorsArray } from "../../constants/colors";
import DashboardMap from "../../assets/img/DashboardMap.png";
import DeviceMap, { DeviceData, GpsPosData } from "../DevicesPage/DeviceMap";
const { Text } = Typography;

const MapLandingCard = () => {
  const { t } = useTranslation();
  const { data: parameters } = useGetParametersQuery({});
  const { data: gpsPos } = useGetAllProjectGpsQuery();
  const { data: projects } = useGetAllProjectsQuery();

  const colorsArray = [...deviceColorsArray];

  const getLineColor = () => {
    return { color: colorsArray.pop() ?? "#409c46" };
  };

  const gpsData = gpsPos
    ? gpsPos.map((gps) => {
        const lastElement = gps.gpsData?.[gps.gpsData.length - 1];

        const timezoneOffset = new Date().getTimezoneOffset();
        const lastDateTime = dayjs
          .utc(lastElement?.dateTime)
          .subtract(timezoneOffset, "minutes")
          .format("YYYY-MM-DD, HH:mm:ss");

        const lastVelocity = lastElement?.velocity;
        const lastStatus = lastElement?.status ?? 100;

        const lastLat = lastElement?.lat ?? 57.8563;
        const lastLon = lastElement?.lon ?? 14.30782;

        const lastPos = {
          lastDateTime: lastDateTime,
          lastLat: lastLat,
          lastLon: lastLon,
          lastVelocity: lastVelocity ?? 0,
          lastStatus: lastStatus
        };

        /** Used for Polyline */
        const gpsPosData: GpsPosData[] = gps.gpsData
          ? gps.gpsData.map((data) => {
              return {
                path: [data.lat, data.lon],
                velocity: data.velocity,
                dateTime: data.dateTime,
                status: data.status
              };
            })
          : [];

        const sortedGpsPos = gpsPosData.sort((a, b) =>
          a.dateTime.localeCompare(b.dateTime)
        );

        return {
          parameterId: gps.parameterId ?? "",
          lastPos: lastPos,
          gpsPosData: sortedGpsPos
        };
      })
    : [];

  // sort parameters by id
  const sortedParameters = parameters
    ? [...parameters].sort((a, b) => b.id.localeCompare(a.id) ?? 0)
    : [];
  // remove duplicate parameters by deviceName
  const uniqueProjectDevices = uniqBy(sortedParameters, "deviceName");

  const deviceData: DeviceData[] = uniqueProjectDevices
    ? uniqueProjectDevices.map((param, index) => {
        const gps = gpsData.find((gps) => gps.parameterId === param.id);
        const project = projects?.find(
          (project) => project.id === param.projectId
        );
        const lineColor = !isUndefined(gps?.lastPos)
          ? getLineColor().color
          : "";

        return {
          parameterId: param.id ?? "",
          serialNumber: param.deviceName ?? "",
          project: {
            id: project?.id ?? "",
            name: project?.title ?? ""
          },
          lastPos: gps?.lastPos,
          deviceDescription: "",
          gpsPosData: gps?.gpsPosData ?? [],
          lineColor: lineColor,
          key: index.toString()
        };
      })
    : [];

  /** Used for centering the map when there is only one device in the project */
  let center: LatLngTuple = [57.8563, 14.30782];
  /** Used for creating a map bounds that shows all the devices in the project*/
  let outerBounds: LatLngTuple[] = [];

  // Sets the map boundaries making all devices and positions visible on the map when showing the map
  deviceData.forEach((device) => {
    if (device?.lastPos) {
      const mapOrigo: LatLngExpression = [
        device.lastPos.lastLat,
        device.lastPos.lastLon
      ];
      center = mapOrigo;
      outerBounds.push(mapOrigo);
    }

    device?.gpsPosData?.forEach((data) => {
      outerBounds.push([data.path[0], data.path[1]]);
    });
  });

  return (
    <>
      {LicenseAccess(
        "ABA",
        <LandingCard
          key="devices"
          title={
            <LargeHeaderTitle>
              <EnvironmentOutlined /> {t("Map")}
            </LargeHeaderTitle>
          }
          styles={{ body: { padding: 0, overflow: "hidden" } }}
        >
          {outerBounds.length > 0 ? (
            // HAS GPS DATA
            <DeviceMap
              center={center}
              outerBounds={outerBounds}
              deviceData={deviceData}
              showStartPos={false}
              showAlarms={false}
              showDevices={true}
              showPolylines={true}
            />
          ) : (
            // NO GPS DATA
            <div>
              <Row
                justify="center"
                align="middle"
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "245px",
                  marginBlock: "auto",
                  zIndex: 1040,
                  paddingInline: size.xl2
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                    padding: size.m1,
                    borderRadius: size.m1,
                    boxShadow: "0px 0px 75px 10px rgba(0,0,0,0.12)"
                  }}
                >
                  <Row justify="center">
                    <SmallTitle style={{ marginBottom: 0 }}>
                      {t("LocationsNotFound")}
                    </SmallTitle>
                  </Row>
                  <Row style={{ textAlign: "center" }}>
                    <Text>
                      {t("ThereAreCurrentlyNoActiveDevicesWithGPSData")}
                    </Text>
                  </Row>
                </div>
              </Row>
              <div
                style={{
                  overflow: "hidden",
                  borderBottomLeftRadius: size.m1,
                  borderBottomRightRadius: size.m1,
                  width: "100%",
                  height: "245px"
                }}
              >
                <img
                  src={DashboardMap}
                  style={{
                    width: "100%",
                    height: "245px",
                    overflow: "hidden",
                    filter: "blur(6px)",
                    borderBottomLeftRadius: size.m1,
                    borderBottomRightRadius: size.m1,
                    zIndex: 1035
                  }}
                />
              </div>
            </div>
          )}
        </LandingCard>
      )}
    </>
  );
};

export default MapLandingCard;
