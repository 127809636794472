import * as React from "react";
import type { SVGProps } from "react";
const SvgAccGeneral = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 141.73 141.73"
    {...props}
  >
    <path
      fill={props.fill === undefined ? "#ec008c" : props.fill}
      d="M123.99 20.05h10.16v110.8h-10.16z"
    />
    <path
      fill={props.fill === undefined ? "#ec008c" : props.fill}
      d="m116.41 23.75 12.66-21.92 12.66 21.92z"
    />
    <path
      fill={props.fill === undefined ? "#00aeef" : props.fill}
      d="M18.22 122.17h114.21v10.16H18.22z"
    />
    <path
      fill={props.fill === undefined ? "#00aeef" : props.fill}
      d="M21.93 139.91 0 127.24l21.93-12.66z"
    />
    <path
      fill={props.fill === undefined ? "#8dc63f" : props.fill}
      d="m83.1 88.462 7.185-7.185 39.513 39.513-7.185 7.185z"
    />
    <path
      fill={props.fill === undefined ? "#8dc63f" : props.fill}
      d="m98.27 78.53-24.46-6.55 6.55 24.46z"
    />
    <circle
      cx={129.07}
      cy={127.24}
      r={8.92}
      fill={props.fill === undefined ? "#8dc63f" : props.fill}
    />
  </svg>
);
export default SvgAccGeneral;
