import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { AccData } from "../../state/openDatxSlice";
import { SmallText } from "../Common/CommonFonts";
import { createTzDate } from "../../helpers/dateHelper";
import { useTranslation } from "react-i18next";

interface ITableData {
  key: number;
  timestamp: string;
  xAcc: number;
  yAcc: number;
  zAcc: number;
  resultant: string;
  duration: string;
  trigger: "x" | "y" | "z";
}

interface IProps {
  //acc data of size 10
  accData: AccData[];
}
const TopAccTable = (props: IProps) => {
  const { t } = useTranslation();
  const { accData } = props;

  const tableData: ITableData[] = accData.map((d, index) => {
    const [xAcc, xDuration] = d.xAcc;
    const [yAcc, yDuration] = d.yAcc;
    const [zAcc, zDuration] = d.zAcc;

    const key = index;
    const trigger = xDuration > 0 ? "x" : yDuration > 0 ? "y" : "z";
    const resultant =
      Math.sqrt(
        Math.pow(xAcc, 2) + Math.pow(yAcc, 2) + Math.pow(zAcc, 2)
      ).toFixed(2) + "g";
    const duration = Math.max(xDuration, yDuration, zDuration) + "ms";

    const timestamp = createTzDate(d.timestamp, d.timezone).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    return {
      xAcc,
      yAcc,
      zAcc,
      duration,
      resultant,
      key,
      trigger,
      timestamp
    };
  });

  const marker = (mark: boolean): React.CSSProperties => ({
    backgroundColor: mark ? "#d9d9d9" : "transparent"
  });

  const renderAcc = (v: number, isWithDurr: boolean) => (
    <SmallText style={marker(isWithDurr)}>{`${v}g`}</SmallText>
  );

  const columns: ColumnsType<ITableData> = [
    {
      title: t("Timestamp"),
      dataIndex: "timestamp",
      key: "timestamp"
    },

    {
      title: t("XAxis"),
      dataIndex: "xAcc",
      key: "xAcc",
      render: (v, r) => renderAcc(v, r.trigger === "x")
    },
    {
      title: t("YAxis"),
      dataIndex: "yAcc",
      key: "yAcc",
      render: (v, r) => renderAcc(v, r.trigger === "y")
    },
    {
      title: t("ZAxis"),
      dataIndex: "zAcc",
      key: "zAcc",
      render: (v, r) => renderAcc(v, r.trigger === "z")
    },
    {
      title: t("Resultant"),
      dataIndex: "resultant",
      key: "resultant"
    },
    {
      title: t("Duration"),
      dataIndex: "duration",
      key: "duration"
    }
  ];

  return (
    <Table
      size="small"
      dataSource={tableData}
      columns={columns}
      pagination={false}
    />
  );
};

export default TopAccTable;
