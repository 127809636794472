import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreApi } from "./store";

/** Main interface for this state-slice */
export interface ExportState {
  mapExportImage?: string;
  isFirstScreenshot: boolean;
}

/** Initial state for this slice */
const initialState: ExportState = {
  mapExportImage: "",
  isFirstScreenshot: true
};

export const slice = createSlice({
  name: "Export",
  initialState,
  reducers: {
    setMapExportImage: (state, action: PayloadAction<string>) => {
      state.mapExportImage = action.payload;
    },
    setIsFirstScreenshot: (state, action: PayloadAction<boolean>) => {
      state.isFirstScreenshot = action.payload;
    }
  }
});

export const { setMapExportImage, setIsFirstScreenshot } = slice.actions;

export const selectExport = (state: StoreApi) => state.export;

export default slice.reducer;
