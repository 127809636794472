import { Row, Tabs } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  AccGeneral,
  AngleIcon,
  ExtInputIcon,
  ExtRhTempIcon,
  PressureIcon,
  RhIcon,
  TempIcon
} from "../../icons";
import ParamsBuilderAcc from "./ParamsBuilderAcc";
import ParamsBuilderAngle from "./ParamsBuilderAngle";
import ParamsBuilderRh from "./ParamsBuilderRh";
import ParamsBuilderTemp from "./ParamsBuilderTemp";
import ParamsBuilderExtIO from "./ParamsBuilderExtIO";
import { GeneralSystemInfo } from "../../models/ISystemInfo";
import {
  getExternalSensor,
  interpretExternalSensorFeatures,
  interpretSystemInfoHasFeatures
} from "../../helpers/parsers/parseSystemInfoHelper";
import {
  ParameterTab,
  selectActiveParameterTab,
  setActiveParametersTab
} from "../../state/paramsPageSlice";
import ParamsBuilderFreeText from "./ParamsBuilderFreeText";
import commonColors from "../../styles/commonColors";
import "../../styles/overrides.css";
import { getAccMaxG, getSensorNrFromId } from "../../helpers/paramsHelper";
import {
  ScheduleOutlined,
  ThunderboltOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import ParamsBuilderTriggers from "./ParamsBuilderTriggers";
import ParamsBuilderPressure from "./ParamsBuilderPressure";
import ParamsBuilderExtSensors from "./ParamsBuilderExtSensors";
import { insertIf } from "../../utils/generalUtils";
import { selectExternalSensors } from "../../state/openParxSlice";
import ParamsBuilderExtRhTempSensor from "./ParamsBuilderExtRhTempSensor";
import { RhTemp } from "../../models/FAT100DataTypes";

/**
 * Local component used for content in a tab
 * @param props
 */
const TabContent = (props: {
  icon: JSX.Element;
  text: string;
  isHovering: (b: boolean) => void;
}) => (
  <Row
    align="middle"
    justify="space-between"
    wrap={false}
    style={{ width: "100%" }}
    onMouseEnter={() => props.isHovering(true)}
    onMouseLeave={() => props.isHovering(false)}
  >
    {props.icon}
    <span style={{ width: "3px" }} />
    {props.text}
  </Row>
);

interface IProps {
  systemInfo: GeneralSystemInfo;
}
const ParamsBuilderChooseParams: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const activeTab = useSelector(selectActiveParameterTab);
  const externalSensorsTabs = useSelector(selectExternalSensors);

  const dispatch = useDispatch();

  const deviceFeatures = interpretSystemInfoHasFeatures(
    props.systemInfo.hasFeatures
  );
  const extSensorFeatures = interpretExternalSensorFeatures(props.systemInfo);

  const accMaxG = getAccMaxG(props.systemInfo);

  const [hoveredTab, setHoveredTab] = useState<ParameterTab | "">("");

  const iconStyle = {
    width: "20px",
    height: "20px"
  };
  const notSelectedIconStyle = {
    ...iconStyle,
    fill: commonColors.darkGrayText
  };
  const disabledIconStyle = {
    ...iconStyle,
    fill: commonColors.disabledTextOnDarkBg
  };

  const getIconStyle = (tab: ParameterTab, hasFeature: boolean) => {
    if (!hasFeature) {
      return disabledIconStyle;
    }

    return activeTab === tab || hoveredTab === tab
      ? iconStyle
      : notSelectedIconStyle;
  };

  const extSensorTech = getExternalSensor(deviceFeatures.DF_EXT_IO_CFG);
  const hasExtSensors = extSensorTech === "I2C";
  const hasExtIO = extSensorTech === "IO";

  const dynamicTabs = hasExtSensors
    ? externalSensorsTabs.map((extSensor, index) => {
        const ESTI = extSensor.params.sensorTypeId;
        const tabKey = ESTI.toString();
        // If the sensor is a RH/Temp sensor
        if (RhTemp.includes(ESTI)) {
          const tabIcon = <ExtRhTempIcon {...getIconStyle(tabKey, true)} />;
          const tabContent = <ParamsBuilderExtRhTempSensor index={index} />;
          let tabName =
            t("RhTempSensor") +
            " nr " +
            getSensorNrFromId(extSensor.params.sensorTypeId);
          if (extSensor.params.sensorName.length > 0)
            tabName += " (" + extSensor.params.sensorName + ")";
          return {
            label: (
              <TabContent
                icon={tabIcon}
                text={tabName}
                isHovering={(yes) =>
                  yes ? setHoveredTab(tabKey) : setHoveredTab("")
                }
              />
            ),
            key: tabKey,
            children: tabContent
          };
        }
        // If the sensor is any other sensor
        else {
          return {
            label: (
              <TabContent
                icon={
                  <QuestionCircleOutlined {...getIconStyle(tabKey, true)} />
                }
                text={"Unknown sensor " + ESTI.toString(16)}
                isHovering={(yes) =>
                  yes ? setHoveredTab(tabKey) : setHoveredTab("")
                }
              />
            ),
            key: tabKey,
            children: <></>
          };
        }
      })
    : [];

  const tabPanes = [
    {
      label: (
        <TabContent
          icon={<ScheduleOutlined {...getIconStyle("freeText", true)} />}
          text={t("cpUserInfoPaneTitle")}
          isHovering={(yes) =>
            yes ? setHoveredTab("freeText") : setHoveredTab("")
          }
        />
      ),
      key: "freeText",
      children: <ParamsBuilderFreeText />
    },
    ...insertIf(deviceFeatures.DF_ACC, {
      label: (
        <TabContent
          icon={<AccGeneral {...getIconStyle("acc", deviceFeatures.DF_ACC)} />}
          text={t("generalAccTitle")}
          isHovering={(yes) => (yes ? setHoveredTab("acc") : setHoveredTab(""))}
        />
      ),
      key: "acc",
      children: (
        <ParamsBuilderAcc
          features={{
            hasAngle: deviceFeatures.DF_ANGLE,
            hasDva: deviceFeatures.DF_DVA,
            hasGps: deviceFeatures.DF_GPS,
            hasLte: deviceFeatures.DF_LTE
          }}
          maxG={accMaxG}
        />
      )
    }),
    ...insertIf(deviceFeatures.DF_TEMP, {
      label: (
        <TabContent
          icon={<TempIcon {...getIconStyle("temp", deviceFeatures.DF_TEMP)} />}
          text={t("generalTempTitle")}
          isHovering={(yes) =>
            yes ? setHoveredTab("temp") : setHoveredTab("")
          }
        />
      ),
      key: "temp",
      children: (
        <ParamsBuilderTemp
          features={{
            hasGps: deviceFeatures.DF_GPS,
            hasLte: deviceFeatures.DF_LTE
          }}
        />
      )
    }),
    ...insertIf(deviceFeatures.DF_PRESSURE, {
      label: (
        <TabContent
          icon={
            <PressureIcon
              {...getIconStyle("pressure", deviceFeatures.DF_PRESSURE)}
            />
          }
          text={t("generalPressureTitle")}
          isHovering={(yes) =>
            yes ? setHoveredTab("pressure") : setHoveredTab("")
          }
        />
      ),
      key: "pressure",
      children: (
        <ParamsBuilderPressure
          features={{
            hasGps: deviceFeatures.DF_GPS,
            hasLte: deviceFeatures.DF_LTE
          }}
        />
      )
    }),
    ...insertIf(deviceFeatures.DF_RH, {
      label: (
        <TabContent
          icon={<RhIcon {...getIconStyle("rh", deviceFeatures.DF_RH)} />}
          text={t("generalRhTitle")}
          isHovering={(yes) => (yes ? setHoveredTab("rh") : setHoveredTab(""))}
        />
      ),
      key: "rh",
      disabled: !deviceFeatures.DF_RH,
      children: (
        <ParamsBuilderRh
          features={{
            hasGps: deviceFeatures.DF_GPS,
            hasLte: deviceFeatures.DF_LTE
          }}
        />
      )
    }),
    ...insertIf(deviceFeatures.DF_ANGLE, {
      label: (
        <TabContent
          icon={
            <AngleIcon {...getIconStyle("angle", deviceFeatures.DF_ANGLE)} />
          }
          text={t("generalAngleTitle")}
          isHovering={(yes) =>
            yes ? setHoveredTab("angle") : setHoveredTab("")
          }
        />
      ),
      key: "angle",
      children: (
        <ParamsBuilderAngle
          features={{
            hasGps: deviceFeatures.DF_GPS,
            hasLte: deviceFeatures.DF_LTE
          }}
        />
      )
    }),
    // {
    //   label: (
    //     <TabContent
    //         icon={<LightSensIcon {...disabledIconStyle} />}
    //         text="Light"
    //         isHovering={() => ""}
    //       />
    //   ),
    //   key: "light",
    // }
    ...insertIf(hasExtIO, {
      label: (
        <TabContent
          icon={
            <ExtInputIcon
              {...getIconStyle("extIO", deviceFeatures.DF_EXT_IO)}
            />
          }
          text={t("generalExtIOTitle")}
          isHovering={(yes) =>
            yes ? setHoveredTab("extIO") : setHoveredTab("")
          }
        />
      ),
      key: "extIO",
      disabled: !deviceFeatures.DF_EXT_IO,
      children: (
        <ParamsBuilderExtIO
          features={{
            hasExtIO: deviceFeatures.DF_EXT_IO,
            extIOCfg: deviceFeatures.DF_EXT_IO_CFG
          }}
        />
      )
    }),
    ...insertIf(hasExtSensors, {
      label: (
        <TabContent
          icon={<ExtInputIcon {...getIconStyle("extSensors", true)} />}
          text={t("generalExtSensorsTitle")}
          isHovering={(yes) =>
            yes ? setHoveredTab("extSensors") : setHoveredTab("")
          }
        />
      ),
      key: "extSensors",
      children: <ParamsBuilderExtSensors availableSensors={extSensorFeatures} />
    }),
    ...dynamicTabs,
    {
      label: (
        <TabContent
          icon={<ThunderboltOutlined {...getIconStyle("triggers", true)} />}
          text={t("generalTriggersTitle")}
          isHovering={(yes) =>
            yes ? setHoveredTab("triggers") : setHoveredTab("")
          }
        />
      ),
      key: "triggers",
      children: (
        <ParamsBuilderTriggers
          features={{
            hasExtIO: deviceFeatures.DF_EXT_IO,
            extIOCfg: deviceFeatures.DF_EXT_IO_CFG,
            hasGps: deviceFeatures.DF_GPS,
            hasLte: deviceFeatures.DF_LTE,
            hasAcc: deviceFeatures.DF_ACC,
            hasTemp: deviceFeatures.DF_TEMP,
            hasPressure: deviceFeatures.DF_PRESSURE,
            hasRh: deviceFeatures.DF_RH,
            hasAngle: deviceFeatures.DF_ANGLE
          }}
        />
      )
    }
  ];

  return (
    <Tabs
      type="card"
      tabPosition="top"
      items={tabPanes}
      onChange={(key) => dispatch(setActiveParametersTab(key as ParameterTab))}
      style={{ width: "100%" }}
    />
  );
};

export default ParamsBuilderChooseParams;
