import { Badge, Card, Tooltip } from "antd";
import { isEmpty, isNil, range } from "lodash-es";
import React from "react";
import { useDispatch } from "react-redux";
import {
  FlyoutProps,
  VictoryBar,
  VictoryGroup,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryScatterProps,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer
} from "victory";
import { EventPropTypeInterface, Selection } from "victory-core";
import { iconColors } from "../../constants/colors";
import { unix2Date } from "../../helpers/dataModelHelper";
import { dateToUnix } from "../../helpers/dateHelper";
import {
  commonPrimaryGraphProps,
  getCanvasOffset,
  isItemWithinDomain
} from "../../helpers/graphHelper";
import { ExtInputIcon, ExtOutputIcon } from "../../icons";
import { VMExternalOutputParams } from "../../models/ViewModelRecordingParameters/VMExternalOutputParams";
import { VMExternalInputParams } from "../../models/ViewModelRecordingParameters/VMExtternalInputParams";
import {
  DataFilterStates,
  setNewCursorPosition
} from "../../state/openDatxSlice";
import { Optional } from "../../utils/utilTypes";

export interface ExternalIOGraphData {
  dataFilters: DataFilterStates;
  externalOutputs?: ExternalIO[];
  externalInputs?: ExternalIO[];
  externalInputParams?: VMExternalInputParams[];
  externalOutputParams?: VMExternalOutputParams[];
}

/** Line graph data point */
interface LineGraphDataPoint {
  x: Date;
  y: number;
}

interface LineGraphDataSeries {
  name: string;
  data: LineGraphDataPoint[];
}

/** Data type for External IO symbols in status graph */
export interface ExternalIOPoint {
  x: Date;
  y: number;
  y0: number;
  data: boolean;
}

/** Data type for External IO symbols in status graph */
export interface ExternalIO {
  x: Date;
  y: number;
  data: {
    changed: boolean[];
    state: boolean[];
    names: string[];
  };
  occurrences?: number;
}
interface TypedIODatumProps {
  datum?: ExternalIO;
}

/**
 * Helper function that returns one data point for each changed IO
 * @param data
 * @param domain
 */
export const prepareInputDataPointsForGrid = (
  data: Optional<ExternalIO[]>,
  inputs: number = 8
) => {
  if (isNil(data)) {
    return undefined;
  }

  let pointsData: ExternalIOPoint[] = [];
  data.forEach((item) => {
    for (let i = 0; i < 8; i++) {
      if (item.data.changed[i]) {
        pointsData.push({
          x: item.x,
          y: inputs - i,
          y0: inputs - i - 1,
          data: item.data.state[i]
        });
      }
    }
  });
  return pointsData;
};

export const prepareInputDataForLineGraph = (
  data: Optional<ExternalIO[]>,
  inputs: number = 8,
  outputs: number = 8
) => {
  if (isNil(data)) {
    return undefined;
  }
  let series: LineGraphDataSeries[] = [];

  data.forEach((item) => {
    // Split data by input channel
    for (let i = 0; i < inputs; i++) {
      // Start by adding an extra data point to make the line start at the beginning of the graph
      // If the first input is "changed" use the oposite of the "state" for the extra point, otherwise add the same value as the first input
      if (!series[i]) {
        const prevState = item.data.changed[i]
          ? !item.data.state[i]
          : item.data.state[i];
        const prevDown = prevState ? 0.1 : 0.9;
        series[i] = {
          name: "Input" + i.toString(),
          data: [{ x: new Date(0), y: outputs + inputs - i - prevDown }]
        };
      }
      const down = item.data.state[i] ? 0.1 : 0.9;
      // We add outputs to the y value as the graph is drawn from the bottom and we want the inputs to be drawn above the outputs
      series[i].data.push({ x: item.x, y: outputs + inputs - i - down });
    }
  });
  // Add an extra data point to make each series reach the end of the graph
  series.forEach((item) => {
    const lastItem = item.data[item.data.length - 1];
    item.data.push({ x: new Date(8640000000000000), y: lastItem.y });
  });

  return series;
};

export const prepareOutputDataForLineGraph = (
  data: Optional<ExternalIO[]>,
  outputs: number = 8
) => {
  if (isNil(data)) {
    return undefined;
  }
  let series: LineGraphDataSeries[] = [];

  data.forEach((item) => {
    // Split data by output channel
    for (let i = 0; i < outputs; i++) {
      // Start by adding an extra data point to make the line start at the beginning of the graph
      // If the first output is "changed" use the oposite of the "state" for the extra point, otherwise add the same value as the first output
      if (!series[i]) {
        const prevState = item.data.changed[i]
          ? !item.data.state[i]
          : item.data.state[i];
        const prevDown = prevState ? 0.1 : 0.9;
        series[i] = {
          name: "Output" + i.toString(),
          data: [{ x: new Date(0), y: outputs - i - prevDown }]
        };
      }
      const down = item.data.state[i] ? 0.1 : 0.9;
      series[i].data.push({ x: item.x, y: outputs - i - down });
    }
  });
  // Add an extra data point to make each series reach the end of the graph
  series.forEach((item) => {
    const lastItem = item.data[item.data.length - 1];
    item.data.push({ x: new Date(8640000000000000), y: lastItem.y });
  });

  return series;
};

export const prepareOutputDataPointsForGrid = (
  data: Optional<ExternalIO[]>,
  outputs: number = 8
) => {
  if (isNil(data)) {
    return undefined;
  }

  let pointsData: ExternalIOPoint[] = [];
  data.forEach((item) => {
    for (let i = 0; i < 8; i++) {
      if (item.data.changed[i]) {
        pointsData.push({
          x: item.x,
          y: outputs - i,
          y0: outputs - i - 1,
          data: item.data.state[i]
        });
      }
    }
  });
  return pointsData;
};

/**
 * Helper function that makes sure that IO data don't overlap in graph
 * @param data
 * @param domain
 * @param maxDataPoints Max amount of data points in the returned array
 */
export const clusterData = (
  data: Optional<ExternalIO[]>,
  domain: [Date, Date],
  maxDataPoints: number
) => {
  if (isNil(data)) {
    return undefined;
  }

  /** total amount of seconds for domain */
  const diff = (domain[1].getTime() - domain[0].getTime()) / 1e3;

  /** how many seconds space 1 data unit takes */
  const unit = diff / maxDataPoints;

  return data.reduce((arr: ExternalIO[], item, index) => {
    //outside of domain
    if (!isItemWithinDomain(item, domain)) {
      return arr;
    }

    /** Previously added value */
    const prev = arr.pop();

    if (isNil(prev)) {
      return [item];
    }

    /** amount of seconds between 2 data points */
    const innerDiff = dateToUnix(item.x) - dateToUnix(prev.x);

    /** if 2 angles is too close group them together */
    if (innerDiff < unit / 2) {
      /** this data point now includes more than one */
      const occurrences = prev?.occurrences ? prev.occurrences + 1 : 2;
      arr.push({ ...prev, occurrences });
    } else {
      arr.push(prev, item);
    }

    return arr;
  }, []);
};

/** The height of the component. Can be used for various calculations in the component */
const iconGraphHeight = 50;

/** Determina if obj is of type ExternalIO */
const isExternalIO = (obj: any): obj is ExternalIO => {
  const maybeExternalIO = obj as ExternalIO;

  return (
    !isNil(maybeExternalIO.data) &&
    !isNil(maybeExternalIO.data.changed) &&
    !isNil(maybeExternalIO.data.state) &&
    !isNil(maybeExternalIO.x) &&
    !isNil(maybeExternalIO.y)
  );
};

/** Local component used to render input icon in graph */
const RenderInputIcon: React.FC<VictoryScatterProps & TypedIODatumProps> = (
  props
) => {
  const { x, y, datum } = props;
  //if any of the used values from props is undefined, we can't continue
  if (isNil(x) || isNil(y) || isNil(datum)) {
    return null;
  }
  const { occurrences } = datum;
  const { changed } = datum.data;
  if (isNil(changed)) return null;
  const width = 60;
  const height = iconGraphHeight - 2;
  const midX = Number(x) - 25;
  return (
    <g style={{ pointerEvents: "none" }} x={midX} y={Number(y)}>
      <foreignObject x={midX} y={2} width={width} height={height}>
        <div style={{ width: width, height: height, padding: 10 }}>
          <Badge
            count={occurrences ?? 0}
            style={{ backgroundColor: iconColors.inputDark }}
          >
            <ExtInputIcon style={{ fontSize: "2em" }} />
          </Badge>
        </div>
      </foreignObject>
    </g>
  );
};

/** Local component used to render output icon in graph */
const RenderOutputIcon: React.FC<VictoryScatterProps & TypedIODatumProps> = (
  props
) => {
  const { x, y, datum } = props;
  //if any of the used values from props is undefined, we can't continue
  if (isNil(x) || isNil(y) || isNil(datum)) {
    return null;
  }
  const { occurrences } = datum;
  const { changed } = datum.data;
  if (isNil(changed)) return null;
  const width = 60;
  const height = iconGraphHeight - 2;
  const midX = Number(x) - 25;
  return (
    <g style={{ pointerEvents: "none" }} x={midX} y={Number(y)}>
      <foreignObject x={midX} y={2} width={width} height={height}>
        <div style={{ width: width, height: height, padding: 10 }}>
          <Badge
            count={occurrences ?? 0}
            style={{ backgroundColor: iconColors.outputDark }}
          >
            <ExtOutputIcon style={{ fontSize: "2em" }} />
          </Badge>
        </div>
      </foreignObject>
    </g>
  );
};

/** Local component used to render graph tooltips */
const RenderTooltip: React.FC<FlyoutProps & TypedIODatumProps> = (props) => {
  const { x, y, datum } = props;

  //if any of the used values from props is undefined, we can't continue
  if (isNil(x) || isNil(y) || isNil(datum)) {
    return null;
  }

  if (isExternalIO(datum)) {
    return renderIOTooltip(datum, x, y);
  }

  return null;
};

/** Render IO data tooltip as a component */
const renderIOTooltip = (
  datum: ExternalIO,
  x: number,
  y: number
): React.JSX.Element => {
  const { changed, state } = datum.data;
  return (
    <g style={{ pointerEvents: "none" }} y={0} x={x}>
      <foreignObject
        x={x}
        y={y}
        width={iconGraphHeight}
        height={iconGraphHeight}
      >
        <Tooltip
          getPopupContainer={(triggerNode: HTMLElement) =>
            document.getElementById("primarygraph") ||
            (triggerNode.parentNode as HTMLElement)
          }
          placement="bottom"
          open={true}
          title={() => (
            <p style={{ marginBlock: 2 }}>
              {changed.some((value) => value === true) ? (
                changed.map((value, index) => {
                  const stateText = state[index] ? "ON" : "OFF";

                  if (value) {
                    return (
                      <p style={{ marginBlock: 0 }} key={index}>
                        {datum.data.names[index]} changed to {stateText}
                      </p>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <p style={{ marginBlock: 0 }}>{"No change"}</p>
              )}
            </p>
          )}
        />
      </foreignObject>
    </g>
  );
};

interface IProps {
  data: ExternalIOGraphData;
  zoomDomain: { x: [Date, Date] };
  scoreValuesCursorPos?: number;
  width: number;
  height: number;
}

const ExternalIOGraph: React.FC<IProps> = (props) => {
  const { data, zoomDomain, scoreValuesCursorPos } = props;
  const dispatch = useDispatch();

  /** Max ammount of data points in the graph */
  const maxDataPoints = props.width > 1500 ? 20 : props.height > 1000 ? 15 : 8;

  // If the graph should be rendered at all
  const shouldRenderInputsGraph = data.dataFilters.extInput.dataToggle.isActive;
  const shouldRenderOutputsGraph =
    data.dataFilters.extOutput.dataToggle.isActive;

  /*
    Some early DATX files with External IO do not contain the external IO parameters as they were hard coded into the units. In order to be able to read that data we will show all 8 inputs and outputs if the parameters are missing. If the parameters are present we will only show the used inputs and outputs. When opening a file without parameters, the default empty parameters will be used instead, which means we have to check for usage.
  */
  const usedInputParams = data.externalInputParams?.filter(
    (param) => param.used
  );
  const inputParams =
    usedInputParams && usedInputParams.length > 0
      ? usedInputParams
      : data.externalInputParams;

  const usedOutputParams = data.externalOutputParams?.filter(
    (param) => param.used
  );
  const outputParams =
    usedOutputParams && usedOutputParams.length > 0
      ? usedOutputParams
      : data.externalOutputParams;

  // If the there is any data to render in the graph
  const shouldRenderInputsData = !isEmpty(data.externalInputs);
  const shouldRenderOutputsData = !isEmpty(data.externalOutputs);

  // Cluster data to make sure that data points don't overlap in graph
  const clusteredInputData = clusterData(
    data.externalInputs,
    zoomDomain.x,
    maxDataPoints
  );
  const clusteredOutputData = clusterData(
    data.externalOutputs,
    zoomDomain.x,
    maxDataPoints
  );

  const leftOffset = getCanvasOffset(props.data.dataFilters);

  const ioGridHeight = props.height - iconGraphHeight;
  const numberOfRows =
    (shouldRenderInputsGraph ? inputParams?.length ?? 0 : 0) +
    (shouldRenderOutputsGraph ? outputParams?.length ?? 0 : 0);
  const rowHeight = ioGridHeight / numberOfRows;
  const inputOffset = shouldRenderOutputsGraph ? outputParams?.length ?? 0 : 0;
  const outputOffset = shouldRenderInputsGraph ? inputParams?.length ?? 0 : 0;

  const lineGraphInputSeries = prepareInputDataForLineGraph(
    data.externalInputs,
    inputParams?.length,
    inputOffset
  );
  const lineGraphOutputSeries = prepareOutputDataForLineGraph(
    data.externalOutputs,
    outputParams?.length
  );

  const iconGraphEvents: EventPropTypeInterface<"parent", ""> = {
    target: "parent",
    eventHandlers: {
      onMouseDown: () => ({
        target: "parent",
        mutation: (targetProps) => {
          const { activePoints } = targetProps;
          if (activePoints.length > 0) {
            dispatch(setNewCursorPosition(dateToUnix(activePoints[0].x)));
          }
        }
      })
    }
  };
  const gridEvents: EventPropTypeInterface<"parent", ""> = {
    target: "parent",
    eventHandlers: {
      onClick: (evt) => ({
        target: "parent",
        mutation: (targetProps) => {
          const { x } = Selection.getSVGEventCoordinates(evt);
          const xPosIngraph: Date = targetProps.scale.x.invert(x);
          if (xPosIngraph) {
            dispatch(setNewCursorPosition(dateToUnix(xPosIngraph)));
          }
        }
      })
    }
  };
  return (
    <>
      <Card
        style={{
          width: "100%",
          height: `${iconGraphHeight}px`,
          padding: "0px 0px 0px 0px",
          marginTop: "10px",
          boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset"
        }}
        styles={{
          body: {
            padding: "0 0 0 0",
            height: "100%",
            marginLeft: `${leftOffset - commonPrimaryGraphProps.chartPadding.right}px`
          }
        }}
      >
        <VictoryGroup
          standalone={true}
          events={[iconGraphEvents]}
          width={props.width}
          height={iconGraphHeight - 2}
          scale="time"
          domain={{ x: props.zoomDomain.x, y: [-1, 6] }}
          //same padding/domain padding as primary graph
          domainPadding={commonPrimaryGraphProps.domainPadding}
          padding={{
            left: commonPrimaryGraphProps.chartPadding.right,
            right: commonPrimaryGraphProps.chartPadding.right
          }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={() => " "}
              name="tooltipContainer"
              labelComponent={
                <VictoryTooltip flyoutComponent={<RenderTooltip />} />
              }
            />
          }
          theme={VictoryTheme.material}
        >
          {/* externalInputs */}
          {shouldRenderInputsGraph && shouldRenderInputsData && (
            <VictoryScatter
              name="externalInputs"
              data={clusteredInputData}
              dataComponent={<RenderInputIcon />}
              size={8}
            />
          )}
          {/* externalOutputs */}
          {shouldRenderOutputsGraph && shouldRenderOutputsData && (
            <VictoryScatter
              name="externalOutputs"
              data={clusteredOutputData}
              dataComponent={<RenderOutputIcon />}
              size={8}
            />
          )}
        </VictoryGroup>
      </Card>
      <Card
        style={{
          width: "100%",
          height: `${ioGridHeight}px`,
          padding: "0px 0px 0px 0px",
          marginTop: "10px",
          boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset"
        }}
        styles={{
          body: {
            padding: "0 0 0 0",
            height: "100%"
          }
        }}
      >
        <VictoryGroup
          theme={VictoryTheme.material}
          events={[gridEvents]}
          standalone={true}
          width={props.width}
          height={ioGridHeight - 2}
          scale="time"
          domain={{ x: props.zoomDomain.x, y: [0, numberOfRows] }}
          //same padding/domain padding as primary graph
          domainPadding={commonPrimaryGraphProps.domainPadding}
          padding={{
            left: leftOffset,
            right: commonPrimaryGraphProps.chartPadding.right
          }}
        >
          {range(1, numberOfRows).map((i) => (
            <VictoryLine
              key={i}
              name={`horizontalLine${i}`}
              style={{ data: { stroke: "#eeeeee", strokeWidth: 1 } }}
              data={[
                { x: zoomDomain.x[0], y: i },
                { x: zoomDomain.x[1], y: i }
              ]}
            />
          ))}

          {/* score values line */}
          {scoreValuesCursorPos && (
            <VictoryBar
              data={[
                { x: unix2Date(scoreValuesCursorPos), y: numberOfRows, y0: 0 }
              ]}
              style={{ data: { fill: "#eeeeee" } }}
              barWidth={2}
            />
          )}

          {/* Renders Inputs as a line graph */}
          {shouldRenderInputsGraph &&
            shouldRenderInputsData &&
            lineGraphInputSeries?.map((inputLine) => (
              <VictoryLine
                name={inputLine.name}
                data={inputLine.data}
                style={{ data: { stroke: iconColors.input } }}
                interpolation="stepAfter"
              />
            ))}
          {/* Renders Outputs as a line graph */}
          {shouldRenderOutputsGraph &&
            shouldRenderOutputsData &&
            lineGraphOutputSeries?.map((outputLine) => (
              <VictoryLine
                name={outputLine.name}
                data={outputLine.data}
                style={{ data: { stroke: iconColors.output } }}
                interpolation="stepAfter"
              />
            ))}

          {shouldRenderInputsGraph &&
            inputParams &&
            inputParams.map((input, index) => (
              <VictoryLabel
                style={{
                  fill: iconColors.inputDark,
                  fontSize: "10px",
                  fontFamily: "Arial"
                }}
                key={input.inputNumber}
                x={leftOffset - 8}
                y={index * rowHeight + 8}
                text={input.description}
                textAnchor="end"
              />
            ))}

          {shouldRenderOutputsGraph &&
            outputParams &&
            outputParams.map((output, index) => (
              <VictoryLabel
                style={{
                  fill: iconColors.outputDark,
                  fontSize: "10px",
                  fontFamily: "Arial"
                }}
                key={output.outputNumber}
                x={leftOffset - 8}
                y={(outputOffset + index) * rowHeight + 8}
                text={output.description}
                textAnchor="end"
              />
            ))}
        </VictoryGroup>
      </Card>
    </>
  );
};

export default React.memo(ExternalIOGraph);
