import { Col, InputNumber, Row, Space, Switch } from "antd";
import { isNull } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import {
  maxPressureValue,
  minPressureValue
} from "../../constants/params-defaults";
import {
  selectPressure,
  setPressureHighAlarm,
  setPressureIgnoreCount,
  setPressureIntervalDays,
  setPressureIntervalHours,
  setPressureIntervalMinutes,
  setPressureLowAlarm,
  setPressureSlopeValue,
  setPressureTempValue,
  togglePressure,
  togglePressureStoreGps,
  togglePressureUseLte
} from "../../state/openParxSlice";
import {
  ParamsCardParagraph,
  ParamsCardSubHeader
} from "../Common/CommonFonts";
import {
  CommonParamsInputCard,
  CommonParamsMainContentRow,
  SendGsmAlarmCard,
  willFitWidth2,
  willFitWidth3
} from "../Common/CommonParametersComponents";
import { size } from "../../helpers/pageHelper";
import TempCompensationGraph from "./TempCompensationGraph";
import SlopeCalculatorModal from "./SlopeCalculatorModal";

interface AvailablePressureFeatures {
  hasGps: boolean;
  hasLte: boolean;
}

interface IProps {
  features: AvailablePressureFeatures;
}

const ParamsBuilderPressure: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const pressureParams = useSelector(selectPressure);
  const dispatch = useDispatch();
  const { width, ref } = useResizeDetector();
  const componentWidth = width ?? window.innerWidth;

  //the max interval for a period is exacly 28 days in ms. Therefore if 28 days
  //is set, hours and minututes can't be more than 0
  const maxHours = pressureParams.intervalDays === 28 ? 0 : 23;
  const maxMinutes = pressureParams.intervalDays === 28 ? 0 : 59;

  const mainToggle = pressureParams.usePressure;
  const storeGps =
    mainToggle && (pressureParams.channelBits.GPS ? true : false);
  const useLte = mainToggle && (pressureParams.channelBits.LTE ? true : false);
  const ignoreCount = pressureParams.IgnoreCountLTE;

  /** Style used for input fields */
  const inputFieldStyle: React.CSSProperties = {
    margin: size.s2
  };

  const setLowAlarm = (val: number | null) => {
    if (isNull(val)) return;
    if (val < minPressureValue) val = minPressureValue;
    if (val > maxPressureValue) val = maxPressureValue;
    dispatch(setPressureLowAlarm(val));
  };

  const setHighAlarm = (val: number | null) => {
    if (isNull(val)) return;
    if (val < minPressureValue) val = minPressureValue;
    if (val > maxPressureValue) val = maxPressureValue;
    dispatch(setPressureHighAlarm(val));
  };

  /** If high alarm is lower than low alarm, set it to low*/
  const syncHighToLow = () => {
    if (pressureParams.params.lowAlarm > pressureParams.params.highAlarm) {
      dispatch(setPressureHighAlarm(pressureParams.params.lowAlarm));
    }
  };

  /** If low alarm is higher than high alarm, set it to high*/
  const syncLowToHigh = () => {
    if (pressureParams.params.highAlarm < pressureParams.params.lowAlarm) {
      dispatch(setPressureLowAlarm(pressureParams.params.highAlarm));
    }
  };

  const setSlope = (val: number | null) => {
    if (isNull(val)) return;
    dispatch(setPressureSlopeValue(val));
  };

  const setTemp = (val: number | null) => {
    if (isNull(val)) return;
    dispatch(setPressureTempValue(val));
  };

  // Render functions
  const renderMainToggle = () => (
    <Row align="middle" style={{ marginBottom: size.s2 }}>
      <Space>
        <Switch
          checked={mainToggle}
          onChange={() => dispatch(togglePressure())}
        />
        <ParamsCardSubHeader>
          {t("cpPressureMainToggleTitle")}
        </ParamsCardSubHeader>
      </Space>
    </Row>
  );

  const renderLogInterval = (withDescription: boolean) => (
    <CommonParamsInputCard
      title={t("cpPressureLogIntervalTitle")}
      width={withDescription ? 2 : 1}
      height={withDescription ? 1 : 2}
    >
      <Row justify="space-between">
        <Col>
          <ParamsCardParagraph>
            {t("cpPressureLogIntervalContent")}
          </ParamsCardParagraph>
        </Col>
        <div style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genDays")}</ParamsCardSubHeader>
            </Col>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genHours")}</ParamsCardSubHeader>
            </Col>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genMinutes")}</ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input-fields */}
          <Row>
            <Col span={8}>
              <InputNumber
                disabled={!mainToggle}
                value={pressureParams.intervalDays}
                onChange={(val) =>
                  isNull(val)
                    ? {}
                    : dispatch(setPressureIntervalDays(Number(val)))
                }
                min={0}
                max={28}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                disabled={!mainToggle}
                value={pressureParams.intervalHours}
                onChange={(val) =>
                  isNull(val)
                    ? {}
                    : dispatch(setPressureIntervalHours(Number(val)))
                }
                min={0}
                max={maxHours}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                disabled={!mainToggle}
                value={pressureParams.intervalMinutes}
                onChange={(val) =>
                  isNull(val)
                    ? {}
                    : dispatch(setPressureIntervalMinutes(Number(val)))
                }
                min={0}
                max={maxMinutes}
                style={{ ...inputFieldStyle, marginRight: 0 }}
              />
            </Col>
          </Row>
        </div>
      </Row>
    </CommonParamsInputCard>
  );

  const renderStoreGps = () => (
    <CommonParamsInputCard
      title={t("cpPressureStoreGpsPosTitle")}
      disabled={!props.features.hasGps}
      width={1}
      height={1}
      useToggle
      toggleChecked={storeGps && props.features.hasGps}
      toggleDisabled={!mainToggle}
      toggleChangeAction={() => dispatch(togglePressureStoreGps())}
    >
      <ParamsCardParagraph disabled={!props.features.hasGps}>
        {t("cpPressureStoreGpsPosContent")}
      </ParamsCardParagraph>
    </CommonParamsInputCard>
  );

  const renderAlarmLevel = (withDescription: boolean) => (
    <CommonParamsInputCard
      title={t("cpPressureAlarmLevelTitle")}
      width={withDescription ? 2 : 1}
      height={2}
    >
      <Row justify="space-between" wrap>
        <Col flex="auto">
          <ParamsCardParagraph>
            {t("cpPressureAlarmLevelContent")}
          </ParamsCardParagraph>
        </Col>
        <Col style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={12}>
              <ParamsCardSubHeader>
                {t("generalMinTitle")} (mbar)
              </ParamsCardSubHeader>
            </Col>
            <Col span={12}>
              <ParamsCardSubHeader>
                {t("generalMaxTitle")} (mbar)
              </ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input fields */}
          <Row>
            <Col span={12}>
              <InputNumber
                disabled={!mainToggle}
                value={pressureParams.params.lowAlarm}
                onChange={(value) => setLowAlarm(value)}
                onBlur={() => syncHighToLow()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                disabled={!mainToggle}
                value={pressureParams.params.highAlarm}
                onChange={(value) => setHighAlarm(value)}
                onBlur={() => syncLowToHigh()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );

  const renderTempCompensation = (withDescription: boolean) => (
    <CommonParamsInputCard
      title={t("TempCompensation")}
      width={withDescription ? 2 : 1}
      height={2}
    >
      <Row justify="space-between" wrap>
        <Col flex="auto">
          <ParamsCardParagraph>
            {t("TempCompensationExplanation")}
          </ParamsCardParagraph>
          <TempCompensationGraph />
        </Col>
        <Col style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={12}>
              <ParamsCardSubHeader>{t("Slope")} (mbar/°C)</ParamsCardSubHeader>
            </Col>
            <Col span={12}>
              <ParamsCardSubHeader>{t("Temp")} (°C)</ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input fields */}
          <Row style={{ marginBottom: size.s2 }}>
            <Col span={12}>
              <InputNumber
                disabled={!mainToggle}
                value={pressureParams.params.slopeValue}
                onChange={(value) => setSlope(value)}
                precision={3}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                disabled={!mainToggle}
                value={pressureParams.params.tempValue}
                onChange={(value) => setTemp(value)}
                precision={2}
                style={inputFieldStyle}
              />
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col>
              <SlopeCalculatorModal />
            </Col>
          </Row>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );

  const renderGsmAlarm = () => (
    <SendGsmAlarmCard
      hasLte={props.features.hasLte}
      sendGsmTextContent={t("cpPressureSendGsmAlarmContent")}
      isParentParamsActive={mainToggle}
      useLte={useLte}
      toggleUseLte={() => dispatch(togglePressureUseLte())}
      ignoreCount={ignoreCount}
      setIgnoreCount={(v: number) => dispatch(setPressureIgnoreCount(v))}
    />
  );

  return (
    <div ref={ref}>
      {renderMainToggle()}
      <CommonParamsMainContentRow>
        {/* logging interval content */}
        <Col order={1}>{renderLogInterval(!willFitWidth2(componentWidth))}</Col>
        {/* store gps position content. Order explanation: if small screen size, put element below "alarm" */}
        <Col order={willFitWidth3(componentWidth) ? 4 : 2}>
          {renderStoreGps()}
        </Col>
        {/* alarm level content */}
        <Col order={willFitWidth3(componentWidth) ? 2 : 3}>
          {renderAlarmLevel(!willFitWidth2(componentWidth))}
        </Col>
        <Col order={willFitWidth3(componentWidth) ? 3 : 5}>
          {renderTempCompensation(!willFitWidth2(componentWidth))}
        </Col>
        {/* send gsm alarm content */}
        <Col order={willFitWidth3(componentWidth) ? 5 : 4}>
          {renderGsmAlarm()}
        </Col>
      </CommonParamsMainContentRow>
    </div>
  );
};

export default ParamsBuilderPressure;
