import { isNil, isUndefined } from "lodash-es";
import { GeneralSystemInfo } from "../models/ISystemInfo";
import { VMRecordingParameters } from "../models/ViewModelRecordingParameters/VMRecordingParameters";
import { Optional } from "../utils/utilTypes";
import {
  recordingParameters2ViewModel,
  vmRecordingParameters2DataModel
} from "./dataModelHelper";
import { dateToUnix } from "./dateHelper";
import { interpretSystemInfoHasFeatures } from "./parsers/parseSystemInfoHelper";

export interface RecordingParametersValidationResult {
  hasStartDate: boolean;
  hasEndDate: boolean;
  startDateHasNotPassed: boolean;
  endDateHasNotPassed: boolean;
  hasAccOrNotUsed: boolean;
  hasDvaOrNotUsed: boolean;
  hasTempOrNotUsed: boolean;
  hasRhOrNotUsed: boolean;
  hasAngleOrNotUsed: boolean;
  hasLteOrNotUsed: boolean;
  hasGpsOrNotUsed: boolean;
  hasExtIOOrNotUsed: boolean;

  /** If no parameters that measure some data is used */
  hasAtlestOneUsedParameter: boolean;
  hasProjectName: boolean;
}

const criticalRecordingParametersValidationFlags: (keyof RecordingParametersValidationResult)[] =
  ["hasStartDate", "hasEndDate", "endDateHasNotPassed"];

/**
 * Validate recording parameters before download.
 * @param parameters
 * @param targetDevice
 */
export const validateRecordingParamsBeforeDownload = (
  parameters: VMRecordingParameters,
  targetDevice: GeneralSystemInfo
): boolean => {
  // Converting to data model and back to view model will disable all channels that are not supported by the target device. The conversion to DataModel will also take place when the user clicks the "load" button and is where the channels are disabled.
  const autoClean = recordingParameters2ViewModel(
    vmRecordingParameters2DataModel(parameters, targetDevice)
  );

  // Validate the parameters
  const validationResult = validateRecordingParameters(autoClean, targetDevice);

  // Check the validation result
  if (isValidationObjAllValid(validationResult)) {
    return true;
  } else {
    // The auto clean does not fix incorrect times, which is probably the problem if we end up here. This can currently not be fixed automatically.
    return false;
  }
};

/**
 * Returns a recording parameters validation result, where every flag should be
 * true for the parameters to be valid
 * @param parameters
 * @param targetDevice
 */
export const validateRecordingParameters = (
  parameters: VMRecordingParameters,
  targetDevice: GeneralSystemInfo
): RecordingParametersValidationResult => {
  const {
    startRecordingType,
    endRecordingType,
    startTimestamp,
    stopTimestamp,
    stopAfterDuration
  } = parameters.RecParams;

  const deviceFeatures = interpretSystemInfoHasFeatures(
    targetDevice.hasFeatures
  );

  // Validate dates

  const hasStartDate =
    startRecordingType === "directly" ||
    startRecordingType === "button" ||
    (startRecordingType === "date" && !isUndefined(startTimestamp));

  // stop timestamp is calulated at later stage
  const hasEndDate = stopAfterDuration.days + stopAfterDuration.hours > 0;

  const startDateHasNotPassed =
    startRecordingType !== "date" ||
    (startRecordingType === "date" &&
      !isNil(startTimestamp) &&
      startTimestamp > dateToUnix(new Date()));

  const endDateHasNotPassed =
    startRecordingType === "directly" ||
    endRecordingType === "afterDuration" ||
    (endRecordingType === "date" &&
      !isNil(stopTimestamp) &&
      stopTimestamp > dateToUnix(new Date()));

  // Validate device features

  const hasAccOrNotUsed = hasFeatureOrNotUsed(
    parameters.AccParams.useAcc,
    deviceFeatures.DF_ACC
  );
  const hasDvaOrNotUsed = hasFeatureOrNotUsed(
    parameters.AccParams.useDva,
    deviceFeatures.DF_DVA
  );

  const hasTempOrNotUsed = hasFeatureOrNotUsed(
    parameters.TempParams.useTemp,
    deviceFeatures.DF_TEMP
  );
  const hasRhOrNotUsed = hasFeatureOrNotUsed(
    parameters.RhParams.useRh,
    deviceFeatures.DF_RH
  );

  const hasAngleOrNotUsed = hasFeatureOrNotUsed(
    parameters.AngleParams.useAngle,
    deviceFeatures.DF_ANGLE
  );

  const useLte = parameters.scheduleBlocks.some(
    (block) => block.LteParams.useLteInterval
  );
  const hasLteOrNotUsed = hasFeatureOrNotUsed(useLte, deviceFeatures.DF_LTE);

  const useGps = parameters.scheduleBlocks.some(
    (block) => block.GpsParams.useGpsInterval
  );
  const hasGpsOrNotUsed = hasFeatureOrNotUsed(useGps, deviceFeatures.DF_GPS);

  const useExtIO =
    parameters.ExternalInputParams.some((input) => input.used) ||
    parameters.ExternalOutputParams.some((output) => output.used);

  const hasExtIOOrNotUsed = hasFeatureOrNotUsed(
    useExtIO,
    deviceFeatures.DF_EXT_IO
  );

  const hasAtlestOneUsedParameter =
    parameters.AccParams.useAcc ||
    parameters.TempParams.useTemp ||
    parameters.RhParams.useRh ||
    parameters.AngleParams.useAngle ||
    useLte ||
    useGps ||
    useExtIO;

  // Validate project selection
  const hasProjectName =
    !isNil(parameters.ProjectName) && parameters.ProjectName !== "";

  return {
    hasStartDate,
    hasEndDate,
    startDateHasNotPassed,
    endDateHasNotPassed,
    hasAccOrNotUsed,
    hasDvaOrNotUsed,
    hasTempOrNotUsed,
    hasRhOrNotUsed,
    hasAngleOrNotUsed,
    hasLteOrNotUsed,
    hasGpsOrNotUsed,
    hasExtIOOrNotUsed,
    hasAtlestOneUsedParameter,
    hasProjectName
  };
};

/**
 * Returns true if the device has the feature that the user wants to use or the
 * user don't want to use the feature
 * @param useParameter If the paremer should be used
 * @param hasFeature If the device has the feature
 */
const hasFeatureOrNotUsed = (useParameter: boolean, hasFeature: boolean) =>
  !(useParameter && !hasFeature);

export const isCriticalRecordingParametersValid = (
  validationResult: Optional<RecordingParametersValidationResult>
) => {
  if (isNil(validationResult)) {
    return false;
  }

  return criticalRecordingParametersValidationFlags.every(
    (validationFlag) => validationResult[validationFlag] === true
  );
};

/**
 * Check if a supported validation object is fully valid
 * @param validationObj
 */
export const isValidationObjAllValid = (
  //todo: add validation type objects
  validationObj: Optional<RecordingParametersValidationResult>
) => {
  if (isNil(validationObj)) {
    return false;
  }

  return Object.values(validationObj).every((flagIsValid) => flagIsValid);
};
