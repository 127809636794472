import { Col, InputNumber, Row, Space, Switch } from "antd";
import { isNull, round } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import { maxTempValue, minTempValue } from "../../constants/params-defaults";
import {
  selectTemp,
  setTempHighAlarm,
  setTempIgnoreCount,
  setTempIntervalDays,
  setTempIntervalHours,
  setTempIntervalMinutes,
  setTempLowAlarm,
  toggleTemp,
  toggleTempStoreGps,
  toggleTempUseLte
} from "../../state/openParxSlice";
import { selectTemperatureScale } from "../../state/sessionSlice";
import {
  ParamsCardParagraph,
  ParamsCardSubHeader
} from "../Common/CommonFonts";
import {
  CommonParamsInputCard,
  CommonParamsMainContentRow,
  SendGsmAlarmCard,
  willFitWidth2,
  willFitWidth3
} from "../Common/CommonParametersComponents";
import {
  TempSymbol,
  TemperatureToRoundCelsius,
  TemperatureToScale
} from "../MicroComponents/TemperatureConverter";
import { size } from "../../helpers/pageHelper";

interface AvailableTempFeatures {
  hasGps: boolean;
  hasLte: boolean;
}

interface IProps {
  features: AvailableTempFeatures;
}

/** Rounds number to use no decimal */
const toInt = (n: number) => round(n, 0);

const ParamsBuilderTemp: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const tempParams = useSelector(selectTemp);
  const dispatch = useDispatch();
  const { width, ref } = useResizeDetector();
  const componentWidth = width ?? window.innerWidth;

  //the max interval for a period is exacly 28 days in ms. Therefore if 28 days
  //is set, hours and minututes can't be more than 0
  const maxHours = tempParams.intervalDays === 28 ? 0 : 23;
  const maxMinutes = tempParams.intervalDays === 28 ? 0 : 59;

  const mainToggle = tempParams.useTemp;
  const storeGps = tempParams.channelBits.GPS ? true : false && mainToggle;
  const useLte = tempParams.channelBits.LTE ? true : false && mainToggle;
  const ignoreCount = tempParams.IgnoreCountLTE;

  const tempScale = useSelector(selectTemperatureScale);

  /** Style used for input fields */
  const inputFieldStyle: React.CSSProperties = {
    margin: size.s2
  };

  // The values of the inputs rounded to full numbers
  const low = toInt(TemperatureToScale(tempParams.params.lowAlarm, tempScale));
  const high = toInt(
    TemperatureToScale(tempParams.params.highAlarm, tempScale)
  );

  const setLowAlarm = (val: number | null) => {
    if (isNull(val)) return;
    let celciusVal = TemperatureToRoundCelsius(val, tempScale);
    if (celciusVal < minTempValue) celciusVal = minTempValue;
    if (celciusVal > maxTempValue) celciusVal = maxTempValue;
    dispatch(setTempLowAlarm(celciusVal));
  };

  const setHighAlarm = (val: number | null) => {
    if (isNull(val)) return;
    let celciusVal = TemperatureToRoundCelsius(val, tempScale);
    if (celciusVal < minTempValue) celciusVal = minTempValue;
    if (celciusVal > maxTempValue) celciusVal = maxTempValue;
    dispatch(setTempHighAlarm(celciusVal));
  };

  /** If high alarm is lower than low alarm, set it to low*/
  const syncHighToLow = () => {
    if (tempParams.params.lowAlarm > tempParams.params.highAlarm) {
      dispatch(setTempHighAlarm(tempParams.params.lowAlarm));
    }
  };

  /** If low alarm is higher than high alarm, set it to high*/
  const syncLowToHigh = () => {
    if (tempParams.params.highAlarm < tempParams.params.lowAlarm) {
      dispatch(setTempLowAlarm(tempParams.params.highAlarm));
    }
  };

  // Render functions
  const renderMainToggle = () => (
    <Row align="middle" style={{ marginBottom: size.s2 }}>
      <Space>
        <Switch checked={mainToggle} onChange={() => dispatch(toggleTemp())} />
        <ParamsCardSubHeader>{t("cpTempMainToggleTitle")}</ParamsCardSubHeader>
      </Space>
    </Row>
  );

  const renderLogInterval = (withDescription: boolean) => (
    <CommonParamsInputCard
      title={t("cpTempLogIntervalTitle")}
      width={withDescription ? 2 : 1}
      height={withDescription ? 1 : 2}
    >
      <Row justify="space-between">
        <Col>
          <ParamsCardParagraph>
            {t("cpTempLogIntervalContent")}
          </ParamsCardParagraph>
        </Col>
        <div style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genDays")}</ParamsCardSubHeader>
            </Col>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genHours")}</ParamsCardSubHeader>
            </Col>
            <Col span={8}>
              <ParamsCardSubHeader>{t("genMinutes")}</ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input-fields */}
          <Row>
            <Col span={8}>
              <InputNumber
                disabled={!mainToggle}
                value={tempParams.intervalDays}
                onChange={(val) =>
                  isNull(val) ? {} : dispatch(setTempIntervalDays(Number(val)))
                }
                min={0}
                max={28}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                disabled={!mainToggle}
                value={tempParams.intervalHours}
                onChange={(val) =>
                  isNull(val) ? {} : dispatch(setTempIntervalHours(Number(val)))
                }
                min={0}
                max={maxHours}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={8}>
              <InputNumber
                disabled={!mainToggle}
                value={tempParams.intervalMinutes}
                onChange={(val) =>
                  isNull(val)
                    ? {}
                    : dispatch(setTempIntervalMinutes(Number(val)))
                }
                min={0}
                max={maxMinutes}
                style={{ ...inputFieldStyle, marginRight: 0 }}
              />
            </Col>
          </Row>
        </div>
      </Row>
    </CommonParamsInputCard>
  );

  const renderStoreGps = () => (
    <CommonParamsInputCard
      title={t("cpTempStoreGpsPosTitle")}
      disabled={!props.features.hasGps}
      width={1}
      height={1}
      useToggle
      toggleChecked={storeGps && props.features.hasGps}
      toggleDisabled={!mainToggle}
      toggleChangeAction={() => dispatch(toggleTempStoreGps())}
    >
      <ParamsCardParagraph disabled={!props.features.hasGps}>
        {t("cpTempStoreGpsPosContent")}
      </ParamsCardParagraph>
    </CommonParamsInputCard>
  );

  const renderAlarmLevel = (withDescription: boolean) => (
    <CommonParamsInputCard
      title={t("cpTempAlarmLevelTitle")}
      width={withDescription ? 2 : 1}
      height={2}
    >
      <Row justify="space-between" wrap>
        <Col flex="auto">
          <ParamsCardParagraph>
            {t("cpTempAlarmLevelContent")}
          </ParamsCardParagraph>
        </Col>
        <Col style={{ textAlign: "center" }}>
          {/* titles */}
          <Row>
            <Col span={12}>
              <ParamsCardSubHeader>
                Min (<TempSymbol />)
              </ParamsCardSubHeader>
            </Col>
            <Col span={12}>
              <ParamsCardSubHeader>
                Max (<TempSymbol />)
              </ParamsCardSubHeader>
            </Col>
          </Row>
          {/* input fields */}
          <Row>
            <Col span={12}>
              <InputNumber
                disabled={!mainToggle}
                value={low}
                onChange={(val) => setLowAlarm(val)}
                onBlur={() => syncHighToLow()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
            <Col span={12}>
              <InputNumber
                disabled={!mainToggle}
                value={high}
                onChange={(val) => setHighAlarm(val)}
                onBlur={() => syncLowToHigh()}
                precision={0}
                style={inputFieldStyle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </CommonParamsInputCard>
  );

  const renderGsmAlarm = () => (
    <SendGsmAlarmCard
      hasLte={props.features.hasLte}
      sendGsmTextContent={t("cpTempSendGsmAlarmContent")}
      isParentParamsActive={mainToggle}
      useLte={useLte}
      toggleUseLte={() => dispatch(toggleTempUseLte())}
      ignoreCount={ignoreCount}
      setIgnoreCount={(v: number) => dispatch(setTempIgnoreCount(v))}
    />
  );

  return (
    <div ref={ref}>
      {renderMainToggle()}
      <CommonParamsMainContentRow>
        {/* logging interval content */}
        <Col order={1}>{renderLogInterval(!willFitWidth2(componentWidth))}</Col>
        {/* store gps position content. Order explanation: if small screen size, put element below "alarm" */}
        <Col order={willFitWidth3(componentWidth) ? 3 : 2}>
          {renderStoreGps()}
        </Col>
        {/* alarm level content */}
        <Col order={willFitWidth3(componentWidth) ? 2 : 3}>
          {renderAlarmLevel(!willFitWidth2(componentWidth))}
        </Col>
        {/* send gsm alarm content */}
        <Col order={4}>{renderGsmAlarm()}</Col>
      </CommonParamsMainContentRow>
    </div>
  );
};

export default ParamsBuilderTemp;
